import { SegmentId } from '@types';

/**
 * Profile interfaces
 */
export interface InPersonalInfo {
  pan?: string;
  kycStatus?: string;
  phoneNo?: string;
  dob?: string;
  mobile?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  country?: string;
  nationality?: string;
  socialStatus?: string;
  aadharNo?: string;
  doi?: string;
  pinCode?: string;
  fatherName?: string;
  fatherFirstName?: string;
  fatherMiddleName?: string;
  fatherLastName?: string;
  maidenFirstName?: string;
  maidenMiddleName?: string;
  maidenLastName?: string;
  motherFirstName?: string;
  motherMiddleName?: string;
  motherLastName?: string;
  spouseName?: string;
  whatsAppOptFlag?: string;
  frkAccessToken?: string;
  smsOptflag?: string;
  date?: string;
  time?: string;
  aadhaarStatus?: string;
  passwordExpired?: string;
  kycStatusDesc?: string;
  kycMode?: string;
  kraKycStatus?: string;
  kycId?: string;
  kycSource?: string;
  gender?: string;
  email?: string;
  userFirstName?: string;
  lastLogin?: string;
  // distributor details
  userLastName?: string;
  userId?: string;
  bankDetails?: string;
  image?: string;
  isRIA?: string;
  officeAddress?: string;
  middleName?: string;
  showAppReview?: string;
  campaignAvailable?: string;
  lmsAvailable?: string;
  isTrxnMenuAvailable?: string;
  ARN?: string;
  ARNRegdate?: string;
  KYDStatus?: string;
  PAN?: string;
}

export interface MaintenanceSession {
  maintainanceFlag?: string;
  maintainanceETA?: string;
  azureFlag?: string;
  disableDownload?: string;
  maintenanceFor?: string;
  maintenanceWebContent?: string;
  maintenanceWebCTA?: string;
}

export interface IUserProfileInfo {
  accountsAccess?: string;
  addressLine1?: string;
  businessKey?: string;
  challengeQuestions?: boolean;
  city?: string;
  dashboardUrl?: string;
  dealerNo?: string;
  displayName?: string;
  email?: string;
  firm?: string;
  hypotool?: string;
  loginName?: string;
  newApplnStatus?: string;
  phoneNumber?: string;
  rememberMe?: boolean;
  remindUpgrade?: boolean;
  role?: string; // should this be an enum?
  ssotool?: string;
  state?: string;
  userId?: string;
  userSysNo?: string;
  webExperience?: string;
  zip?: string;
  userGroup?: string;
  ostId?: string;
  actor?: string;
  pcsAcceptFlag?: string;
  // myfunds profile attrs
  uid?: string;
}

export enum LoginSource {
  BYPASS = 'BYPASS',
  SEGMENT_SWITCH = 'SWITCH',
  DEBUG = 'DEBUG',
  OAUTH = 'OAUTH',
  NEW = 'NEW',
  STORAGE = 'STORAGE', // the least reliable
}

export enum IsLoginUser {
  LOGGEDIN = 'logged in',
  LOGGEDOUT = 'logged out',
}

export interface IUserProfile {
  name: string;
  profileInfo: IUserProfileInfo;
  isLoggedIn: boolean;
  // isIdentified: boolean;
  loginSource?: LoginSource;
  bypassRoles?: (BypassRole | UserRole)[];
  firmRoles?: FirmRole[];
  defaultForSegment?: SegmentId;

  // newAuthSession: boolean;
}

export interface ISegmentCharacteristics {
  isLoggedIn: 'true' | 'false';
  dashboardAccess: 'true' | 'false';
  // isIdentified: string;
  segment: SegmentId;
  subsegment: string;
  firm?: string;
}

// this represents profile data that is stored in LocalStorage
// WARNING! Do not store Personally Identifiable Information (PII) here
export interface ProfileSummary {
  isLoggedIn?: boolean; // stored in SessionStorage
  role: string;
  webExperience: string;
  firm: string;
  source: LoginSource;
  accountsAccess: string;
  dashboardUrl: string;
  pcsAcceptFlag?: string;
}

export enum BypassRole {
  DC = 'internal-dc',
  FA = 'internal-advisor',
  FP = 'internal-professionals',
  INV = 'internal-investor',
  RIA = 'internal-ria',
  SH = 'internal-shareholder',
  GK = 'gatekeeper',
}

export enum FirmRole {
  EJ = 'partner-edward-jones',
  RJ = 'partner-raymond-james',
  ML = 'partner-merrill-lynch',
  PM = 'partner-primerica',
  LPL = 'partner-LPL',
  MS = 'partner-morgan-stanley',
}

export enum UserRole {
  // Order is important to keep backward functionality
  INV = 'investor',
  SH = 'sh',
  FP = 'fp',
  DC = 'dc',
  GK = 'gk',
  FA = 'financial-professionals',
  INST = 'institutional',
  DISTRIBUTOR = 'distributor',
  RIA = 'ria',
}

export interface DashboardConfig {
  firm: string;
  dashBoardUrl: string;
}
