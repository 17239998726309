import { Component, Input, OnInit, } from '@angular/core';
import { Logger } from '@utils/logger';
import { Component as BrComponent, Content, Document, Page } from '@bloomreach/spa-sdk';

const logger = Logger.getLogger('MediaArticlesComponent');

export interface Tabs {
  type: string;
  text: string;
}

@Component({
  selector: 'ft-media-articles',
  templateUrl: './media-articles.component.html',
  styleUrls: ['./media-articles.component.scss'],
})
export class MediaArticlesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  public isLoaded = false;
  public articleBRConfig: any;
  public mediaContacts: any;
  configData: any;
  tab1Title: string;
  tab2Title: string;

  constructor() {
  }


  ngOnInit(): void {
    // Pull BR component data
    const categoryData: Content = this.document;
    this.configData = categoryData; // passing to the Children components
    this.tab1Title = categoryData?.getData()?.mediaArticleTabs?.[0]?.tabTitle;
    this.tab2Title = categoryData?.getData()?.mediaArticleTabs?.[1]?.tabTitle;

    this.articleBRConfig = categoryData?.getData();
    // Set content
    this.mediaContacts = this.articleBRConfig?.mediaArticlesTextBlock?.map(
      (content) => ({
        heading: content.mediaTextBlockTitle,
        markup: content.mediaTextBlockContent.content.value,
      })
    )[0];
  }

  get document() {
    const {document} = this.component?.getModels() || {};
    return document && this.page?.getContent<Document>(document);
  }

  scrollTo(param: any) {
    const elementToScroll = document.getElementById(param);
    if (elementToScroll) {
      // Scroll to the element with a delay of 10 milliseconds (or any desired delay)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
}
