import { Injectable, OnDestroy } from '@angular/core';
import { DebugService as DebugInterface } from '../types/debug-service.interface';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { Logger, LogLevel } from '@utils/logger';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class DebugService implements DebugInterface, OnDestroy {
  private _debugOverride = false;
  private showLabelKeys$ = new BehaviorSubject<boolean>(false);
  private caveatDebug$ = new BehaviorSubject<boolean>(false);
  private pdsEnv$ = new BehaviorSubject<string>('');
  private portfolioDebug$ = new BehaviorSubject<boolean>(false);
  private showDummyCaveats$ = new BehaviorSubject<boolean>(false);
  private showTooltipKeys$ = new BehaviorSubject<boolean>(false);
  private showInsightsData$ = new BehaviorSubject<boolean>(false);
  private showInsightsReport$ = new BehaviorSubject<boolean>(false);
  private expandAllComponents$ = new ReplaySubject<boolean>(1);

  private expandAll = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private cookieService: CookieService
  ) {
    this.expandAllComponents$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((expandAll: boolean) => {
        this.expandAll = expandAll;
      });

    // check if smarsh cookie exists
    const cookieExists: boolean = this.cookieService.check(
      'expandAllComponents'
    );
    if (cookieExists) {
      this.expandAllComponents$.next(true);
    }

    this.loadFromStorage().then(() => {
      combineLatest([
        this.pdsEnv$,
        this.caveatDebug$,
        this.showLabelKeys$,
        this.portfolioDebug$,
        this.showDummyCaveats$,
        this.showTooltipKeys$,
        this.showInsightsData$,
        this.showInsightsReport$,
        this.expandAllComponents$,
      ])
        .pipe(debounceTime(100))
        .subscribe((vals: any) => {
          this.storeState(vals);
        });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private storeState(
    vals: [
      string,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean
    ]
  ) {
    if (this._debugOverride) {
      this.storageService.store(
        'debugOptions',
        {
          debugOverride: this._debugOverride,
          pdsEnv: vals[0],
          caveatDebug: vals[1],
          showLabelKeys: vals[2],
          portfolioDebug: vals[3],
          showDummyCaveats: vals[4],
          showTooltipKeys: vals[5],
          showInsightsData: vals[6],
          showInsightsReport: vals[7],
          expandAllComponents: vals[8],
        },
        true
      );
    }
  }

  getFootnoteDebug$(): Observable<boolean> {
    return this.caveatDebug$.asObservable();
  }
  getProximalDebug$(): Observable<boolean> {
    return this.caveatDebug$.asObservable();
  }
  isCaveatDebug$(): Observable<boolean> {
    return this.caveatDebug$.asObservable();
  }
  toggleCaveatDebug(): void {
    this.caveatDebug$.next(!this.caveatDebug$.getValue());
  }
  isShowDummyCaveats$(): Observable<boolean> {
    return this.showDummyCaveats$.asObservable();
  }
  toggleShowDummyCaveats(): void {
    this.showDummyCaveats$.next(!this.showDummyCaveats$.getValue());
  }
  isShowTooltipKeys$(): Observable<boolean> {
    return this.showTooltipKeys$.asObservable();
  }
  toggleShowTooltipKeys(): void {
    this.showTooltipKeys$.next(!this.showTooltipKeys$.getValue());
  }

  getPdsEnv$(): Observable<string> {
    return this.pdsEnv$.asObservable();
  }
  getPdsEnv(): string {
    return this.pdsEnv$.getValue();
  }
  setPdsEnv(env: string) {
    this.pdsEnv$.next(env);
  }

  isShowLabelKeys$(): Observable<boolean> {
    return this.showLabelKeys$.asObservable();
  }
  toggleShowLabelKeys(): void {
    this.showLabelKeys$.next(!this.showLabelKeys$.getValue());
  }

  isShowInsightsData$(): Observable<boolean> {
    return this.showInsightsData$.asObservable();
  }
  toggleShowInsightsData$(): void {
    this.showInsightsData$.next(!this.showInsightsData$.getValue());
  }

  isShowInsightsReport$(): Observable<boolean> {
    return this.showInsightsReport$.asObservable();
  }
  toggleShowInsightsReport$(): void {
    this.showInsightsReport$.next(!this.showInsightsReport$.getValue());
  }
  // turns on debugging for portfolio components
  isPortfolioDebug$(): Observable<boolean> {
    return this.portfolioDebug$.asObservable();
  }
  togglePortfolioDebug(): void {
    this.portfolioDebug$.next(!this.portfolioDebug$.getValue());
  }
  getExpandAllComponents$(): Observable<boolean> {
    return this.expandAllComponents$.asObservable();
  }
  toggleExpandAll(): void {
    this.expandAllComponents$.next(!this.expandAll);
  }

  clearAll(): void {
    this.storageService.remove('debugOptions', true);
    this._debugOverride = false;
    this.loadState({});
  }

  private loadFromStorage(): Promise<boolean> {
    return this.storageService.retrieve('debugOptions', true).then((vals) => {
      if (vals) {
        this.loadState(vals);
      }
      return true;
    });
  }

  private loadState(vals: any): void {
    this._debugOverride = vals.debugOverride;
    if (this._debugOverride) {
      Logger.addConfiguration({
        defaultLevel: LogLevel.DEBUG,
      });
    }
    this.caveatDebug$.next(vals.caveatDebug === true);
    this.pdsEnv$.next(vals.pdsEnv || '');
    this.showLabelKeys$.next(vals.showLabelKeys === true);
    this.portfolioDebug$.next(vals.portfolioDebug === true);
    this.showDummyCaveats$.next(vals.showDummyCaveats === true);
    this.showTooltipKeys$.next(vals.showTooltipKeys === true);
    this.showInsightsData$.next(vals.showInsightsData === true);
    this.showInsightsReport$.next(vals.showInsightsReport === true);
    this.expandAllComponents$.next(vals.expandAllComponents === true);
  }

  get debugOverride() {
    return this._debugOverride;
  }

  set debugOverride(flag: boolean) {
    this._debugOverride = flag;
    if (this._debugOverride) {
      this.storeState([
        '',
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
      Logger.addConfiguration({
        defaultLevel: LogLevel.DEBUG,
      });
    }
  }
}
