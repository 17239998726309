<section class="FT-footer-pages media-page FT-knowledge" [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <div class="container">
    <div class="FT-title-medium text-bold text-blue">{{ "products.media-title" | translate }}</div>
    <div class="media-contact">
      <h3 class="d-none d-md-block">{{ mediaContacts?.heading }}</h3>
      <div [innerHTML]="mediaContacts?.markup"></div>
    </div>
  </div>

  <div class="container release-container">
    <div class="FT-blocks-nav sticky-top">
      <ul class="mb-0 ps-0">
        <li><a class="articles active-item" style="cursor:pointer" (click)="scrollTo('articles')">{{ tab1Title }}</a>
        </li>
        <li><a class="releases" style="cursor:pointer" (click)="scrollTo('releases')">{{ tab2Title }}</a></li>
      </ul>
    </div>
    <ft-articles [config]="configData"></ft-articles>
    <ft-media-releases [config]="configData"></ft-media-releases>
  </div>
</section>
