<!-- start section calculator -->
<section class="FT-calculator-box mb-6 mt-2">
  <div class="container">
    <h1 class="FT-title-medium text-blue mb-2 mt-2">{{ "ftiCalc.calculator-cagr-title" | translate }}</h1>
    <div>
      {{ "ftiCalc.calculator-cagr-description" | translate }}
    </div>
    <div class="FT-financial">
      <!-- Tab panes -->
      <div class="tab-content calculator-box">
        <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab"
             tabindex="0">
          <div class="tab-content">
            <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
                 tabindex="0">
              <div class="row align-items-center">
                <!-- Section: Form -->
                <div class="col-lg-5 col-12">
                  <form [formGroup]="cagrCalcForm" *ngIf="cagrCalcForm">
                    <!-- Amount Source Fund -->
                    <div class="form-group">
                      <label class="form-label">
                        {{ "ftiCalc.calculator-invest-text1" | translate }}
                        <div class="selected-range">
                          <span class="calc-input-prefix">{{
                                  "ftiCalc.calculator-indian-rupee-symbol" | translate
                            }}</span>
                          <input type="number"
                                 [attr.min]="getInputLimit(sliderKeyName.initAmount, false)"
                                 [attr.max]="getInputLimit(sliderKeyName.initAmount, true)"
                                 [(ngModel)]="cagrCalcForm.value.initAmount"
                                 formControlName="initAmount"
                                 (change)="onCalculatorChange($event, 'initAmount')"
                                 class="calc-input amount text-start">
                        </div>
                      </label>
                      <div class="range-slider">
                        <input type="range"
                               [attr.min]="getInputLimit(sliderKeyName.initAmount, false)"
                               [attr.max]="getInputLimit(sliderKeyName.initAmount, true)"
                               [value]="cagrCalcForm.value.initAmount || getInputLimit(sliderKeyName.initAmount)"
                               formControlName="initAmount"
                               (change)="onCalculatorChange($event, 'initAmount')"
                               class="custom-range" [style.background]="initAmountSliderStyle">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                                "ftiCalc.calculator-10k" | translate
                          }}</span>
                        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                                "ftiCalc.calculator-10Cr" | translate
                          }}</span>
                      </div>
                    </div>
                    <!-- Final Investment -->
                    <div class="form-group ">
                      <label class="form-label">{{
                              "ftiCalc.calculator-cagr-resultant-text" | translate
                        }}
                        <div class="selected-range">
                          <span class="calc-input-prefix">{{
                                  "ftiCalc.calculator-indian-rupee-symbol" | translate
                            }}</span>
                          <input type="number" style="width: 100%;"
                                 [attr.min]="getInputLimit(sliderKeyName.resultantAmt, false)"
                                 [attr.max]="getInputLimit(sliderKeyName.resultantAmt, true)"
                                 [(ngModel)]="cagrCalcForm.value.resultantAmt"
                                 formControlName="resultantAmt"
                                 (change)="onCalculatorChange($event, 'resultantAmt')"
                                 class="calc-input amount text-start">
                        </div>
                      </label>
                      <div class="range-slider">
                        <input type="range"
                               [attr.min]="getInputLimit(sliderKeyName.resultantAmt, false)"
                               [attr.max]="getInputLimit(sliderKeyName.resultantAmt, true)"
                               [value]="cagrCalcForm.value.resultantAmt || getInputLimit(sliderKeyName.resultantAmt)"
                               formControlName="resultantAmt"
                               (change)="onCalculatorChange($event, 'resultantAmt')"
                               class="custom-range" [style.background]="resultantAmtSliderStyle">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                                "ftiCalc.calculator-10k" | translate
                          }}</span>
                        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                                "ftiCalc.calculator-10Cr" | translate
                          }}</span>
                      </div>
                    </div>
                    <!-- Period -->
                    <div class="form-group ">
                      <label for="period" class="form-label">
                        {{ "ftiCalc.calculator-year" | translate }}
                        <div class="selected-range" (click)="autoFocus($event, 'period')">
                          <input type="number"
                                 [attr.min]="getInputLimit(sliderKeyName.period, false)"
                                 [attr.max]="getInputLimit(sliderKeyName.period, true)"
                                 formControlName="period" [(ngModel)]="cagrCalcForm.value.period"
                                 (change)="onCalculatorChange($event, 'period')"
                                 class="calc-input text-end forchangecustomcalculator"
                                 id="period">
                          <span class="calc-input-prefix">
                            {{ "ftiCalc.calculator-years" | translate }}</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input type="range" formControlName="period"
                               [value]="cagrCalcForm.value.period || getInputLimit(sliderKeyName.period)"
                               (change)="onCalculatorChange($event, 'period')" class="custom-range"
                               [attr.min]="getInputLimit(sliderKeyName.period, false)"
                               [attr.max]="getInputLimit(sliderKeyName.period, true)"
                               [style.background]="periodSliderStyle">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.period, false) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                        <span>{{ getInputLimit(sliderKeyName.period, true) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                      </div>
                    </div>
                  </form>
                  <div class="mb-2">
                    <a class="login-dark2" href="" data-bs-toggle="modal" data-bs-target="#exampleModal">{{
                            "ftiCalc.calculator-sip-disclaimer" | translate
                      }}</a>
                  </div>
                </div>

                <!-- Right side Table & graph -->
                <div class="col-lg-7 col-12 ps-lg-4">
                  <div class="calculate-statics">
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-4 pb-md-2">
                      <div class="graph-table-btns mb-lg-0 mb-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="btn active" id="Graph-tab" data-bs-toggle="tab"
                                    data-bs-target="#Graph-tab-pane" type="button" role="tab"
                                    aria-controls="Graph-tab-pane" aria-selected="true"
                                    (click)="setIsGraphView(true)">{{ "ftiCalc.calculator-graph"| translate }}
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="btn" id="Table-tab" data-bs-toggle="tab"
                                    data-bs-target="#Table-tab-pane" type="button" role="tab"
                                    aria-controls="Table-tab-pane" aria-selected="false"
                                    (click)="setIsGraphView(false)">{{ "ftiCalc.calculator-table" | translate }}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="text-small text-bold" *ngIf="isGraphView">
                        <div class="d-none d-md-block">
                          <span class="me-3">
                            <span class="circle" style="background:#A669CA"></span>
                            {{ "ftiCalc.calculator-invest-amount" | translate }}
                          </span>
                          <span>
                            <span class="circle" style="background:#00847D"></span>
                            {{ "ftiCalc.calculator-invest-value" | translate }}
                          </span>
                        </div>

                        <div class="d-md-none row text-center">
                          <div class="col-6">
                            <span class="me-3">
                              <span class="circle" style="background:#A669CA"></span>
                              {{ "ftiCalc.calculator-invest-amount" | translate }}
                            </span>
                          </div>
                          <div class="col-6">
                            <span>
                              <span class="circle" style="background:#00847D"></span>
                              {{ "ftiCalc.calculator-cagr-gains" | translate }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="Graph-tab-pane" role="tabpanel"
                           aria-labelledby="Graph-tab" tabindex="0">
                        <div class="calculator-graph-box">
                          <div class="graph-container">
                            <div class="graph-container">
                              <div id="highcharts-container-cagr"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Table-tab-pane" role="tabpanel"
                           aria-labelledby="Table-tab" tabindex="0">
                        <div class="table-responsive text-center">
                          <table class="table table-success table-striped stiky-head">
                            <thead>
                            <tr>
                              <th>{{ "ftiCalc.calculator-end-of-year" | translate }}</th>
                              <th>{{ "ftiCalc.calculator-amount-invested" | translate }}
                              </th>
                              <th>{{ "ftiCalc.calculator-invest-value" | translate }}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let val of calculatedData.eachYearData">
                              <td>{{ val.year }}</td>
                              <td>{{ val.initAmount | INRCurrency }}
                              </td>
                              <td>{{ val.finalInvestment | INRCurrency }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="p-3 bg-light-blue">
                      <div
                          class="numbers d-flex justify-content-center text-center mb-4 bg-light-blue">
                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-cagr" | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds">
                              {{ calculatedData.cagr }}{{ "ftiCalc.calculator-percentage-symbol" | translate }}
                            </h3>
                          </ng-container>
                        </div>
                      </div>
                      <button (click)="investNow('')" class="btn btn-primary recommende-btn">
                        {{ "ftiCalc.calculator-invest-now" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-end">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          {{ "ftiCalc.sip-calc-disclaimer-pop-up" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
