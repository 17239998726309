<section>
  <div class="knowledge-box categories" id="learning" [class]="kcLanding ?'fd-tab-sections':'open-learning-main'">
    <div class="FT-ad-box" *ngIf="kcLanding">
      <div class="row align-items-center justify-content-between">
        <div class="col-md col-12">
          <div class="FT-title-medium text-bold mb-2">{{ "ftiKc.open-lear-tab-text1" | translate }}</div>
          <div class="text-big mb-lg-4 mb-3">{{ "ftiKc.open-lear-banner-desc" | translate }}</div>
          <ul>
            <li><strong>{{ "ftiKc.50-lacs" | translate }}</strong>{{ "ftiKc.open-lear-banner-text1" | translate }}</li>
            <li><strong>{{ "ftiKc.30" | translate }}</strong>{{ "ftiKc.open-lear-banner-text2" | translate }}</li>
            <li><strong>{{ "ftiKc.30" | translate }}</strong>{{ "ftiKc.open-lear-banner-text3" | translate }}</li>
          </ul>
        </div>
        <div class="col-md-auto col-12 mt-4 mt-md-0">
          <a [routerLink]="startLearningURL" class="btn btn-primary">{{ "ftiKc.start-learning" | translate }}</a>
        </div>
      </div>
    </div>
    <ft-category [component]="component" [page]="page" [contentType]="contentType"></ft-category>
  </div>
</section>
