import { Component, OnInit, Input } from '@angular/core';
import {
  Component as BrComponent,
  Page,
} from "@bloomreach/spa-sdk";
import { AnalyticsService } from '@services/analytics.service';
import { InvestNowService } from "@services/invest-now.service";

@Component({
  selector: 'ft-goals-calculator',
  templateUrl: './goals-calculator.component.html',
  styleUrls: ['./goals-calculator.component.scss']
})
export class GoalsCalculatorComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;
  formValue: any
  isSIP = true
  isTargeted = false
  radioValue: any;

  constructor(
    private analyticsService: AnalyticsService,
    private investNowService: InvestNowService,
  ) {
  }

  ngOnInit(): void {
  }

  toggleIsSIP = (value: boolean) => {
    this.isSIP = value
  }

  toggleIsisTargeted = (value: boolean, data: string) => {
    if (data === 'Investment amount') {
      this.isTargeted = false;
      this.radioValue = 'invest'
    }
    if (data === 'Target amount') {
      this.isTargeted = true;
      this.radioValue = 'amount'
    }
    this.setAnalytics(false, true);
  }

  /**
   * Sets Analytics for SIP calculator page
   * @param radioBtnSelected radio button selected
   */
  setAnalytics(isSas?: boolean, GA?: boolean) {
    const invest = 'invest';
    const wantToInvest = 'I Want to Invest';
    const knowMyGoal = 'I Know MY Goal';
    let event =
      this.radioValue === invest ? 'Event wenttoinvest' : 'Event knowmygoal';
    if (isSas) {
      event =
        this.radioValue === invest
          ? 'Event  saswanttoinvest'
          : 'Event sasknowmygoal';
    }
    const label = this.radioValue === invest ? wantToInvest : knowMyGoal;
    this.analyticsService.trackEvent({
      event,
      category: 'SIP-Calculator',
      action: isSas ? 'Start A SIP' : 'Select Option',
      label,
    });

    this.analyticsService.trackEvent({
      event: "Home Page",
      ft_event: this.isSIP ? 'ft_StartSIP' : 'ft_InvestNow_Clicked',
      category: "Homepage",
      action: this.isSIP ? 'SIPCalculator' : 'Lumpsum',
      label: this.isSIP ? 'StartSIP' : 'InvestNow',
    });

    if (!GA) {
      this.investNowService.investNow('');
    }
  }

}
