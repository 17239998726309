<div class="container">
  <section class="conatiner checkGoToCheckout mt-5 mb-4">
    <div class="FT-Watchlist-Content-Color-Checkout">
      <div class="d-flex forCheckoutView">
        <div class="checkAdded">
          {{ title }}
        </div>
        <ng-container *ngFor="let content of linkLists">
          <div style="margin: auto;">
            <button type="button" [routerLink]="[content?.linkCompound?.url]"
                    class="btn btn-outline-primary bg-white checkOutTitle">
              <span class="addNow">{{ content?.linkCompound?.title }}</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</div>
