<div class="ngxSlick">
  <ng-container *ngIf="contentType === 'About-History'">
    <ft-who-we-are-slick-slide [component]="component" [page]="page"></ft-who-we-are-slick-slide>
  </ng-container>
  <ng-container *ngIf="contentType === 'About-CSR'">
    <ft-csr-initiatives [component]="component" [page]="page"></ft-csr-initiatives>
  </ng-container>
  <ng-container *ngIf="contentType === 'Testimonials'">
    <ft-testimonials [component]="component" [page]="page"></ft-testimonials>
  </ng-container>
  <ng-container *ngIf="contentType === 'Related-Articles'">
    <ft-related-article [component]="component" [page]="page"></ft-related-article>
  </ng-container>

</div>
