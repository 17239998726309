import { Injectable } from "@angular/core";
import { Logger } from "@utils/logger";
import { BehaviorSubject, Observable } from "rxjs";

const logger = Logger.getLogger("MediaService");

@Injectable({
  providedIn: "root",
})
export class KnowledgeCenterService {
  private watchlistKCArticles: any[] = [];
  private watchlistKCSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  watchlistKC$: Observable<any> = this.watchlistKCSubject.asObservable();

  constructor() {}

  addToWatchList(documentId: string): void {
    this.watchlistKCArticles = this.getWatchList();
    if (!this.watchlistKCArticles.includes(documentId)) {
      this.watchlistKCArticles.push(documentId);
      this.updateLocalStorage();
    }
  }

  removeFromWatchList(documentId: any): void {
    this.watchlistKCArticles = this.getWatchList();
    let index: number = -1;
    if(documentId.pageTitle){
      for(let document of this.watchlistKCArticles){
        if(document.pageTitle === documentId.pageTitle){
          index = this.watchlistKCArticles.indexOf(document);
        }
      }
    } else {
      for(let document of this.watchlistKCArticles){
        if(document.pageTitle === documentId.title){
          index = this.watchlistKCArticles.indexOf(document);
        }
      }
    }
    
    if (index !== -1) {
      this.watchlistKCArticles.splice(index, 1);
      this.updateLocalStorage();
    }
  }

  // Other methods

  getWatchList(): any[] {
    let watchListString;
    watchListString = localStorage.getItem("kc-watch-list");
    return watchListString ? JSON.parse(watchListString) : [];
  }

  private updateLocalStorage(): void {
      localStorage.setItem(
        "kc-watch-list",
        JSON.stringify(this.watchlistKCArticles)
      );
    this.watchlistKCSubject.next(this.watchlistKCArticles);
  }
}