import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from "@shared/translate/translate.service";
import { EnvConfigService } from "@services/env-config.service";

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private data = new BehaviorSubject<any>(null);

  private city = new BehaviorSubject<any>(null);
  cityData = this.city.asObservable();
  labels: any;

  @Output() send_fund_data = new EventEmitter<any>();
  @Output() send_added_fund = new EventEmitter<any>();
  @Output() mobileAddFund = new EventEmitter<any>();
  @Output() is_watchList_fund_added = new EventEmitter<any>();
  @Output() updateWatchList = new EventEmitter<any>();

  constructor(
    private envConfig: EnvConfigService,
    private translateService: TranslateService
  ) {
  }

  changeData(message: string) {
    this.data.next(message);
  }

  setCities(cities: string) {
    this.city.next(cities);
  }

  setLabels(models) {
    this.labels = models;
  }

  getLabels() {
    return this.labels;
  }

  isValidMobile(mobile) {
    return mobile.length === 10 || mobile.length === 11 || mobile.length === 12;
  }

  isValidEmail(email: string) {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
      email
    );
  }

  getBaseUrl(): string {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.substring(0, currentUrl.indexOf('/', 8)); // Assuming https:// prefix
    return baseUrl;
  }

  shareOn(app: string, fundLink: String): void {
    const baseUrl = this.getBaseUrl();
    let ShareUrl = '';
    switch (app) {
      case "TWITTER": {
        ShareUrl = `https://twitter.com/share?url=${encodeURIComponent(
          `${baseUrl}${fundLink}`
        )}`;
        break;
      }
      case "WHATAPP": {
        ShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Check out this fund: ${baseUrl}${fundLink}`
        )}`;
        break;
      }
      case "FACEBOOK": {
        ShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          `${baseUrl}${fundLink}`
        )}`;
        break;
      }
      case "LINKEDIN": {
        ShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          `${baseUrl}${fundLink}`
        )}`;
        break;
      }
    }
    window.open(ShareUrl, '_blank');
  }

  getRisk(productLabel?: string) {
    let fundRiskStatus = this.riskFromFundDoc(productLabel);
    let fundRiskName: string;
    if (fundRiskStatus) {
      fundRiskName = this.translateService.instant("products.fund-header-risk").replace("RISK_VALUE-FD", fundRiskStatus);
    }
    return fundRiskName;
  }

  riskFromFundDoc(html: string): string {
    const start = html.indexOf("riskometer-img");
    let riskName: string;
    if (start > -1) {
      const riskStart =
        start + html.substring(start).indexOf('<div class="riskometer-img">');
      const riskEnd =
        start + html.substring(start).indexOf('<div class="text-meduim">');
      let riskType = html.substring(riskStart, riskEnd);
      if (riskType.includes("RISK_LOW")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-low"
        );
      } else if (riskType.includes("RISK_LOW_MODERATE")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-low-moderate"
        );
      } else if (riskType.includes("RISK_MODERATE")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-moderate"
        );
      } else if (riskType.includes("RISK_MODERATELY_HIGH")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-moderately-high"
        );
      } else if (riskType.includes("RISK_HIGH")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-high"
        );
      } else if (riskType.includes("RISK_VERY_HIGH")) {
        riskName = this.translateService.instant(
          "products.fund-header-risk-very-high"
        );
      }
      return riskName;
    }
    return riskName;
  }

  cartdetails: any[] = [];
  private dataChangeSubject = new Subject<any>();

  setInvestmentData(newData: any) {
    this.data = newData;
    this.dataChangeSubject.next(this.data);
  }

  isHome(page: string) {
    let isHome = false;
    if (page === '/?segment=investor' || page === '/?first-load=true&segment=investor' || page === '/index/_index_?first-load=true&segment=investor' || page === '/index/_index_?segment=investor') {
      isHome = true;
    }
    return isHome;
  }


  setFontResize(fontSize: any = null) {
    let activeFontSize: any = fontSize;

    if (!activeFontSize) {
      activeFontSize = localStorage.getItem("activeFontSize") || 2;
    }

    localStorage.setItem("activeFontSize", activeFontSize);

    activeFontSize = Number(activeFontSize);
    switch (activeFontSize) {
      case 1:
        document.documentElement.classList.add("small")
        document.documentElement.classList.remove("large")
        break;
      case 2:
        document.documentElement.classList.remove("small", "large")
        break;
      case 3:
        document.documentElement.classList.add("large")
        document.documentElement.classList.remove("small")
        break;
      default:
        document.documentElement.classList.remove("small", "large")
    }
  }

  checkForAccountsLink(url?: string | string[]) {
    if (url?.indexOf('ngGuest') > 0 || url?.indexOf('nxGuest') > 0) {
      return (
        this.envConfig.getEnvConfig().ftiAccountsUrl + url);
    }
    return url;
  }

  shareOnApp(app: string, fundLink: String, type: String): void {
    const baseUrl = this.getBaseUrl();
    let setUrl = ''
    if(type !== undefined){
      setUrl = `${fundLink}`
    }else{
      setUrl = `${baseUrl}${fundLink}`
    }
    let ShareUrl = '';
    switch (app) {
      case "TWITTER": {
        ShareUrl = `https://twitter.com/share?url=${encodeURIComponent(
          setUrl
        )}`;
        break;
      }
      case "WHATAPP": {
        ShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Check out this fund: ${setUrl}`
        )}`;
        break;
      }
      case "FACEBOOK": {
        ShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          setUrl
        )}`;
        break;
      }
      case "LINKEDIN": {
        ShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          setUrl
        )}`;
        break;
      }
    }
    window.open(ShareUrl, '_blank');
  }
}
