<div class="container row gx-3 text-center form-success-msg mb-3" *ngIf="submitSuccess">
  <span style="font-size:18px; font-weight: bold" class="success-msg" [innerHTML]="successMsgInvest"></span>
</div>

<form [formGroup]="intrestedForm">
  <div class="row g-3">
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="text" id="communication_Name" class="form-control" formControlName="name"
             placeholder="{{namePlaceholder}} *">
      <div
          *ngIf="intrestedForm?.get('name')?.hasError('required') && !intrestedForm?.get('name')?.value && intrestedForm?.get('name')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-name" | translate }}</span>
      </div>
      <div
          *ngIf="intrestedForm?.get('name')?.hasError('pattern') && !intrestedForm?.get('name')?.hasError('required') && intrestedForm?.get('name')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.form-name-validator" | translate }}</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="email" id="communication_Email" class="form-control" formControlName="email"
             placeholder="{{emailPlaceholder}} *">
      <div
          *ngIf="intrestedForm?.get('email')?.hasError('required') && !intrestedForm?.get('email')?.value && intrestedForm?.get('email')?.touched">
        <span style="color: #F04438;"
              class="error-msg">{{ "ftiGeneric.lead-form-email" | translate }}</span>
      </div>
      <div class="communicationEmail-error"
           *ngIf="intrestedForm?.get('email')?.hasError('pattern') && !intrestedForm?.get('email')?.hasError('required') && intrestedForm?.get('email')?.touched">
        <span style="color: #F04438;" class="error-msg">{{
                "ftiGeneric.lead-form-email-invalid" |
                    translate
          }}</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-12 mb-3': !isSmall}">
      <input type="text" id="communicationPan"
             class="form-control" formControlName="pan" placeholder="{{panPlaceholder}}" maxlength="10"
             (keyup)="control1Change()" style="text-transform: uppercase;">
      <div class="communicationEmail-error"
           *ngIf="intrestedForm?.get('pan')?.hasError('pattern') || panFormatError">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.form-pan-validator" | translate }}</span>
      </div>
    </div>
    <div class="form-group col-12 mb-3">
      <div
          *ngIf="intrestedForm?.get('query')?.hasError('required') && intrestedForm?.get('query')?.touched">
        <span style="color: #F04438;"
              class="error-msg">{{ "ftiGeneric.lead-form-remarks" | translate }}</span>
      </div>
      <textarea class="form-control" type="text" id="email_query" maxlength="500"
                formControlName="query" (keyup)="onKeyUp($event, 'query')"
                (paste)="onPaste($event)" placeholder="{{queryPlaceholder}} *"></textarea>
      <div>
        <span style="color: #F04438;"
              class="error-msg">{{ "ftiFeedback.specialCharactersNotAllowed" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="container-fluid py-3">
  
    <form>
      <div class="row mt-3">
        <div class="col-sm-12 mb-2">
          <re-captcha id="recaptcha" name="recaptcha" #recaptcha [(ngModel)]="captchaResponse" required
           (resolved)="onCaptchaResolved($event)"></re-captcha>
        </div>
      </div>
    </form>
  </div>
  <div class="row gx-3 location-custom-btn">
    <div class="col-12">
      <button id="continue-btn" type="submit" class="btn btn-primary w-100"
              [disabled]="!intrestedForm.valid || !captchaResolved"
              (click)="submitInvestForm()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ "ftiGeneric.submit-btn-label" | translate }}
      </button>
    </div>
  </div>
  <div class="email_OTP-error field-error">
    &nbsp;
  </div>
  <div class="row gx-2 d-md-none" style="gap:10px;">
    <div class="col-12">
    </div>
  </div>
</form>
