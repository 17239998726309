import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Inject,
  OnDestroy
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import * as Highcharts from 'highcharts';
import { TranslateService } from "@shared/translate/translate.service";
import { ChartService } from '../ind-calculators.service';
import { Subscription } from 'rxjs';
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger('LumpsumCalculatorComponent');

@Component({
  selector: 'ft-lumpsum-calculator',
  templateUrl: './lumpsum-calculator.component.html',
  styleUrls: ['./lumpsum-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LumpsumCalculatorComponent implements OnInit, OnDestroy {

  @Input() component!: BrComponent;
  @Input() page: Page;
  formValue: any;
  retrievedValue: any;

  isGraphView: boolean = true;
  readonly sipType: any = {
    normal: "normal",
    amount: "amount",
    year: "year",
  };

  selectedSipType: any = this.sipType.normal;
  showRiskOptions = false;
  showRecomendedFunds = false;

  label1: string;
  label2: string;
  private chartOptionsSubscription: Subscription;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private translateService: TranslateService,
    private chartService: ChartService,
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.label1 = this.translateService.instant('ftiCalc.calculator-invest-amount');
    this.label2 = this.translateService.instant('ftiCalc.calculator-invest-value');

    this.chartOptionsSubscription = this.chartService.getChartOptionsObservable().subscribe((chartOptions) => {
      this.renderChart(chartOptions);
    });
    this.chartService.initializeChartOptions({series: []});
  }

  // Set isGraphView
  setIsGraphView(v: any) {
    this.isGraphView = v;
  }

  toggleIsSIP = (type: any) => {
    this.selectedSipType = type;
  };

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get isPreview() {
    return this.page?.isPreview();
  }

  OnRecalculate(data: any) {
    const {labels, totalValueData, investedData} = this.chartService.processData(data);

    this.chartService.updateChart({
      xAxis: {categories: labels,
        title: {
          text: 'Investment Period',
          style: {
            color: '#3C425D',
            fontWeight: 'bold',
            fontSize : '14px',
            fontFamily : 'TTCommonsProRegular, sans-serif'
        }
        },
      },
      series: [
        {
          name: this.label1,
          data: investedData,
          type: 'line',
        },
        {
          name: this.label2,
          data: totalValueData,
          type: 'line',
        },
      ],
    });
  }

  private renderChart(chartOptions: Highcharts.Options): void {
    Highcharts.chart('highcharts-container-lumpsum', chartOptions);
  }
  investNow(fund) {
    this.analyticsService.trackEvent({
      event: 'calculator Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow Sticky',
    });
    this.investNowService.investNow('');
  }

  ngOnDestroy(): void {
    if (this.chartOptionsSubscription) {
      this.chartOptionsSubscription.unsubscribe();
    }
  }
}
