import { Component, Input } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from "@utils/logger";

const logger = Logger.getLogger('CsrInitiativesComponent');

@Component({
  selector: 'ft-csr-initiatives',
  templateUrl: './csr-initiatives.component.html',
  styleUrls: ['./csr-initiatives.component.scss'],
})
export class CsrInitiativesComponent {
  @Input() page: Page;
  @Input() component: BrComponent;

  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [

      {
        breakpoint: 768,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  showLoadMore: boolean = false;
  totalCSRLength: number;

  constructor() {
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    logger.debug("CSR Initiatives:", this.document?.getData());
    //console.log("CSR Initiatives:", this.document?.getData().contentBlock);
    this.totalCSRLength = this.document?.getData()?.contentBlock?.length;
    return this.document?.getData().contentBlock;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }

  showAllCSRData() {
    this.showLoadMore = true;
  }

}
