import { NgModule } from '@angular/core';

export type ComponentMapping = { [componentName: string]: any };
export interface DynamicModule extends NgModule {
  getComponentMapping(): ComponentMapping;
}
export enum Dependency {}

export class ModuleLoaderConfig {
  static readonly CAVEATS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "caveats" */ '../ft-components/products/caveats/caveats.module'
      )
  );
  static readonly PRODUCT_DETAILS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "product-details" */ '../ft-components/products/product-details.module'
      )
  );
  static readonly FUND_LISTING_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-listing" */ '../ft-components/products/fund-listing/fund-listing.module'
      )
  );

  static readonly DEBUG_PAGEMODEL_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "debug-page-model" */ '../ft-components/shared/debug/debug-page-model/debug-page-model.module'
      )
  );

  private static readonly componentToModuleMap: {
    [name: string]: ModuleLoaderConfig;
  } = {
    // Fund Listing Module
    'IND Fund Listing': ModuleLoaderConfig.FUND_LISTING_MODULE,
    // Fund Details Module
    'IND Fund Content': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Portfolio': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Manager Roster / Allocation': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Header': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Performance': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Price Distribution': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,

    // Footnote Module
    'Legal Caveats': ModuleLoaderConfig.CAVEATS_MODULE,
  };

  readonly moduleLoader: () => Promise<any>;
  readonly dependencies: Dependency[];
  private loaded = false;

  private constructor(
    moduleLoader: () => Promise<any>,
    dependencies?: Dependency[]
  ) {
    this.moduleLoader = moduleLoader;
    this.dependencies = dependencies;
  }

  public static getRequiredModules(
    componentNames: string[],
    isDebug = false
  ): ModuleLoaderConfig[] {
    const requiredModules: ModuleLoaderConfig[] = [];
    if (isDebug && !ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE.loaded) {
      ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE.loaded = true;
      requiredModules.push(ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE);
    } else {
      componentNames.forEach((componentName) => {
        const module = this.componentToModuleMap[componentName];
        if (module && !module.loaded) {
          requiredModules.push(module);
          module.loaded = true;
        }
      });
    }
    return requiredModules;
  }
}
