import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, } from "@angular/core";
import { Component as BrComponent, Document, Page, Reference } from "@bloomreach/spa-sdk";
import { Logger } from "@utils/logger";
import { getPageUrl } from "@utils/pagemodel-utils";
import { TranslateService } from "@shared/translate/translate.service";

const logger = Logger.getLogger("RegulatoryUpdatesComponent");

@Component({
  selector: "ft-regulatory-updates",
  templateUrl: "./regulatory-updates.component.html",
  styleUrls: ["./regulatory-updates.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegulatoryUpdatesComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;
  navigationArrow = true;
  navigationIndicators = true;
  isCollapsed: boolean = false;

  hiddenSection = true;
  getCollapsableDate = Date.now();
  setCollapsableDate: any;
  ftiOutageMessage: any;

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    const carouselLength = this.CarouselImages;
    this.navigationArrow = carouselLength?.length > 1;
    this.navigationIndicators = carouselLength?.length > 1;

    const cookkievalues = document.cookie.indexOf('outageBanner=');
    let noticeBannerStatus: boolean = false;
    this.ftiOutageMessage = this.translateService.instant(
      'ftiGeneric.outage-msg-status'
    );
    noticeBannerStatus = !(cookkievalues <= -1 && this.ftiOutageMessage === 'true');
    const notificationBanner: any = document.getElementById('updates-banners');
    if (noticeBannerStatus) {
      window.innerWidth > 700 ? notificationBanner?.classList.add('margin-top-10')
        : notificationBanner?.classList.add('mobile-margin-top');
    }
  }

  ngAfterViewInit() {
    this.getCollapsableData(this.CarouselImages);
    this.cd.detectChanges();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get CarouselImages() {
    return this.content?.carouselCompoundType;
  }

  /**
   * For template expressions, a function is provided to set collapsable data.
   * The flag's initial value is true, but after it becomes false, the collapse icon won't initially be shown.
   * The collapse icon will not change synchronously because the data source is not instantly invoked.
   * To solve this problem, we used the ngAfterViewInit() lifecycle hook to invoke the getCollapsableData() function.
   * @param data
   */
  getCollapsableData(data) {
    this.isCollapsed = !this.isCollapsed;
    let setDateForCollapse;
    if (data?.length > 0) {
      this.hiddenSection = true;
      if (localStorage.getItem("date") !== null) {
        this.getCollapsableDate = Number(localStorage.getItem("date"));
      }

      let getDate = data.filter((val) => {
        return this.getCollapsableDate < val.carouselDate;
      });

      if (getDate.length > 0) {
        this.setCollapsableDate = getDate[0].carouselDate;
        setDateForCollapse = true;
      } else {
        setDateForCollapse = false;
      }

    } else {
      setDateForCollapse = false;

      this.hiddenSection = false;
    }
    this.isCollapsed = setDateForCollapse;
  }

  checkCollaps(checkisCollapsed: boolean) {
    this.isCollapsed = checkisCollapsed;
  }

  get contentBlocks() {
    return this.content?.contentBlocks;
  }

  public getPageUrl(ref: Reference | string) {
    return getPageUrl(this.page, ref);
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }

    return "";
  }

  public getImages(imageUrl: any): string {
    let images;
    if (imageUrl?.imagelink) {
      const url = imageUrl?.imagelink?.$ref?.split("/content/")[1] || "";
      const modelKey = "model";
      const contentKey = "content";
      images = this.page?.[modelKey][contentKey][url].links.site.href || "";
    } else if (
      imageUrl?.contentType === "ftcore:ImageCompound" &&
      imageUrl?.widenAsset
    ) {
      images = JSON.parse(imageUrl?.widenAsset).url
        ? JSON.parse(imageUrl?.widenAsset).url
        : "";
    }
    return images;
  }

  toggleCollapse(): void {
    this.getCollapsableDate = this.setCollapsableDate;
    if (this.getCollapsableDate) {
      localStorage.setItem("date", this.setCollapsableDate.toString());
    }
  }
}
