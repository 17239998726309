<section class="FT-footer-pages pt-5">
  <div class="container">
    <div class="FT-title-medium text-bold text-blue mb-3">{{ "ftiGeneric.search-title" | translate }}</div>
    <div>
      {{ "ftiGeneric.search-para" | translate }}
    </div>
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="form-group">
          <div class="category-btns">
            <ul>
              <li *ngFor="let category of categories; let i = index"
                  [class.active]="selectedCategory === category.value" [id]="category.id"
                  (click)="selectCategory(category.value);onClickOfSearchFilter(category.id,getNonFormattedCount(i))"
                  class="me-3">
                {{ category.value }} {{ getFormattedCount(i) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-auto search-fb">
        <div class="feedback-box"
             *ngIf="(selectedCategory === categories[0].value || selectedCategory === categories[1].value) && categories.indexOf('Funds') < visibleCategories ">
          <div class="form-group">
            <label for="form-label" class="form-label">{{ categories[1].value }}
            </label>
            <ft-search-results-funds
                *ngIf="fundsItems"
                [results]="fundsItems"
                [totalCount]="totalCount?.funds"
                [valueToShow]="valueToShow?.funds"
                [isViewAll]="isViewAll?.funds"
                (viewAllClick)="viewSearchResults($event)"
                (loadMoreFunds)="loadMore($event)">
            </ft-search-results-funds>
          </div>
        </div>
        <div class="feedback-box"
             *ngIf="selectedCategory === categories[0].value || selectedCategory === categories[2].value">
          <div class="">
            <label class="form-label">{{ categories[2].value }}
            </label>
          </div>
          <div>
            <ft-search-results-literature
                *ngIf="literatureItems"
                [results]="literatureItems"
                [totalCount]="totalCount?.literature"
                [valueToShow]="valueToShow?.literature"
                [isViewAll]="isViewAll?.literature"
                (viewAllClick)="viewSearchResults($event)"
                (loadMoreForms)="loadMore($event)">
            </ft-search-results-literature>
          </div>
        </div>

        <div class="feedback-box"
             *ngIf="selectedCategory === categories[0].value || selectedCategory === categories[3].value">
          <div class="">
            <label class="form-label">{{ categories[3].value }}
            </label>
          </div>
          <div>
            <ft-search-results-general
                *ngIf="generalItems"
                [results]="generalItems"
                [totalCount]="totalCount?.pages"
                [valueToShow]="valueToShow?.pages"
                [isViewAll]="isViewAll?.pages"
                (viewAllClick)="viewSearchResults($event)"
                (loadMoreGeneral)="loadMore($event)">
            </ft-search-results-general>
          </div>
        </div>

        <div class="feedback-box"
             *ngIf="selectedCategory === categories[0].value || selectedCategory === categories[4].value">
          <div class="">
            <label class="form-label">{{ categories[4].value }}
            </label>
          </div>
          <div>
            <ft-search-results-investor
                *ngIf="investorResults"
                [results]="investorResults"
                [totalCount]="totalCount?.investor"
                [valueToShow]="valueToShow?.investor"
                [isViewAll]="isViewAll?.investor"
                (viewAllClick)="viewSearchResults($event)"
                (loadMoreGeneral)="loadMore($event)">
            </ft-search-results-investor>
          </div>
        </div>
        <div class="feedback-box"
             *ngIf="selectedCategory === categories[0].value || selectedCategory === categories[5].value">
          <div class="">
            <label class="form-label">{{ categories[5].value }}
            </label>
          </div>
          <div>
            <ft-search-results-advisor
                *ngIf="advisorResults"
                [results]="advisorResults"
                [totalCount]="totalCount?.advisor"
                [valueToShow]="valueToShow?.advisor"
                [isViewAll]="isViewAll?.advisor"
                (viewAllClick)="viewSearchResults($event)"
                (loadMoreGeneral)="loadMore($event)">
            </ft-search-results-advisor>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
