import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscriber, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "ft-search-knowledge-centre",
  templateUrl: "./search-knowledge-centre.component.html",
  styleUrls: ["./search-knowledge-centre.component.scss"],
})
export class SearchKnowledgeCentreComponent implements OnInit {
  @Input() allArticles: any;
  @Input() searchPlaceholderText: any;

  searchedInput = "";
  searchedInput$: Subscriber<string>;
  dataSubscription: Subscription;
  searchSuggestions: string[] = [];
  showNoSearchSuggestionMessage = false;
  searchedInputAgainstNoSearchSuggestionMessage: string;
  clearTextBox = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  /**
   * Triggered when any text in entered in search input.
   * @param event generated input event
   */
  onSearchInput(event: InputEvent): void {
    const searchedText: string = (event.target as HTMLInputElement).value;

    // Reset clearTextBox value to false so that suggestions will be visible
    this.clearTextBox = false;

    // Create new observable and assign its subscriber to search text subscriber.
    // This will indirectly be used to debounce the key input to currently 500 ms.
    // Also, not to omit event when the new value is not distinct.
    if (!this.searchedInput$) {
      this.dataSubscription = new Observable<string>((observer) => {
        this.searchedInput$ = observer;
      })
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((fullSearchedText) => {
          this.searchedInput = fullSearchedText.trim(); // Remove empty spaces in the search input.

          if (this.searchedInput) {
            this.searchSuggestions = this.allArticles.filter((article) =>
              article?.pageTitle
                ?.toLowerCase()
                .includes(this.searchedInput.toLowerCase())
            );
            // Show "showNoSearchSuggestionMessage" only when filter returns empty list.
            if (this.searchSuggestions.length) {
              this.showNoSearchSuggestionMessage = false;
            } else {
              this.searchedInputAgainstNoSearchSuggestionMessage =
                this.searchedInput;
              this.showNoSearchSuggestionMessage = true;
            }
          } else {
            this.showNoSearchSuggestionMessage = false; // For blank search input, need not to show message.
            this.searchSuggestions = []; // Make it blank, as it can be case, user cleared the search input.
          }
        });
    }

    this.searchedInput$.next(searchedText);
  }

  /**
   * Trigger search filter based upon selected search suggestion.
   * @param selectedSuggestion selected suggestion from the search suggestion list
   */
  onSearchSuggestionSelected(selectedSuggestion: any): void {
    if (selectedSuggestion) {
      this.searchSuggestions = [];
      this.searchedInput = selectedSuggestion.pageTitle;
      this.categoryOnClick(selectedSuggestion);
    }
  }

  onClearSearch() {
    this.searchedInput = "";
    this.searchedInputAgainstNoSearchSuggestionMessage = "";
    this.clearTextBox = true;
    this.searchSuggestions = [];
  }

  categoryOnClick(slide: any) {
    if (!slide?.pdfUrl) {
      this.router.navigate([slide?.url]);
    }
    if (slide?.pdfUrl) {
      window.open(slide?.data?._source?.pdfURL, "_blank");
    }
  }
}
