import { Component, Input, HostListener, OnInit } from '@angular/core';
import {
  Component as BrComponent,
  Page
} from "@bloomreach/spa-sdk";
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";

@Component({
  selector: 'ft-invest-now-form',
  templateUrl: './invest-now-form.component.html',
  styleUrls: ['./invest-now-form.component.scss']
})
export class InvestNowFormComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;
  showHeading: boolean = true;
  isMobileView: boolean = false;

  constructor(
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    let menu: any = document.getElementById("invest-now");
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if ((winScroll > 850 && window.innerWidth < 700) || (winScroll > 1600 && window.innerWidth > 900)) {
      menu.classList.add('invest-now-fixed')
    } else {
      menu.classList?.remove('invest-now-fixed')
    }
  }

  ngOnInit(): void {
    this.isMobileView = window.innerWidth < 790;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize($event: any = {}) {
    this.isMobileView = window.innerWidth < 790;
  }

  investNow(fund) {
    this.analyticsService.trackEvent({
      event: 'Home Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow Sticky',
    });
    this.analyticsService.trackEvent({
      event: "Home Page",
      ft_event:'ft_InvestNow_Clicked',
      category: "Homepage",
      action: 'Sticky',
      label: 'InvestNow',
    });
    this.investNowService.investNow('');
  }

  backTosteps(event: any) {
    this.showHeading = event;
  }
}
