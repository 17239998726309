import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ft-text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss'],
})
export class TextContentComponent implements OnInit {
  @Input() document;
  @Input() content;
  @Input() page;

  constructor() {}

  ngOnInit(): void {}
}
