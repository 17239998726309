import { TranslateService } from '@shared/translate/translate.service';
import { PortfolioComponentConfig } from '@products/portfolio/portfolio-component.config';
import { SiteConfigService } from '@services/site-config.service';
import { ConfigurationId, FundId, WebProductTaxonomy } from '@types';
import { MapperFactory, MapperType } from './mapper-factory';

export abstract class Mapper<T> {
  constructor(
    protected mapperParams: MapperParams,
    private mapperFactory: MapperFactory
  ) {}

  abstract toDomain(...args: any[]): T;

  createMapper<U extends Mapper<any>>(mapper: MapperType<U>): U {
    return this.mapperFactory.createMapper(mapper, this.mapperParams);
  }
}

export interface MapperParams {
  config?: SiteConfigService;
  translateService?: TranslateService;
  productAlerts?: any[];
  useFundCurrencyCode?: boolean;
  isDebugMode?: boolean;
  isShowLabelKeys?: boolean;
  fundId?: FundId;
  taxonomy?: WebProductTaxonomy;
  includeSoftClosed?: boolean;
  configurationName?: ConfigurationId;
}
