import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import { CommonService } from '@services/common.service';
import { Subject } from 'rxjs';
import { AnalyticsService } from '@services/analytics.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { takeUntil } from 'rxjs/operators';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { BlogListCategory } from 'src/app/ft-constants/blog-list-category';
import { KnowledgeCenterService } from '@services/knowledge-center.service';

@Component({
  selector: 'ft-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;

  articleArray = [];
  videoArray = [];
  blogArray = [];
  articlePageName: string;
  videoPageName: string;
  componentDestroyed$: Subject<boolean> = new Subject();
  start = 0;
  end = 8;
  videoEnd = 8;
  articleEnd = 8;
  blogEnd = 8;
  pages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  videoPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  allPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  blogPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  totalResults: number;
  blogTotalResults: number;
  videoTotalResults: number;
  allTotalResults: number;

  // Resource Bundle
  defaultArticleBannerImage: any;
  startLearningURL: string;


  //new
  toggleFilter = false;
  isDropdownOpen = false;
  showTooltip: boolean = false;
  funds: any = []; // please maintain model don't use any 
  getMutualCard: any;
  selectedFund: any = null;
  fundsForSearch: any = [];
  fullFundsArray: any = [];

  showIconForWishlist: boolean[] = [];
  selectedCategory: string = 'All';
  categories: string[] = ['All', 'Videos', 'Articles']; // 'Term Busters' later to be added in the list
  blogCategory: string[] = ['Investing in Fixed income', 'A SIP of financial freedom', 'Financial Planning for Success', 'Investing in Equity', 'Planning your Retirement', 'For the Love of Money'];
  getData: any = [];
  commonData: any = [];
  allPageName: string;
  searchTerm: string = '';
  pageType: string;
  showLoadMore: boolean = false;
  dropdownTitle: string;
  filterDataBlog = [];
  linkToCopy: string;
  showCopyDialoge: boolean = false;
  noSearchFlag: boolean = false;
  watchListFull: boolean = false;
  relatedTag: string = '';

  constructor(
    private commonService: CommonService,
    private searchService: FtsearchService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private knowledgeService: KnowledgeCenterService
  ) {
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {

    const sections = document.querySelectorAll<any>('.fd-tab-sections');
    const navLi = document.querySelectorAll('.FT-blocks-nav ul li');
    let current = '';

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (pageYOffset >= (sectionTop - 85)) {
        current = section.getAttribute('id');
      }
    });

    navLi.forEach((li) => {
      current ? li.children[0].classList.remove('active-item') : '';
      // li.children[0].classList.remove('active-item');
      if (li.children[0].classList.contains(current)) {
        li.children[0].classList.add('active-item');
      }
    });
  }

  /** Scroll to learning Banner tabs */
  scrollTo(elementId: string) {
    const elementToScroll = document.getElementById(elementId);
    if (elementToScroll) {
      // Scroll to the element with a delay of 10 milliseconds (or any desired delay)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }

  ngOnInit(): void {
    this.startLearningURL = this.translateService.instant('ftiKc.open-learning-banner-start-learning-url')
    // this.content();
    const routerUrl = this.router.url;
    // let pageType;
    if (routerUrl.includes('index')) {
      this.pageType = routerUrl.split('/')[
      routerUrl.split('/').indexOf('index') - 1
        ];
    } else {
      this.pageType = routerUrl.split('/')[routerUrl.split('/').length - 1];
    }
    if (this.pageType?.includes('related-articles?') && this.pageType?.split('rc=')[1] !== undefined) {
      this.relatedTag = this.pageType?.split('rc=')[1];
      this.pageType = this.pageType?.split('?')[0];
      this.getFTIALLData(this.relatedTag, 0, 8, true);
    } else {
      if (this.pageType === "blog") {
        this.selectedCategory = 'For the Love of Money';
        this.getFTIBlogData([BlogListCategory.forTheLoveOfMoney], 0, 8);
      } else {
        this.getFTIALLData(this.pageType, 0, 8);
      }
    }
    // Get values from resource bundle
    this.defaultArticleBannerImage = this.translateService.instant(
      'ftiKc.defaultArticleBannerImage'
    );
    this.dropdownTitle = this.getTitle();
  }

  getTitle() {
    let title = "";
    for (let i = 0; i < this.pageType.length; i++) {
      if (this.pageType[i] == '-') {
        title += ' ';
      } else {
        title += this.pageType[i];
      }
    }
    return title;
  }

  setAnalytics(title, fromArticle?: any): void {
    let viewArticle = 'View Article';
    fromArticle === 'INDVideoArticles' ? viewArticle = 'View video' : viewArticle = 'View Article'

    this.analyticsService.trackEvent({
      event: 'Event InvestorEducation',
      category: 'Investor Education',
      action: viewArticle,
      label: 'New to Investing | ' + title,
    });
  }

  getFTIArticleData(pageType, start, end) {
    this.commonData = [];
    const watchListedIds = this.knowledgeService.getWatchList();
    if (start === 0) {
      this.pages.results = [];
      this.articleArray = [];
    }
    this.articleEnd = end;
    this.articlePageName = pageType;
    const filterData = [
      {fieldName: 'documentType.exact', fieldValue: ['INDArticleDetails']},
      {fieldName: 'pageType', fieldValue: [pageType]},
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.articleEnd)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.pages.results = data['results']?.response?.hits['hits'] || [];
        this.totalResults = data['results']?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          // const isWatchListed = watchListedIds.includes(pageTitle);
          let isWatchListed = false;
          watchListedIds.forEach(id => {
            if (id.pageTitle === pageTitle) {
              isWatchListed = true;
            }
          })
          this.articleArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pdfUrl: article?._source?.pdfURL,
            pageTitle: `${pageTitle}`,
            isWatchListed: isWatchListed,
          });
        });
        this.commonData = this.articleArray;
        this.showLoadMoreButton(this.totalResults, start);
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  getFTIVideoData(pageType, start, end) {
    this.commonData = [];
    const watchListedIds = this.knowledgeService.getWatchList();
    if (start === 0) {
      this.videoPages.results = [];
      this.videoArray = [];
    }
    this.videoEnd = end;
    this.videoPageName = pageType;
    const filterData = [
      {fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles']},
      {fieldName: 'pageType', fieldValue: [pageType]},
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.videoEnd)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.videoPages.results = data['results']?.response?.hits['hits'] || [];
        this.videoTotalResults = data['results']?.response?.hits?.total?.value;
        this.videoPages.count = this.videoPages.results.length;
        this.videoPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          // const isWatchListed = watchListedIds.includes(pageTitle);
          let isWatchListed = false;
          watchListedIds.forEach(id => {
            if (id.pageTitle === pageTitle) {
              isWatchListed = true;
            }
          })
          this.videoArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pageTitle: `${pageTitle}`,
            isWatchListed: isWatchListed,
          });
        });
        this.commonData = this.videoArray;
        this.showLoadMoreButton(this.videoTotalResults, start);
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  private getFTIALLData(pageType, start, end, relatedTag?: boolean) {
    this.commonData = [];
    let fieldname = 'pageType';
    if (relatedTag) {
      fieldname = 'relatedTags.keyword';
    }
    const watchListedIds = this.knowledgeService.getWatchList();
    if (start === 0) {
      this.allPages.results = [];
      this.getData = [];
    }
    this.end = end;
    this.allPageName = pageType;
    const filterData = [
      {fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles', 'INDArticleDetails']},
      {fieldName: fieldname, fieldValue: [pageType]},
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.allPages.results = data['results']?.response?.hits['hits'] || [];
        this.allTotalResults = data['results']?.response?.hits?.total?.value;
        this.allPages.count = this.allPages.results.length;
        this.allPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          let isWatchListed = false;
          watchListedIds.forEach(id => {
            if (id.pageTitle === pageTitle) {
              isWatchListed = true;
            }
          })
          // const isWatchListed = watchListedIds.includes(pageTitle);
          if (article._source.articleType === "Article") {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pdfUrl: article?._source?.pdfURL,
              pageTitle: `${pageTitle}`,
              isWatchListed: isWatchListed,
            });
          } else {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pageTitle: `${pageTitle}`,
              isWatchListed: isWatchListed,
            });
          }
        });
        this.commonData = this.getData;
        this.showLoadMoreButton(this.allTotalResults, start);
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  loadMore(selected) {
    if (selected === 'All' && this.pageType !== 'blog' && !this.relatedTag) {
      this.getFTIALLData(this.allPageName, this.getData.length, 8);
      this.loadLastitem(this.getData, this.getData.length + 3);
    } else if (selected === 'Videos') {
      this.getFTIVideoData(this.videoPageName, this.videoArray.length, 8);
      this.loadLastitem(this.videoArray, this.videoArray.length + 3);
    } else if (selected === 'Articles') {
      this.getFTIArticleData(this.articlePageName, this.articleArray.length, 8);
      this.loadLastitem(this.articleArray, this.articleArray.length + 3);
    } else if (this.pageType === 'blog') {
      this.getFTIBlogData(this.filterDataBlog, this.blogArray.length, 8);
      this.loadLastitem(this.blogArray, this.blogArray.length + 1);
    } else if (selected === 'All' && this.relatedTag !== '') {
      this.getFTIALLData(this.relatedTag, this.getData.length, 8, true);
      this.loadLastitem(this.getData, this.getData.length + 3);
    }
  }

  loadLastitem(arrayList: any, idvalue: number) {
    setTimeout(() => {
      const elementId = arrayList[arrayList.length - 1].data._id;
      this.scrollTo(elementId);
    }, 1000);
  }

  toggleFilterBox() {
    this.toggleFilter = !this.toggleFilter
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

  toggleIconForWatchlist(article: any) {
    this.watchListFull = false;
    if (!article?.isWatchListed) {
      const watchListedIds = this.knowledgeService.getWatchList();
      if (watchListedIds.length <= 7) {
        this.addToWatshlist(article);
      } else {
        this.watchListFull = true;
      }
    } else if (article?.isWatchListed) {
      this.removeToWatchlist(article);
    }
  }

  closeWatchlist() {
    this.watchListFull = false;
  }

  addToWatshlist(article: any) {
    const articleToUpdate = this.commonData.find(
      (articleInArray) => articleInArray.pageTitle === article?.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = true;
    }
    this.knowledgeService.addToWatchList(article);
  }

  removeToWatchlist(article: any) {
    // Remove from watchlist
    this.knowledgeService.removeFromWatchList(article);

    const articleToUpdate = this.commonData.find(
      (articleInArray) => articleInArray.pageTitle === article?.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = false;
    }
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
    this.commonData = [];
    if (this.selectedCategory === 'All') {
      if (this.getData.length === 0) {
        this.getFTIALLData(this.pageType, this.start, 8);
      } else {
        this.commonData = this.getData;
        this.showLoadMoreButton(this.allTotalResults, this.getData.length);
      }
    } else if (this.selectedCategory === 'Videos') {
      if (this.videoArray.length === 0) {
        this.getFTIVideoData(this.pageType, this.start, 8);
      } else {
        this.commonData = this.videoArray;
        this.showLoadMoreButton(this.videoTotalResults, this.videoArray.length);
      }
    } else if (this.selectedCategory === 'Articles') {
      if (this.articleArray.length === 0) {
        this.getFTIArticleData(this.pageType, this.start, 8);
      } else {
        this.commonData = this.articleArray;
        this.showLoadMoreButton(this.totalResults, this.articleArray.length);
      }
    }
  }

  selectBlogCategory(selectedKey) {
    this.selectedCategory = selectedKey;
    if (this.selectedCategory === 'All') {
      this.getFTIBlogData(this.blogCategory, this.start, 8);
    } else if (this.selectedCategory === BlogListCategory.investingInFDMoney) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    } else if (this.selectedCategory === BlogListCategory.aSipFinancial) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    } else if (
      this.selectedCategory === BlogListCategory.financialPlanningOfSuccess
    ) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    } else if (this.selectedCategory === BlogListCategory.investingInEquity) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    } else if (
      this.selectedCategory === BlogListCategory.planningYourRetirement
    ) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    } else if (this.selectedCategory === BlogListCategory.forTheLoveOfMoney) {
      this.getFTIBlogData([this.selectedCategory], this.start, 8);
    }
  }

  resetFundExplore() {
    this.searchTerm = '';
    this.fundsForSearch = [];
    this.noSearchFlag = false;
  }

  getTableTitle(selectedSuggestion: any) {
    if (selectedSuggestion) {
      // this.fundsForSearch = [];
      this.searchTerm = selectedSuggestion.pageTitle;
      this.categoryOnClick(selectedSuggestion);
    }
  }

  filterFunds(searchTerm: string) {
    if (searchTerm) {
      if (this.pageType !== 'blog') {
        const filterData = [
          {fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles', 'INDArticleDetails']},
          {fieldName: 'pageType', fieldValue: [this.allPageName]},
        ];
        this.searchArticles(searchTerm, filterData);
      } else if (this.pageType === 'blog') {
        const filteredValue = [
          {fieldName: 'documentType.exact', fieldValue: ['INDArticleDetails']},
          {fieldName: 'articleType.keyword', fieldValue: ['Blog']},
          {fieldName: 'blogCategory.keyword', fieldValue: this.blogCategory},
        ];
        this.searchArticles(searchTerm, filteredValue);
      }
    }
  }

  private searchArticles(searchTerm: string, filterData) {
    let results: any;
    let arrayTemp: any = [];
    this.searchService
      .getFTIArticleSearchResults(JSON.stringify(filterData), searchTerm)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        results = data['results']?.response?.hits['hits'] || [];
        results?.forEach((article) => {
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          if (article._source.articleType === "Article") {
            arrayTemp.push({
              url: article._source.navigationUrl,
              pdfUrl: article?._source?.pdfURL,
              pageTitle: `${pageTitle}`,
            });
          } else {
            arrayTemp.push({
              url: article._source.navigationUrl,
              pageTitle: `${pageTitle}`,
            });
          }
        });
        this.fundsForSearch = arrayTemp.filter((article) =>
          article?.pageTitle
            ?.toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        );
        this.noSearchFlag = !this.fundsForSearch.length;
      });

    this.analyticsService.trackEvent({
      event: 'Event InvestorEducation',
      category: 'Investor Education',
      action: 'Search',
      label: searchTerm,
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  sortUsingDate() {
    if (this.selectedCategory === 'All') {
      this.getData = this.sortDataByDate(this.getData);
    } else if (this.selectedCategory === 'Videos') {
      this.videoArray = this.sortDataByDate(this.videoArray);
    } else if (this.selectedCategory === 'Articles') {
      this.articleArray = this.sortDataByDate(this.articleArray);
    }
  }

  sortDataByDate(list: any[]): any[] {
    return list.sort(
      (a, b) =>
        new Date(a.data?._source?.publishDate).getTime() -
        new Date(b.data?._source?.publishDate).getTime()
    );
  }

  getFTIBlogData(category, start, end) {
    this.commonData = [];
    const watchListedIds = this.knowledgeService.getWatchList();
    if (start === 0) {
      this.blogPages.results = [];
      this.blogArray = [];
      this.filterDataBlog = [];
    }
    this.filterDataBlog = category;
    const filteredValue = [
      {fieldName: 'documentType.exact', fieldValue: ['INDArticleDetails']},
      {fieldName: 'articleType.keyword', fieldValue: ['Blog']},
      {fieldName: 'blogCategory.keyword', fieldValue: category},
    ];
    this.blogEnd = end;
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIBlogResults(JSON.stringify(filteredValue), start, this.blogEnd)
      ?.pipe(takeUntil(this.componentDestroyed$))
      ?.subscribe((data: any) => {
        this.blogPages.results = data['results']?.response?.hits['hits'] || [];
        this.blogTotalResults = data['results']?.response?.hits?.total?.value;
        this.blogPages.count = this.blogPages.results.length;
        this.blogPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.description;
          // const isWatchListed = watchListedIds.includes(pageTitle);
          let isWatchListed = false;
          watchListedIds.forEach(id => {
            if (id.pageTitle === pageTitle) {
              isWatchListed = true;
            }
          })
          this.blogArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pageTitle: pageTitle,
            isWatchListed: isWatchListed,
            date: moment(article?._source?.publishDate).format(
              Constant.dateFormat
            ),
          });
        });
        this.commonData = this.blogArray;
        this.showLoadMoreButton(this.blogTotalResults, start);
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  shareOnApp(appName, article): void {
    let url = ''
    if (article.pdfUrl !== undefined) {
      url = `${article.pdfUrl}`;
    } else {
      url = `${article.url}`;
    }
    this.commonService.shareOnApp(appName, url, article.pdfUrl);
  }

  categoryOnClick(card: any) {
    if (!card?.pdfUrl) {
      this.router.navigate([this.page.getUrl(card?.url)]);
    }
    if (card?.pdfUrl) {
      window.open(card?.data?._source?.pdfURL, "_blank");
    }
  }

  private showLoadMoreButton(results: any, start: number) {
    if (results <= 8 || start + 8 >= results) {
      this.showLoadMore = false;
    } else {
      this.showLoadMore = true;
    }
  }

  async toggleShowCopyDialoge(check: boolean, article): Promise<void> {
    if (check) {
      if (article?.pdfUrl) {
        this.linkToCopy = article?.pdfUrl;
      } else {
        this.linkToCopy = article?.data?._id;
      }
      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        // logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        // logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  getFormatedDate(date) {
    let timeDate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-US', {month: "long", day: "2-digit", year: "numeric"});
    return formatter.format(timeDate)
  }

  ngOnDestroy() {
  }
}
