import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@components/shared/translate/translate.service';
import { LiteratureService } from '@services/literature.service';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { LiteratureDTO } from '@types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ft-latest-updates',
  templateUrl: './latest-updates.component.html',
  styleUrls: ['./latest-updates.component.scss']
})
export class LatestUpdatesComponent implements OnInit, OnDestroy {

  @Input() component!: BrComponent;
  @Input() page: Page;

  viewallupdatesLink: any;

  // Limit the number of rows to be shown on the component. Default is 4
  @Input() limit = 4;
  updateList: LiteratureDTO;
  updateListdocument: any;
  updateListoddDoc: any = [];
  updateListevenDoc: any = [];
  subscription: Subscription;

  constructor(
    private litService: LiteratureService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.viewallupdatesLink = this.translateService.instant(
      'literature.updatesViewAllLink'
    );
    // Call Literature Service to pull the data
    this.subscription = this.litService
      .getData$(
        '?channel=en-in&contentGrouping=Latest%20Updates&limitIndex=' +
        this.limit
      )
      ?.subscribe((response: LiteratureDTO): void => {
        this.updateList = response;
        if (this.updateList) {
          this.updateListdocument = this.updateList.document;
          for (let i = 0; i < this.updateListdocument.length; i++) {
            if (i % 2 == 0) {
              this.updateListevenDoc.push(this.updateListdocument[i]);
            } else {
              this.updateListoddDoc.push(this.updateListdocument[i]);
            }
          }
        }
      });
  }

  public fileExtension(input) {
    const extensionIndex = input?.literatureHref.lastIndexOf('.');
    return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
