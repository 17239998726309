import {
  AfterViewInit,
  Component, HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('LocationsComponent');

@Component({
  selector: 'ft-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationsComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;

  isMobileView: boolean = false;
  isMediaTypeImage: boolean = true;
  mediaSrc: any;
  isVideoPlaying = false;

  constructor(
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.linkLists;
    this.LocationImages();
  }

  ngAfterViewInit(): void {
    this.LocationImages();
  }

  learnMore = this.translateService.instant('ftiGeneric.locator-learn-more');
  learnMoreURL = this.translateService.instant('ftiGeneric.locator-learn-more-url');

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  scrolled: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.pageYOffset > 350;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize($event: any = {}) {
    this.isMobileView = window.innerWidth < 900;
  }

  playPauseVideoOnHover = () => {
    const statBlock = document.getElementById('map-statistics-block')
    const statBlock1 = document.getElementById('map-statistics-block-new')
    const video: any = document.getElementById('map-video');
    video?.addEventListener('canplay', () => {
      video?.addEventListener('mouseenter', () => {
        video.currentTime = 0;
        this.ensureVideoPlays(video);
        this.isVideoPlaying = true;
        statBlock?.classList.add('d-none')
        statBlock1?.classList.add('d-none')
        setTimeout(() => {
          if (video.currentTime > 3) {
            video.pause();
            this.isVideoPlaying = false;
          }
        }, 3800);
      })
      video?.addEventListener('mouseleave', () => {
        video?.pause()
        this.isVideoPlaying = false;
      })
      video.onended = () => {
        this.isVideoPlaying = false;
      }
    })
  }
  ensureVideoPlays = (video: any) => {
    if (!video) return;

    const promise = video.play();
    if (promise !== undefined) {
      promise.then(() => {
      }).catch((error: any) => {
        video.muted = true;
        video.play();
      });
    }
  }

  LocationImages() {
    let data = this.content?.widenAssetCompound?.widenDocument;
    if (data) {
      this.mediaSrc = JSON.parse(data)?.url;
      this.isMediaTypeImage = !this.isImage(JSON.parse(data)?.url);
    } else {
      logger.error("data is undefined");
    }
    this.handleVideoPlayback();
  }

  isImage(url: string) {
    return /\.(jpg|png|jpeg|webp|avif|gif|svg)$/.test(url);
  }

  handleVideoPlayback() {
    if (window.innerWidth < 900) {
      const statBlock = document.getElementById('map-statistics-block');
      const statBlock1 = document.getElementById('map-statistics-block-new')
      const video: any = document.getElementById('map-video-mobile');
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
      this.isMobileView = true;
      video?.addEventListener('canplaythrough', () => {
        this.isVideoPlaying = true;
        statBlock?.classList.add('d-none');
        this.ensureVideoPlays(video);
        setTimeout(() => {
          if (video.currentTime > 3) {
            video.onended();
          }
        }, 4000);
        video.onended = () => {
          this.isVideoPlaying = false;
          setTimeout(() => {
            if (isIOS) {
              statBlock?.classList.add('d-none')
              statBlock1?.classList.add('d-none');
            }
            this.ensureVideoPlays(video);
          }, 1500);
        }
      })
    } else {
      this.isMobileView = false;
      this.playPauseVideoOnHover();
    }
  }
}
