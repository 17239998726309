import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fti-test-your-learning',
  templateUrl: './test-your-learning.component.html',
  styleUrls: ['./test-your-learning.component.scss']
})
export class TestYourLearningComponent {

  @Input() questions: any;
  @Output() reloadQuiz: EventEmitter<any> = new EventEmitter<any>();

  selectedOptions: { index: number, option: string, question: any }[] = [];
  quizPercentage: any;
  currentQuestionIndex: number = 0;

  showSaveDialoge = false;

  constructor() {
  }

  getCurrentQuestion() {
    if (this.questions) {
      return this.questions[this.currentQuestionIndex];
    }
    return
  }

  goToNextQuestion() {
    this.currentQuestionIndex = (this.currentQuestionIndex + 1) % this.questions?.length;
  }


  goToPreviousQuestion() {
    this.currentQuestionIndex = (this.currentQuestionIndex - 1 + this.questions?.length) % this.questions?.length;
  }


  selectOption(index: number, option: string, question: any) {
    const existingIndex = this.selectedOptions.findIndex(item => item.index === index);

    if (existingIndex !== -1) {
      // If index exists, update the option
      this.selectedOptions[existingIndex].option = option;
    } else {
      // If index doesn't exist, push a new object
      this.selectedOptions.push({index, option, question});
    }
    if (this.currentQuestionIndex + 1 !== this.questions?.length) {
      this.goToNextQuestion();
    }
  }


  isCurrentQuestion(index: number): boolean {
    return this.currentQuestionIndex === index;
  }

  goToQuestion(index: number) {
    this.currentQuestionIndex = index;
  }

  toggleShowSavDialoge() {
    this.showSaveDialoge = !this.showSaveDialoge;
  }

  validateQuiz() {
    this.toggleShowSavDialoge();
    let totalQuestions = this.selectedOptions?.length;
    let correctAnswers = 0;

    this.selectedOptions.forEach(selection => {
      if (selection.option === selection.question.option5) {
        correctAnswers++;
      }
    });

    this.quizPercentage = (correctAnswers / totalQuestions) * 100;
  }

  resetQuiz() {
    this.reloadQuiz.emit(false);
  }
}
