/**
 * helper functions for mappers
 */

import { EMDASH } from '@products/utils/constants/product.constants';

/**
 * Basic helper to parse std formatted number strings into numbers
 * Returns null when NaN
 */
export const parseStdNumber = (stdString: string): number => {
  const num = parseFloat(stdString);
  if (num || num === 0) {
    return num;
  }
  return null;
};

/**
 * Used to parse GQL data where value is either 'Y', 'N', '', null or undefined
 */
export const parseYN = (val: string): boolean | null => {
  if (val === 'Y') {
    return true;
  }
  if (val === 'N') {
    return false;
  }
  return null;
};

/**
 * This determines if an object's property has data
 * null, undefined, '', '—' and '-' are all considered false
 * every other value is considered true, including the number 0, true and false
 * Explains how types work: https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
 */
export function hasValue<T, K extends keyof T>(item: T, propName: K): boolean {
  const exclusions: Array<T[K] | string> = [null, undefined, '', EMDASH, '-'];
  return !exclusions.includes(item[propName]);
}

/**
 * Returns the first defined property in an array of objects
 * Used when graphql returns an array of objects, but some properties have the same value (or undefined)
 * Explains how types work: https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
 */
export function getFirstNonEmptyProp<T, K extends keyof T>(
  items: T[],
  propName: K
): T[K] | null {
  const found: T = items.find((obj: T): boolean => hasValue(obj, propName));
  if (found !== undefined) {
    return found[propName];
  }
  return null;
}

/**
 * returns true if an array of objects has at least one object prop with a truthy value
 * this also works on properties that have no data formatted as '—', '-' etc (because '-' is truthy!)
 */
export function hasValues<T, K extends keyof T>(
  items: T[],
  propName: K
): boolean {
  return items.some((item: T): boolean => hasValue(item, propName));
}
