<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <section class="FT-calculators-slider mb-6" *ngIf="document">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-sm-4 mb-3">
        <div class="FT-title-small">{{ title }}</div>
        <a class="line login-dark2" href="{{'ftiCalc.calculator-explore-all-link' | translate }}">{{ 'ftiGeneric.where-do-you-stand-view-all-text' | translate }}
          <i class="bi bi-chevron-right"></i>
        </a>
      </div>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <ng-container *ngFor="let item of linkLists">
          <div ngxSlickItem class="slide" *ngIf="item?.linkCompound?.url !== currentPage">
            <div>
              <div class="calc-box">
                <img src="{{ getImageURL(item?.widenAssetCompound?.widenDocument) }}"
                     alt="{{ item?.linkCompound?.displayText }}"/>
                <h4>{{ item?.linkCompound?.displayText }}</h4>
                <a [href]="item?.linkCompound?.url"
                   [target]="item?.linkCompound?.target == '' ? '_self' : item?.linkCompound?.target"
                   class="btn btn-primary" (click)="calculateGA(item?.linkCompound?.displayText)">{{ item?.linkCompound?.title }}</a>
              </div>
            </div>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>
  </section>
</div>
