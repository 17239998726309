import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@components/shared/translate/translate.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-knowledge-center-modal',
  templateUrl: './knowledge-center-modal.component.html',
  styleUrls: ['./knowledge-center-modal.component.scss']
})
export class KnowledgeCenterModalComponent implements OnInit{

  checkboxes = [];

  checkboxeFranklin = [];

  checkboxeMarket = [];

  @Input() selectedValue = '';

  constructor(private translateService: TranslateService) {
    let checkboxes = this.translateService.instant("ftiKc.KC-categories-list");
    let checkboxeFranklin = this.translateService.instant("ftiKc.KC-FTA-list");
    let checkboxeMarket = this.translateService.instant("ftiKc.KC-MI-List");

     /** 
     * Implement the timer to set the suggested data.
     */
     timer(0).subscribe(() =>{ this.getCategoryCheckboxes(checkboxes,checkboxeFranklin,checkboxeMarket)});
  }

  ngOnInit(): void {
    
  }

  getCategoryCheckboxes(checkboxes,checkboxeFranklin,checkboxeMarket){
    this.checkboxes = JSON.parse(checkboxes);
    this.checkboxeFranklin = JSON.parse(checkboxeFranklin);
    this.checkboxeMarket = JSON.parse(checkboxeMarket);

     this.checkboxes.forEach(checkbox => checkbox.isChecked = false);
    this.checkboxeFranklin.forEach(checkbox => checkbox.isChecked = false);
    this.checkboxeMarket.forEach(checkbox => checkbox.isChecked = false);
    this.checkboxes.forEach(checkbox => {
      if(checkbox.id === this.selectedValue){
        checkbox.isChecked = true;
      }
    });
    this.checkboxeMarket.forEach(checkbox => {
      if(checkbox.id === this.selectedValue){
        checkbox.isChecked = true;
      }
    });
  }

  toggleCheckbox(clickedCheckbox: { id: string, label: string, isChecked: boolean }): void {
    this.checkboxes.forEach(checkbox => checkbox.isChecked = false);
    clickedCheckbox.isChecked = true;
  }

  toggleFranklinCheckbox(clickedCheckbox: { id: string, label: string, isChecked: boolean }): void {
    this.checkboxeFranklin.forEach(checkbox => checkbox.isChecked = false);
    clickedCheckbox.isChecked = true;
  }

  toggleMarketCheckbox(clickedCheckbox: { id: string, label: string, isChecked: boolean }): void {
    this.checkboxeMarket.forEach(checkbox => checkbox.isChecked = false);
    clickedCheckbox.isChecked = true;
  }

}
