import {
  Component,
  Input,
  OnInit, OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CalculatorService } from "@services/calculator.service";
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import * as Highcharts from 'highcharts';
import { ChartService } from '../ind-calculators.service';
import { Subscription } from 'rxjs';
import { Logger } from "@utils/logger";
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";

@Component({
  selector: 'ft-stp-calculator',
  templateUrl: './stp-calculator.component.html',
  styleUrls: ['./stp-calculator.component.scss']
})
export class StpCalculatorComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;

  label1: string = "Equity Funds";
  label2: string = "Liquid Funds";
  private chartOptionsSubscription: Subscription;

  calcForm!: FormGroup;
  calculatedData: any;
  isGraphView: boolean = true;

  sliderKeyName: any = {
    sourceFund: "sourceFund",
    targetFund: "targetFund",
    period: "period",
    sourceROR: "sourceROR",
    targetROR: "targetROR",
  }

  sourceFundSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  targetFundSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  periodSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  sourceRORSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  targetRORSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";

  constructor(
    private _fb: FormBuilder,
    private _calcService: CalculatorService,
    private chartService: ChartService,
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
  ) {
  }

  investNow(fund) {
    this.analyticsService.trackEvent({
      event: 'calculator Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow Sticky',
    });
    this.investNowService.investNow('');
  }

  ngOnInit(): void {
    this.initForm();

    this.chartOptionsSubscription = this.chartService.getChartOptionsObservable().subscribe((chartOptions) => {
      this.renderChart(chartOptions);
    });
    this.chartService.initializeChartOptions({series: []});

    // slider track color handle on form value change
    this.calcForm.valueChanges.subscribe((v) => {
      this.handleAllSliderFill();
    });
    this.onCalculatorChange();
  }

  // Form Init
  initForm = () => {
    this.calcForm = this._fb.group({
      [this.sliderKeyName.sourceFund]: [10000],
      [this.sliderKeyName.targetFund]: [500],
      time: ['MONTHLY'],
      [this.sliderKeyName.period]: [20],
      [this.sliderKeyName.sourceROR]: [5],
      [this.sliderKeyName.targetROR]: [10],
    });
  };

// set all slider according to their values.
  handleAllSliderFill() {
    if (!this.calcForm) {
      return;
    }
    for (let keyName in this.sliderKeyName) {
      let v = this.calcForm.value[keyName];

      if (v != undefined) {
        this.applyFillSlider(keyName, v);
      }
    }
  }

// Handle slide track color
  applyFillSlider = (type: any, value: any = null, slider: any = null) => {
    let element = slider;
    if (slider && slider.hasOwnProperty('first')) {
      element = slider.first.nativeElement;
    } else if (slider && slider.hasOwnProperty('nativeElement')) {
      element = slider.nativeElement;
    }

    let currentValue = value || element?.value || 0;

    const settings = {
      fill: '#3769ff',
      background: '#e0e0e07a',
    };

    let minValue: any = this.getInputLimit(type, false);
    let maxValue: any = this.getInputLimit(type, true)

    let percentage = ((currentValue - minValue)) * 100 / (maxValue - minValue);
    if (!isNaN(Number(currentValue)) && Number(currentValue) > Number(maxValue)) {
      percentage = 100;
    } else if (currentValue <= 0) {
      percentage = 0;
    }

    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage + 0.1}%) !important`;

    switch (type) {
      case this.sliderKeyName.sourceFund:
        this.sourceFundSliderStyle = bg;
        break;
      case this.sliderKeyName.targetFund:
        this.targetFundSliderStyle = bg;
        break;
      case this.sliderKeyName.period:
        this.periodSliderStyle = bg;
        break;
      case this.sliderKeyName.sourceROR:
        this.sourceRORSliderStyle = bg;
        break;
      case this.sliderKeyName.targetROR:
        this.targetRORSliderStyle = bg;
        break;
    }
  };

// Get input field min & max limit
  getInputLimit = (type: any, isMax: any = false) => {
    let v;
    const minSourceFund = 1e4;
    const maxSourceFund = 1e8;

    const minTargetFund = 500;
    const maxTargetFund = 1e6;

    const minPeriod = 1;
    const maxPeriod = 30;

    const minSourceROR = 2;
    const maxSourceROR = 8;

    const minTargetROR = 2;
    const maxTargetROR = 13;

    switch (type) {

      case this.sliderKeyName.sourceFund:
        v = isMax ? maxSourceFund : minSourceFund;
        break;
      case this.sliderKeyName.targetFund:
        v = isMax ? maxTargetFund : minTargetFund;
        break;
      case this.sliderKeyName.period:
        v = isMax ? maxPeriod : minPeriod;
        break;
      case this.sliderKeyName.sourceROR:
        v = isMax ? maxSourceROR : minSourceROR;
        break;
      case this.sliderKeyName.targetROR:
        v = isMax ? maxTargetROR : minTargetROR;
        break;
    }

    return v;
  }

  setGraphValues(data: any) {
    const {labels, totalValueData, investedData} = this.chartService.processData(data);
    this.chartService.updateChart({
      xAxis: {categories: labels,
        title: {
          text: 'Investment Period',
          style: {
            color: '#3C425D',
            fontWeight: 'bold',
            fontSize : '14px',
            fontFamily : 'TTCommonsProRegular, sans-serif'
        }
        },
      },
      series: [
        {
          name: this.label1,
          data: investedData,
          type: "column",
        },
        {
          name: this.label2,
          data: totalValueData,
          type: "column",
        },
      ],
    });
  }

// On Calculation params change
  onCalculatorChange = (e: any = null, type: string = "") => {
    let value: any = e?.target?.value || null;
    const maxLimit = this.getInputLimit(type, true);
    const minLimit = this.getInputLimit(type, false);


    if (type && maxLimit != undefined && minLimit != undefined
      && Object.values(this.sliderKeyName).includes(type)) {
      e.preventDefault();
      let v = value;
      if (Number(value) > maxLimit) {
        v = maxLimit;
      } else if (Number(value) < minLimit) {
        v = minLimit;
      }
      this.calcForm.controls[type].patchValue(v);
    }

    if (!this.calcForm || !this.calcForm.value) {
      return;
    }
    // Form Data
    const {
      sourceFund,
      targetFund,
      time,
      period,
      sourceROR,
      targetROR,
    } = this.calcForm.value

    // Clear previous calculated Data
    this.calculatedData = {};
    this.setGraphValues({});

    if (!sourceFund || !period) {
      return;
    }
    // -> Handle SIP
    const finalData = this._calcService.get_stp(
      sourceFund,
      targetFund,
      time,
      period,
      sourceROR,
      targetROR,
    );

    this.calculatedData = finalData;
    this.setGraphValues(finalData);

  };

  private renderChart(chartOptions: Highcharts.Options): void {
    Highcharts.chart('highcharts-container-stp', chartOptions);
  }

  setIsGraphView(v: any) {
    this.isGraphView = v;
  }

  autoFocus(event: any, refId: any) {
    const divId = document.getElementById(refId);
    divId.focus();
  }

  ngOnDestroy(): void {
    if (this.chartOptionsSubscription) {
      this.chartOptionsSubscription.unsubscribe();
    }
  }

}
