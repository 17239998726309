import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private chartOptions: Highcharts.Options = {};
  private chartOptionsSubject = new Subject<Highcharts.Options>();
  currentYear: number = new Date().getFullYear();

  getChartOptionsObservable(): Observable<Highcharts.Options> {
    return this.chartOptionsSubject.asObservable();
  }

  constructor() {

  }

  initializeChartOptions(data: Highcharts.Options, type?: boolean): void {

    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Hanken Grotesk,sans-serif'
        }
      }
    });
    if (!type) {
      this.chartOptions = {
        colors: this.getThemeColors(),
        chart: {
          type: 'areaspline',
          height: 277,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false
        },
        legend: {enabled: false},
        yAxis: {
          title: {
            text: 'Value Of Investment(&#8377)',
            style : {
              color: '#3C425D',
              fontWeight: 'bold',
              fontSize : '14px',
              fontFamily : 'TTCommonsProRegular, sans-serif'
          }
          },
          labels: {
            format: '{value}',
          },
        },
        /* xAxis: {
           title: {
             text: 'Investment Period',
           },
         },*/
        xAxis: {
          /*type: 'datetime',
          tickInterval: 1000 * 3600 * 24 * 365,
          units: [['year', [1]]],*/
          title: {
            text: 'Investment Period'
          },
          /*labels: {
            rotation: 330,
            format: '{value: %Y}',
          },*/
        },
        accessibility: {enabled: false},
        plotOptions: {
          areaspline: {
            fillOpacity: 0.3,
            threshold: 15
          },
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          },
          /*series: {
            lineWidth: 2
          }*/
          series: {
            lineWidth: 2,
            marker: {
              enabled: false
            },
            label: {
              connectorAllowed: false
            },
            /*pointStart: Date.UTC(2024, 0, 1),
            pointInterval: 365 * 24 * 3600 * 1000 / 1,
            relativeXValue: true*/
          }
        },
        tooltip: {
          shared: true
        },
        ...data
      };
    } else {
      this.chartOptions = {
        colors: ['#0d6efd', '#00847D'],//this.getThemeColors()
        chart: {
          type: 'column',
          height: 300,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        accessibility: {enabled: false},
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          borderWidth: 0,
          enabled: true,
          x: 10,
          y: 20,
          alignColumns: false,
          itemStyle: {color: '#000', fontWeight: 'bold'},
        },
        xAxis: {
          /*type: 'datetime',
          tickInterval: 1000 * 3600 * 24 * 365,
          units: [['year', [1]]],*/
          title: {
            text: ''
          },
          /*labels: {
            rotation: 330,
            format: '{value: %Y}',
          },*/
        },
        yAxis: {
          title: {
            text: ''
          },
        },
        tooltip: {
          shared: true,
          valueDecimals: 0,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            grouping: false,
          },
          series: {
            marker: {
              enabled: false
            },
            label: {
              connectorAllowed: false
            },
            /*pointStart: Date.UTC(2024, 0, 1),
            pointInterval: 365 * 24 * 3600 * 1000 / 1,
            relativeXValue: true*/
          }
        },
        ...data
      };
    }
  }

  updateChart(data: Highcharts.Options): void {
    this.chartOptions.series = data.series;
    this.chartOptions.xAxis = data.xAxis;
    this.chartOptionsSubject.next({...this.chartOptions});
  }

  processData(rawData: any): any {
    const labels: any[] = [];
    const totalValueData: any[] = [];
    const investedData: any[] = [];

    if (!rawData || !rawData.eachYearData) {
      return {
        labels: [],
        totalValueData: [],
        investedData: [],
      };
    }

    rawData.eachYearData.forEach((e: any) => {
      labels.push(e.year);
      e.initAmount ? totalValueData.push({y: Math.round(e.initAmount), name: e.year}) :
        e.sourceFund ? totalValueData.push({
          y: Math.round(e.sourceFund),
          name: e.year
        }) : totalValueData.push({y: Math.round(e.expectedAmt), name: e.year})
      e.finalInvestment ? investedData.push({y: Math.round(e.finalInvestment), name: e.year}) :
        e.destinationFund ? investedData.push({y: Math.round(e.destinationFund), name: e.year}) :
          investedData.push({y: Math.round(e.investedAmt), name: e.year})
    });

    return {
      labels,
      totalValueData,
      investedData,
    };
  }

  private getThemeColors(): string[] {
    return ['#A669CA', '#00847D'];
  }
}
