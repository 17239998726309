<div id="page-content" class="bg-img">
  <!-- start page breadcrumb block -->
  <section class="FT-footer-pages forms-download">
    <div class="container">
      <ng-container *ngIf="isLoaded; else showError">
        <div class="row" *ngIf="pageCategory === 'Reports'">
          <div class="col-md-5">
            <div class="FT-title-medium text-bold text-blue mb-4">
              {{ ("literature.reportPageHeading" | translate) }}
            </div>
            <div class="nav nav-tabs justify-content-between" id="myTab" role="tablist">
              <div class="form-group col-11">
                <input type="text" class="form-control search-box report-search" placeholder="Search" id="search-rep"
                       [(ngModel)]="searchedInput" (input)="onSearchInput($event)">
                <div class="search-result" *ngIf="searchSuggestions?.length && !clearTextBox">
                  <div class="inner">
                    <ng-container *ngFor="let suggestion of searchSuggestions">
                      <div class="funds-seach-title" (click)="onSearchSuggestionSelected(suggestion)"
                           style="position: relative; z-index: 999999;">
                        {{ suggestion }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="search-result"
                     *ngIf="!searchSuggestions.length && showNoSearchSuggestionMessage && !clearTextBox">
                  <div class="inner">
                    <div class="funds-seach-title" style="position: relative; z-index: 999999;">
                      {{ "literature.literature-listing-no-search-suggestion" | translate }}
                      "{{ searchedInputAgainstNoSearchSuggestionMessage }}"
                    </div>
                  </div>
                </div>

              </div>

              <div>
                <span class="make-tag-for-all-page d-md-none mob-view" id="x-icon" (click)="onClearSearch()"
                      style="position: relative;left: -21px;top: 8px">
                  <i class="bi bi-x-lg" style="cursor: pointer;"></i>
                </span>
                <span
                    class="make-tag-for-all-page search-tab-close d-none d-md-block" (click)="onClearSearch()"
                    style="position: relative;right:55px;top: 7px;">
                  <i class="bi bi-x-lg" style="cursor: pointer;"></i></span>
              </div>

            </div>
          </div>

          <div class="col-md-7">
            <div class="form-tabs" style="display: flex; margin-top: 7%;">
              <div class="d-lg-block d-none">
                <div class="d-flex filter-date fillter-date-box report-bg">
                  <span class="text-small text-medium d-md-inline-block mt-2 mx-4 report-tital">Filter by
                    Date
                  </span>
                  <form class="d-flex report-date-fiilter">
                    <div class="date-group bg-white report-date-group">
                      <input aria-label="selectdate" class="form-control date-field fillter-date" type="text"
                             placeholder="From"
                             [(ngModel)]="fromDate" [ngModelOptions]="{standalone:true}"/>
                      <ft-date-picker
                          [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd"
                          [convertToFormat]="dateFormatForComponent"
                          (finalDate)="onFinalDate($event, 'fromDate')"
                          [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
                    </div>
                    <div class="date-group mx-2 bg-white report-date-group">
                      <input aria-label="selectdate" type="text" style="background: #fff;"
                             class="form-control date-field fillter-date" placeholder="To"
                             [(ngModel)]="toDate" [ngModelOptions]="{standalone: true}">
                      <ft-date-picker
                          [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd"
                          [convertToFormat]="dateFormatForComponent" (finalDate)="onFinalDate($event, 'toDate')"
                          [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
                    </div>
                    <button class="btn btn-primary mx-2" (click)="applyFilter()">Apply</button>
                  </form>
                </div>
              </div>

            </div>
          </div>
          <div class="d-lg-none" style="margin-top: 7%;">
            <div class="dropdown-custom mb-2">
              <div class="form-group d-flex justify-content-between">
                <select class="form-select report-categ reports-page" style="font-size: 0.875rem"
                        aria-label="Default select example"
                        (change)="selectTabByDropDown($event,false)">
                  <option *ngFor="let filterDropdown of filterDropdowns[0]"
                          [selected]="filterDropdown?.label === selectedTab.label"
                          value="{{filterDropdown?.label}}" class="option-title">
                    {{ filterDropdown?.label }}
                  </option>
                </select>
                <button class="btn btn-white toggle-filter" id="report-filter" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottomFilter" aria-controls="offcanvasBottomFilter">
                  <i class="bi bi-filter fs-6"> </i>Date Filters
                </button>
              </div>
            </div>
          </div>

        </div>
        <ng-container *ngIf="pageCategory !== 'Reports'">
          <div class="FT-title-medium text-bold text-blue mb-4">{{ ("literature.downloadPageHeading" | translate) }}
          </div>
          <div class="d-flex form-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="filterDropdowns">
              <li class="nav-item" role="presentation" *ngFor="let filterDropdown of filterDropdowns[0]">
                <button class="nav-link" [class.active]="filterDropdown?.label === selectedTab.label"
                        (click)="selectTab(filterDropdown,false)" type="button" role="tab">
                  {{ filterDropdown?.label }}
                </button>
              </li>
            </ul>

            <div class="form-group">
              <input type="text" class="form-control search" placeholder="Search" [(ngModel)]="searchedInput"
                     (input)="onSearchInput($event)">
              <div class="search-result" *ngIf="searchSuggestions && !clearTextBox">
                <div class="inner">
                  <ng-container *ngFor="let suggestion of searchSuggestions">
                    <div class="funds-seach-title" (click)="onSearchSuggestionSelected(suggestion)"
                         style="position: relative; z-index: 999999;">
                      {{ suggestion }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="search-result" *ngIf="
                        !searchSuggestions.length &&
                        showNoSearchSuggestionMessage && !clearTextBox">
                <div class="inner">
                  <ng-container>
                    <div class="funds-seach-title"
                         style="position: relative; z-index: 999999;">
                      {{ "literature.literature-listing-no-search-suggestion" | translate }}
                      "{{ searchedInputAgainstNoSearchSuggestionMessage }}"
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div>
              <img alt="image" src="./assets/images/comparefund/closebutton.svg"
                   class="make-tag-for-all-page d-md-none share-icon2"
                   (click)="onClearSearch()" style="
                              position: relative;
                              left: 1210%;
                              top: 28px;">
              <img alt="image" src="./assets/images/comparefund/closebutton.svg"
                   class="make-tag-for-all-page search-tab-close d-none d-md-block share-icon2"
                   (click)="onClearSearch()" style="
                                 position: relative;
                                 left: -197%;
                                 top: -1px;">
            </div>
          </div>
        </ng-container>

        <div class="dropdown-custom mt-4 d-lg-none d-block" *ngIf="pageCategory!=='Reports' && filterDropdowns">
          <div class="form-group">
            <select class="form-select download-search" aria-label="Default select example"
                    (change)="selectCategory($event)">
              <option *ngFor="let category of categories" [selected]="selectedCategory === category"
                      value="{{category}}" class="option-title">{{ category }}
              </option>
            </select>
          </div>
        </div>


        <div class="bg-md-white mt-lg-5 mt-4">
          <div class="row cust-report" id="bg-form">
            <div class="col-lg-3 d-none d-lg-block bg-color" *ngIf="filterDropdowns">
              <div id="scroll-bar-report" class="scrollable-container">
                <div *ngIf="pageCategory ==='Reports'" class="nav nav-pills" id="v-pills-tab" role="tablist"
                     aria-orientation="vertical">
                  <button *ngFor="let filterDropdown of filterDropdowns[0]" class="nav-link"
                          [class.active]="filterDropdown?.label === selectedTab.label"
                          (click)="selectTab(filterDropdown,false)">
                    {{ filterDropdown?.label }}
                  </button>
                </div>
                <div *ngIf="pageCategory!=='Reports'" class="nav nav-pills" id="v-pills-tab" role="tablist"
                     aria-orientation="vertical">
                  <button class="nav-link" *ngFor="let category of categories;"
                          [class.active]="selectedCategory === category" (click)="selectCategory(category)">
                    {{ category }}
                  </button>
                </div>
              </div>
              <div class="button-container d-flex justify-content-center">
                <div class="audio-class-box" (click)="scrollToBottom()">
                  <img alt="scroll down" src="assets/images/fund/iconamoon_arrow-down-2.svg">
                </div>
              </div>

            </div>
            <div class="col-lg-9 col-12 bg-color" id="report-tabs">
              <ft-document-panel [fundDocuments]="fundDocuments" [allDocs]="documentName?.allDocs"
                                 [errorInService]="errorInService"
                                 [hasFilters]="true" [selectedFilter]="selectedCategory" [pageCategory]="pageCategory"
                                 [selectedTab]="selectedTab"
                                 [selectedCategoryData]="selectedCategoryData"
                                 (categoryChange)="onCategoryChange($event)"></ft-document-panel>
            </div>

          </div>
        </div>
      </ng-container>
      <ng-template #showError>
        <div class="text-center pb-5 mt-5">
          <div id="loader" *ngIf="serviceStatus ==='Loading' else elseBlock">
            <img src="assets/images/home/loader.gif" alt="Loading...">
          </div>
          <ng-template #elseBlock>
            <p>{{ serviceStatus }}</p>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </section>
</div>
<!-- filter mobile view popup -->

<div class="offcanvas offcanvas-bottom sort-bottom" tabindex="-1" id="offcanvasBottomFilter"
     aria-labelledby="offcanvasBottomLabel"
     style="height:75%; border-top-left-radius: 39px; border-top-right-radius: 39px;">
  <div class="offcanvas-header">
    <div class="line"></div>
    <div class="d-flex justify-content-between mt-4 w-100">
      <div>
        <div class="modal-title custom-modal-title" id="callBackLabel">Filters</div>
      </div>
      <div class="text-end">
        <div type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><img
            alt="Close" src="assets/images/general/Frame1000009207.svg"></div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body small">
    <div class="d-flex filter-date report-bg">
      <div class="mb-3">
        <span class="text-small text-medium d-md-inline-block mt-2 report-tital fw-bold fs-6 mx-2">Select Date
        </span>
      </div>

      <form class="d-flex report-date-fiilter filter-form" style="margin-left: 8px;">
        <div class="date-group bg-white report-date-group">
          <input aria-label="selectdate" class="form-control date-field fillter-date" type="text" placeholder="From"
                 [(ngModel)]="fromDate" [ngModelOptions]="{standalone:true}"/>
          <ft-date-picker [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd"
                          [convertToFormat]="dateFormatForComponent" (finalDate)="onFinalDate($event, 'fromDate')"
                          [customLeftPosition]="'calc(0% - 120px)'"></ft-date-picker>
        </div>
        <div class="date-group mx-2 bg-white report-date-group">
          <input aria-label="selectdate" type="text" style="background: #fff;"
                 class="form-control date-field fillter-date"
                 placeholder="To" [(ngModel)]="toDate" [ngModelOptions]="{standalone: true}">
          <ft-date-picker [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd"
                          [convertToFormat]="dateFormatForComponent" (finalDate)="onFinalDate($event, 'toDate')"
                          [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
        </div>

      </form>
    </div>
  </div>
  <div class="filter-footer d-flex d-lg-none">
    <button class="btn btn-outline-primary" (click)="clearFilter()">Clear All</button>
    <button class="btn btn-primary" data-bs-dismiss="offcanvas" (click)="applyFilter()">Apply</button>
  </div>
</div>
