export const KC_CATEGORIES = [
  {
    id: "all",
    label: "All",
    active: true
  },
  {
    id: "watchlist",
    label: "Watchlist",
    active: true
  },
];
