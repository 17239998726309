import { Distribution } from '@products/models/distribution';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  CurrencyCode,
  DistributionDTO,
  FundId,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class DistributionsMapper extends Mapper<Distribution[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(distributionsDTO: DistributionDTO[]): Distribution[] {
    const distributions: Distribution[] = [];

    distributionsDTO.forEach((distributionDTO: DistributionDTO) => {
      const distribution: Distribution = this.createMapper(
        DistributionMapper
      ).toDomain(distributionDTO);

      if (distribution) {
        distributions.push(distribution);
      }
    });

    return distributions;
  }
}

export class DistributionMapper extends Mapper<Distribution> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(distributionDTO: DistributionDTO): Distribution {
    return {
      fundId: distributionDTO.fundid as FundId,
      shareClassCode: distributionDTO.shclcode as ShareClassCode,
      countryCode: distributionDTO.cntrycode as ConfigurationCountry,
      languageCode: distributionDTO.langcode as ConfigurationLocale,
      currencyCode: distributionDTO.cntrycode as CurrencyCode,
      distributionAsOfDate: distributionDTO.asofdate,
      distributionAsOfDateStd: distributionDTO.asofdatestd,
      incomeDistributionRecordDate: distributionDTO.incmdistrecorddate,
      incomeDistributionExDividendDate: distributionDTO.incmdistexdivdate,
      reInvestmentDate: distributionDTO.reinvstmntdate,
      incomeDistributionAmount: distributionDTO.incmdistamt,
      taxEquivalentDistributionRate: distributionDTO.taxequivdistrate,
      reInvestmentAmount: distributionDTO.reinvestmentamt,
      exDividendNavValue: distributionDTO.exdivnavval,
      recordDateNavVal: distributionDTO.recdtnavval,
    };
  }
}
