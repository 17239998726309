<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <section class="FT-updates-banners" id="updates-banners">
    <div class="accordion" id="accordionExample" *ngIf="hiddenSection">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne" (click)="toggleCollapse()">
          <button
              class="accordion-button"
              [ngClass]="{'collapsed': !isCollapsed}"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              [attr.aria-expanded]="isCollapsed"
              aria-controls="collapseOne"
              (click)="checkCollaps(isCollapsed)">
            <div class="container-fluid regulatory-content">
              <img alt="Important Regulatory Updates" height="19px" src="assets/images/home/note.svg"/>
              Important Regulatory Updates
            </div>
          </button>
        </h2>
        <div
            id="collapseOne"
            class="accordion-collapse collapse"
            [ngClass]="{ show : isCollapsed }"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
          <div class="accordion-body p-0">
            <div id="banners" class="carousel slide swiper" data-bs-ride="carousel"
                 [attr.data-bs-interval]="content?.dataInterval">
              <div class="carousel-indicators">
                <button type="button" *ngFor="let result of CarouselImages; let i = index" data-bs-target="#banners"
                        [attr.data-bs-slide-to]="i"
                        [attr.aria-current]="{ true: i === 0 }"
                        aria-label="Slide i"
                        [ngClass]="{ active: i === 0 }"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item"
                     *ngFor="let result of CarouselImages; let i = index"
                     [ngClass]="{ active: i === 0 }">
                  <a title="{{ result?.title }}"
                     [target]="result?.linkCompound?.linkCollection[0]?.target"
                     [href]="result?.linkCompound?.linkCollection[0]?.url"
                     class="analytics-carousel-link quicAction-link redirectGuestUrl a1">
                    <img src="{{getImageURL(result?.imageForDesktop?.widenDocument)}}"
                         alt="{{ result?.altTitle }}"
                         title="{{ result?.title }}"
                         class="d-none d-sm-block w-100"/>

                    <div class="d-block d-sm-none text-center" class="banner2-sm">
                      <img src="{{getImageURL(result?.imageForMobile?.widenDocument)}}"
                           alt="{{ result?.altTitle }}" title="{{ result?.title }}"/>
                    </div>
                  </a>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#banners" data-bs-slide="prev">
                <i class="bi bi-arrow-left-short"></i>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#banners" data-bs-slide="next">
                <i class="bi bi-arrow-right-short"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
          class="accordion-button close-btn notification-close-btn"
          [ngClass]="{collapsed : !isCollapsed}"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          [attr.aria-expanded]="isCollapsed"
          aria-controls="collapseOne"
          (click)="checkCollaps(isCollapsed)">
        <img alt="image" src="assets/images/home/collape-btn.webp"/>
      </button>
    </div>
  </section>
</div>
