import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@components/shared/translate/translate.service";
import { Subject, fromEvent, timer } from "rxjs";
import { debounceTime, filter, map, pluck, takeUntil } from "rxjs/operators";
@Component({
  selector: "ft-search-data",
  templateUrl: "./search-data.component.html",
  styleUrls: ["./search-data.component.scss"],
})
export class SearchDataComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("searchBarInput") searchBarInput: ElementRef;
  private unsubscribe$: Subject<void> = new Subject<void>();
  searchText = "";
  showAutoSuggestions: boolean = false;
  triggerChange: boolean = false;
  recentSearches: any;
  strIntoObj: any[];
  suggestions: any = [];
  getSuggestionsArray: any;
  
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loadRecentSearches();
    this.getSuggestionsArray = this.translateService.instant('ftiGeneric.search-suggestions')
    /** 
     * Implement the timer to set the suggested data.
     */
    timer(0).subscribe(() =>{ this.getSearchSuggetions()});
  }

  getSearchSuggetions(){
    this.strIntoObj = JSON.parse(this.getSuggestionsArray);
    this.suggestions = this.strIntoObj;
  }

  resetFundExplore() {
    this.searchText = "";
    this.showAutoSuggestions = false;
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
    fromEvent<string>(this.searchBarInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          if (event.keyCode === 13) {
            this.navigateToSearchResults();
            return;
          }
          return event;
        }),
        filter(Boolean),
        pluck("target", "value"),
        debounceTime(500),
        filter((term) => String(term).length >= 3 || term === ""),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((term: string) => {
        this.showAutoSuggestions = false;
        if (term) {
          this.searchText = term;
          this.showAutoSuggestions = true;
          this.triggerChange = !this.triggerChange;
        }
        this.changeDetector.detectChanges();
      });
  }
  
  navigateToSearchResults(searchTerm?:any): void {
    let searchText = searchTerm ? searchTerm : this.searchText;
    this.router.navigate(["/search"], {
      queryParams: { query: searchText, filter: "all" },
    });
    if(searchText){
      this.addToRecentSerch(searchText);
    }
    this.redirectToNextPage();
  }
  

  redirectToNextPage() {
    let ele = document.getElementById("modalClosebtn");
    if(ele){
      ele.click();
    }    
    this.close.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  checkRecentSearch(){
    this.loadRecentSearches();
    this.redirectToNextPage();
  }
  loadRecentSearches() {
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    this.recentSearches = recentSearches.slice(-4);
  }

  clearRecentSearch(){
    localStorage.removeItem('recentSearches');
    this.loadRecentSearches();
    this.changeDetector.detectChanges();
  }
  addToRecentSerch(searchTerm:any, type= 'general'){
    const MAX_RECENT_SEARCHES = 4;
    let recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    recentSearches.unshift({ term: searchTerm, type:type, timestamp: new Date().toISOString() });
    recentSearches = recentSearches.slice(0, MAX_RECENT_SEARCHES);
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }
  renderHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);// NOSONAR
  }
}
