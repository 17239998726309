import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { asyncScheduler, fromEvent, Observable } from 'rxjs';
import { map, startWith, throttleTime } from 'rxjs/operators';

/**
 * represents the available width and height of browser window
 */
export interface WindowSize {
  width: number;
  height: number;
}

/**
 * represents the x and y scroll position of browser window
 */
export interface WindowOffset {
  x: number;
  y: number;
}

/**
 * A service that provides the Window object's events such as resize and scroll etc
 */
@Injectable({
  providedIn: 'root',
})
export class WindowService {
  /**
   * constructor gets injected with the Angular CDK BreakpointObserver
   */
  constructor(@Inject(WINDOW) private windowRef: Window) {}

  /**
   * Returns an observable stream that fires every time the window is resized
   * @param throttleMs: throttle time in millisecs (default 100ms)
   */
  public getResizeObs$ = (throttleMs = 100): Observable<WindowSize> =>
    fromEvent(this.windowRef, 'resize').pipe(
      throttleTime(throttleMs, asyncScheduler, {
        leading: true,
        trailing: true,
      }),
      map(this.getWindowSize),
      startWith(this.getWindowSize())
    );

  // returns a WindowSize object with the current window height and width
  public getWindowSize = (): WindowSize => ({
    width: this.windowRef.innerWidth,
    height: this.windowRef.innerHeight,
  });

  /**
   * Returns Observable from window scroll event
   * @param throttleMs: throttle time in millisecs (default 100ms)
   * @returns Observable<Event>
   */
  public getScrollObs$ = (throttleMs = 100): Observable<WindowOffset> =>
    fromEvent(this.windowRef, 'scroll').pipe(
      throttleTime(throttleMs, asyncScheduler, {
        leading: true,
        trailing: true,
      }),
      map(
        (): WindowOffset => ({
          x: this.windowRef.scrollX,
          y: this.windowRef.scrollY,
        })
      )
    );
}
