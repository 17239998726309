<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                parameter="document"
                [relative]="false" root="">
  </ng-container>
  <section class="FT-solutions bg-gradient">
    <!-- <section class="FT-digitalize  bg-gradient"> -->
    <div class="container-fluid px-0">
      <div class="text-center">
        <div class="FT-title"> {{ preTitle }}
        </div>
        <div class="text-big text-white services-desc">
          {{ title }}
        </div>
      </div>

      <ngx-slick-carousel class="carousel desktop-services-wrapper" #slickModal="slick-carousel" [config]="slideConfig"
                          style="padding: 0px 10px;">
        <ng-container *ngFor="let content of linkLists">
          <div ngxSlickItem class="slide">
            <a class="solution-box" href="{{content?.linkCompound?.url}}"
               [target]="content?.linkCompound?.target ==='' ? '_self' : content?.linkCompound?.target">
              <h3 class="colorTitle">{{ content?.linkCompound?.displayText }}</h3>
              <img alt="image" src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}"/>
              <div class="btn btn-primary goal-btn-zoom-fixes">
                {{ content?.linkCompound?.title }}
                <!-- <button  class="btn btn-primary"> {{ content?.linkCompound?.title }} </button> -->
              </div>
            </a>
          </div>

        </ng-container>
      </ngx-slick-carousel>
      <div class="mobile-services-wrapper">
        <ng-container *ngFor="let contentPair of pairElements(linkLists)">
          <div class="services">
            <ng-container *ngFor="let content of contentPair">
              <a class="service" href="{{ content?.linkCompound?.url }}"
                 [target]="content?.linkCompound?.target === '' ? '_self' : content?.linkCompound?.target">
                <div class="image-wrapper">
                  <img alt="image" class="image" src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}"
                       alt="{{ content?.linkCompound?.displayText }}">
                </div>
                <p class="description">{{ content?.linkCompound?.displayText }}</p>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="text-center">
        <a href="{{exploreallsolutionsURL}}" class="more-links footer-links-CTA"
           target="_self"><span>{{ exploreallsolutionsLabel }}</span><i class="bi bi-chevron-right ms-3"></i></a>
      </div>
    </div>
  </section>
</div>
