<!-- start section related tags -->
<section class="FT-related-tags rel-tags" id="related_tag">
  <div class="container">
    <ul class="tags-list" *ngIf="relatedTags && relatedTags.length > 0">
      <li>
        {{ "ftiKc.related-tags-label" | translate }}
        <ng-container *ngFor="let breadcrumb of relatedTags">
          <span class="tag">
            <span class="knowledge-related-heading-cta"
                  [routerLink]="['/knowledge-centre/quick-learn/related-articles']"
                  [queryParams]="{rc:breadcrumb.trim().replaceAll(' ','_')}">{{ breadcrumb }}</span>
          </span>
        </ng-container>
      </li>
    </ul>
    <div class="row justify-content-between">
      <div class="col-md-6 col-12" *ngIf="previousArticle && previousArticle?.title !==''">
        <div class="text-big mb-md-4 mb-3 text-bold" *ngIf="documentType==='video' else prevArticleTitle">
          {{ "ftiKc.previous-video-label" | translate }}
        </div>
        <ng-template #prevArticleTitle>
          <div class="text-big mb-md-4 mb-3 text-bold">
            {{ "ftiKc.previous-article-label" | translate }}
          </div>
        </ng-template>
        <div class="category-card big-card mb-3">
          <div class="card-img">
            <a [href]="previousArticle?.links?.site?.href"> <!--ftiKc.defaultArticleBannerImage-->
              <img src="{{ getImageURL('previous')? getImageURL('previous'): defaultArticleBannerImage }}"
                   alt="Previous Article"/>
            </a>
            <span class="time-tag">{{ previousArticle?.readingTitle }}</span>
          </div>
          <div class="card-body">
            <div class="">
              <div class="card-icons" style="float: right;">
                <!-- <span class="img-with-tooltip me-3">
                  <i *ngIf="previousArticle.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark-fill"
                     (click)="removeToWatchlist(previousArticle)"></i>
                  <i *ngIf="!previousArticle.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark"
                     (click)="addToWatshlist(previousArticle)"></i>
                  <span *ngIf="!previousArticle.isWatchListed" class="tooltip-block">{{
                          "products.add-cart-save-button" |
                              translate
                    }}</span>
                  <span *ngIf="previousArticle.isWatchListed" class="tooltip-block">{{
                          "products.article-saved-cource-button" | translate
                    }}</span>
                </span> -->
                <span class="dropdown">
                  <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="image" src="assets/images/knowledge-center/share.svg" (mouseenter)="showTooltip = true"
                         (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                         class="d-inline-block mobile-icons light-mode-icon" style="width: 18px;">
                    <img alt="image" src="assets/images/dark/share-dark-icon.png" (mouseenter)="showTooltip = true"
                         (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                         class="mobile-icons dark-mode-icon"
                         style="width: 21px; top: 7px; position: relative; left: 0;">
                    <span *ngIf="showTooltip" class="tooltip-block">{{ "ftiKc.share-label" | translate }}</span>
                  </span>

                  <ul class="dropdown-menu dropdown-menu-mobile">
                    <li><a style="cursor: pointer;" (click)="toggleShowCopyDialoge(true,'previous')"
                           class="dropdown-item"><img alt="Share/Copy"
                                                      src="assets/images/general/Link.webp">{{
                            "products.home-fund-card-share-copy-btn" | translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER','previous')">
                      <img alt="image" class="share-icon" style="width: 20px;"
                           src="assets/images/general/twitter-new-icon.webp">{{
                            "products.home-fund-card-share-twitter-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('FACEBOOK','previous')">
                      <img alt="image" src="assets/images/general/FacebookLogo.webp">{{
                            "products.home-fund-card-share-fb-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN','previous')">
                      <img alt="image" src="assets/images/general/LinkedinLogo.webp">{{
                            "products.home-fund-card-share-linkedin-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP','previous')">
                      <img alt="image" src="assets/images/general/whatsapps.webp">{{
                            "products.home-fund-card-share-whatsapp-btn" |
                                translate
                      }}</a></li>
                  </ul>
                </span>
              </div>
            </div>
            <div class="text-bold card-title mt-md-4 mt-3 forlikecardtitle">{{ previousArticle?.title }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12" *ngIf="nextArticle"> <!--col-md-auto converted to col-md-6 by sp8 to fix the width-->
        <div class="text-big mb-md-4 mb-3 text-bold" *ngIf="documentType==='video' else nextArticleTitle">
          {{ "ftiKc.next-video-label" | translate }}
        </div>
        <ng-template #nextArticleTitle>
          <div class="text-big mb-md-4 mb-3 text-bold">
            {{ "ftiKc.next-article-label" | translate }}
          </div>
        </ng-template>
        <div class="category-card big-card mb-3">
          <div class="card-img">
            <a [href]="nextArticle?.links?.site?.href">
              <img src="{{ getImageURL('previous')? getImageURL('previous'): defaultArticleBannerImage }}"
                   alt="Next Article"/>
            </a>
            <span class="time-tag">{{ nextArticle?.readingTitle }}</span>
          </div>
          <div class="card-body">
            <div class="">
              <div class="card-icons" style="float: right;">
                <!-- <span class="img-with-tooltip me-3">
                  <i *ngIf="nextArticle.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark-fill"
                     (click)="removeToWatchlist(nextArticle)"></i>
                  <i *ngIf="!nextArticle.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark"
                     (click)="addToWatshlist(nextArticle)"></i>
                  <span *ngIf="!nextArticle.isWatchListed" class="tooltip-block">{{
                          "products.add-cart-save-button" |
                              translate
                    }}</span>
                  <span *ngIf="nextArticle.isWatchListed" class="tooltip-block">{{
                          "products.article-saved-cource-button" | translate
                    }}</span>
                </span> -->
                <span class="dropdown">
                  <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="image" src="assets/images/knowledge-center/share.svg" (mouseenter)="showTooltip = true"
                         (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                         class="d-inline-block mobile-icons light-mode-icon" style="width: 18px;">
                    <img alt="image" src="assets/images/dark/share-dark-icon.png" (mouseenter)="showTooltip = true"
                         (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                         class="mobile-icons dark-mode-icon"
                         style="width: 21px; top: 7px; position: relative; left: 0;">
                    <span *ngIf="showTooltip" class="tooltip-block">{{ "ftiKc.share-label" | translate }}</span>
                  </span>
                  <ul class="dropdown-menu dropdown-menu-mobile" style="right:0">
                    <li><a style="cursor: pointer;" (click)="toggleShowCopyDialoge(true,'next')"
                           class="dropdown-item"><img alt="Copy Link"
                                                      class="share-icon" src="assets/images/general/Link.webp">{{
                            "products.home-fund-card-share-copy-btn" | translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER','next')">
                      <img alt="image" class="share-icon" style="width: 20px;"
                           src="assets/images/general/twitter-new-icon.webp">{{
                            "products.home-fund-card-share-twitter-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('FACEBOOK','next')">
                      <img alt="image" class="share-icon" src="assets/images/general/FacebookLogo.webp">{{
                            "products.home-fund-card-share-fb-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN','next')">
                      <img alt="image" class="share-icon" src="assets/images/general/LinkedinLogo.webp">{{
                            "products.home-fund-card-share-linkedin-btn" |
                                translate
                      }}</a></li>
                    <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP','next')">
                      <img alt="image" class="share-icon" src="assets/images/general/whatsapps.webp">{{
                            "products.home-fund-card-share-whatsapp-btn" |
                                translate
                      }}</a></li>
                  </ul>
                </span>
              </div>
            </div>
            <div class="text-bold card-title mt-md-4 mt-3 forlikecardtitle">{{ nextArticle?.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">

  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false,'none')">
      <img alt="image" src="assets/images/general/close.webp">
    </span>
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>

</div>

<!-- Dialoge: Max allowed alert -->
<div class="save-alert" style="z-index:10000" *ngIf="watchListFull">
  <div class="content">
    <ng-container>
      <a class="close-btn" (click)="closeWatchlist()">
        <img alt='image' src="assets/images/general/close.webp">
      </a>
      <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
      <span style="font-size: 1.063rem;">{{ "ftiKc.kc-watchlist-full" | translate }}</span>
    </ng-container>
  </div>
</div>

