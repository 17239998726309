import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, } from "@angular/core";
import { Subject, timer } from "rxjs";
import { Constant } from "src/app/ft-constants/constant";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import { CommonService } from "@services/common.service";
import { FtsearchService } from "@components/shared/search/ftsearch.service";
import { TranslateService } from "@components/shared/translate/translate.service";
import { AnalyticsService } from "@services/analytics.service";
import { Router } from "@angular/router";
import { getPagination } from "@utils/pagemodel-utils";
import { takeUntil } from "rxjs/operators";
import { KC_CATEGORIES } from "src/app/ft-constants/knowledge-center.constants";
import { KnowledgeCenterService } from "@services/knowledge-center.service";
import { Logger } from "@utils/logger";
import moment from "moment";

const logger = Logger.getLogger("CategoryComponent");

@Component({
  selector: "ft-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;
  pagination: any;
  selectedTab = "";
  allArticleArray = [];
  articleArray = [];
  articleListArray: { [key: string]: any[] } = {};
  articlePageName: string;
  componentDestroyed$: Subject<boolean> = new Subject();
  start = 0;
  end = 8;
  pages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: "",
  };
  totalResults: number;
  watchListFull: boolean = false;

  // Resource Bundle
  defaultArticleBannerImage: any;
  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow:
      "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow:
      "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  showTooltip: boolean = false;
  showIconForWishlist: boolean[] = [];
  showIconForWatchlist: boolean[] = [];
  searchTerm: string = "";
  funds: any = []; // please maintain model don't use any
  selectedFund: any = null;
  fundsForSearch: any = [];
  fullFundsArray: any = [];

  selectedCategory: any = KC_CATEGORIES[0];
  visibleCategories: number = 3;
  categories: any;

  searchPlaceholderText: string = "";
  dataArray: any = [];
  dataArrayCopy: any;
  showCopyDialoge: boolean = false;
  linkToCopy: string;
  categoryList: any;
  strIntoObj: any[];

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event: any) {
    const sections = document.querySelectorAll<any>(".fd-tab-sections");
    const navLi = document.querySelectorAll(".FT-blocks-nav ul li");
    let current = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (scrollY >= sectionTop - 85) {
        current = section.getAttribute("id");
      }
    });

    navLi.forEach((li) => {
      current ? li.children[0].classList.remove("active-item") : "";
      if (li.children[0].classList.contains(current)) {
        li.children[0].classList.add("active-item");
      }
    });
  }

  @HostListener("click", ["$event"])
  getTableDataPage(event: Event) {
    if (
      (event.target as Element).className ===
      "form-control search ng-untouched ng-pristine ng-valid" ||
      (event.target as Element).className ===
      "form-control search ng-valid ng-dirty ng-touched" ||
      (event.target as Element).className ===
      "form-control search ng-valid ng-touched ng-dirty"
    ) {
      this.fundsForSearch = this.fullFundsArray;
    } else {
      this.fundsForSearch = [];
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize($event: any = {}) {
    // based on width changed search placeholder
    const element: any = window.innerWidth;
    if (element < 500) {
      this.searchPlaceholderText = "Search";
    } else {
      this.searchPlaceholderText =
        "Search articles, videos, blogs, podcasts, & e-books...";
    }
  }

  constructor(
    private commonService: CommonService,
    private searchService: FtsearchService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private knowledgeService: KnowledgeCenterService
  ) {
  }

  ngOnInit(): void {
    const categoryList = this.translateService.instant("ftiKc.KC-categories-list")
    timer(0).subscribe(() => {
      this.getSearchSuggetions(categoryList)
    });
  }

  getSearchSuggetions(categoryList) {
    this.strIntoObj = JSON.parse(categoryList);
    this.categoryList = JSON.parse(categoryList);

    this.getFilteredCategoryList()
  }

  getFilteredCategoryList() {
    const filteredCategoryList = this.categoryList.filter(
      (val) => val.active == true
    );
    this.categories = [...KC_CATEGORIES, ...filteredCategoryList];
    this.onWindowResize();
    this.content();
    for (let i = 1; i <= this.visibleCategories; i++) {
      const filteredCategories = this.categories.filter(
        (val, index) => index !== 1 && val.active == true
      );
      this.getFTIArticleData(filteredCategories[i].id, 0, 5);
    }

    // Get values from resource bundle
    this.defaultArticleBannerImage = this.translateService.instant(
      "ftiKc.defaultArticleBannerImage"
    );
  }

  get params() {
    return this.component?.getParameters();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  content() {
    if (!this.pagination) {
      this.pagination = getPagination(this.page, this.component);
    }
    return this.pagination;
  }

  /**
   * sets anlytics data on click of tiles of articles/videos
   * @param title title of the artcle/video
   */

  setAnalytics(title, fromArticle?: any): void {
    let viewArticle: string;
    fromArticle === 'INDVideoArticles' ? viewArticle = 'View video' : viewArticle = 'View Article'

    this.analyticsService.trackEvent({
      event: "Event InvestorEducation",
      category: "Investor Education",
      action: viewArticle,
      label: "New to Investing | " + title,
    });
  }

  getFTIArticleData(pageType, start, end) {
    if (this.selectedCategory.id == "all") {
      this.articleListArray[pageType] = [];
    }
    this.articleArray = [];
    const watchListedIds = this.getWatchListedIds(); // Get watch-listed documentIds from local storage
    if (start === 0) {
      this.pages.results = [];
    }
    this.end = end;
    this.articlePageName = pageType;
    const filterData = [
      {
        fieldName: "documentType.exact",
        fieldValue: ["INDArticleDetails", "INDVideoArticles"],
      },
      {fieldName: "pageType", fieldValue: [pageType]},
    ];
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.pages.results = data["results"]?.response?.hits["hits"] || [];
        this.totalResults = data["results"]?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl =
              article?._source?.documentPath?.split("site-pages")[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          let isWatchListed = false;          
          watchListedIds.forEach(id =>{ 
            if(id.pageTitle === pageTitle){
              isWatchListed = true;
            }
          })
            
          // const isWatchListed = watchListedIds.includes(pageTitle);
          const selectedArticleList = [];
          selectedArticleList.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pdfUrl: article?._source?.pdfURL,
            pageTitle: pageTitle,
            isWatchListed: isWatchListed,
          });
          selectedArticleList.sort((a, b) =>
            a.data?._source?.publishDate.localeCompare(
              b.data?._source?.publishDate
            )
          );
          selectedArticleList.forEach((item) => {
            if (
              !this.allArticleArray.some(
                (article) => article?.data?._id === item?.data?._id
              )
            ) {
              this.allArticleArray.push(item);
            }
          });
          if (this.selectedCategory.id == "all") {
            selectedArticleList.forEach((item) => {
              this.articleListArray[pageType].push(item);
            });
            this.dataArray = Object.entries(this.articleListArray);
            this.dataArrayCopy = JSON.parse(JSON.stringify(this.dataArray));
          } else {
            selectedArticleList.forEach((item) => {
              this.articleArray.push(item);
            });
          }
        });
        this.cdRef.detectChanges();
      });
  }

  getFTIBlogData(pageType, start, end) {
    if (this.selectedCategory.id == "all") {
      this.articleListArray[pageType] = [];
    }
    this.articleArray = [];
    const watchListedIds = this.getWatchListedIds(); // Get watch-listed documentIds from local storage
    if (start === 0) {
      this.pages.results = [];
    }
    const filteredValue = [
      {fieldName: "documentType.exact", fieldValue: ["INDArticleDetails"]},
      {fieldName: "articleType.keyword", fieldValue: ["Blog"]},
      {
        fieldName: "blogCategory.keyword",
        fieldValue: ["For the Love of Money"],
      },
    ];
    this.end = end;
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIBlogResults(JSON.stringify(filteredValue), start, this.end)
      ?.pipe(takeUntil(this.componentDestroyed$))
      ?.subscribe((data: any) => {
        this.pages.results = data["results"]?.response?.hits["hits"] || [];
        this.totalResults = data["results"]?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl =
              article?._source?.documentPath?.split("site-pages")[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          const isWatchListed = watchListedIds.includes(pageTitle);
          const selectedArticleList = [];
          selectedArticleList.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            date: moment(article?._source?.publishDate).format(
              Constant.dateFormat
            ),
            pageTitle: pageTitle,
            isWatchListed: isWatchListed,
          });
          selectedArticleList.sort((a, b) =>
            a.data?._source?.publishDate.localeCompare(
              b.data?._source?.publishDate
            )
          );
          selectedArticleList.forEach((item) => {
            if (
              !this.allArticleArray.some(
                (article) => article?.data?._id === item?.data?._id
              )
            ) {
              this.allArticleArray.push(item);
            }
          });
          if (this.selectedCategory.id == "all") {
            selectedArticleList.forEach((item) => {
              this.articleListArray[pageType].push(item);
            });
            this.dataArray = Object.entries(this.articleListArray);
            this.dataArrayCopy = JSON.parse(JSON.stringify(this.dataArray));
          } else {
            selectedArticleList.forEach((item) => {
              this.articleArray.push(item);
            });
          }
        });
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  ngOnDestroy() {
  }

  scrollTo(param: any) {
    const elementToScroll = document.getElementById(param);
    if (elementToScroll) {
      // Scroll to the element with a delay of 10 milliseconds (or any desired delay)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  categoryOnClick(slide: any) {
    if (!slide?.pdfUrl && slide?.url) {
      this.router.navigate([this.page.getUrl(slide?.url)]);
    }
    if (slide?.pdfUrl) {
      window.open(slide?.data?._source?.pdfURL, "_blank");
    }
  }

  selectCategory(category: any): void {
    this.articleArray = [];
    this.selectedCategory = category;
    if (
      category.id !== "all" &&
      category.id !== "watchlist"
    ) {
      const articlesMatchingCategory = this.allArticleArray.filter(
        (value) => value?.data?._source?.pageType == category.id || value?.data?._source?.pageType.includes(category.id)
      );
      if (articlesMatchingCategory?.length !== undefined) {
        if (articlesMatchingCategory?.length > 0) {
          this.articleArray = articlesMatchingCategory;
        } else {
          if (category.id !== "blog") {
            this.getFTIArticleData(category.id, 0, 5);
          } else {
            this.getFTIBlogData(category.id, 0, 5);
          }
        }
      }

    }
    if (category.id == "watchlist") {
      const watchList = this.knowledgeService.getWatchList();
      if (watchList) {
        this.articleArray = watchList;
      }
    }
  }

  loadMoreCategories(): void {
    const filteredCategories = this.categories.filter(
      (val, index) => index !== 1
    );

    if (filteredCategories?.length !== undefined) {
      if (this.visibleCategories + 3 <= filteredCategories?.length - 1) {
        this.visibleCategories += 3;
      } else {
        this.visibleCategories = filteredCategories?.length - 1;
      }
      if (this.dataArrayCopy?.length != filteredCategories?.length - 1) {
        for (
          let i = this.dataArray?.length + 1;
          i <= this.visibleCategories;
          i++
        ) {
          if (filteredCategories[i].id != "blog") {
            this.getFTIArticleData(filteredCategories[i].id, 0, 5);
          } else {
            this.getFTIBlogData(filteredCategories[i].id, 0, 5);
          }
        }
      } else {
        this.dataArray = [];
        this.dataArray = this.dataArrayCopy.slice(0, this.visibleCategories);
      }
      setTimeout(() => {
        this.scrollTo(filteredCategories[this.visibleCategories].id)
      }, 1000);
    }

  }

  loadLessCategories(): void {
    this.visibleCategories = 3;
    this.dataArray.splice(3);
    this.scrollTo(this.categories[this.visibleCategories + 1].id)
  }

  toggleIconForWishlist(index: number) {
    this.showIconForWishlist[index] = !this.showIconForWishlist[index];
  }

  getCategoryTitle(categoryId: any) {
    if (this.categories) {
      const category = this.categories.find((value) => value.id === categoryId);
      return category ? category.label : "";
    }
    return
  }

  getTableTitle(fund: any) {
    this.selectedFund = fund; // Update the selected fund variable
    this.searchTerm = this.selectedFund.title;
    const fundsArray = [...this.fullFundsArray];
    const selectedFund = fundsArray.find(
      (fund) => fund.id === this.selectedFund.id
    );
    this.funds = [selectedFund];
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

  async toggleShowCopyDialoge(check: boolean, article?: any): Promise<void> {
    if (check) {
      this.linkToCopy = article?.data?._id;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  shareOnApp(appName, article): void {
    const url = `${article.url}`;
    this.commonService.shareOn(appName, url);
  }

  toggleIconForWatchlist(index: number) {
    this.showIconForWatchlist[index] = !this.showIconForWatchlist[index];
  }

  addToWatshlist(article: any) {
    this.watchListFull = false;
    const watchListedIds = this.knowledgeService.getWatchList();
    if (watchListedIds?.length !== undefined) {
      if (watchListedIds?.length <= 7) {
        const articleToUpdate = this.articleArray.find(
          (articleInArray) => articleInArray.pageTitle === article?.pageTitle
        );
        if (articleToUpdate) {
          articleToUpdate.isWatchListed = true;
        }

        this.dataArray.forEach((item) => {
          const foundArticle = item[1].find(
            (articleInArray) => articleInArray.pageTitle === article?.pageTitle
          );
          if (foundArticle) {
            foundArticle.isWatchListed = true;
          }
        });
        this.knowledgeService.addToWatchList(article);
      } else {
        this.watchListFull = true;
      }
    }
  }

  closeWatchlist() {
    this.watchListFull = false;
  }

  removeToWatchlist(article: any) {
    // Remove from watchlist
    this.knowledgeService.removeFromWatchList(article);

    const articleToUpdate = this.articleArray.find(
      (articleInArray) => articleInArray.pageTitle === article?.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = false;
    }
    const allArticleToUpdate = this.allArticleArray.find(
      (articleInArray) => articleInArray.pageTitle === article?.pageTitle
    );
    if (allArticleToUpdate) {
      allArticleToUpdate.isWatchListed = false;
    }

    this.dataArray.forEach((item) => {
      const foundArticle = item[1].find(
        (articleInArray) => articleInArray.pageTitle === article?.pageTitle
      );
      if (foundArticle) {
        foundArticle.isWatchListed = false;
      }
    });
    if (this.selectedCategory.id === "watchlist") {
      const watchList = this.knowledgeService.getWatchList();
      if (watchList) {
        this.articleArray = watchList;
      }

      // const watchList = this.knowledgeService.getWatchList();
      // this.articleArray = this.allArticleArray.filter((item) =>
      //   watchList.includes(item?.pageTitle)
      // );
    }
  }

  getWatchListedIds(): any {
    // Retrieve watch-listed articleIds from local storage
    const watchListString = localStorage.getItem("kc-watch-list");
    return watchListString ? JSON.parse(watchListString) : [];
  }
}
