import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Component as BrComponent, Page, Document } from "@bloomreach/spa-sdk";
import { getPagination } from '@utils/pagemodel-utils';
import { Logger } from "@utils/logger";
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics.service';
import { KnowledgeCenterService } from '@services/knowledge-center.service';
import { CommonService } from '@services/common.service';

const logger = Logger.getLogger("MarketInsightsComponent");

@Component({
  selector: 'ft-market-insights',
  templateUrl: './market-insights.component.html',
  styleUrls: ['./market-insights.component.scss']
})
export class MarketInsightsComponent implements OnInit {

  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;
  pagination: any;
  getData: any = [];
  allPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  start = 0;
  end = 8;
  componentDestroyed$: Subject<boolean> = new Subject();
  showTooltip: boolean = false;
  linkToCopy: string;
  showCopyDialoge: boolean = false;

  relateddata1: any = [
    {
      "title": "Emerging Markets",
      "date": "",
      "image": "https://franklintempletonprod.widen.net/content/dzqnspzeqz/jpeg/fti_blog_thumb_01.jpeg",
      "time": "",
      "target": "https://emergingmarkets.blog.franklintempleton.com/"
    },
    {
      "title": "Beyond Bulls & Bears",
      "date": "",
      "image": "https://franklintempletonprod.widen.net/content/bodveddyle/jpeg/fti_blog_thumb_03.jpeg",
      "time": "",
      "target": "https://global.beyondbullsandbears.com/"
    },
    {
      "title": "It’s All in the Mind !",
      "date": "",
      "image": "https://franklintempletonprod.widen.net/content/t5e0bm59zq/jpeg/ind-blog-icon-63-by-63.jpeg",
      "time": "",
      "target": "KC"

    }
  ]

  startLearningURL: string;

  constructor(
    private searchService: FtsearchService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private analyticsService: AnalyticsService,
    private knowledgeService: KnowledgeCenterService,
    private commonService: CommonService,
  ) {

  }

  ngOnInit(): void {
    this.startLearningURL = this.translateService.instant(
      'ftiKc.market-insights-banner-start-learning-url'
    );
    this.contentType = this.component?.getParameters().contentType;
    this.getFTIALLData("latest-commentaries", 1, 3);
    this.content();
  }

  getFormatedDate(date) {
    let timeDate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-US', {month: "long", day: "2-digit", year: "numeric"});
    const formattedDate = formatter.format(timeDate);
    return formattedDate
  }

  get params() {
    return this.component?.getParameters();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  content() {
    if (!this.pagination) {
      this.pagination = getPagination(this.page, this.component);
    }
    return this.pagination;
  }

  blogLinks(card: any) {
    if (card !== 'KC') {
      window.open(card, "_blank");
    } else {
      this.router.navigate(["/knowledge-centre/quick-learn/blog"]);
    }
  }

  categoryOnClick(card: any) {
    if (!card?.pdfUrl) {
      this.router.navigate([this.page.getUrl(card?.url)]);
    }
    if (card?.pdfUrl) {
      window.open(card?.data?._source?.pdfURL, "_blank");
    }
  }

  setAnalytics(title, fromArticle?: any) {
    let viewArticle = 'View Article';
    if (fromArticle) {
      fromArticle === 'INDVideoArticles' ? viewArticle = 'View video' : viewArticle = 'View Article'
    } else {
      viewArticle = 'Blogs'
    }

    this.analyticsService.trackEvent({
      event: 'Event Market Insights',
      category: 'Market Insights',
      action: viewArticle,
      label: 'New to Investing | ' + title,
    });
  }

  private getFTIALLData(pageType, start, end) {
    const watchListedIds = this.knowledgeService.getWatchList();
    if (start === 0) {
      this.allPages.results = [];
      this.getData = [];
    }
    this.end = end;
    const filterData = [
      {fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles', 'INDArticleDetails']},
      {fieldName: 'pageType', fieldValue: [pageType]},
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.allPages.results = data['results']?.response?.hits['hits'] || [];
        this.allPages.count = this.allPages.results.length;
        this.allPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
            let isWatchListed = false;          
            watchListedIds.forEach(id =>{ 
              if(id.pageTitle === pageTitle){
                isWatchListed = true;
              }
            })
          if (article._source.articleType === "Article") {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pdfUrl: article?._source?.pdfURL,
              pageTitle: `${pageTitle}`,
              isWatchListed: isWatchListed,
            });
          } else {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pageTitle: `${pageTitle}`,
              isWatchListed: isWatchListed,
            });
          }
        });
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  toggleIconForWatchlist(commentary: any) {
    if (!commentary?.isWatchListed) {
      const watchListedIds = this.knowledgeService.getWatchList();
      if (watchListedIds.length <= 7) {
        this.addToWatshlist(commentary);
      }
    } else if (commentary?.isWatchListed) {
      this.removeToWatchlist(commentary);
    }

  }

  /**
   * Add to watchlist
   * @param pageTitle
   */
  addToWatshlist(article: any) {
    const articleToUpdate = this.getData.find(
      (articleInArray) => articleInArray.pageTitle === article.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = true;
    }
    this.knowledgeService.addToWatchList(article);
  }

  removeToWatchlist(article: any) {
    // Remove from watchlist
    this.knowledgeService.removeFromWatchList(article);

    const articleToUpdate = this.getData.find(
      (articleInArray) => articleInArray.pageTitle === article.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = false;
    }
  }

  async toggleShowCopyDialoge(check: boolean, article): Promise<void> {
    if (check) {
      if (article?.pdfUrl) {
        this.linkToCopy = article?.pdfUrl;
      } else {
        this.linkToCopy = article?.data?._id;
      }
      try {
        await navigator.clipboard.writeText(this.linkToCopy);
      } catch (err) {
        // logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  shareOnApp(appName, article): void {
    let url = ''
    if(article.pdfUrl !== undefined){
      url = `${article.pdfUrl}`;
    }else{
      url = `${article.url}`;
    }
    this.commonService.shareOnApp(appName, url, article.pdfUrl);
  }

  toggleTooltip() {
    this.showTooltip = false;
  }
}
