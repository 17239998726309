<div *ngIf="document" [ngClass]="{ 'has-edit-button': page.isPreview() }">
  <div
    [style.marginTop.px]="params?.marginTop | spacing"
    [style.marginBottom.px]="params?.marginBottom | spacing"
    [style.paddingBottom.px]="params?.paddingBottom | spacing"
    [style.paddingTop.px]="params?.paddingTop | spacing"
    [ngClass]="{ 'html-page-container': params?.container }"
  >
    <ng-container
      [brManageContentButton]="document"
      class="has-edit-button float-right"
      documentTemplateQuery="new-ContentBlocks-document"
      folderTemplateQuery="new-ContentBlocks-folder"
      parameter="document"
      [relative]="false"
    ></ng-container>
    <div
      #container
      *ngIf="data?.code"
      [innerHTML]="page.rewriteLinks(data!.code!.value) | safeHtml"
    ></div>
  </div>
</div>
