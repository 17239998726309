<section class="FT-download-options" [ngClass]="{'bg-none': !isMobileView}">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto d-none d-md-block pe-0">
        <img alt="Download Franklin India App" src="{{downloadAppImageDesktop}}" class="download-img">
      </div>
      <div class="col ps-md-0">
        <h2 [innerHTML]="downloadText1"></h2>
        <div class="d-block d-md-none mb-3">
          <img alt="Download Franklin India App" src="{{downloadAppImageMobile}}">
        </div>
        <div>
          <a rel="noopener" target="_blank" href="{{appStoreURL}}">
            <img alt="Get it on Google Play" src="{{appPlayStoreImage}}"></a>
          <a rel="noopener" target="_blank" href="{{playStoreURL}}">
            <img alt="Download on the App Store" src="{{appStoreImage}}"></a>
        </div>
      </div>
    </div>
  </div>
</section>
