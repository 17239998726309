<div class="box-body autoCustom">
  <div class="d-flex text-bold">
    <span style="padding: 5px;">Funds</span>
    <a class="text-blue ms-auto" style="padding: 5px; cursor: pointer;" *ngIf="fundItems && fundItems?.length > 0"
       (click)="viewAllFunds()">
      {{ "ftiGeneric.where-do-you-stand-view-all-text" | translate }}</a>
  </div>

  <ng-container *ngIf="fundItems && fundItems?.length > 0; else noFunds">
    <ng-container *ngFor="let fund of fundItems">
      <div class="funds-seach-title" (click)="addToRecentSerch(fund,'fund')"
           style="position: relative; z-index: 999999; cursor: pointer;"
           [routerLink]="fund?.titleLink"
           [innerHTML]="fund?.title"></div>
    </ng-container>
  </ng-container>
  <ng-template #noFunds>
    <div class="funds-seach-title" style="position: relative; z-index: 999999; cursor: pointer;">
      {{ "ftiGeneric.search-no-results-desc" | translate }}
      <span class="text-blue">{{ searchText }}</span>
    </div>
  </ng-template>
</div>
<div class="box-footer autoCustom">
  <div class="d-flex text-bold">
    <span style="padding: 5px;">{{ "ftiGeneric.search-title" | translate }}</span>
    <a class="text-blue ms-auto" style="padding: 5px; cursor: pointer;" (click)="viewAllGeneral()">
      {{ "ftiGeneric.where-do-you-stand-view-all-text" | translate }}</a>
  </div>
  <div class="funds-seach-title">
    {{ "ftiGeneric.search-para1" | translate }}<span class="text-blue" style="cursor: pointer;"
                                                     (click)="viewAllGeneral()">
    {{ searchText }}</span> {{ "ftiGeneric.search-para2" | translate }}
  </div>
</div>
