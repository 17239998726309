<div class="row">
  <div class="col-12">
    <ng-container *ngIf="results?.length !== 0; else elseBlock">
      <div class="search-item" *ngFor="let result of results; let i = index">
        <ng-container *ngIf="i < valueToShow">
          <ft-search-fund-card [fund]="result"></ft-search-fund-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <p class="search-item">
        {{ "ftiGeneric.search-no-results-desc" | translate }}
        "{{ this.queryParams.query }}"
      </p>
    </ng-template>
  </div>
  <div class="col-6 text-right mt-4" *ngIf="!isViewAll && totalCount > 0">
    <a class="view-all" style="cursor: pointer;" (click)="clickEvent($event)">View All {{ totalCount }} results</a>
  </div>
</div>
<div class="text-center mt-4" *ngIf="isViewAll && totalCount > valueToShow">
  <button class="btn btn-white" (click)="loadMore($event)">{{
          "products.fund-listing-load-more-button" | translate
    }}
  </button>
</div>
