<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                parameter="document" [relative]="false" root="">
  </ng-container>

  <section class="FT-guide bg-gradient">
    <div class="container">
      <div class="text-center section-head" style="margin-bottom: 40px">
        <div class="FT-title">{{ preTitle }}</div>
        <div class="text-big text-white"> {{ title }}</div>
      </div>
      <div class="row">
        <div class="guide-box">
          <ngx-slick-carousel class="carousel d-flex desktop-blog-wrapper" #blogSlickModal="slick-carousel"
                              [config]="slideConfig">
            <ng-container *ngFor="let result of getCarouselBlogsImage; let i = index">
              <div ngxSlickItem class="slide col-4" [id]="'openlearning' + i">
                <div class="container d-flex flex-column mb-4" style="gap:10px;">
                  <span class="card-heading text-wrap"
                        style=" min-height: 0;">{{ result?.linkCompound?.displayText }}</span>
                </div>
                <div class="blog-box" (mouseover)="changeOnHover($event, 'openlearning' + i); $event.preventDefault()">
                  <img class="bright-50" alt="image" src="{{
                                    getImageURL(result?.widenAssetCompound?.widenDocument)
                                  }}" style="height: 300px;">
                  <div class="blog-info fundamentals">
                    <div class="title">
                      <!--<small *ngIf="result?.linkCompound?.signInIntroTextLabel">{{ result?.linkCompound?.signInIntroTextLabel }}</small>-->
                      <small *ngIf="result?.linkCompound?.title">{{ result?.linkCompound?.title }}</small>
                      <a [href]="result?.linkCompound?.url"
                         [target]="result?.linkCompound?.target === '' ? '_self' : result?.linkCompound?.target"
                         class="btn btn-primary making-money fontResize" style="padding:10px 40px;">View
                        Details</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slide1 col-4 d-none" [id]="'openlearning' + i + '1'"
                   (mouseleave)="changeOnHover1($event, 'openlearning' + i)">
                <div class="blog-box-new h-100">
                  <img alt="image" class="bright-50" src="{{
                                    getImageURL(result?.widenAssetCompound?.widenDocument)
                                  }}" style="height: 100%;border-radius: 8px;">
                  <div class="blog-heading" style="max-width: 350px;">
                    <span class="title text-center">{{ result?.linkCompound?.displayText }}</span>
                    <div [innerHtml]="result?.content?.content?.value" style="font-size:16px; margin-left: 17px">
                    </div>
                    <a [href]="result?.linkCompound?.url" class="btn btn-primary"
                       [target]="result?.linkCompound?.target === '' ? '_self' : result?.linkCompound?.target"
                       style="padding:10px 40px;">{{ result?.linkCompound?.signInContentTextLabel }}</a>
                  </div>
                </div>
              </div>
            </ng-container>
          </ngx-slick-carousel>
          <!-- Mobile view -->
          <div class="horizontal-scroll-container bg-gradient">
            <div class="mobile-blog-wrapper" style="padding: 24px;">
              <a [href]="result?.linkCompound?.url" class="blog blog-mobile-card card p-3"
                 *ngFor="let result of getCarouselBlogsImage; let i = index"
                 style="box-shadow: 0 2px 11.81px 0 #0000001A ; border: none;">
                <div class="container d-flex flex-column" style="gap:10px;">
                  <span class="card-heading text-wrap"
                        style="min-height: 0">{{ result?.linkCompound?.displayText }}</span>
                  <span class="card-subheading mb-3" style="text-wrap: wrap;" *ngIf="result?.content?.content?.value"
                        [innerHtml]="result?.content?.content?.value"></span>
                </div>
                <div class="blog-box" (mouseover)="changeOnHover($event,'openlearning')">
                  <img alt="image" class="blog-box-img bright-50"
                       src="{{getImageURL(result?.widenAssetCompound?.widenDocument)}}">
                  <div class="blog-info">
                    <div class="title text-wrap">
                      <small>{{ result?.linkCompound?.signInIntroTextLabel }}</small>
                      {{ result?.linkCompound?.title }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center blog-CTA1">
        <a [href]="'ftiGeneric.home-blog-explore-more-link' | translate" class="more-links">
          <span>{{ "ftiGeneric.home-blog-explore-label" | translate }}</span><i class="bi bi-chevron-right ms-3"></i>
        </a>
      </div>
    </div>
  </section>
</div>


