<div class="row">
  <div class="col-lg-5 col-md-5 col-sm-10">
    <div class="d-flex gap-4 mb-2">
      <div class=" d-flex knowledge-center" style="gap: 23px;">
        <div *ngFor="let question of questions; let i = index"
             [ngClass]="{ 'for-to-current-question-tab': isCurrentQuestion(i), 'for-to-next-question-tab': !isCurrentQuestion(i) }"
             (click)="isCurrentQuestion(i) ? goToQuestion(i) : null">
          Q{{ i + 1 }}
          <ng-container *ngIf="i < (questions.length-1)">
            <img alt="image" src="assets/images/knowledge-center/Line299.svg" class="checked-img">
          </ng-container>
        </div>
      </div>
    </div>

    <div class="for-question-get-title mt-2 mb-2">
      {{ getCurrentQuestion()?.question }}
    </div>

    <div>
      <div>
        <ng-container *ngFor="let option of getCurrentQuestion()?.options;let i = index">
          <div *ngIf="option.trim().length > 0" class="for-to-option-seleted mb-2 mt-2"
               (click)="selectOption(currentQuestionIndex, option, getCurrentQuestion())" style="cursor: pointer;"
               [ngClass]="{ 'for-to-option-seleted-highlight': option === selectedOptions?.[currentQuestionIndex]?.option }">
            {{ option }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button type="button" class="btn btn-outline-primary forSubmitButton" *ngIf="selectedOptions.length > 0 "
              (click)="goToPreviousQuestion()">Previous
      </button>
      <button type="button" class="btn btn-primary forSubmitButton"
              *ngIf="selectedOptions.length === this.questions.length" (click)="validateQuiz()">Submit
      </button>
    </div>
  </div>

  <div class="col-1"></div>

  <div class="col-lg-5 col-md-5 col-sm-10" *ngIf="selectedOptions.length > 0">
    <div class="for-answer-question-input">
      {{ "ftiKc.testLeanring-myInput" | translate }}
    </div>
    <div class="mt-2">
      <ng-container *ngFor="let selected of selectedOptions">
        <div class="mt-2">
          <div class="for-answer-question-input-title">
            {{ selected?.question?.question }}
          </div>
          <div class="for-question-option-seleted mt-1">
            {{ selected.option }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="save-alert" style="z-index:1000000" *ngIf="showSaveDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowSavDialoge()" data-bs-dismiss="modal">
      <img alt="image" src="assets/images/general/close.webp">
    </span>

    <ng-container *ngIf="quizPercentage > 75;  else quizFail">
      <img alt="image" src="assets/images/knowledge-center/Vector-trophy.svg" class="checked-img">
      <div class="mb-2 for-congratulation">{{ "ftiKc.congratulations" | translate }}</div>
      <div class="mt-2 for-card-about1"><span style="color: #595A6C;">{{ "ftiKc.successline1" | translate }}</span>
      </div>
      <div class="mt-2 for-card-about2"><span style="color: #595A6C">{{ "ftiKc.successline2" | translate }}</span><span
          style="color: #00847D; font-size: 1.188rem; margin-left: 10px;">{{ quizPercentage }}%</span></div>
    </ng-container>
    <ng-template #quizFail>
      <p>
        {{ "ftiKc.failureLine1" | translate }}{{ quizPercentage }}{{ "ftiKc.failureLine2" | translate }}
      </p>
    </ng-template>
    <a class="mt-3 tag-for-go" style="cursor: pointer;" data-bs-dismiss="modal"
       (click)="resetQuiz()">{{ "ftiKc.closeBtn-testlearning" | translate }}</a>
  </div>
</div>
