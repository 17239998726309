import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { PrerenderService } from '@services/prerender.service';
import { SegmentService } from '@services/segment.service';
import { StorageService } from '@services/storage.service';
import { SegmentId } from '@types';
import { Logger } from '@utils/logger';
import { from, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

const logger = Logger.getLogger('resource-api-interceptor');

@Injectable({
  providedIn: 'root',
})
export class ResourceApiHeadersInterceptor implements HttpInterceptor {
  firstLoad = true;

  constructor(
    private appStateService: AppStateService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private prerenderService: PrerenderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getProfile(request, next));
  }
  async getProfile(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('resourceapi') !== -1) {
      logger.debug('intercept resourceapi request', req.url);

      let params = req.params;
      let headers = req.headers;

      // check if first time loading and add url param
      if (this.firstLoad) {
        this.firstLoad = false; // only want to load once
        logger.debug('add firstLoad url param');
        params = params.append('first-load', 'true');
      }

      const segmentChars: string = this.segmentService.getSegmentCharacteristicsString();
      if (segmentChars) {
        logger.debug(
          'append profile characteristics string header',
          segmentChars
        );
        headers = headers.append('profile', segmentChars);
      }

      /**
       * work out segment and add as url param
       * TODO: this will be cleverer in future and take into account Profile data
       * for now, it just uses segment from LS/Cookie or site default
       */
      await this.segmentService
        .getCurrentSegmentId$()
        .pipe(first())
        .toPromise<SegmentId>()
        .then((segmentId: SegmentId): void => {
          if (this.segmentService.getCurrentSegmentId() !== segmentId) {
            // When segment is selected in modal selector we are not updating observable to avoid loading resource-api
            // It needs to be updated here. Otherwise wrong content can be loaded.
            segmentId = this.segmentService.getCurrentSegmentId();
            this.segmentService.setSegment(segmentId);
          }
          logger.debug('append segment param', segmentId);
          params = params.append('segment', segmentId);
        });

      req = req.clone({
        withCredentials: true,
        params,
        headers,
        url: req.url,
      });
      logger.debug('resourceapi url:', req.url);
      logger.debug('resourceapi headers:', req.headers);
    }
    return next.handle(req).toPromise();
  }
}
