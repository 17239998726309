<!-- start section calculator -->
<section class="FT-calculator-box mb-6 mt-2">
  <div class="container">
    <h1 class="FT-title-medium text-blue mb-2 mt-2">{{ "ftiCalc.calculator-lumpsum-heading" | translate }}</h1>
    <div>
      {{ "ftiCalc.calculator-lumpsum-description" | translate }}
    </div>
    <div class="FT-financial">

      <!-- Tab panes -->
      <div class="tab-content calculator-box">
        <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab"
             tabindex="0">

          <div class="tab-content">
            <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
                 tabindex="0">
              <div class="row align-items-center">
                <div class="col-lg-5 col-12 mb-2">
                  <div>
                    <div class="graph-table-btns">
                      <ul class="nav nav-tabs justify-content-center" id="myTab2" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button (click)="toggleIsSIP(sipType.normal)" class="btn"
                                  [ngClass]="{'active': selectedSipType === sipType.normal}" id="Investment-tab"
                                  type="button" role="tab" aria-controls="Investment"
                                  aria-selected="true">
                            {{ "ftiCalc.calculator-invest-text1" | translate }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button (click)="toggleIsSIP(sipType.amount)" class="btn"
                                  [ngClass]="{'active': selectedSipType === sipType.amount}" id="Target-tab"
                                  type="button"
                                  role="tab" aria-controls="Target" aria-selected="false">
                            {{ "ftiCalc.calculator-invest-text2" | translate }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button (click)="toggleIsSIP(sipType.year)" class="btn"
                                  [ngClass]="{'active': selectedSipType === sipType.year}" id="Target-tab" type="button"
                                  role="tab" aria-controls="Target" aria-selected="false">
                            {{ "ftiCalc.calculator-target-year" | translate }}
                          </button>
                        </li>
                      </ul>
                    </div>

                  </div>
                  <ft-lumpsum-calculator-form
                      [(form)]="formValue"
                      [selectedSipType]="selectedSipType"
                      (OnRecalculate)="OnRecalculate($event)">
                  </ft-lumpsum-calculator-form>
                  <a href="" class="login-dark2" data-bs-toggle="modal"
                     data-bs-target="#exampleModal">{{ 'ftiCalc.calculator-sip-disclaimer' |translate }}</a>
                </div>
                <div class="col-lg-7  col-12 ps-lg-4">
                  <div class="calculate-statics">
                    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-4 pb-md-2">
                      <div class="graph-table-btns mb-lg-0 mb-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="btn active" id="Graph-tab" data-bs-toggle="tab"
                                    data-bs-target="#Graph-tab-pane" type="button" role="tab"
                                    aria-controls="Graph-tab-pane"
                                    aria-selected="true"
                                    (click)="setIsGraphView(true)">{{ "ftiCalc.calculator-graph" | translate }}
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="btn" id="Table-tab" data-bs-toggle="tab"
                                    data-bs-target="#Table-tab-pane" type="button" role="tab"
                                    aria-controls="Table-tab-pane"
                                    aria-selected="false"
                                    (click)="setIsGraphView(false)">{{ "ftiCalc.calculator-table" | translate }}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="text-small text-bold" *ngIf="isGraphView">
                        <span class="me-3">
                          <span class="circle" style="background:#A669CA"></span>
                          {{ "ftiCalc.calculator-invest-amount" | translate }}
                        </span>
                        <span>
                          <span class="circle" style="background:#00847D"></span>
                          {{ "ftiCalc.calculator-invest-value" | translate }}
                        </span>
                      </div>
                    </div>

                    <div class="tab-content calculator-graph-box1" id="myTabContent2">
                      <div class="tab-pane fade show active" id="Graph-tab-pane" role="tabpanel"
                           aria-labelledby="Graph-tab" tabindex="0">
                        <div class="calculator-graph-box">
                          <!--<div class="text-small rotated"> {{ "ftiCalc.calculator-invest-value" | translate }}</div>-->
                          <div class="graph-container">
                            <div id="highcharts-container-lumpsum"></div>
                          </div>
                          <!--<div class="text-small text-center "> {{ "ftiCalc.calculator-invest-period" | translate }}</div>-->
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Table-tab-pane" role="tabpanel"
                           aria-labelledby="Table-tab" tabindex="0">
                        <div class="table-responsive text-center">
                          <table class="table table-success table-striped stiky-head">
                            <thead>
                            <tr>
                              <th>{{ "ftiCalc.calculator-end-of-year" | translate }}</th>
                              <th>{{ "ftiCalc.calculator-invest-text3" | translate }}</th>
                              <th>{{ "ftiCalc.calculator-invest-value" | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let val of formValue?.eachYearData">
                              <td>{{ val.year }}</td>
                              <td><span class="sign">₹</span>{{ val.investedAmt }}</td>
                              <td><span class="sign">₹</span>{{ val.expectedAmt }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                    <div class="p-3 bg-light-blue">
                      <!-- Final Values: normal -->
                      <div class="numbers d-flex justify-content-center text-center mb-4"
                           *ngIf="selectedSipType === sipType.normal">
                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-invest-text5" | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds">
                              {{ formValue?.totalValues | INRCurrency }}
                            </h3>
                          </ng-container>
                        </div>
                      </div>

                      <!-- Final Values: amount -->
                      <div class="numbers d-flex justify-content-center text-center mb-4"
                           *ngIf="selectedSipType === sipType.amount">
                        <div class="px-3">
                          <div>
                            {{ "ftiCalc.calculator-invest-text6" | translate }}
                          </div>
                          <h3 class="sip-recommended-funds">
                            {{ formValue?.requiredInvestment | INRCurrency }}
                          </h3>
                        </div>
                      </div>

                      <!-- Final Values: year -->
                      <div class="numbers d-flex justify-content-center text-center mb-4"
                           *ngIf="selectedSipType === sipType.year">
                        <div class="px-3">
                          <div>
                            {{ "ftiCalc.calculator-years-required" | translate }}
                          </div>
                          <h3 class="sip-recommended-funds">
                            {{ formValue?.targetYear }}
                          </h3>
                        </div>
                      </div>
                      <button (click)="investNow('')" class="btn btn-primary recommende-btn">
                        {{ "ftiCalc.calculator-invest-now" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-end">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          {{ 'ftiCalc.sip-calc-disclaimer-pop-up' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
