import { Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger('MoreCalculatorsComponent');

@Component({
  selector: 'ft-more-calculators',
  templateUrl: './more-calculators.component.html',
  styleUrls: ['./more-calculators.component.scss']
})
export class MoreCalculatorsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() calculators: any = [];
  slideConfig = {
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",
    infinite: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [

      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2.2
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.4,
          slidesToScroll: 1
        }
      }
    ]
  };
  currentPage = '';

  constructor(
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.currentPage = location.pathname;
  }


  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  get contentBlocks() {
    return this.content?.contentBlocks;
  }


  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }

  calculateGA(displayText: any) {
    this.analyticsService.trackEvent({
      event: 'calculator Page',
      ft_event: 'ft_CalculateNow_Clicked',
      category: displayText,
      action: 'CalculateNow',
      label: displayText,
    });
  }
}
