<section class="ft-search">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-10 cust-search">
        <div class="d-flex search-results">
          <div class="search-inpt" style="flex: 1; margin-right: 5px;">
            <input #searchBarInput class="form-control ft-search-place"
                   placeholder="{{'ftiGeneric.search-placeholder' | translate }}" type="text" id="search-bar"
                   aria-label="Search"
                   autocomplete="off" style="padding: 11px; padding-left: 35px;" tabindex="0"
                   [value]="searchText?searchText:''" [(ngModel)]="searchText"
                   (click)="searchText?.length >= 3? (showAutoSuggestions = true): (showAutoSuggestions = false)"/>
          </div>

          <div class="search-result" *ngIf="showAutoSuggestions">
            <div class="inner" style="text-align: left !important;cursor: pointer;">
              <ft-auto-suggestions-list [searchText]="searchText"
                                        [triggerChange]="triggerChange"
                                        (newSearch)="checkRecentSearch()"></ft-auto-suggestions-list>
            </div>

          </div>

          <div>
            <img alt="image" src="./assets/images/comparefund/closebutton.svg"
                 class="make-tag-for-all-page d-md-none mob-view share-icon2" id="x-icon" (click)="resetFundExplore()" style="
                                  position: relative;
                                  left: -21px;
                                  top: 8px;cursor: pointer;">
            <img alt="image" src="./assets/images/comparefund/closebutton.svg"
                 class="make-tag-for-all-page search-tab-close d-none d-md-block share-icon2" (click)="resetFundExplore()" style="
                                     position: relative;
                                     left: -2rem;
                                      top: 18px;cursor: pointer;">
          </div>
          <div>
            <button type="button" class="btn btn-primary ft-search-btn"
                    (click)="navigateToSearchResults()">{{ 'ftiGeneric.saerch-btn-placeholder' | translate }}
            </button>
          </div>
        </div>
        <div class="card" style="border-radius: unset !important;">
          <div class="card-body" id="search-bar">
            <div class="row">
              <div class="col-4">
                <div class="search-results" id="search-bar">
                  <div class="box-body">
                    <div class="d-flex text-bold mb-2">
                      <span>{{ 'ftiGeneric.search-heading1' | translate }}</span>
                    </div>
                    <ul style="list-style-type: none;">
                      <li *ngFor="let suggestion of suggestions" style="cursor: pointer;">
                        <a style="cursor: pointer;" class="d-flex justify-content-between"
                           (click)="navigateToSearchResults(suggestion?.value)">
                          <span>{{ suggestion?.value }}</span>
                          <span><i class="bi bi-arrow-up-right"></i></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="search-results" id="search-bar">
                  <div class="box-body">
                    <div class="d-flex text-bold mb-2">
                      <span>{{ 'ftiGeneric.search-heading2' | translate }}</span>
                    </div>
                    <ul style="list-style-type: none;">
                      <li *ngFor="let recentSearch of recentSearches" style="cursor: pointer;">
                        <ng-container *ngIf="recentSearch?.type === 'fund'; else general">
                          <a class="d-flex justify-content-between"
                             [routerLink]="recentSearch?.term?.titleLink"
                             [innerHTML]="renderHTML(recentSearch?.term?.title?.changingThisBreaksApplicationSecurity)"></a>
                        </ng-container>
                        <ng-template #general>
                          <a style="cursor: pointer;" class="d-flex justify-content-between"
                             (click)="navigateToSearchResults(recentSearch?.term)">
                            <span>{{ recentSearch.term }}</span>
                            <span><i class="bi bi-arrow-up-right"></i></span>
                          </a>
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <a style="font-size: 0.875rem;font-weight: 600;letter-spacing: -0.28px;cursor: pointer;"
                   (click)="clearRecentSearch()">{{ 'ftiGeneric.search-clear-btn' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
