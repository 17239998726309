<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document" [relative]="false" root="">
  </ng-container>
  <section class="FT-glossary">
    <div class="container">
      <div class="FT-title-medium text-blue">{{ "ftiKc.investment-glossary" | translate }}</div>
      <div class="row">
        <div class="col-md-auto">
          <div class="filter-box" #stickyHeader>
            <div class="filter-head">{{ "products.fund-listing-filters" | translate }}</div>
            <div class="filter-body">
              <div class="form-check" (click)="onAlphabetClick($event)">
                <input class="form-check-input" type="radio" name="letters" id="all" checked>
                <label class="form-check-label" for="all">
                  {{ "literature.ALL" | translate }}
                </label>
              </div>
              <div class="form-check form-check-inline"
                   *ngFor="let glossaryItem of glosarryData; let i = index"
                   (click)="clickedIndex = i; onAlphabetClick($event)"
                   [ngClass]="{'disabled-alphabet': !(glossaryItem?.glossaryContent?.length > 0)}">
                <input class="form-check-input" type="radio" name="letters" [id]="'letter' + i"
                       [attr.disabled]="!(glossaryItem?.glossaryContent?.length > 0) ? true : null ">
                <label class="form-check-label" [for]="'letter' + i">{{ glossaryItem?.alphabets }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md">
          <!-- Search -->
          <div class="d-flex align-items-center justify-content-between mb-40">
            <div class="form-group search-group">
              <div class="filter-options mt-0 mb-4">
                <div class="form-group ">
                  <input type="text" class="form-control search" maxlength="64"
                         id="siteSearch" autocomplete="off" placeholder="Search" #searchBarInput
                         [(ngModel)]="searchTerm" name="searchBar" (input)="onSearchClick($event)"/>
                  <div class="search-result" *ngIf="!isSearchItemClicked && !isSearch && searchTerm">
                    <div [ngClass]="finalResults.length === 0 ? 'inner-shadow' : 'inner' ">
                      <ng-container *ngIf="finalResults && finalResults?.length > 0">
                        <div *ngFor="let resultItem of finalResults"
                             class="funds-seach-title"
                             (click)="onSearchItemClick(resultItem)"
                             style="position: relative; z-index: 999999;">
                          {{ resultItem.title }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="noResultsFound && searchTerm">
                        <div class="funds-seach-title no-Results" (click)="noItemSearch(searchTerm)">
                          {{ "products.fund-listing-no-search-suggestion" | translate }} '{{ searchTerm }}'
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div>
                  <img alt="close button" src="./assets/images/comparefund/closebutton.svg"
                       class="make-tag-for-all-page share-icon2" (click)="resetFundExplore()" style="
                                  position: relative;
                                  left: 93%;
                                  top: -31px;">
                </div>
              </div>
            </div>
          </div>
          <div class="glossary-box">
            <!-- Main content with Alphabetical sort-->
            <ng-container *ngIf="!isSearchItemClicked && !isSearch">
              <div *ngFor="let glossaryItem of alphabeticalData">
                <div class="letter-title mb-4" *ngIf="glossaryItem?.alphabets">{{ glossaryItem?.alphabets }}.</div>
                <div class="mb-40" *ngFor="let glossaryContent of glossaryItem?.glossaryContent">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="text-bold">{{ glossaryContent?.title }}</div>
                  </div>
                  <div>
                    {{ glossaryContent?.description }}
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Search -->
            <ng-container *ngIf="isSearch">
              <div *ngFor="let glossaryContent of finalResults">
                <div class="letter-title mb-4" *ngIf="glossaryContent?.alphabets">{{ glossaryContent?.alphabets }}.
                </div>
                <div class="mb-40">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="text-bold">{{ glossaryContent?.title }}</div>
                  </div>
                  <div>
                    {{ glossaryContent?.description }}
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isSearchItemClicked">
              <div *ngFor="let glossaryContent of filteredSearchResults">
                <div class="letter-title mb-4" *ngIf="glossaryContent?.alphabets">{{ glossaryContent?.alphabets }}.
                </div>
                <div class="mb-40">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="text-bold">{{ glossaryContent?.title }}</div>
                  </div>
                  <div>
                    {{ glossaryContent?.description }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
