import { BMAssoc } from '@components/products/models';
import { BMAssocDTO } from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class BMAssocListMapper extends Mapper<BMAssoc[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(bmAssocListDTO: BMAssocDTO[]): BMAssoc[] {
    const bmAssocList: BMAssoc[] = [];

    bmAssocListDTO.forEach((bmAssocDTO: BMAssocDTO) => {
      const bmAssoc: BMAssoc = this.createMapper(BMAssocMapper).toDomain(
        bmAssocDTO
      );

      if (bmAssoc) {
        bmAssocList.push(bmAssoc);
      }
    });

    return bmAssocList;
  }
}

export class BMAssocMapper extends Mapper<BMAssoc> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(bmAssocDTO: BMAssocDTO): BMAssoc {
    return {
      bmName: bmAssocDTO.bmname,
      bmLabel: bmAssocDTO.bmlabel,
    };
  }
}
