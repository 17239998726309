<section class="FT-invest-form bg-gradient" [ngClass]="{'mb-cust-8':!isMobileView}"
         style="padding-bottom: 133px;">
  <div class="container">
    <div class="form-box">
      <ft-call-back-form (backToInvest)="backTosteps($event)" [classVal]="'invest'"></ft-call-back-form>
    </div>
    <div id="invest-now" class="">
      <a class="btn btn-primary btn-sm"
         (click)="investNow('')">{{ "ftiGeneric.fund-listing-button-invest-now" | translate }}</a>
    </div>
  </div>
</section>
