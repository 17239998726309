<div class="cont-first-main" [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>

  <div class="FT-title text-center mb-4" *ngIf="data?.param">{{ data!.param }}</div>
  <div class="FT-blocks-nav sticky-top hello">
    <ul>
      <li *ngFor="let item of accordion; let i = index">
        <a style="cursor:pointer" (click)="scrollTo(item.titleBlock?.preTitle)" class="{{ item.titleBlock?.preTitle }}"
           [ngClass]="i===0?'active-item' : ''">
          {{ item.titleBlock?.title }}
        </a>
      </li>
    </ul>
  </div>
</div>
