import { Component, Input, OnInit, } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('CheckProgressComponent');

@Component({
  selector: 'ft-check-progress',
  templateUrl: './check-progress.component.html',
  styleUrls: ['./check-progress.component.scss']
})
export class CheckProgressComponent implements OnInit {

  @Input() component!: BrComponent;
  @Input() page: Page;

  constructor() {
  }

  ngOnInit(): void {
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  get linkLists() {
    return this.content?.linkList;
  }

}
