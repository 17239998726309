import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CustomDimensions } from '../types/analytics-interface';

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer: any[];
}

/**
 * Custom Options
 */
export interface CustomOptions {
  ft_event?:string;
  event?: string;
  category?: string;
  action?: string;
  label?: string;
  fh_inv_user_id?: string;
  fh_dis_user_id?: string;
  fh_inv_PAN?: string;
  fh_inv_Bank_Account?: string;
  pagePath?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  pageView: CustomDimensions;

  constructor(@Inject(WINDOW) private windowRef: DataWindow) {
    this.windowRef.dataLayer = this.windowRef.dataLayer || [];
  }

  /**
   * Updates analytics object in datalayer when called
   * @param analyticsObject updated object
   */
  updateAnalytics(analyticsObject): void {
    let pageView = this.createAbstractObject();
    pageView = { ...this.pageView, ...analyticsObject };
    this.windowRef.dataLayer.push(this.getInitialObjOnSiteLoad());
    this.windowRef.dataLayer.push(pageView);
  }

  /**
   * gets analytics object on intial load
   * @returns initial analytics object
   */
  getInitialObjOnSiteLoad(): void {}

  /**
   * Track event with GTM DataLayer
   * @param options a list of options
   */
  trackEvent(options?: CustomOptions): void {
    this.windowRef.dataLayer.push({
      event: options.event || 'linkOrButtonClick',
      ...options,
    });
  }

  /**
   * creates an abstract object
   * @returns analytics object with all required values
   */
  createAbstractObject(): object {
    return {
      ft_event: undefined,
      event: undefined,
      category: undefined,
      action: undefined,
      label: undefined,
    };
  }
}
