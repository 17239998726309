<!-- start categories box -->
<div class="fd-tab-sections knowledge-box categories" id="articles">
  <!-- start ad box -->
  <div class="FT-ad-box">
    <div class="FT-title-medium text-bold mb-2 text-white">{{ tabTitle }}</div>
    <div class="text-big">{{ tabDescription }}</div>
  </div>
  <!-- start categories filter block -->
  <div class="filter-block">
    <div class="row justify-content-between">
      <div class="col-auto">
        <div class="filter-options mt-0 mb-4">
          <div class="form-group">
            <input type="text" placeholder="{{ 'products.add-fund-search-input-placeholder' | translate }}"
                   class="form-control search" [(ngModel)]="searchTerm" (input)="searchArticles(searchTerm)">
            <div class="search-result" *ngIf="showFundSearchDropdown && searchTerm && searchTerm.length >= 3">
              <div class="inner">
                <ng-container *ngFor="let article of articleDataSearch">
                  <div class="funds-seach-title" (click)="showSelectedArticle(article)"
                       style="position: relative; z-index: 999999; cursor: pointer;">
                    <span class="fund-search-filter-title">{{ article?.title }} - {{ article?.year }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div>
            <img alt="image" src="./assets/images/comparefund/closebutton.svg"
                 class="make-tag-for-all-page share-icon2 share-icon-pos" (click)="clearSearch()"
                 style="
                    position: relative;
                    left: 93%;
                    top: -31px;">
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="dropdown me-3">
          <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedSortItem?.value }}
          </button>
          <ul class="dropdown-menu">
            <ng-container *ngFor="let item of sortingOptions">
              <li style="cursor: pointer;" (click)="sortMedia(item)"
                  [class.selected-option]="item.key === selectedSortItem.key">
                <a class="dropdown-item">{{ item?.value }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- start selected category btn -->
  <div class="category-btns">
    <div class="category-container">
      <ul>
        <li *ngFor="let year of yearsFilterDropdown" [class.active]="selectedYear === year" (click)="selectYear(year)">
          {{ year }}
        </li>
      </ul>
    </div>
  </div>

  <!-- start categories block -->
  <div class="category-block">
    <div class="row g-4">
      <ng-container *ngFor="let article of articleData; let i = index">
        <div *ngIf="i < itemsToShow" class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="category-card-container">
            <div class="category-card">
              <div class="card-img">
                <img alt="thumbnail" src="{{'ftiGeneric.media-article-thumbnail-image' | translate}}"
                     (click)="openLink(article)"
                     style="cursor: pointer;">
                <span class="time-tag">{{ article?.longDate }}</span>
              </div>
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div><i class="bi bi-eye-fill me-1"></i>{{ article?.views ? article?.views : '1K' }}</div>
                  <div class="card-icons">
                    <span class="img-with-tooltip me-3" (click)="toggleIconForWatchlist(i)">
                      <!-- Use ngIf to conditionally display the appropriate bookmark icon -->
                      <i *ngIf="article.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark-fill"
                         (click)="removeToWatchlist(article)"></i>
                      <i *ngIf="!article.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark"
                         (click)="addToWatshlist(article)"></i>
                      <!-- Use ngIf to conditionally display the tooltip based on isWatchListed -->
                      <span *ngIf="!article.isWatchListed"
                            class="tooltip-block">{{ "products.home-fund-card-add-to-wlist-btn" | translate }}</span>
                      <span *ngIf="article.isWatchListed"
                            class="tooltip-block">{{ "products.home-fund-card-added-to-wlist-btn" | translate }}</span>
                    </span>
                    <span class="dropdown">
                      <span type="button" class="img-with-tooltip" data-bs-toggle="dropdown" aria-expanded="false"
                            id="{{article?.documentId}}">
                        <img alt="image" src="assets/images/knowledge-center/share.svg" style="height: 17px;">
                        <span class="tooltip-block d-none">{{ "products.fund-header-share" | translate }}</span>
                      </span>
                      <ul class="dropdown-menu action-menu">
                        <li><a style="cursor: pointer;" (click)="toggleShowCopyDialoge(true,article)"
                               class="dropdown-item"><img alt="Copy/Share"
                            src="assets/images/general/Link.webp"><span
                            class="action-icon">{{ "products.home-fund-card-share-copy-btn" | translate }}</span></a>
                        </li>
                        <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER',article)">
                          <img alt="image" src="assets/images/general/twitter-new-icon.webp" style="width: 20px;"><span
                            class="action-icon">{{
                                "products.home-fund-card-share-twitter-btn" |
                                    translate
                          }}</span></a></li>
                        <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('FACEBOOK',article)">
                          <img alt="image" src="assets/images/general/FacebookLogo.webp"><span class="action-icon">{{
                                "products.home-fund-card-share-fb-btn" |
                                    translate
                          }}</span></a></li>
                        <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN',article)">
                          <img alt="image" src="assets/images/general/LinkedinLogo.webp"><span class="action-icon">{{
                                "products.home-fund-card-share-linkedin-btn" |
                                    translate
                          }}</span></a></li>
                        <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP',article)">
                          <img alt="image" src="assets/images/general/whatsapps.webp"><span class="action-icon">{{
                                "products.home-fund-card-share-whatsapp-btn" |
                                    translate
                          }}</span></a></li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div class="text-bold card-title mt-3" style="cursor: pointer;" (click)="openLink(article)">
                  {{ truncateText(article.title) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="text-center" *ngIf="itemsToShow < articleData?.length">
    <button class="btn btn-white" (click)="loadMore()">{{ "products.fund-listing-load-more-button" | translate }}
    </button>
  </div>
</div>
<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false)">
      <img alt="image" src="assets/images/general/close.webp">
    </span>
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>
