import { RiskStatistics } from '@products/models/risk-statistics';
import { RiskStatisticsDTO } from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class RiskStatisticsMapper extends Mapper<RiskStatistics> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(riskStatisticsDTO: RiskStatisticsDTO): RiskStatistics {
    return {
      beta: this.concatVals([
        riskStatisticsDTO.beta1yr,
        riskStatisticsDTO.beta2yr,
        riskStatisticsDTO.beta3yr,
        riskStatisticsDTO.beta5yr,
        riskStatisticsDTO.beta7yr,
        riskStatisticsDTO.betaactinc,
      ]),
      rSquared: this.concatVals([
        riskStatisticsDTO.rsquared1yr,
        riskStatisticsDTO.rsquared2yr,
        riskStatisticsDTO.rsquared3yr,
        riskStatisticsDTO.rsquared5yr,
        riskStatisticsDTO.rsquared7yr,
        riskStatisticsDTO.rsquaredactinc,
      ]),
      standardDeviation: this.concatVals([
        riskStatisticsDTO.stddeviation1yr,
        riskStatisticsDTO.stddeviation2yr,
        riskStatisticsDTO.stddeviation3yr,
        riskStatisticsDTO.stddeviation5yr,
        riskStatisticsDTO.stddeviation7yr,
        riskStatisticsDTO.stddeviationactinc,
      ]),
      sharpeRatio: this.concatVals([
        riskStatisticsDTO.sharperatio1yr,
        riskStatisticsDTO.sharperatio2yr,
        riskStatisticsDTO.sharperatio3yr,
        riskStatisticsDTO.sharperatio5yr,
        riskStatisticsDTO.sharperatio7yr,
        riskStatisticsDTO.sharperatioactinc,
      ]),
      riskFreeRate: riskStatisticsDTO.riskfreeror3yr,
    };
  }

  private concatVals(vals: any[]): string | null {
    let ret = '';
    vals.forEach((val) => {
      if (val) {
        ret += val + ' ';
      }
    });
    return ret.length ? ret : null;
  }
}
