import { Inject, Injectable } from '@angular/core';
import { USER_AGENT } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('userAgentService');

/**
 * This is a simple service that determines if the request is from Prerender.io based on user agent
 */

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  private _isPrerender = false;
  private _isSmarsh = false;

  constructor(@Inject(USER_AGENT) readonly userAgentRef: string) {
    // for now, just check the user agent for string 'Prerender"
    this._isPrerender = this.userAgentRef.indexOf('Prerender') !== -1;
    this._isSmarsh = this.userAgentRef.indexOf('Smarsh') !== -1;
    logger.debug(
      'Is Prerender.io request?',
      this.isPrerender(),
      this.userAgentRef
    );
  }

  /**
   * returns true if the user agent is Prerender
   * returns false if this is a normal user
   */
  public isPrerender(): boolean {
    return this._isPrerender;
  }

  /**
   * returns true if the user agent is Smarsh
   * returns false if this is a normal user
   */
  public isSmarsh(): boolean {
    return this._isSmarsh;
  }

  public isNotPrerenderOrSmarsh(): boolean {
    return !(this._isPrerender || this._isSmarsh);
  }
}
