import {
  cloneMomentLocale,
  updateMomentLocale,
} from './custom-moment-locales/moment-utils';
 
/**
 * config for all localisation for web-platform
 *
 * NUMBER LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Decimal_separator
 *
 * DATE LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Date_format_by_country
 *
 * Nice database of both
 * https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_locale_quick_reference.html
 * (es-MX example) https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_formats_es_MX.html
 */
 
export const HIGHCHARTS_DEFAULT_CONFIG = {
  decimalPoint: '.',
  thousandsSep: ',',
};
 
export const HIGHCHARTS_EUROPEAN_CONFIG = {
  decimalPoint: ',',
  thousandsSep: '.',
};
 
export const DEFAULT_CONFIG = {
  decimalPoint: '.',
  thousandsSep: ',',
  fractionPrecision: 2,
};
 
export const EUROPEAN_CONFIG = {
  decimalPoint: ',',
  thousandsSep: '.',
  fractionPrecision: 2,
};
 
// Examples of what delimiters should be can be found in many il8n projects
//    https://github.com/svenfuchs/rails-i18n/tree/master/rails/locale
//    https://github.com/angular/angular/tree/master/packages/common/locales
//    https://en.wikipedia.org/wiki/Common_Locale_Data_Repository
//
// If we ever wanted to we could automate this using this
//    https://github.com/angular/angular/tree/master/tools/gulp-tasks/cldr
 
// NOTE : Currency / Percentage can have different formatting
// aka switzerland currency == dot(.) and percentage == comma(,)
// COREWEB-3902 Switzerland - Decimal separator for NAV should be (.) in Pricing chart
//   The currency value will use Decimal Separator as dot(.) and the Percentage value will use Decimal Separator as comma(,).
 
/**
 * Locale settings for
 * see components\common\elements\charting\ft-charts-configs.js for implementation of actually global option config
 * // WORKAROUND: could set Highcharts.setOptions({lang: xxx}) directly here but might want to lazy load high charts in future
 */
export const LANG_CONFIG = {
  'cs-cz': {
    // Czech (Czech Republic) [cs-cz]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('cs-cz', 'cs',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d let"
      }
    }),
  },
  'de-at': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('de-at','de')
  },
  'de-de': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('de-de','de',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d Jahre"
      }
    })
  },
  'de-ch': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('de-ch','de')
  },
  'el-gr': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('el-gr','el',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
      relativeTime: {
        yy: "%d χρόνια"
      }
    })
  },
  en: {
    // English (United States) [en-us]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: 'default-locale-hardcoded-into-framework',
  },
  'en-au': {
    moment: cloneMomentLocale('en-au','en',{longDateFormat:{
      L:"DD/MM/YYYY",
    }})
  },
  'en-ca': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-ca','en',{
      longDateFormat: {
        L:"MM/DD/YYYY",
      },
    })
   },
   'en-ch': {
    // English (United States) [en-us]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-ch','en',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
    }),
  },
  'en-cy':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-cy','en',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
  'en-hk':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-hk','en',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
 
  'en-gb': {
    // English (United Kingdom) [en-gb]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-gb','en',{
      longDateFormat:{
        LLLL: "dddd, D MMMM YYYY HH:mm"
      }
    }),
  },
  'en-my':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-my','en',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
  'en-sg':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-gb','en',{
      longDateFormat:{
        L:"DD/MM/YYYY"
      }
    })
  },
  'en-os':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-os','en')
  },
  'en-za':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-za','en',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
    })
  },
  'es-es':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('es-es','es',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d años"
      }
    })
  },
  'es-mx':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('es-mx','es'),
  },
  'es-os':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('es-os','es',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
      relativeTime: {
        yy: "%d años"
      }
    })
  },
  'eu-inst':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('eu-inst','eu',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
      relativeTime: {
        yy: "%d years"
      }
    })
  },
  'fr-be':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('fr-be','fr',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
      relativeTime: {
        yy: "%d ans"
      }
    })
  },
  'fr-ca': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
      moment: cloneMomentLocale('fr-ca','fr',{
      longDateFormat: {
        L:"DD/MM/YYYY",  
        LL:"D, MMMM YYYY",
        LLL:"D, MMMM YYYY HH:mm",
        LLLL:"dddd D MMMM YYYY HH:mm",
        LT:"HH:mm",
        LTS:"HH:mm:ss"
      },
      months: [
        "Janvier","Février",
        "Mars","Avril",
        "Mai","Juin",
        "Juillet","Août",
        "Septembre","Octobre",
        "Novembre","Décembre"
      ]
    },
 
    ),
  },
  'fr-fr':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('fr-fr','fr',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d ans"
      }
    })
  },
  'hu-hu':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('hu-hu','hu',{
      longDateFormat: {
        L:"YYYY.MM.DD.",
      },
      relativeTime: {
        yy: "%d év"
      }
    })
  },
  'it-it': {
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('it-it', 'it',{
      weekdays: [
        "Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"
      ],
      longDateFormat: {
        L:"DD.MM.YYYY",
        LLLL:"dddd D MMMM YYYY HH:mm"
      },
      months: [
        "Janvier","Février",
        "Mars","Avril",
        "Mai","Juin",
        "Juillet","Août",
        "Septembre","Octobre",
        "Novembre","Décembre"
      ],
      relativeTime: {
        yy: "%d anni"
      }
    }),
  },
  'ja-ja':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('ja-ja','ja',{
      longDateFormat: {
        L:"YYYY/MM/DD",
      },
      relativeTime: {
        yy: "%d年"
      }
    })
  },
  'nl-be':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('nl-be','nl',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
  'nl-nl':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('nl-nl','nl',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d jaar"
      }
    })
  },
  'nordics':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('nordics','en',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
  'pl-pl':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('pl-pl','pl',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d lat"
      }
    })
  },
  'pt-br':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('pt-br','pt')
  },
  'pt-os':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('pt-os','pt',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
      relativeTime: {
        yy: "%d anos"
      }
    })
  },
  'pt-pt':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('pt-pt','pt',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d anos"
      }
    })
  },
  'ro-ro':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('ro-ro','ro',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d ani"
      }
    })
  },
  'sk-sk':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('sk-sk','sk',{
      longDateFormat: {
        L:"DD.MM.YYYY",
      },
      relativeTime: {
        yy: "%d rokov"
      }
    })
  },
  'zh-hk':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('zh-hk','zh-hk',{
      longDateFormat: {
        L:"DD/MM/YYYY",
      },
    })
  },
  'zh-tw':{
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('zh-tw','zh')
  },
};
