import { Pipe, PipeTransform } from '@angular/core';
import { EMDASH } from '@components/products/utils/constants/product.constants';

@Pipe({
  name: 'inrSymbol',
})
export class InrSymbolPipe implements PipeTransform {
  transform(value: string, placeholder: string): string {
    // Firstly check, if value not EMDASH.
    if (value && value !== EMDASH) {
      // Then, Check for 'pre' or 'post' for correct placement.
      if (placeholder === 'pre') {
        return `₹${value}`;
      } else {
        return `${value} (₹)`;
      }
    }

    return value;
  }
}
