import { Inject, Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';

const logger = Logger.getLogger('FormService');

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(@Inject(DOCUMENT) readonly documentRef: Document) {
  }

  /**
   * Disabling copy paste for server functionality
   * @param event - ClipboardEvent
   */
  public onPasteForms(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    logger.debug('pastedText:', pastedText);
    if (this.documentRef.domain !== 'localhost') {
      // Block paste for non localhost domains, for localhost development
      event.preventDefault();
    }
  }

  /**
   * Set form input field
   * @param minLength - field min length number
   * @param preFillValue - string value to pre-fill in field
   */
  public setFormField(
    minLength: number,
    preFillValue?: string,
    isRequired: boolean = true
  ): FormControl {
    let formState = '';
    if (preFillValue) {
      formState = preFillValue;
    }
    if (isRequired) {
      return new FormControl(formState, [
        Validators.required,
        Validators.minLength(minLength),
      ]);
    }
    return new FormControl(formState, [Validators.minLength(minLength)]);
  }
}
