import { PortfolioChars } from '@components/products/models';
import { PortfolioCharsDTO } from '@types';
import { MapperFactory } from '../mapper-factory';
import { Mapper, MapperParams } from '../type.mapper';

export class PortfolioCharsMapper extends Mapper<PortfolioChars[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(portfolioCharsListDTO: PortfolioCharsDTO[]): PortfolioChars[] {
    const portfolioCharsList: PortfolioChars[] = [];

    portfolioCharsListDTO.forEach((portfolioCharsDTO: PortfolioCharsDTO) => {
      const portfolioChars: PortfolioChars = this.createMapper(
        PortfolioCharMapper
      ).toDomain(portfolioCharsDTO);

      if (portfolioChars) {
        portfolioCharsList.push(portfolioChars);
      }
    });

    return portfolioCharsList;
  }
}

export class PortfolioCharMapper extends Mapper<PortfolioChars> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(portfolioCharsDTO: PortfolioCharsDTO): PortfolioChars {
    return {
      asOfDate: portfolioCharsDTO.asofdate,
      elementName: portfolioCharsDTO.elemname,
      elementValueLocal: portfolioCharsDTO.elemvaluelocal,
    };
  }
}
