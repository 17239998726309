import { Component, Input } from "@angular/core";
import { Logger } from "@utils/logger";
import { Router, UrlTree } from "@angular/router";

const logger = Logger.getLogger("SearchFundCardComponent");

@Component({
  selector: "ft-search-fund-card",
  templateUrl: "./search-fund-card.component.html",
  styleUrls: ["./search-fund-card.component.scss"],
})
export class SearchFundCardComponent {
  @Input() fund: any;
  @Input() fundCompareIds: any;

  constructor(
    private router: Router
  ) {
  }

  funPageLinkClick(navURL: string | UrlTree) {
    if (navURL) {
      this.router.navigateByUrl(navURL);
    }
  }
}
