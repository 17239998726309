<div class="articleDetail">
  <div class="fti-container position-relative">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                    documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                    parameter="document" [relative]="false" root="">
      </ng-container>
      <ng-container *ngIf="document">
        <ng-container *ngIf="layout === largeTabsLayout">
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
            <div class="wrapper" [innerHTML]="content?.html?.value"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="layout === 'video-inner'">
          <!-- start section article content -->
          <section class="FT-article-content">
            <div class="container">
              <div class="article-title">
                <div class="text-meduim mb-2">{{ content?.readingVideoTitle }}</div>
                <h1 class="mb-3 text-blue">{{ content?.title }}</h1>

                <div class="d-flex justify-content-between">
                  <div class="text-small mb-4">
                    <span>{{ content?.publishDate | date:'dd MMMM, yyyy' }}</span>
                    <span class="tag"
                          *ngFor="let breadcrumb of getRelatedTags(content?.videoPageBreadcrumbs)">{{ breadcrumb }}</span>
                  </div>
                  <div class="icons d-flex mb-3" style="margin-top: 2px;">
                    <div class="card-icons">
                      <!-- <span class="img-with-tooltip me-4">
                        <i *ngIf="modifiedContent.isWatchListed" style="cursor: pointer;"
                           class="bi bi-bookmark-fill"
                           (click)="removeToWatchlist(content)"></i>
                        <i *ngIf="!modifiedContent.isWatchListed" style="cursor: pointer;"
                           class="bi bi-bookmark" (click)="addToWatchlist(content)"></i>
                        <span *ngIf="!modifiedContent.isWatchListed" class="tooltip-block">{{
                                "products.add-cart-save-button" |
                                    translate
                          }}</span>
                        <span *ngIf="modifiedContent.isWatchListed" class="tooltip-block">{{
                                "products.article-saved-cource-button" | translate
                          }}</span>
                      </span> -->
                      <span class="dropdown">
                        <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown"
                              aria-expanded="false">
                          <img alt="image" src="assets/images/knowledge-center/share.svg"
                               (mouseenter)="showTooltip = true"
                               (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                               class="d-inline-block mobile-icons light-mode-icon"
                               style="width: 18px;">
                          <img alt="image" src="assets/images/dark/share-dark-icon.png"
                               (mouseenter)="showTooltip = true"
                               (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                               class="mobile-icons dark-mode-icon"
                               style="width: 21px; top: 4px; position: relative; left: 0;">
                          <span *ngIf="showTooltip" class="tooltip-block">{{
                                  "products.fund-header-share" |
                                      translate
                            }}</span>
                        </span>
                        <ul class="dropdown-menu">
                          <li><a style="cursor: pointer;"
                                 (click)="toggleShowCopyDialoge(true)"
                                 class="dropdown-item"><img alt="copy/share"
                                                            src="assets/images/general/Link.webp"><span
                              class="action-icon">
                            {{
                                  "products.home-fund-card-share-copy-btn" | translate
                            }}</span>
                          </a>
                          </li>
                          <li><a style="cursor: pointer;" class="dropdown-item"
                                 (click)="shareOnApp('TWITTER')">
                            <img alt="image" class="share-icon" style="width: 20px;"
                                 src="assets/images/general/twitter-new-icon.webp"><span
                              class="action-icon">
                            {{
                                  "products.home-fund-card-share-twitter-btn" |
                                      translate
                            }}</span></a></li>
                          <li><a style="cursor: pointer;" class="dropdown-item"
                                 (click)="shareOnApp('FACEBOOK')">
                            <img alt="image" src="assets/images/general/FacebookLogo.webp"><span
                              class="action-icon">
                            {{
                                  "products.home-fund-card-share-fb-btn" |
                                      translate
                            }}</span></a></li>
                          <li><a style="cursor: pointer;" class="dropdown-item"
                                 (click)="shareOnApp('LINKEDIN')">
                            <img alt="image" src="assets/images/general/LinkedinLogo.webp"><span
                              class="action-icon">
                            {{
                                  "products.home-fund-card-share-linkedin-btn" |
                                      translate
                            }}</span></a></li>
                          <li><a style="cursor: pointer;" class="dropdown-item"
                                 (click)="shareOnApp('WHATAPP')">
                            <img alt="image" src="assets/images/general/whatsapps.webp"><span
                              class="action-icon">
                            {{
                                  "products.home-fund-card-share-whatsapp-btn" |
                                      translate
                            }}</span></a></li>
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="article-language" *ngIf="!isScrom">
                  <div class="languages-box">
                    <span>{{ "ftiKc.choose-language" | translate }}</span>
                    <div class="languages">
                      <div>
                        <div class="form-check custom-check"
                             *ngFor="let videoInfo of modifiedContent;let i = index">
                          <input class="form-check-input" type="checkbox" [id]="videoInfo?.id"
                                 (click)="toggleCheckbox(videoInfo)"
                                 [checked]="videoInfo?.isChecked">
                          <label *ngIf="!isScrom" class="form-check-label"
                                 [for]="videoInfo?.id">
                            {{ videoInfo?.language }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button (click)="subscribe()" class="btn btn-warning">
                    {{ "ftiKc.subscribe" | translate }}
                  </button>
                </div>
              </div>
              <div class="video-img-block mt-md-4 mt-3 mb-3">
                <div class="w-100 article-img">
                  <div>
                    <div *ngIf="isScrom; else iframeYT" class="Scrom-Vid">
                      <div class="article-img-block audio-img-block mt-5" *ngIf="content?.bannerImage?.widenDocument">
                        <img class="w-100 article-img" alt="{{ content?.title }}" title="{{ content?.title }}"
                             [src]="getThumbnailImage(content?.bannerImage?.widenDocument)"/>
                      </div>
                      <span class="scrom-s"></span><br/>
                      <a class="btn btn-outline-primary me-3" (click)="scromOpenModal(content3)">
                        <i class="bi bi-camera-video"></i> Launch
                        Video</a>
                    </div>
                    <ng-template #iframeYT>
                      <iframe width="100%" height="332px" allowfullscreen="allowfullscreen"
                              id="vid_frame" frameborder="0" [src]="urlSafe"
                              loading="lazy"></iframe>
                    </ng-template>
                    <ng-template #content3 let-modal>
                      <div class="fti-modal">
                        <div class="modal-body scrom-modal-body clearfix">
                          <a type="button" class="fill-circle-close" (click)="modal.dismiss('Cross click')">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </a>
                          <div class="text-big text-bold">
                            {{ content?.title }}
                          </div>
                          <div class="scrom-main-div" [innerHTML]="scromVid | safe: 'html'"></div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="d-flex align-items-center mb-4 content-btns"
                     [ngClass]="quizDocument.quizQuestions && quizDocument.quizQuestions.length > 0 ? 'justify-content-between':'justify-content-end'">
                  <a data-bs-toggle="modal" data-bs-target="#testLearning" (click)="isReload = true"
                     class="btn btn-transparent text-blue"
                     *ngIf=" quizDocument.quizQuestions && quizDocument.quizQuestions.length > 0">
                    <img alt="Quiz"
                         src="assets/images/knowledge-center/quiz.svg">{{ "ftiKc.learning-test-label" | translate }}
                  </a>
                  <div class="flex-wrap">
                    <button type="button" class="btn btn-light-primary me-3 custom-mb-10"
                            (click)="moveToRelatedTag()">
                      <img alt="image" class="d-none d-md-inline-block"
                           src="https://franklintempletonprod.widen.net/content/9zwvfbzzyq/original/thumb2.png">
                      <img alt="image" class="d-none d-md-inline-block"
                           src="https://franklintempletonprod.widen.net/content/vxqbxrhr7c/original/thumb1.png">
                      {{ "ftiKc.related-videos-label" | translate }}
                    </button>
                    <ng-container *ngIf="isMobileView else desktop">
                      <button type="button" data-bs-toggle="offcanvas" aria-controls="offcanvasBottom"
                              class="btn btn-outline-primary me-3"
                              data-bs-target="#offcanvascallBackRequest">
                        {{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}
                      </button>
                    </ng-container>
                    <ng-template #desktop>
                      <button type="button" class="btn btn-outline-primary me-3" data-bs-toggle="modal"
                              data-bs-target="#callBackRequest">
                        {{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}
                      </button>
                    </ng-template>
                    <a (click)="investNowLink()" class="btn btn-primary invest-btn">
                      {{ 'ftiCalc.calculator-invest-now' | translate }}
                    </a>
                  </div>
                </div>
                <ng-container *ngIf="currentVideo?.langContent.value !==''">
                  <div class="mb-md-5 mb-3">
                    <div class="toggle-content-box">
                      <span class="me-2" [class.active]="showTranscript" (click)="toggleTranscript()">
                        {{ "ftiKc.show-transcript" | translate }}</span>
                      <span [class.active]="!showTranscript" (click)="toggleTranscript()">
                        {{ "ftiKc.hide-transcript" | translate }}</span>
                    </div>
                  </div>
                  <div class="content-box" *ngIf="showTranscript">
                    <div [innerHtml]="currentVideo?.langContent.value"></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </section>
        </ng-container>
      </ng-container>
      <ft-related-tags documentType="video" [relatedTags]="getRelatedTags(content?.videoPageBreadcrumbs)"
                       [nextArticle]="nextArticleToCome"
                       [previousArticle]="previousArticleToCome"></ft-related-tags>
      <ft-related-category [page]="page"
                           [relatedTags]="getRelatedTags(content?.videoPageBreadcrumbs)"></ft-related-category>
    </div>
  </div>
</div>

<!----modal for test learning-->
<div class="modal fade" id="testLearning" tabindex="-1" aria-labelledby="testLearningLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered testLearning-modal" style="margin-top: 135px;">
    <div class="modal-content" style="min-height: 460px; z-index: 1000000; max-height: fit-content;">
      <div class="d-flex justify-content-between data-header">
        <div class="modalTitle">{{ "ftiKc.learning-test-label"| translate }}
        </div>
        <div type="button" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x fs-3"></i></div>
      </div>
      <div class="modal-body">
        <div class="modalTitle">{{ quizDocument?.displayName }}</div>
        <fti-test-your-learning *ngIf="isReload" (reloadQuiz)="reloadQuiz($event)"
                                [questions]="quizDocument?.quizQuestions"></fti-test-your-learning>
      </div>
    </div>
  </div>
</div>
<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false)">
      <img alt="image" src="assets/images/general/close.webp">
    </span>
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>

<!-- Dialoge: Max allowed alert -->
<div class="save-alert" style="z-index:10000" *ngIf="watchListFull">
  <div class="content">
    <ng-container>
      <a class="close-btn" (click)="closeWatchlist()">
        <img alt='image' src="assets/images/general/close.webp">
      </a>
      <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
      <span style="font-size: 1.063rem;">{{ "ftiKc.kc-watchlist-full" | translate }}</span>
    </ng-container>
  </div>
</div>
