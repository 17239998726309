import groupBy from 'lodash/groupBy';
import {
  AssetAllocation,
  PortfolioAllocation,
  PortfolioAllocationDataPoint,
} from '@models';
import { sortPortfolioDataPoints } from '@products/portfolio/portfolio.utils';
import {
  PortfolioAssetAllocationDTO,
  PortfolioComponentId,
  PortfolioDTO,
} from '@types';
import { formatPercentage } from '@utils/text/number-utils';
import { MapperFactory } from '../mapper-factory';
import { Mapper, MapperParams } from '../type.mapper';
import { Logger } from '@utils/logger';
import { getFirstNonEmptyProp, parseStdNumber, parseYN } from '../mapper.utils';

const logger = Logger.getLogger('AssetAllocationMapper');

export const isAssetAllocation = (
  point: PortfolioAssetAllocationDTO
): boolean => true;

/**
 * example fund:
 * https://en-us.staging.ftsites.com/investments/options/mutual-funds/products/4402/A/franklin-biotechnology-discovery-fund/FBDIX#portfolio
 */
export const mapAssetAllocation = (
  data: PortfolioAssetAllocationDTO[]
): PortfolioAllocation => ({
  componentId: PortfolioComponentId.ASSET_ALLOCATION,
  baseLabelKey: 'asset-allocation',
  allocationFlag: getFirstNonEmptyProp(data, 'allocflag'),
  allocationHeading: getFirstNonEmptyProp(data, 'allocheadinglocal'),
  asOfDate: getFirstNonEmptyProp(data, 'asofdate'),
  calculationBasis: getFirstNonEmptyProp(data, 'calcbasislocal'),
  calculationType: getFirstNonEmptyProp(data, 'calctypelocal'),
  hasDerivatives: parseYN(getFirstNonEmptyProp(data, 'hasderivatives')),
  updateFrequency: getFirstNonEmptyProp(data, 'frequency'),
  data: data.sort(sortPortfolioDataPoints).map(
    (obj: PortfolioAssetAllocationDTO): PortfolioAllocationDataPoint => ({
      label: obj.assetalloccatg,
      breakdown: formatPercentage(obj.brkdwnpct),
      breakdownStd: parseStdNumber(obj.brkdwnpctstd),
    })
  ),
});

export class AssetAllocationMapper extends Mapper<PortfolioAllocation[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(portfolioDto: PortfolioDTO): PortfolioAllocation[] {
    const data: PortfolioAssetAllocationDTO[] = portfolioDto.assetallocation;

    // if no data, return null
    if (!data || data.length === 0) {
      return null;
    }

    const allocations: PortfolioAllocation[] = [];

    const assetAllocationData: PortfolioAssetAllocationDTO[] = data.filter(
      isAssetAllocation
    );
    if (assetAllocationData.length > 0) {
      allocations.push(mapAssetAllocation(assetAllocationData));
    }

    return allocations;
  }
}

export class OneToOneAssetAllocationsMapper extends Mapper<AssetAllocation[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    portfolioAssetAllocationsDTO: PortfolioAssetAllocationDTO[]
  ): AssetAllocation[] {
    const assetAllocations: AssetAllocation[] = [];

    portfolioAssetAllocationsDTO.forEach((portfolioAssetAllocationDTO) => {
      const assetAllocation: AssetAllocation = this.createMapper(
        OneToOneAssetAllocationMapper
      ).toDomain(portfolioAssetAllocationDTO);

      if (assetAllocation) {
        assetAllocations.push(assetAllocation);
      }
    });

    return assetAllocations;
  }
}

class OneToOneAssetAllocationMapper extends Mapper<AssetAllocation> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    portfolioAssetAllocationDTO: PortfolioAssetAllocationDTO
  ): AssetAllocation {
    return {
      assetAllocationCategory: portfolioAssetAllocationDTO.assetalloccatg,
      breakdownPercent: portfolioAssetAllocationDTO.brkdwnpct,
    };
  }
}
