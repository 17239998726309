import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from '@services/common.service';
import { KnowledgeCenterService } from '@services/knowledge-center.service';
import { Logger } from '@utils/logger';
import { TranslateService } from "@shared/translate/translate.service";

const logger = Logger.getLogger("RelatedTagsComponent");

@Component({
  selector: 'ft-related-tags',
  templateUrl: './related-tags.component.html',
  styleUrls: ['./related-tags.component.scss'],
  providers: [
    Location,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
  ],
})
export class RelatedTagsComponent implements OnInit, OnChanges {
  showTooltip: boolean = false;
  showCopyDialoge: boolean = false;

  @Input() nextArticle: any;
  @Input() previousArticle: any;
  @Input() documentType: string;
  @Input() relatedTags: any;

  linkToCopy: any;
  watchListFull: boolean = false;
  defaultArticleBannerImage: any;
  constructor(
    private commonService: CommonService,
    private knowledgeCenter: KnowledgeCenterService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.knowledgeCenter.watchlistKC$.subscribe((article) => {
      if (this.nextArticle) {
        this.nextArticle = {
          ...this.nextArticle,
          isWatchListed: this.checkWatchList(this.nextArticle),
        };
      }
      this.previousArticle = {
        ...this.previousArticle,
        isWatchListed: this.checkWatchList(this.previousArticle),
      };
    });
  }

  ngOnInit(): void {
    this.defaultArticleBannerImage = this.translateService.instant(
      "ftiKc.defaultPrevNextImage"
    );
    if (this.nextArticle) {
      this.nextArticle = {
        ...this.nextArticle,
        isWatchListed: this.checkWatchList(this.nextArticle),
      };
    }
  }

  checkWatchList(article: any): boolean {
    const watchListedIds = this.knowledgeCenter.getWatchList();
    return watchListedIds.includes(article?.title);
  }

  getImageURL(type?: string | undefined): string | null {
    const data =
      type == "next"
        ? this.documentType == "article"
          ? this.nextArticle?.indwidenImage?.bannerImage?.widenDocument
          : this.nextArticle?.widenAssetCompound?.widenDocument
        : this.documentType == "article"
          ? this.previousArticle?.indwidenImage?.bannerImage?.widenDocument
          : this.previousArticle?.widenAssetCompound?.widenDocument;

    try {
      const parsedData = data ? JSON.parse(data) : '';
      return parsedData?.url || null;
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      return null;
    }
  }

  addToWatshlist(article: any) {
    this.watchListFull = false;
    const watchListedIds = this.knowledgeCenter.getWatchList();
    if (watchListedIds.length <= 7) {
      this.knowledgeCenter.addToWatchList(article?.title);
    } else {
      this.watchListFull = true;
    }
  }

  closeWatchlist() {
    this.watchListFull = false;
  }

  removeToWatchlist(article: any) {
    this.knowledgeCenter.removeFromWatchList(article?.title);
  }

  shareOnApp(appName, type: string): void {
    let href;
    if (type === 'next') {
      this.linkToCopy = this.nextArticle?.links?.site?.href;
    } else if (type === 'previous') {
      href = this.previousArticle?.links?.site?.href;
    }
    this.commonService.shareOn(appName, href);
  }

  async toggleShowCopyDialoge(check: boolean, type: string): Promise<void> {
    let href;
    const baseUrl = this.commonService.getBaseUrl();
    if (type === 'next') {
      // this.linkToCopy = this.nextArticle?.links?.site?.href;
      this.linkToCopy = `${baseUrl}${this.nextArticle?.links?.site?.href}`;
    } else if (type === 'previous') {
      // href = this.previousArticle?.links?.site?.href;
      href = `${baseUrl}${this.nextArticle?.links?.site?.href}`;
    }
    if (check) {
      this.linkToCopy = href ?? this.linkToCopy;
      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

}
