import { Component, Input, OnInit } from "@angular/core";
import { Component as BrComponent, Document, Page } from "@bloomreach/spa-sdk";
import { CommonService } from "@services/common.service";
import { Logger } from "@utils/logger";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("AddressBookComponent");

@Component({
  selector: "ft-address-book",
  templateUrl: "./address-book.component.html",
  styleUrls: ["./address-book.component.scss"],
})
export class AddressBookComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  itemsToShow = 6;
  selectedCategory: string = "All";
  allStates: any;
  allCities: any;
  allLocations: any;
  selectedState: string = "All";
  selectedCity: string = "All";
  allBranches: any;
  filteredBranches: any;
  citiesValues: any = [
    {
      id: 'all-cities',
      state_id: 'all-cities',
      name: 'All Cities',
      slug: 'all-cities',
    },
  ];

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit(): void {
    this.allValues();
    this.selectCategory("All");
    this.getCities();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    this.document?.getData()?.stateCompoundType?.forEach((element) => {
      const slug = element?.state
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      element.slug = slug;
      element.citiesCompoundType.forEach((el) => {
        const cityslug = el.cityname
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "");
        el.cityslug = cityslug;
      });
    });
    logger.debug("contentvalue >>> ", this.document?.getData());
    return this.document?.getData();
  }


  getCities() {
    const cityvalue = this.document?.getData()?.stateCompoundType;
    const citiesValue = [];
    cityvalue?.forEach((element, stateid) => {
      element?.citiesCompoundType?.forEach((el, index) => {
        const slug = el?.cityname
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        const StateId = element?.state?.replace(/ /g, '');
        const cityId = el?.cityname?.replace(/ /g, '');
        const name = el?.cityname;
        const citypusvalue = {
          id: cityId,
          state_id: StateId,
          name: el?.cityname,
          slug,
        };
        this.citiesValues?.push(citypusvalue);
      });
    });
    const array1 = this.citiesValues?.filter((e) => e.id === 'all-cities');
    const array2 = this.citiesValues
      ?.filter((e) => e?.id !== 'all-cities')
      .sort((a, b) => a?.id.localeCompare(b?.id));

    this.citiesValues = [].concat(array1, array2);
    this.commonService.setCities(this.citiesValues);
    return citiesValue;
  }

  allValues() {
    const allBranchValues = [];
    const uniqueStatesSet = new Set<string>();
    const uniqueCitiesSet = new Set<string>();
    const uniqueStatesCitiesSet = new Set<string>();
    this.content?.stateCompoundType.forEach((element) => {
      element.citiesCompoundType.forEach((el) => {
        el.branchCompoundType.forEach((bel) => {
          const branchValues = {
            branchname: bel?.branchname,
            latitude: bel?.latitude,
            longitude: bel?.longitude,
            telephoneNumber: bel?.telephoneNumber,
            sales: bel?.sales,
            services: bel?.services,
            address: bel?.address,
            cityname: el?.cityname,
            state: element?.state,
            slug: element?.slug,
            cityslug: el?.cityslug,
          };

          allBranchValues.push(branchValues);
          uniqueStatesSet.add(
            JSON.stringify({state: element.state, slug: element.slug})
          );
          uniqueCitiesSet.add(
            JSON.stringify({cityname: el.cityname, cityslug: el.cityslug})
          );
          uniqueStatesCitiesSet.add(
            JSON.stringify({
              state: element.state,
              slug: element.slug,
              cityname: el.cityname,
              cityslug: el.cityslug,
            })
          );
        });
      });
    });

    allBranchValues.sort((a, b) => a.cityname.localeCompare(b.cityname));
    this.allBranches = allBranchValues;
    this.allStates = Array.from(uniqueStatesSet).map((stateString) =>
      JSON.parse(stateString)
    );
    this.allCities = Array.from(uniqueCitiesSet).map((cityString) =>
      JSON.parse(cityString)
    );
    this.allLocations = Array.from(uniqueStatesCitiesSet).map((location) =>
      JSON.parse(location)
    );
  }

  loadMore() {
    this.itemsToShow += 6;
  }

  get canLoadMore(): boolean {
    return this.itemsToShow < this.filteredBranches.length;
  }

  selectCategory(category: string) {
    this.selectedCategory = category;

    if (category === "All") {
      this.filteredBranches = [...this.allBranches];
    } else if (category === "CAMS") {
      this.filteredBranches = this.allBranches.filter(
        (branch) => branch.branchname === category
      );
    } else {
      this.filteredBranches = this.allBranches.filter(
        (branch) => branch.branchname !== "CAMS"
      );
    }
  }

  stateChange(event: any) {
    this.selectedState = event.target.value;
    this.allCities = this.allLocations.filter(
      (state) => state.slug === this.selectedState
    );
    this.selectedCity = "All";
  }

  cityChange(event: any) {
    this.selectedCity = event.target.value;
    let allStates = this.allLocations.filter(
      (state) => state.cityslug === this.selectedCity
    );
    this.selectedState = allStates[0]?.slug;
  }

  onSearchInit() {
    if (this.selectedState === "All" && this.selectedCity === "All") {
      // No filters applied, show all branches
      this.filteredBranches = [...this.allBranches];
      this.setAnalytics('All States|All Cities');
    } else if (this.selectedState === "All" && this.selectedCity !== "All") {
      // Filter only by city
      this.filteredBranches = this.allBranches.filter(
        (branch) => branch.cityslug === this.selectedCity
      );
      this.setAnalytics(this.selectedCity);
    } else if (this.selectedState !== "All" && this.selectedCity === "All") {
      // Filter only by state
      this.filteredBranches = this.allBranches.filter(
        (branch) => branch.slug === this.selectedState
      );
      this.setAnalytics(this.selectedState);
    } else {
      // Filter by both state and city
      this.filteredBranches = this.allBranches.filter(
        (branch) =>
          branch.slug === this.selectedState &&
          branch.cityslug === this.selectedCity
      );
      this.setAnalyticsBoth(this.selectedCity, this.selectedState);
    }
    this.itemsToShow = 6;

  }

  setAnalytics(label) {
    this.analyticsService.trackEvent({
      event: 'Event Distributor Zone',
      category: 'Distributor Zone',
      action: 'Search by Location',
      label: label
    });
  }

  setAnalyticsBoth(city, state) {
    this.analyticsService.trackEvent({
      event: 'Event Distributor Zone',
      category: 'Distributor Zone',
      action: 'Search by Location',
      label: state + '|' + city
    });
  }

  reset() {
    this.selectedState = "All";
    this.selectedCity = "All";
    this.onSearchInit();
  }

  modalReferance: any;

  showModal(content: any, latitude?: any, longitude?: any) {
    this.modalReferance = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
  }

  display: any;
  /*center: google.maps.LatLngLiteral = {
    lat: 7.147615499732514,
    lng: 96.41816013112184
  };
  zoom = 5;

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  markerOptions: google.maps.MarkerOptions = {
    draggable: false
  };
  markerPositions: google.maps.LatLngLiteral[] = [];

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
  }*/

}
