import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@services/common.service';
import { KnowledgeCenterService } from '@services/knowledge-center.service';
import { Logger } from '@utils/logger';
import { Page } from "@bloomreach/spa-sdk";
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KC_CATEGORIES } from 'src/app/ft-constants/knowledge-center.constants';
import { Router } from '@angular/router';
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger("RelatedTagsComponent");

@Component({
  selector: 'ft-related-category',
  templateUrl: './related-category.component.html',
  styleUrls: ['./related-category.component.scss'],
  providers: [
    Location,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
  ],
})
export class RelatedCategoryComponent implements OnInit {
  @Input() calcTags!: any;
  @Input() relatedTags: any;
  @Input() page: Page;
  articleArray = [];
  componentDestroyed$: Subject<boolean> = new Subject();
  watchListFull: boolean = false;
  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow:
      "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow:
      "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  showIconForWatchlist: boolean[] = [];
  defaultArticleBannerImage: any;
  showTooltip: boolean = false;
  linkToCopy: string;
  showCopyDialoge: boolean = false;
  selectedCategory: any = KC_CATEGORIES[0];
  articleCategoryLink: string;
  dropdownTitle: string;


  constructor(
    private translateService: TranslateService,
    private knowledgeService: KnowledgeCenterService,
    private commonService: CommonService,
    private searchService: FtsearchService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
  }


  ngOnInit(): void {
    this.defaultArticleBannerImage = this.translateService.instant(
      "ftiKc.defaultArticleBannerImage"
    );
    let routerUrl = this.router.url;
    if (routerUrl.indexOf('/article/') > 0) {
      routerUrl = routerUrl?.split('/article/')[0];
    } else if (routerUrl.indexOf('/video/') > 0) {
      routerUrl = routerUrl?.split('/video/')[0];
    } else if (routerUrl.indexOf('/blogs/') > 0) {
      routerUrl = routerUrl?.split('/blogs/')[0] + '/blog';
    }
    this.articleCategoryLink = routerUrl;
    this.dropdownTitle = routerUrl.split("/").pop().replace(/-/g, " ");
    this.dropdownTitle = this.dropdownTitle === "blog" ? "Blogs" : this.dropdownTitle;
    this.getFTIArticleData();
  }


  getFTIArticleData() {
    this.articleArray = [];
    let relatedTag = this.dropdownTitle.toLocaleLowerCase().replace(/\s+/g, '-');
    let param1 = 'pageType';
    if (this.calcTags) {
      relatedTag = this.calcTags;
      param1 = 'relatedTags.keyword';
    }
    let routerUrl = this.router.url;
    if (routerUrl.indexOf('/blogs/') > 0) {
      relatedTag = 'Blog';
      param1 = 'articleType.keyword';
    }
    const filterData = [
      {
        fieldName: "documentType.exact",
        fieldValue: ["INDArticleDetails", "INDVideoArticles"],
      },
      {fieldName: param1, fieldValue: [relatedTag]},
    ];

    const watchListedIds = this.getWatchListedIds(); // Get watch-listed documentIds from local storage

    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), 0, 8)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        const results = data?.results?.response?.hits?.hits || [];

        this.articleArray = results.map((article) => {
          const source = article._source;
          const widenAssetJson = source?.widenAssetJson ? JSON.parse(source.widenAssetJson) : null;
          const image = widenAssetJson?.url;
          const navigationUrl = source?.documentPath?.split("site-pages")[1] || '';
          const pageTitle = source?.pageTitle || source?.title;
          // const isWatchListed = watchListedIds.includes(pageTitle);
          let isWatchListed = false;
          watchListedIds.forEach(id => {
            if (id.pageTitle === pageTitle) {
              isWatchListed = true;
            }
          })

          return {
            data: article,
            url: navigationUrl,
            image: image,
            pdfUrl: source?.pdfURL,
            pageTitle: pageTitle,
            isWatchListed: isWatchListed,
          };
        }).sort((a, b) => new Date(b.data?._source?.publishDate).getTime() - new Date(a.data?._source?.publishDate).getTime());

        this.cdRef.detectChanges();
      });
  }

  getWatchListedIds(): any[] {
    // Retrieve watch-listed articleIds from local storage
    const watchListString = localStorage.getItem("kc-watch-list");
    return watchListString ? JSON.parse(watchListString) : [];
  }

  categoryOnClick(slide: any) {
    if (!slide?.pdfUrl) {
      this.router.navigate([this.page.getUrl(slide?.url)]);
    }
    if (slide?.pdfUrl) {
      window.open(slide?.data?._source?.pdfURL, "_blank");
    }
  }

  toggleIconForWatchlist(index: number) {
    this.showIconForWatchlist[index] = !this.showIconForWatchlist[index];
  }

  addToWatshlist(article: any) {
    this.watchListFull = false;
    const watchListedIds = this.knowledgeService.getWatchList();
    if (watchListedIds.length <= 7) {
      const articleToUpdate = this.articleArray.find(
        (articleInArray) => articleInArray.pageTitle === article?.pageTitle
      );
      if (articleToUpdate) {
        articleToUpdate.isWatchListed = true;
      }
      this.knowledgeService.addToWatchList(article);
    } else {
      this.watchListFull = true;
    }
  }

  removeToWatchlist(article: any) {
    this.knowledgeService.removeFromWatchList(article);
    const articleToUpdate = this.articleArray.find(
      (articleInArray) => articleInArray.pageTitle === article?.pageTitle
    );
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = false;
    }
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

  async toggleShowCopyDialoge(check: boolean, article?: any): Promise<void> {
    if (check) {
      this.linkToCopy = article?.data?._id;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  shareOnApp(appName, article): void {
    const url = `${article.url}`;
    this.commonService.shareOn(appName, url);
  }

  closeWatchlist() {
    this.watchListFull = false;
  }
}
