<div class="fund-card-wrapper">
  <div class="fund-block position-relative">
    <div class="d-flex justify-content-between fund-head" style="margin-top: 5px;">
    </div>
    <div class="row">
      <div class="col-12 col-sm-2 fund-record-code">
        <div class="search-record-thumb">
          {{ fund?.ticker }}
        </div>
      </div>
      <div class="col-12 col-sm-9">
        <h4 class="fund-card-title mb-0" style="font-size: 1.25rem">
          <a class="forfunddcardtitle" [routerLink]="fund?.fundsOverviewLink" [innerHTML]="fund?.fundName">
          </a>
        </h4>
        <div class="search-other-info">
          <span class="asofTitle">{{ "products.as-of-capitalize" | translate }} {{ fund?.navdate }}</span>&nbsp;&nbsp;
          <strong>{{ "products.nav" | translate }}&nbsp;{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}
            <span class="nav-val">{{ fund?.navvalue }}</span>
          </strong>
        </div>
        <p class="search-description p-0">
          <a
              *ngIf="fund?.fundsOverviewLink"
              [routerLink]="fund?.fundsOverviewLink">
            {{ "products.tab-overview" | translate }}
          </a>&nbsp;|&nbsp;
          <a href="javascript:void(0)"
             *ngIf="fund?.fundsPerformanceLink"
             (click)="funPageLinkClick(fund?.fundsOverviewLink +'?scrolltoperf=true')">
            {{ "products.tab-performance" | translate }}
          </a>&nbsp;|&nbsp;
          <a href="javascript:void(0)"
             *ngIf="fund?.fundsPortfolioLink"
             (click)="funPageLinkClick(fund?.fundsOverviewLink +'?scrolltoport=true')">
            {{ "products.tab-portfolio" | translate }}
          </a>&nbsp;|&nbsp;
          <a href="javascript:void(0)"
             *ngIf="fund?.fundsHistoricalNavsLink"
             (click)="funPageLinkClick(fund?.fundsOverviewLink +'?scrolltonav=true')">
            {{ "products.tab-historical-navs" | translate }}
          </a>&nbsp;|&nbsp;
          <a href="javascript:void(0)"
             *ngIf="fund?.fundsIDCWLink"
             (click)="funPageLinkClick(fund?.fundsOverviewLink+'?scrolltoidcw=true')">
            {{ "products.distributions-dividend-history" | translate }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
