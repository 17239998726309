/** @module */

import { fromLocaleNumber } from '../il8n/number-il8n';
import { symbols } from '../il8n/currency';
import { formatString, makeStringNonBreaking } from './string-utils';

export const CLEAN_STRING_END_REGEX = /[^\d.-]+$/g;//NOSONAR

/**
 * This function performs light-touch number parsing, this is never for display
 * only ever for light calc (e.g is a number positive or negative)
 *
 * returns null instead of NaN for invalid numbers, due to NaN can't be JSON encoded
 *
 * E.g. parsing german numbers 0,0
 */
export function parseNumber(val) {
  return fromLocaleNumber(val);
}

/**
 * This function performs light-touch currency formatting, added a currency symbol to a arbitrary string
 * not guarentued to be a string e.g. "4.55 million" -> "£4.55 million"
 */
export function appendCurrency(val, currency) {
  val = formatString(val);
  if (val === '—') {
    return val;
  }
  // add currency symbol make non breaking
  let currencySymbol = symbols.get(currency);
  if (!currencySymbol) {
    currencySymbol = '';
  }
  val = currencySymbol + makeStringNonBreaking(val);
  return val;
}

/**
 * This function performs light-touch percentage formatting, so as not to lose any locale specific formatting
 * All it does is postpend (if necessary) a percentage symbol, returns emdash on no data
 *
 * Commas and other numeric separators should be left intact
 */
export function formatPercentage(val: string | number = ''): string {
  const stripped = (val + '')
    .replace(CLEAN_STRING_END_REGEX, '')
    .replace('%', '');
  if (val === 'N/A') {
    return val;
  }
  const parsed = parseFloat(stripped);
  if (isNaN(parsed)) {
    return '—';
  }
  return stripped + '%';
}
