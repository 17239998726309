import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit,
  Inject,
} from '@angular/core';
import {
  Component as BrComponent,
  Page,
  Document,
  Reference,
} from '@bloomreach/spa-sdk';
import { takeUntil, windowCount } from 'rxjs/operators';
import { AppStateService } from '@services/app-state.service';
import { Logger } from '@utils/logger';
const logger = Logger.getLogger('IndCarouselComponent');
import { TranslateService } from '@components/shared/translate/translate.service';
import { WindowService, WindowSize } from '@services/window.service';
import { FundHeaderService } from '../../products/overview/services/fund-header.service';
import { WINDOW } from '@ng-web-apis/common';
import { Subject } from 'rxjs';
@Component({
  selector: 'ft-reachforbetterarticle-page',
  templateUrl: './reachforbetterarticle-page.component.html',
  styleUrls: ['./reachforbetterarticle-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReachforbetterarticlePageComponent
  implements OnChanges, OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  shareUrlRefresh: any;
  hostName: any;
  layout: any;
  CareerIneerArticleTopBannerText: any;
  careerInnerArticleFooterTop: any;
  careerInnerArticleFooterbottom: any;
  careerInnerArticleTopBanner: any;
  careerInnerArticleTopbannerTitle: any;
  careerInnerarticleDisclaimer: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    @Inject(WINDOW) private _window: Window,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private windowService: WindowService,
    private fundHeaderService: FundHeaderService
  ) {}
  ngOnChanges() {}
  ngOnInit(): void {
    this.updateAddThis();
    const alContents = this.page?.getComponent('main-content');
    const articleComponents = alContents?.getComponent(
      'IND-reach-for-better-work'
    );

    this.layout = this.page?.getComponent().getParameters().layout;
    this.CareerIneerArticleTopBannerText = this.translateService.instant(
      'ftiCommon.CareerIneerArticleTopBannerText'
    );
    this.careerInnerArticleFooterTop = this.translateService.instant(
      'ftiCommon.careerInnerArticleFooterTop'
    );
    this.careerInnerArticleFooterbottom = this.translateService.instant(
      'ftiCommon.careerInnerArticleFooterbottom'
    );
    this.careerInnerArticleTopBanner = this.translateService.instant(
      'ftiCommon.careerInnerArticleTopBanner'
    );
    this.careerInnerArticleTopbannerTitle = this.translateService.instant(
      'ftiCommon.careerInnerArticleTopbannerTitle'
    );
    this.careerInnerarticleDisclaimer = this.translateService.instant(
      'ftiCommon.careerInnerarticleDisclaimer'
    );
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    const contentValue = this.document?.getData();
    return contentValue;
  }

  ngAfterViewInit() {
    this.updateAddThis();
    const scrollToTop = document.querySelector('a.back-to-top');
    // scrollToTop?.classList.add('d-sm-none');
    scrollToTop?.addEventListener('click', () => {
      window.scrollTo(0, 0);
      // scrollToTop.classList.add('d-sm-none');
    });
    this.windowService
      .getScrollObs$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const navbar = document.getElementById('left-nav');
        const goTopBtn = document.querySelector('.back-to-top');
        const perActive = document.getElementById('perspective');
        const philosophy = document.getElementById('nav-philosophy');
        const perspective = document.getElementById('nav-perspective');
        const pActive = document.getElementById('philosophy');
        const pTop = philosophy?.getBoundingClientRect().top;
        const pRes = perspective?.getBoundingClientRect().top;
        if (window.pageYOffset >= 250) {
          navbar?.classList.add('sticky');
          if (goTopBtn && goTopBtn.classList.contains('d-none')) {
            goTopBtn.classList.remove('d-none');
          }
        } else {
          navbar?.classList.remove('sticky');
          goTopBtn?.classList.add('d-none');
        }

        if (window.pageYOffset >= pTop && window.pageYOffset <= pRes + 40) {
          logger.debug('inside ifffff');
          pActive?.classList.add('active');
          perActive?.classList.remove('active');
        } else if (window.pageYOffset >= pRes) {
          pActive?.classList.remove('active');
          perActive?.classList.add('active');
        } else {
          pActive?.classList.remove('active');
        }
      });
  }
  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  getImageURL(data) {
    const imageURL = JSON.parse(data)?.url;
    return imageURL;
  }

  updateAddThis() {
    // tslint:disable-next-line
    const addThis = this._window['addthis'];
    if (addThis) {
      addThis.url = this._window.location.href;
      addThis.title = this._window.document.title;
      addThis.toolbox('.addthis_inline_share_toolbox');
    }

    if (addThis?.layers?.refresh) {
      addThis.layers.refresh();
    }
  }
  get isPreview() {
    return this.page.isPreview();
  }
}
