import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TotalCount } from '../search.interface';
import { FILTER_SECTION } from '../search.component';

@Component({
  selector: 'ft-search-results-funds',
  templateUrl: './search-results-funds.component.html',
  styleUrls: ['./search-results-funds.component.scss'],
})
export class SearchResultsFundsComponent implements OnInit {
  @Input() results: [];
  @Input() totalCount: TotalCount["funds"];
  @Input() valueToShow: number;
  @Input() isViewAll: boolean;
  @Output()
  viewAllClick: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  loadMoreFunds: EventEmitter<object> = new EventEmitter<object>();
  queryParams: Params;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  loadMore(event): void {
    this.loadMoreFunds.emit({event, type: 'funds'});
  }

  clickEvent(event): void {
    this.isViewAll = true;
    this.viewAllClick.emit(FILTER_SECTION.fundsFilterSection);
  }
}
