import { Mapper, MapperParams } from './type.mapper';
import { Product, ShareClass } from '@models';
import {
  AssetClass,
  ConfigurationId,
  CurrencyCode,
  FundCategory,
  FundId,
  FundUmbrella,
  ProductDTO,
  ProductType,
  WebProductTaxonomy,
} from '@types';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { ShareClassMapper } from './share-class.type.mapper';
import { InvestmentTeamsMapper } from './investment-team.type.mapper';
import { PortfolioCharsMapper } from './portfolio/portfolio-chars.type.mapper';
import {
  OneToOneAssetAllocationsMapper,
} from './portfolio/asset-allocation.mapper';
import { AumMapper } from './aum.type.mapper';

export class ProductsMapper extends Mapper<Product[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(productsList: ProductDTO[]): Product[] {
    const productsData: Product[] = [];
    productsList.forEach((productDto) => {
      const product: Product = this.createMapper(ProductMapper).toDomain(
        productDto
      );
      if (product) {
        productsData.push(product);
      }
    });
    return productsData;
  }
}

export class ProductMapper extends Mapper<Product> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(productDto: ProductDTO): Product {
    let product: Product;
    const productShareClasses: ShareClass[] = [];
    productDto?.shareclass?.forEach((shareClassDto) => {
      const shareClass = this.createMapper(ShareClassMapper).toDomain(
        shareClassDto,
        productDto.producttype as ProductType,
        productDto.webprdcttaxonomy as WebProductTaxonomy,
        productDto.basecurrcode,
        productDto.fundname
      );
      if (shareClass) {
        productShareClasses.push(shareClass);
      }
    });

    product = {
      fundId: productDto.fundid as FundId,
      fundName: productDto.fundname,
      fundNameForSorting:
        productDto.fundname &&
        !this.mapperParams?.config.isSiteInternational() &&
        (this.mapperParams?.configurationName === ConfigurationId._529 ||
          this.mapperParams?.configurationName === ConfigurationId.SMA)
          ? this.getNameForSorting(productDto.fundname)
          : '',
      assetClass: productDto.assetclass as AssetClass,
      investmentCategory: productDto.invstmntcatg,
      fundCategory: productDto.fundcatg as FundCategory,
      investmentManager: productDto.invmangr,
      investmentVehicle: productDto.invvehcile,
      fundUmbrella: productDto.fundumbrlla as FundUmbrella,
      region: productDto.region,
      investmentGroup: productDto.invstmntgroup,
      currencyCode: productDto.basecurrcode as CurrencyCode,
      etfType: productDto.etftype,
      strategy: productDto.strategy,
      methodology: productDto.methodology,
      fundFamily: productDto.fundfamily,
      productType: productDto.producttype as ProductType,
      fundInceptionDate: productDto.fundincdt,
      fundInceptionDateStd: productDto.fundincdtstd,
      productTaxonomy: productDto.webprdcttaxonomy as WebProductTaxonomy,
      alternativeFor: productDto.alternateivefor,
      investmentTenure: productDto.invtenure,
      shareClasses: productShareClasses,
      investmentTeam: productDto.investmentteam
        ? this.createMapper(InvestmentTeamsMapper).toDomain(
            productDto.investmentteam
          )
        : null,
      portfolio: {
        portfoliochars: productDto.portfolio?.portfoliochars
          ? this.createMapper(PortfolioCharsMapper).toDomain(
              productDto.portfolio.portfoliochars
            )
          : null,
        assetAllocation: productDto.portfolio?.assetallocation
          ? this.createMapper(OneToOneAssetAllocationsMapper).toDomain(
              productDto.portfolio.assetallocation
            )
          : null,
        topTenHoldings: productDto.portfolio?.topholdings,
        sectorAllocation: productDto.portfolio?.sectorallocation,
      },
      aum: productDto.aum
        ? this.createMapper(AumMapper).toDomain(productDto.aum)
        : null,
      investmentFocus: productDto.invfocus,
    };
    return product;
  }
  /**
   * DEFECT-FIX: NGC-8947.
   * To handle some special characters before sorting.
   * @param products fund listing
   */
  getNameForSorting(name: string): string {
    if (name.indexOf(' Newborn') !== -1) {
      return name.substring(0, name.indexOf('Newborn'));
    } else if (name.indexOf(' -') !== -1) {
      return name.substring(0, name.indexOf(' -'));
    } else if (name.indexOf('-') !== -1) {
      return name.substring(0, name.indexOf('-'));
    } else if (name.indexOf('+') !== -1) {
      return name.substring(0, name.indexOf('+'));
    } else {
      return name;
    }
  }
}
