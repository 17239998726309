import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ftDashIfEmpty',
})
export class DashIfEmptyPipe implements PipeTransform {
  transform(val?: any): string {
    if (val === '-') {
      return '-';
    }
    return val || val === 0 ? String(val) : '-';
  }
}
