import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'INRCurrency'})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value === undefined || value === null) {
      return '';
    }
    return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR', maximumFractionDigits: 0}).format(value);
  }
}

@Pipe({name: 'INRCurrencyNumber'})
export class CurrencyNumberFormatPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value === undefined || value === null) {
      return '';
    }
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 2
    }).format(value).replace(/₹/g, "");
  }
}

@Pipe({name: 'INRCurrencyNumberInt'})
export class CurrencyNumberFormatIntPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value === undefined || value === null) {
      return '';
    }
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(value).replace(/₹/g, "");
  }
}
