import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '../translate/translate.service';
import { SessionService } from '@services/session.service';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '@services/analytics.service';
import { Logger } from '@utils/logger';
import { Subscription } from 'rxjs';
import { CommonService } from '@services/common.service';
import { EnvConfigService } from '@services/env-config.service';

const logger = Logger.getLogger('ImintrestedformComponent');

@Component({
  selector: 'ft-imintrestedform',
  templateUrl: './imintrestedform.component.html',
  styleUrls: ['./imintrestedform.component.scss']
})
export class ImintrestedformComponent implements OnInit {

  @Input() isSmall = false
  intrestedForm: FormGroup = new FormGroup({})
  errorMessage: boolean = false;
  isFormSubmitted = false;
  environment: any;
  url = '';
  urlPrefix = '';
  isThankYou = false;
  citiesValues: any = [];
  subscription: Subscription;
  accepted: boolean = false;

  namePlaceholder: any;
  panPlaceholder: any;
  emailPlaceholder: any;
  queryPlaceholder: any;
  phonePlaceholder: any;

  constructor(
    private _fb: FormBuilder,
    private translateService: TranslateService,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private changeDetector: ChangeDetectorRef,
    private commonService: CommonService,
    private envConfig: EnvConfigService,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.urlPrefix = this.envConfig?.getEnvConfig()?.ftiApiDomain;
    this.subscription = this.commonService?.cityData?.subscribe(
      (citiesValues) => (this.citiesValues = citiesValues)
    );
    this.initModal();
    this.url =
      this.urlPrefix + this.translateService?.instant('ftiFeedback.interestedFormUrl');

    this.namePlaceholder = this.translateService.instant(
      'ftiGeneric.namePlaceholder'
    );
    this.panPlaceholder = this.translateService.instant(
      'ftiGeneric.panPlaceholder'
    );
    this.emailPlaceholder = this.translateService.instant(
      'ftiGeneric.emailPlaceholder'
    );
    this.queryPlaceholder = this.translateService.instant(
      'ftiGeneric.queryPlaceholder'
    );
    this.phonePlaceholder = this.translateService.instant(
      'ftiGeneric.phonePlaceholder'
    );
  }

  initModal = () => {
    this.intrestedForm = this._fb.group({// NOSONAR
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],// NOSONAR
      phone: ['', [Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]],
      query: [''],
      riaCode: ['', [Validators.required, Validators.pattern(/^[0-9A-Za-z- ]{3,20}$/)]],
      comments: ['', [Validators.required, Validators.pattern(/^(.|\s){1,500}$/)]]
    })
  }


  blockAlphabetsAndSpecialChars(event: any) {
    const char = event.key;
    // Check if the character is a numeric digit (0-9) or Backspace
    if (!/^[0-9]$/.test(char) && char !== 'Backspace') {
      event.preventDefault(); // Prevent the input of non-numeric characters
    }
  }

  public onInput($event: any, fieldName: string): void {
    let enteredCharacter = $event.target.value;
    enteredCharacter = enteredCharacter.replace(/[\/\\$'"<>]/g, '');
    this.intrestedForm.controls[fieldName].setValue(enteredCharacter);
  }

  arnNotRequired($event) {
    if ($event?.target?.checked) {
      this.accepted = true;
      this.intrestedForm?.get('riaCode').clearValidators();
      this.intrestedForm?.get('riaCode').reset();
      this.intrestedForm?.controls?.riaCode?.disable();
    } else {
      this.accepted = false;
      this.intrestedForm?.controls?.riaCode?.setValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
      this.intrestedForm?.controls?.riaCode?.enable();
    }
  }

  interestedSubmit() {
    this.isFormSubmitted = true;

    const name = this.intrestedForm?.value?.name;
    const emailId = this.intrestedForm?.value?.email;
    const mobile = this.intrestedForm?.value?.phone;
    const city = this.intrestedForm?.value?.query;
    const distId = this.intrestedForm?.value?.riaCode;
    const subscriptions = this.intrestedForm?.value?.comments;

    const data = {
      name,
      emailId,
      mobile: mobile ? mobile : '',
      city: city !== 'City' ? city : '',
      distId,
      subscriptions,
    };

    const options =
      this.environment === 'prod'
        ? this.sessionService?.apiHttpOptionsWithCredentials
        : this.sessionService?.apiHttpOptions;

    this.http.post(this.url, data, options).subscribe(
      (response) => {
        this.isThankYou = true;
        this.isFormSubmitted = false;
        this.errorMessage = false;
        this.setAnalytics();
        this.changeDetector.markForCheck();
      },
      (error) => {
        logger.error('error >>>> ', error);
        this.isFormSubmitted = false;
        this.errorMessage = true;
      }
    );
  }

  clear(){
    this.intrestedForm.reset();
    this.isThankYou= false;
  }

  close(){
    this.intrestedForm.reset();
  }

  setAnalytics() {
    this.analyticsService.trackEvent({
      event: 'Event Distributor Zone',
      category: 'Distributor Zone',
      action: 'Submit',
      label: 'Im Interested Form',
    });
  }

}
