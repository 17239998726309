import { Component, Input, OnDestroy, OnInit, } from "@angular/core";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import { AnalyticsService } from "@services/analytics.service";
import { TranslateService } from "@components/shared/translate/translate.service";

@Component({
  selector: "ft-kc-landing",
  templateUrl: "./kc-landing.component.html",
  styleUrls: ["./kc-landing.component.scss"],
})
export class KcLandingComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;
  startLearningURL: string;
  kcLanding: boolean = true;

  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.startLearningURL = this.translateService.instant(
      "ftiKc.open-learning-banner-start-learning-url"
    );
    if (this.params?.contentType === 'article list') {
      this.kcLanding = false;
    }
  }

  get params() {
    return this.component?.getParameters();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get isPreview() {
    return this.page?.isPreview();
  }

  /**
   * sets anlytics data on click of tiles of articles/videos
   * @param title title of the artcle/video
   */

  setAnalytics(title, fromArticle?: boolean): void {
    const viewArticle = "View Article";
    const viewVideo = "View video";
    this.analyticsService.trackEvent({
      event: "Event InvestorEducation",
      category: "Investor Education",
      action: fromArticle ? viewArticle : viewVideo,
      label: "New to Investing | " + title,
    });
  }

  ngOnDestroy() {
  }
}
