import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Constant } from '../../../ft-constants/constant';
import { Logger } from '@utils/logger';
import { VocabularyCategories } from '@types';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { ReachforBetterWorkCategory } from 'src/app/ft-constants/reachforbetter-work-category';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const logger = Logger.getLogger('BlogListComponent');
const ALL = 'All';

@Component({
  selector: 'ft-reachforbetter-work',
  templateUrl: './reachforbetter-work.component.html',
  styleUrls: ['./reachforbetter-work.component.scss'],
})
export class ReachforbetterWorkComponent implements OnInit, OnDestroy {
  @Input() page;
  @Input() categories;
  @Input() originalBlogs;
  videoUrl: string;

  selectedCategory = {
    key: 'cb-choose your career interest',
    label: 'Choose Your Career Interest',
  };
  selectedKHCategory = {
    key: 'kh-choose your career interest',
    label: 'Choose Your Career Interest',
  };

  cbCategories = [
    {
      key: 'cb-choose your career interest',
      label: 'Choose Your Career Interest',
    },
    {
      key: 'cb-finance',
      label: 'Finance',
    },
    {
      key: 'cb-for-driven-women',
      label: 'For Driven Women',
    },
    {
      key: 'cb-human-resources',
      label: 'Human Resources',
    },
    {
      key: 'cb-information-technology',
      label: 'Information Technology',
    },
    {
      key: 'cb-marketing',
      label: 'Marketing',
    },
    {
      key: 'cb-sales/business-development',
      label: 'Sales/Business Development',
    },
  ];
  kHCategories = [
    {
      key: 'kh-choose your career interest',
      label: 'Choose Your Career Interest',
    },
    {
      key: 'kh-finance',
      label: 'Finance',
    },
    {
      key: 'kh-for-driven-women',
      label: 'For Driven Women',
    },
    {
      key: 'kh-human-resources',
      label: 'Human Resources',
    },
    {
      key: 'kh-information-technology',
      label: 'Information Technology',
    },
    {
      key: 'kh-marketing',
      label: 'Marketing',
    },
    {
      key: 'kh-sales/business-development',
      label: 'Sales/Business Development',
    },
  ];
  start: number;
  end: number;
  cBBlogsCategory = [];
  kHBlogsCategory = [];
  showButton = false;
  showKHButton = false;
  isArticleCB = false;
  isArticleKH = false;
  totalRecords = 0;
  articleArray = [];
  articleKHArray = [];
  subscription: Subscription;
  pages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  filterData = [];
  filterKHData = [];
  totalResults: number;
  showmoreText = false;
  recordsPerPage: number = Constant.recordsPerPageBlog;
  nextRecordsCount: number = Constant.recordsPerPageBlog;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    private searchService: FtsearchService,
    private modalService: NgbModal
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    /* tslint:disable:no-string-literal */
    this.cBBlogsCategory = this.cbCategories.map((data) => {
      return data['key'];
    });
    this.kHBlogsCategory = this.kHCategories.map((data) => {
      return data['key'];
    });
    /* tslint:enable:no-string-literal */
    this.start = 0;
    this.end = 6;
    if (this.selectedCategory?.label === 'Choose Your Career Interest') {
      this.getFTIBlogData(this.cBBlogsCategory, 0, 6);
      this.getFTIKHBlogData(this.kHBlogsCategory, this.start, this.end);
    }
    this.totalRecords = this.originalBlogs?.length;
    // if (this.totalRecords > this.recordsPerPage) {
    //   this.showButton = true;
    // }
  }

  getContentBlockData(contentBlockData, charToBeExtracted) {
    if (contentBlockData.length > charToBeExtracted) {
      contentBlockData = contentBlockData.substring(0, charToBeExtracted);
    }
    // Removing logging to avoid logging flood
    logger.debug('newContentData:', contentBlockData);
    return contentBlockData;
  }

  selectCategory(selectedKey) {
    this.selectedCategory = selectedKey;
    if (
      this.selectedCategory?.label ===
      ReachforBetterWorkCategory.chooseYourCareerInterest
    ) {
      this.getFTIBlogData(this.cBBlogsCategory, 0, 6);
    } else if (
      this.selectedCategory?.label === ReachforBetterWorkCategory.finance
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    } else if (
      this.selectedCategory?.label === ReachforBetterWorkCategory.forDrivenWomen
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    } else if (
      this.selectedCategory?.label === ReachforBetterWorkCategory.humanResources
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    } else if (
      this.selectedCategory?.label ===
      ReachforBetterWorkCategory.informationTechnology
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    } else if (
      this.selectedCategory?.label === ReachforBetterWorkCategory.marketing
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    } else if (
      this.selectedCategory?.label ===
      ReachforBetterWorkCategory.salesBusinessDevelopment
    ) {
      this.getFTIBlogData([this.selectedCategory?.key], 0, 6);
    }

    const arr = [];
    if (selectedKey !== ALL) {
      this.originalBlogs.forEach((val) => {
        if (val.data.blogCategory === selectedKey) {
          arr.push(val);
        }
      });
    }
  }

  getFTIBlogData(category, start, end) {
    if (start === 0) {
      this.pages.results = [];
      this.articleArray = [];
      this.filterData = [];
    }
    this.isArticleCB = false;
    this.filterData = category;
    const filteredValue = [
      { fieldName: 'blogCategory.keyword', fieldValue: category },
    ];
    this.end = end;
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIBlogResults(JSON.stringify(filteredValue), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.pages.results = data['results']?.response?.hits['hits'] || [];
        this.totalResults = data['results']?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          this.articleArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            date: moment(article?._source?.publishDate).format(
              Constant.dateFormat
            ),
            description: this.getTrimmedText(article?._source?.description),
            fullDescription: article?._source?.description,
            showFullText:
              article?._source?.description?.length < 140 ? true : false,
            pdfURL: article?._source?.pdfURL,
          });
        });
        if (this.articleArray.length === 0) {
          this.isArticleCB = true;
        }
        if (this.pages.results.length < this.end) {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }
  getTrimmedText(data) {
    if (!data || data === undefined) {
      return;
    }
    if (data.length < 140) {
      return data;
    }

    let trimmedString = data.substr(0, 140);
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
    );
    return `${trimmedString} ...`;
  }
  kHSelectCategory(selectedKey) {
    this.selectedKHCategory = selectedKey;
    if (
      this.selectedKHCategory?.label ===
      ReachforBetterWorkCategory.chooseYourCareerInterest
    ) {
      this.getFTIKHBlogData(this.kHBlogsCategory, 0, 6);
    } else if (
      this.selectedKHCategory?.label === ReachforBetterWorkCategory.finance
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    } else if (
      this.selectedKHCategory?.label ===
      ReachforBetterWorkCategory.forDrivenWomen
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    } else if (
      this.selectedKHCategory?.label ===
      ReachforBetterWorkCategory.humanResources
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    } else if (
      this.selectedKHCategory?.label ===
      ReachforBetterWorkCategory.informationTechnology
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    } else if (
      this.selectedKHCategory?.label === ReachforBetterWorkCategory.marketing
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    } else if (
      this.selectedKHCategory?.label ===
      ReachforBetterWorkCategory.salesBusinessDevelopment
    ) {
      this.getFTIKHBlogData([this.selectedKHCategory?.key], 0, 6);
    }

    const arr = [];
    if (selectedKey !== ALL) {
      this.originalBlogs.forEach((val) => {
        if (val.data.blogCategory === selectedKey) {
          arr.push(val);
        }
      });
    }
  }

  getFTIKHBlogData(category, start, end) {
    if (start === 0) {
      this.pages.results = [];
      this.articleKHArray = [];
      this.filterKHData = [];
    }
    this.isArticleKH = false;
    this.filterKHData = category;
    const filteredValue = [
      { fieldName: 'blogCategory.keyword', fieldValue: category },
    ];
    this.end = end;
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIBlogResults(JSON.stringify(filteredValue), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.pages.results = data['results']?.response?.hits['hits'] || [];
        this.totalResults = data['results']?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          this.articleKHArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            date: moment(article?._source?.publishDate).format(
              Constant.dateFormat
            ),
            description: this.getTrimmedText(article?._source?.description),
            fullDescription: article?._source?.description,
            showFullText:
              article?._source?.description?.length < 140 ? true : false,
          });
        });
        if (this.articleKHArray.length === 0) {
          this.isArticleKH = true;
        }
        if (this.pages.results.length < this.end) {
          this.showKHButton = false;
        } else {
          this.showKHButton = true;
        }
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  loadMoreCB(category, start, end) {
    this.getFTIBlogData(this.filterData, start, 3);
  }
  loadMoreKH(category, start, end) {
    this.getFTIKHBlogData(this.filterKHData, start, 3);
  }

  openVideoModal(modalRef, videoUrl): void {
    // this.resetModal();
    this.modalService.open(modalRef, {
      size: 'lg',
      windowClass: 'ft-modal',
    });
    this.videoUrl = videoUrl;
  }

  ngOnDestroy(): void {
    // Unsubscribe search text subscriber.
    this.modalService.dismissAll();
  }
}
