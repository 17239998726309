<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                parameter="document" [relative]="false" root="">
  </ng-container>
  <section class="FT-digital-offers bg-gradient">
    <div class="container">
      <div class="text-center mb-4">
        <div class="FT-title">{{ distributorHeading }}</div>
        <div class="text-big text-white mb-5">{{ title }}</div>
      </div>
      <div class="row g-3">
        <div class="col-sm-6 col-12" *ngFor="let list of linkLists">
          <div class="offer-box d-flex" style="gap: 20px">
            <div class="col-sm-4">
              <img class="card-img" alt="{{ list?.title }}"
                   src="{{ getImageURL(list?.imageForDesktop?.widenDocument) }}"/>
            </div>
            <div class="col-sm-8 card-body">
              <div class="d-flex align-items-center mb-4">
                <img alt="{{ list?.title }}" class="card-icon"
                     src="{{getImageURL(list?.imageForMobile?.widenDocument)}}"/>
                <h3>{{ list?.title }}</h3>
              </div>
              <div class="content">
                <div class="short-description text-meduim">
                  {{ list?.altTitle }}
                  <a href="#" data-bs-toggle="modal" data-bs-target="#readMore"
                     (click)="setReadMorePopupContent(list)">
                    {{ ("products.read-more" | translate) }}</a>
                </div>
              </div>
              <a [href]="list?.linkCompound?.linkCollection[0]?.url"
                 [target]="list?.linkCompound?.linkCollection[0]?.target"
                 class="btn btn-primary">
                {{ list?.linkCompound?.linkCollection[0]?.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Modal -->
  <div class="modal fade readMore-modal" id="readMore" data-bs-backdrop="static" data-bs-keyboard="false"
       tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-circle-fill"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="offer-box">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <img alt="{{ getReadMoreContent?.title }}" class="card-icon"
                     src="{{getImageURL(getReadMoreContent?.imageForMobile?.widenDocument)}}"/>
                <h3 *ngIf="getReadMoreContent"> {{ getReadMoreContent?.title }} </h3>
              </div>
              <div class="content" [innerHTML]="getReadMoreContent?.description?.value"></div>
              <a class="btn btn-primary"
                 [href]="getReadMoreContent?.linkCompound?.linkCollection[0]?.url                "
                 [target]="getReadMoreContent?.linkCompound?.linkCollection[0]?.target">
                {{ getReadMoreContent?.linkCompound?.linkCollection[0]?.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
