import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('InvestmentServicesComponent');
;

@Component({
  selector: 'ft-investment-services',
  templateUrl: './investment-services.component.html',
  styleUrls: ['./investment-services.component.scss']
})
export class InvestmentServicesComponent implements OnInit {
  exploreallsolutionsLabel: any;
  exploreallsolutionsURL: any;
  @Input() component!: BrComponent;
  @Input() page: Page;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.exploreallsolutionsLabel = this.translateService.instant(
      'ftiGeneric.explore-all-solutions-label'
    );
    this.exploreallsolutionsURL = this.translateService.instant(
      'ftiGeneric.explore-all-solutions-url'
    );
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100)
    if (browserZoomLevel > 100) {
      this.fixStylingOnZoom();
    }
    window?.addEventListener('resize', () => {
      this.fixStylingOnZoom();
    })

  }

  fixStylingOnZoom() {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    let menu: any = document.querySelectorAll('.solution-box .btn');
    let header: any = document.getElementById('mainHeader');
    let solutionbox = document.querySelectorAll('.solution-box');
    let heading = document.querySelectorAll('.solution-box .h3');
    if (browserZoomLevel > 100) {
      menu?.forEach((element: any, index: number) => {
        element?.classList.add("goal-btn-zoom-fixes");
        solutionbox[index]?.classList.add("solution-box-min-height");
      });
    } else {
      menu?.forEach((element: any, index1: number) => {
        element?.classList.add("goal-btn-zoom-fixes");
        solutionbox[index1]?.classList.remove("solution-box-min-height");
      });
    }
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  get target() {
    return this.content?.linkCompound?.target;
  }


  @ViewChild('slickModal')
  slickModal!: SlickCarouselComponent;

  @HostListener('window:resize', ['$event'])
  onWindowResize($event: any) {
    if (this.slickModal !== undefined) {
      if (window.innerWidth < 768) {
        if (this.slickModal.initialized) {
          this.slickModal.unslick();
        }
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    }
  }

  get contentBlocks() {
    const data = this.content?.contentBlocks;
    return data;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }

  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    centerMode: false,
    centerPadding: '15%',
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '10%',
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerPadding: '5%',
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,

        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: '5%',
          slidesToScroll: 1
        }
      }
    ]
  };

  pairElements(array: any[]): any[][] {
    const result = [];
    if (array) { //check if array is null
      for (let i = 0; i < array.length; i += 2) {
        result.push(array.slice(i, i + 2));
      }
      return result;
    }
  }

}
