import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import {
  PageConfig,
  PageContainerService,
} from '@pages/services/page-container.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Page } from '@bloomreach/spa-sdk';
import { DOCUMENT } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { EnvConfigService } from '@services/env-config.service';
import { loadScript } from '@utils/load-script';
import { TitleService } from '@services/title.service';
import { Constant } from 'src/app/ft-constants/constant';
import { WINDOW } from '@ng-web-apis/common';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  page: Page; // FIXME data is a Page not any - why is it called data?!!!!!!
  title = 'Franklin Templeton India';
  initialNavigationComplete = false;
  currentURL = '';

  // Resource Bundle
  locale: any;
  siteName: any;
  channelName: string = Constant.channelName;

  constructor(
    private pageContainerService: PageContainerService,
    private router: Router,
    private titleService: TitleService,
    private metaService: Meta,
    private storageService: StorageService,
    private envConfig: EnvConfigService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private _window: Window,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    const env = this.envConfig.getEnvConfig();
    // First Hive script now moved to TitleService
    const current = new Date();
    const timestamp = current.getTime();
    loadScript(this.document.body, {
      src: `${env?.ftiAccountsUrl}/chatWidget/chatBotWeb.min.js` + '?' + timestamp,
      'data-chat-bot-env': env?.ftiChatBotEnv,
      async:'',
    });
    // Router event
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.currentURL = document?.location?.origin + item.url;
        /*
         * Set hreflang
         */
        const linkRel = 'rel';
        const linkHrefLang = 'hreflang';
        const docHeadKey = 'head';
        const arr = Array.from(this.document.head.children);
        let linkElt = arr.find(
          (e) =>
            e[linkRel] === 'alternate' && e[linkHrefLang] === this.channelName
        );
        // If link not exist
        if (!linkElt) {
          linkElt = this.renderer.createElement('link');
          this.renderer.setAttribute(linkElt, 'rel', 'alternate');
          this.renderer.setAttribute(linkElt, 'hreflang', this.channelName);
          this.renderer.setAttribute(linkElt, 'href', this.currentURL);
          this.renderer.appendChild(this.document[docHeadKey], linkElt);
        } else {
          this.renderer.setAttribute(linkElt, 'href', this.currentURL);
        }
      }
    });

    // Page Container
    this.pageContainerService.page$.subscribe((pageConfig: PageConfig) => {
      this.page = pageConfig?.page;
      this.initialNavigationComplete = true;

      // Get values from resource bundle
      this.locale = this.translateService.instant('ftiMetaData.locale');
      this.siteName = this.translateService.instant('ftiMetaData.siteName');
      // Page Meta
      const pageData = this.page?.getComponent().getModels()?.pageData;
      const metaTitle = pageData?.metaTitle;
      const pageTitle = this.page?.getTitle();
      const metaKeywords = pageData?.metaKeywords;
      const metaDescription = pageData?.metaDescription;

      this.titleService.setTitle(metaTitle);
      this.metaService.updateTag(
        {name: 'keywords', content: metaKeywords ? metaKeywords : ''},
        "name='keywords'"
      );
      this.metaService.updateTag(
        {
          name: 'description',
          content: metaDescription ? metaDescription : '',
        },
        "name='description'"
      );
     /* this.metaService.updateTag(
        {name: 'canonical', content: this.currentURL ? this.currentURL : ''},
        "name='canonical'"
      );*/
      this.metaService.updateTag(
        {
          property: 'og:title',
          content: metaTitle ? metaTitle?.substring(0, 48) + '...' : pageTitle,
        },
        "property='og:title'"
      );
      this.metaService.updateTag(
        {
          property: 'og:description',
          content: metaDescription ? metaDescription : '',
        },
        "property='og:description'"
      );
      this.metaService.updateTag(
        {
          property: 'og:image',
          content: '/web-resources/ui/responsive/images/fti_pos.png',
        },
        "property='og:image'"
      );
      this.metaService.updateTag(
        {property: 'og:url', content: this.currentURL ? this.currentURL : ''},
        "property='og:url'"
      );
      this.metaService.updateTag(
        {property: 'og:locale', content: this.locale ? this.locale : ''},
        "property='og:locale'"
      );
      this.metaService.updateTag(
        {
          property: 'og:site_name',
          content: this.siteName ? this.siteName : '',
        },
        "property='og:site_name'"
      );
    });
    this.messageListener(env);
  }

  private messageListener(env) {
    const that = this;
    this._window.addEventListener('message', (event) => {// NOSONAR
      if (event.data === 'ManageLeadForm') {
        that.storageService.setCookieByNameVal(
          'IsNotAProspect',
          'true',
          env.baseCookieVal
        );
        that.storageService.setCookieByNameVal(
          'membertype',
          'Investor_LogIn',
          env.baseCookieVal
        );
      }
    });
  }

}
