import { Injectable } from '@angular/core';
import { Configuration, initialize, Page } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { from, Subject } from 'rxjs';
import { HeaderComponent } from '@components/shared/header/header.component';
import { ModuleLoaderService } from '@services/module-loader.service';
import { Logger } from '@utils/logger';
import { LabelLoader } from '@utils/label-loader';
import { GlobalConfigService } from '@services/global-config-service';
import { CommonService } from '@services/common.service';

const logger = Logger.getLogger('PageContainerService');

export type PageConfig = {
  page?: Page;
  configuration: Configuration;
  mapping: object;
};
@Injectable({
  providedIn: 'root',
})
export class PageContainerService {
  private currentUrl: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  page$ = new Subject<PageConfig>();
  httResponse: any;
  loaded = false;

  mapping: object = {
    Header: HeaderComponent,
  };

  constructor(
    private moduleLoader: ModuleLoaderService,
    private labelLoader: LabelLoader,
    private globalConfigService: GlobalConfigService,
    private commonService: CommonService,
    private appState: AppStateService
  ) {}

  newPage(url: string) {
    this.currentUrl = url;
    const pageConfig: PageConfig = {
      configuration: {
        ...this.appState.getBrConfiguration(),
        request: {path: url},
        debug: logger.willLogDebug(),
      },
      mapping: this.mapping,
    };
    from(initialize(pageConfig.configuration))
      .pipe(
        tap((page: Page) => {
          logger.debug('processing new resourceApi response', page);
          pageConfig.page = page;
          this.loadLabelModels(page);
          // load labels before config services start, so they're already available
          this.globalConfigService.initialiseFromPage(page);
          this.labelLoader.loadLabelOverrides();
        }),
        switchMap((page: Page) => this.moduleLoader.getMapping$(page)),
        tap((mapping) => logger.debug('mapping', mapping)),
        take(1)
      )
      .subscribe((mapping) => {
        pageConfig.mapping = mapping;
        this.page$.next(pageConfig);
      });
  }

  /**
   *  Set Labels for translation in labelLoader
   * @param page - page object
   */
  loadLabelModels(page: Page) {
    const models = page.getComponent('site-configuration')?.getModels();

    // Resource Bundle - ftiLoginRegister
    const ftiLoginRegister = models['ft.core.ftiLoginRegister'];

    //Resource Bundle - ftiGeneric
    const ftiGeneric = models['fti.redesign.generic'];

    this.setLabels({
      ftiLoginRegister,
      ftiGeneric
    });

    if (models) {
      this.labelLoader.setLabelTranslation(models);
    }
  }

  setLabels(models) {
    this.commonService.setLabels(models);
  }
}
