import { Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('LinkListComponent');

@Component({
  selector: 'ft-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  getCarouselLength: boolean = true;
  /** Slider configuration */
  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 4.4,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,

        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor() {
  }

  ngOnInit(): void {
    const carouselLength = this.linkLists;
    this.getCarouselLength = carouselLength?.length > 3;
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }
}
