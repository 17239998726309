import { Component, Menu, Page, Reference } from '@bloomreach/spa-sdk';

export function getMenus(
  page: Page,
  component: Component,
  name = 'menu'
): Menu {
  return (getModelFromRef(page, component, name) as any)?.model?.data as Menu;
}

export function getModelFromRef(
  page: Page,
  component: Component,
  name: string
) {
  const ref = component?.getModels() ? component.getModels()[name]?.$ref : null;
  return ref ? page.getContent(ref) : null;
}

export function getPagination(
  page: Page,
  component: Component,
  name = 'pagination'
) {
  return (getModelFromRef(page, component, name) as any)?.model;
}

// need to merge data and links for pagemodel api 1.0 to make compatible with old code
export function getPageContent(page: Page, ref: Reference): any {
  const content = page?.getContent(ref) as any;
  return {
    ...content?.getData(),
    links: content?.model?.links,
  };
}

export function getPageUrl(page: Page, ref: Reference | string): string {
  if (ref) {
    return page?.getContent(ref)?.getUrl();
  }
  return page.getUrl()?.replace(/\?.*/, ''); // remove parameters
}
