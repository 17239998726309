import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  ACCESS,
  LOCATION_LABL,
  SIGNIN_SPINNER,
  TOKEN_EXPIRY,
} from '@utils/app.constants';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { SessionService } from '@services/session.service';
import { SegmentService } from '@services/segment.service';
import { AppStateService } from '@services/app-state.service';
import { ProfileService } from '@services/profile.service';
import { StorageService } from './storage.service';
import { InUserType, SegmentId, UserValidate } from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import {
  BehaviorSubject,
  from,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { BnNgIdleService } from 'bn-ng-idle';
import { Logger } from '@utils/logger';
import {
  IUserProfileInfo,
  LoginSource,
  MaintenanceSession,
  ProfileSummary,
} from '@services/profile.interface';
import { EnvConfig } from '@services/env-config.service';
import { map, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { RefreshService } from '@services/refresh.service';
import { AnalyticsService } from '@services/analytics.service';
import { ActivatedRoute } from '@angular/router';

const logger = Logger.getLogger('LoginService');

export enum OtpOption {
  PANOTP,
  DISTOTP,
}

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  private loginSession$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private userFeedbackDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private renewTimeout = 600; // 600 s
  private sessionTime = 900; // 900 s
  private env: EnvConfig;
  private spinnerDetails = {
    spinnerName: SIGNIN_SPINNER,
    spinnerLoadingText: this.translateService.instant('signin.signinSpinner'),
    spinnerStatus: false,
  };
  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly spinner$;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private sessionService: SessionService,
    private appStateService: AppStateService,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private segmentService: SegmentService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private idleService: BnNgIdleService,
    private refreshService: RefreshService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    this.spinner$ = new Subject<any>();
    this.env = this.appStateService.getEnvConfig();
    this.refreshService.renewTimeout = this.renewTimeout;

    this.isLoggedIn$().pipe(takeUntil(this.unsubscribe$)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        // setting login session
        this.setLoginSession(true);
        this.setLogInIdleSession();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /////////////////
  // Public methods
  /////////////////

  public getSpinner$() {
    return this.spinner$;
  }

  public logIn(serviceResponse: UserValidate): void {
    this.isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          this.logOut(false);
        }
        logger.debug('Login user:', serviceResponse?.guId);
        if (serviceResponse.hasOwnProperty('userTokenResponse')) {
          const queryParams = this.route.snapshot.queryParams;
          this.setResponseCookies(serviceResponse);
          this.storageService.storeIsLoggedInSession(true);
          this.storageService
            .retrieve('userType')
            .then((userType: InUserType) => {
              if (userType === InUserType.INVESTOR) {
                let advisorDetails = queryParams?.arncode;
                if (advisorDetails) {
                  advisorDetails = 'arncode=' + advisorDetails;
                  this.storageService.setCookieByNameVal(
                    'advisorDetails',
                    advisorDetails,
                    this.env.baseCookieVal
                  );
                }
              }
            })
            .catch((error) => {
              logger.debug('Error', error);
            });
          let userRedirectURI =
            serviceResponse.userTokenResponse.userRedirectURI;
          if (queryParams.utm_source && queryParams.utm_campaign) {
            const UTMParamsStr = location.href.split('?')[1];
            userRedirectURI = userRedirectURI + '&' + UTMParamsStr;
          }
          this.windowRef.location.href = userRedirectURI;
          return;
        }
        logger.debug('Validation failed');
        this.storageService.storeIsLoggedInSession(false);
        this.logOut(false);
      });
  }

  /**
   * Function to make sign out API call.
   */
  public logOut(redirectToHomepage: boolean = true) {
    if (this.renewTimeout) {
      clearTimeout(this.renewTimeout);
    }
    logger.debug('Triggering logout');
    this.storageService.storeIsLoggedInSession(false);
    this.storageService.retrieve('guId').then((guId: string) => {
      const loginForm = {
        guId,
      };
      const loginDetails = JSON.stringify(loginForm);
      this.sessionService
        .logOutApi$(loginDetails)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (logOutResponse) => {
            logger.debug('logOutResponse:', logOutResponse);
            this.storageService.clearCookies(
              this.appStateService.getEnvConfig().baseCookieVal
            );
            if (redirectToHomepage) {
              this.windowRef.location.href = this.appStateService.getEnvConfig().baseUrl;
            }
          },
          (error) => {
            logger.debug('Error', this.errorStatus(error));
            this.storageService.clearCookies(
              this.appStateService.getEnvConfig().baseCookieVal
            );
            if (redirectToHomepage) {
              this.windowRef.location.href = this.appStateService.getEnvConfig().baseUrl;
            }
          }
        );
    });
  }

  /**
   * Redirecting user to my accounts
   */
  public myAccountsLink(): void {
    logger.debug('Going to My Accounts');
    this.storageService
      .retrieve('userType')
      .then((userType: InUserType) => {
        let accountRedirect =
          this.appStateService.getftiAccountsUrl() + '/ngInvestor/#/dashboard';
        if (userType === InUserType.ADVISOR) {
          accountRedirect =
            this.appStateService.getftiAccountsUrl() + '/ngAdvisor/#/dashboard';
        }
        this.windowRef.location.href = accountRedirect;
      })
      .catch((error) => {
        logger.debug('Error', error);
      });
  }

  public isLoggedIn$(): Observable<boolean> {
    return from(this.setIsLoggedInPromise());
  }

  public loggedInUserType$(): Observable<InUserType> {
    return from(this.setUserTypePromise());
  }

  /**
   * Sets required cookies
   * @param responseBody - user validation object from accounts
   */
  public setResponseCookies(responseBody: UserValidate): void {
    const userTokenResponseCookies = this.storageService
      .userTokenResponseCookies;
    let bodyCookies = this.storageService.serviceBodyCookies;
    // Array of cookie names which needs to session expire age
    const cookiesForSession = ['accessToken', 'guId'];

    if (responseBody.hasOwnProperty('userTokenResponse')) {
      // Set cookies from userTokenResponse
      userTokenResponseCookies.forEach((cookieName: string) => {
        this.storageService.setCookieByName(
          responseBody.userTokenResponse,
          cookieName,
          this.getCustomCookieVal(cookiesForSession, cookieName)
        );
      });
    } else {
      bodyCookies = bodyCookies.concat(userTokenResponseCookies);
    }
    // Sett cookies from body response
    bodyCookies.forEach((cookieName: string) => {
      this.storageService.setCookieByName(
        responseBody,
        cookieName,
        this.getCustomCookieVal(cookiesForSession, cookieName)
      );
    });
    // Set window name cookie
    const windowCookieName = 'windowName';
    this.storageService.store(
      windowCookieName,
      this.windowRef.name,
      false,
      windowCookieName,
      this.env.baseCookieVal
    );
  }

  /**
   * Checking Maintenance
   */
  public checkMaintenance(): Observable<SafeHtml> {
    return this.profileService.getMaintenanceSession$().pipe(
      map((session: MaintenanceSession) => {
        if (session) {
          logger.debug('MaintenanceSession', session);
          const header = session.maintenanceWebContent.split('|')[0];
          const content = session.maintenanceWebContent.split('|')[1];
          if (!header || !content) {
            return session.maintenanceWebContent;
          }
          return this.sanitizer.bypassSecurityTrustHtml(
            '<h3>' + header + '</h3>' + content
          );
        }
        if (!session) {
          return this.sanitizer.bypassSecurityTrustHtml(
            this.translateService.instant(
              'ftiLoginRegister.maintenanceWebContent'
            )
          );
        }
      })
    );
  }

  public getValidated$(): Observable<boolean> {
    return this.profileService.getValidated$();
  }

  public errorStatus(
    httpError: HttpErrorResponse,
    errorDescription?: string
  ): any {
    const errorDescriptionMsg = errorDescription
      ? errorDescription
      : this.translateService.instant('ftiLoginRegister.accountsUnavailable');
    if (httpError?.status === 0 || httpError?.status >= 500) {
      logger.error('exception::::', httpError?.status, httpError?.statusText);
      return {
        errorDescription: errorDescriptionMsg,
      };
    }
    if (errorDescription) {
      logger.debug('errorDescription', errorDescription);
      return {
        errorDescription: errorDescriptionMsg,
      };
    }
    if (httpError?.error.length > 0) {
      logger.debug(httpError?.error[0]);
      return httpError?.error[0];
    }
    if (httpError?.error?.errorDescription) {
      logger.debug('errorDescription', httpError?.error?.errorDescription);
      return httpError.error;
    }
  }

  public validateSessionResponse(
    response: UserValidate
  ): { isValid: boolean; errorMsg: { errorDescription: string } } {
    // TODO: Set mor validation criteria
    const isValid =
      response.accessToken !== '' &&
      response.accessToken !== 'PREAUTH_TOKEN' &&
      response.accessToken.length > 500;
    return {
      isValid,
      errorMsg: {
        errorDescription: this.translateService.instant(
          'ftiLoginRegister.validationFailed'
        ),
      },
    };
  }

  public checkboxAction(documentRef: Document, checkboxID: string): void {
    const checkboxClicked: HTMLInputElement = documentRef.getElementById(
      checkboxID
    ) as HTMLInputElement;
    if (checkboxClicked.checked) {
      checkboxClicked.checked = false;
      return;
    }
    checkboxClicked.checked = true;
  }

  /**
   * Set Login session time
   * @param session - boolean
   */
  public setLoginSession(session: boolean): void {
    this.loginSession$.next(session);
  }

  /**
   * Get login session observable
   */
  public getLoginSession$(): Observable<boolean> {
    return this.loginSession$.asObservable();
  }

  /**
   * Set User Feedback Display modal
   * @param userFeedback - boolean
   */
  public setUserFeedbackDisplay(userFeedback: boolean): void {
    this.userFeedbackDisplay$.next(userFeedback);
  }

  /**
   * Get User Feedback Display modal
   */
  public getUserFeedbackDisplay$(): Observable<boolean> {
    return this.userFeedbackDisplay$.asObservable();
  }

  public resetSession(): void {
    this.idleService.resetTimer();
    this.setLoginSession(true);
  }

  ////////////////////
  // Private  methods
  ////////////////////

  /**
   * Gets cookie parameter values as string
   * @param cookiesForSession - array of strings
   * @param cookieName - string
   */
  private getCustomCookieVal(
    cookiesForSession: string[],
    cookieName: string
  ): string {
    if (cookiesForSession.includes(cookieName)) {
      // Set expires to 0 for session cookie
      return this.env.baseCookieVal + ';expires=0';
    }
    return this.env.baseCookieVal;
  }

  private onSubmitLogin(submitObject): void {
    // NOSONAR - keep commented for analytics implementation
    // this.analyticsService.trackEvent({
    //   event: 'login_attempt',
    //   location: submitObject.analyticsKey,
    //   link_url: this.getLoginUrl(),
    // });
    const loginForm = {
      userId: submitObject?.signInForm?.userid,
      password: submitObject?.signInForm?.password,
      userType: '10',
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService.authenticateUserFormEncoded$(loginDetails).subscribe(
      (response: any) => {
        logger.debug('response:::::', response);
        if (
          response &&
          response.status === 201 &&
          response.headers.get(LOCATION_LABL)
        ) {
          this.trackEvent('login_fail');
          logger.debug(
            'Redirecting Profile App Flows with 201 Status',
            response
          );
          this.windowRef.location.href = response.headers.get(LOCATION_LABL);
        } else {
          this.trackEvent('login');
          logger.debug('US Login Success for ', response?.body?.userSysNo);
          // We need to store correct session on marketing side before reloading the page.
          const profileSummaryFromResponse = this.mapProfileSummaryFromResponse(
            response.body
          );
          this.storageService.storeProfileSummary(profileSummaryFromResponse);
          this.storageService.storeIsLoggedInSession(true);

          // sets segment based on mapped profile user
          this.saveSegmentIntoStorage(response.body.role);

          // Login in from Home page and dashboardUrl is available means, need to redirect accounts dashboard.
          if (
            this.appStateService.isHomePage() &&
            response.body.accountsAccess === ACCESS &&
            response.body.dashboardUrl
          ) {
            logger.debug(
              'Redirecting Dashboard for Accounts User',
              response?.body?.userSysNo
            );
            this.locationRef.href = response.body.dashboardUrl;
          } else {
            logger.debug(
              'Relaod the page for Marketing User',
              response?.body?.userSysNo
            );
            this.windowRef.location.reload();
          }
        }
      },
      (exception: any) => {
        logger.debug('exception::::', exception);
        this.spinnerDetails.spinnerStatus = false;
        this.spinner$.next(this.spinnerDetails);
      }
    );
  }

  /**
   *
   */
  private saveSegmentIntoStorage(role: string) {
    const profileSegmentId: SegmentId = this.profileService.getSegmentRole(
      role
    );
    this.segmentService.setSegment(profileSegmentId, true);
  }

  /**
   * Set Profile Summary from Accounts service response
   * @param profileBodyResponse - response body
   */
  private mapProfileSummaryFromResponse(
    profileBodyResponse: IUserProfileInfo
  ): ProfileSummary {
    return {
      role: profileBodyResponse.role,
      source: LoginSource.OAUTH,
      webExperience: profileBodyResponse.webExperience,
      firm: profileBodyResponse.firm,
      accountsAccess: profileBodyResponse.accountsAccess,
      dashboardUrl: profileBodyResponse.dashboardUrl,
      isLoggedIn: true,
    };
  }

  private trackEvent(type: string) {
    const trackObject = {
      event: type,
      name: type,
      method: 'site',
      page_location: this.windowRef.location.href,
    };
    this.storageService
      .retrieve<boolean>(TOKEN_EXPIRY, true)
      .then((wasUserLoggedInBefore) => {
        /**
         * If a user was logged out due to token expiry and tries
         * to login again in the same session change the analytics event.
         * to re-authentication
         */
        if (wasUserLoggedInBefore) {
          if (type === 'login') {
            trackObject.event = 're-authentication';
            trackObject.event = 're-authentication';
          }
          if (type === 'login_fail') {
            trackObject.event = 're-authentication_fail';
            trackObject.event = 're-authentication_fail';
          }
        }
        // NOSONAR - keep commented for analytics implementation.
        // this.analyticsService.trackEvent(trackObject);
        this.storageService.remove(TOKEN_EXPIRY, true);
      });
  }

  /**
   * Check if user is logged in correctly
   */
  private async setIsLoggedInPromise(): Promise<boolean> {
    const accessToken = this.storageService.retrieveAccessToken();
    const isLoggedInCookie = this.storageService.retrieveIsLoggedIn();
    return accessToken && isLoggedInCookie;
  }

  /**
   * Set user type as promise
   */
  private async setUserTypePromise(): Promise<InUserType> {
    return this.storageService.retrieveUserType();
  }

  private setLogInIdleSession(): void {
    this.idleService
      .startWatching(this.sessionTime)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          logger.debug('Session expired');
          this.setLoginSession(false);
          this.idleService.stopTimer();
        }
      });
  }

  /////////////////////////
  // Login Tracking methods
  /////////////////////////
  /**
   * Tracking login form submit
   * @param idLoginType - login user type InUserType
   * @param userId = User ID string
   */
  public trackIdLogIn(idLoginType: InUserType, userId: string): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'Next',
        label: 'User ID',
        event: 'event investor_login',
        fh_inv_user_id: userId,
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'Next',
        label: 'User ID',
        event: 'event distributor_login',
        fh_dis_user_id: userId,
      });
    }
  }

  /**
   * Tracking login success
   * @param idLoginType - login user type InUserType
   */
  public trackIdLoginSuccess(idLoginType: InUserType): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'Login Success',
        label: 'User ID',
        event: 'event investor_login',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'Login Success',
        label: 'User ID',
        event: 'event distributor_login',
      });
    }
  }

  /**
   * Tracking Login Id Failure
   * @param idLoginType - InUserType
   */
  public trackLoginIdFailure(idLoginType: InUserType): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'Login Failure',
        label: 'User ID',
        event: 'event investor_login',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'Login Failure',
        label: 'User ID',
        event: 'event distributor_login',
      });
    }
  }

  /**
   * Tracking OTP Sent
   * @param otpOption - OtpOption
   */
  public trackOtpSent(otpOption?: OtpOption): void {
    if (otpOption === OtpOption.DISTOTP) {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'OTP Sent',
        label: 'User ID',
        event: 'event distributor_login',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'OTP Sent',
        label: 'User ID',
        event: 'event investor_login',
      });
    }
  }

  /**
   * Tracking OTP Submit
   * @param otpOption - OtpOption
   */
  public trackOtpSubmit(otpOption?: OtpOption): void {
    let dCate = 'Investor_LogIn';
    let dLabel = 'User ID';
    let dEvent = 'event investor_login';
    switch (otpOption) {
      case OtpOption.PANOTP:
        dLabel = 'PAN';
        break;
      case OtpOption.DISTOTP:
        dCate = 'Distributor_Login';
        dEvent = 'event distributor_login';
        break;
      default:
    }
    this.analyticsService.trackEvent({
      category: dCate,
      action: 'OTP Submitted',
      label: dLabel,
      event: dEvent,
    });
  }

  /**
   * Tracking OTP Resend
   * @param otpOption - OtpOption
   */
  public trackOtpResend(otpOption?: OtpOption): void {
    let dCate = 'Investor_LogIn';
    let dLabel = 'User ID';
    let dEvent = 'event investor_login';
    switch (otpOption) {
      case OtpOption.PANOTP:
        dLabel = 'PAN';
        break;
      case OtpOption.DISTOTP:
        dCate = 'Distributor_Login';
        dEvent = 'event distributor_login';
        break;
      default:
    }
    this.analyticsService.trackEvent({
      category: dCate,
      action: 'Resend OTP',
      label: dLabel,
      event: dEvent,
    });
  }

  /**
   * Tracking PAN
   * @param userPan - string
   */
  public trackPan(userPan: string): void {
    this.analyticsService.trackEvent({
      category: 'Investor_LogIn',
      action: 'Enter PAN',
      label: 'PAN',
      event: 'event investor_login',
      fh_inv_PAN: userPan,
    });
  }

  /**
   * Tracking PAN success
   */
  public trackPanSuccess(): void {
    this.analyticsService.trackEvent({
      category: 'Investor_LogIn',
      action: 'Login Success',
      label: 'PAN',
      event: 'event investor_login',
    });
  }

  /**
   * Tracking PAN failure
   */
  public trackPanLoginFailure() {
    this.analyticsService.trackEvent({
      category: 'Investor_LogIn',
      action: 'Login Failure',
      label: 'PAN',
      event: 'event investor_login',
    });
  }

  /**
   * Tracking Bank Account Enter
   */
  public trackBankAccEnter(): void {
    this.analyticsService.trackEvent({
      category: 'Investor_LogIn',
      action: 'Enter Bank Account',
      label: 'PAN',
      event: 'event investor_login',
    });
  }

  /**
   * Tracking Bank Account Submit
   * @param bankAcc - Bank Account string
   */
  public trackBankAccSubmit(bankAcc: string): void {
    this.analyticsService.trackEvent({
      category: 'Investor_LogIn',
      action: 'Bank Account Submitted',
      label: 'PAN',
      event: 'event investor_login',
      fh_inv_Bank_Account: bankAcc,
    });
  }

  /**
   * Tracking Register Now button
   * @param idLoginType - InUserType
   */
  public trackRegisterNow(idLoginType: InUserType): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor Register',
        action: 'Register Now',
        label: 'User ID',
        event: 'event investor_register',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor Register',
        action: 'Register Now',
        label: 'User ID',
        event: 'event distributor_register',
      });
    }
  }

  /**
   * Tracking Forgot User ID
   * @param idLoginType - InUserType
   */
  public trackForgotUser(idLoginType: InUserType): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'Forgot UserID',
        label: 'User ID',
        event: 'event investor_login',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'Forgot UserID',
        label: 'User ID',
        event: 'event distributor_login',
      });
    }
  }

  /**
   * Tracking Forgot User Password
   * @param idLoginType - InUserType
   */
  public trackForgotPass(idLoginType: InUserType): void {
    if (idLoginType === InUserType.INVESTOR) {
      this.analyticsService.trackEvent({
        category: 'Investor_LogIn',
        action: 'Forgot Password',
        label: 'User ID',
        event: 'event investor_login',
      });
    } else {
      this.analyticsService.trackEvent({
        category: 'Distributor_Login',
        action: 'Forgot Password',
        label: 'User ID',
        event: 'event distributor_login',
      });
    }
  }

  /**
   * Tracking login success with OTP flow
   * @param otpOption - OtpOption
   */
  public trackOtpLogin(otpOption: OtpOption) {
    switch (otpOption) {
      case OtpOption.DISTOTP:
        this.trackIdLoginSuccess(InUserType.ADVISOR);
        break;
      case OtpOption.PANOTP:
        this.trackPanSuccess();
        break;
      default:
        this.trackIdLoginSuccess(InUserType.INVESTOR);
    }
  }
}
