<div class="sipCalc">
  <ng-container *ngIf="contentType === 'SIP Calculator'">
    <ft-sip-calculator [page]="page" [title]="title"></ft-sip-calculator>
  </ng-container>
  <div class="lumpsumCalc" *ngIf="contentType === 'LUMPSUM Calculator'">
    <ft-lumpsum-calculator [page]="page" [title]="title"></ft-lumpsum-calculator>
  </div>
  <div class="topupCalc" *ngIf="contentType === 'SIP Top-Up Calculator'">
    <ft-sip-top-up-calculator [page]="page" [title]="title"></ft-sip-top-up-calculator>
  </div>
  <div class="STPCalc" *ngIf="contentType === 'STP Calculator'">
    <ft-stp-calculator [page]="page" [title]="title"></ft-stp-calculator>
  </div>
  <div class="SWPCalc" *ngIf="contentType === 'SWP Calculator'">
    <ft-swp-calculator [page]="page" [title]="title"></ft-swp-calculator>
  </div>
  <div class="CAGRCalc" *ngIf="contentType === 'CAGR Calculator'">
    <ft-cagr-calculator [page]="page" [title]="title"></ft-cagr-calculator>
  </div>
</div>
