import { Inject, Injectable } from '@angular/core';
import { USER_AGENT } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('PrerenderService');

/**
 * This is a simple service that determines if the request is from Prerender.io based on user agent
 */

@Injectable({
  providedIn: 'root',
})
export class PrerenderService {
  private _isPrerender = false;

  constructor(@Inject(USER_AGENT) readonly userAgentRef: string) {
    // for now, just check the user agent for string 'Prerender"
    this._isPrerender = this.userAgentRef.indexOf('Prerender') !== -1;
    logger.debug(
      'Is Prerender.io request?',
      this.isPrerender(),
      this.userAgentRef
    );
  }

  /**
   * returns true if the user agent is Prerender
   * returns false if this is a normal user
   */
  public isPrerender(): boolean {
    return this._isPrerender;
  }
}
