import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNewPage',
})
export class IsNewPagePipe implements PipeTransform {
  transform(value: any): string {
    const newTab: string = value.parameters['Open in New Tab (True or False)'];
    if (newTab.toLowerCase() === 'true') {
      return '_blank';
    }
    return '_self';
  }
}
