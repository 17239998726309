export class Constant {
  public static channelName = 'en-in-new';
  public static isLargeTabsLayout = 'large-tabs';
  public static recordsPerPage = 8;
  public static recordsPerPageBlog = 6;
  public static usFeederPage = 'us-feeder-campaign';
  public static campaign = 'campaigns';
  public static blueChipPage = 'blue-chip-campaign';
  public static primaFundPage = 'prima-fund-campaign';
  public static tfi = 'tfi';
  public static elssFundPage = 'elss-fund-campaign';
  public static smallCompaniesPage = 'small-companies-campaign';
  public static dateFormat = 'MMMM DD, YYYY';
  public static latestDateFormat = 'DD-MM-YYYY';
}
