import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ft-onecol',
  templateUrl: './onecol.component.html',
  styleUrls: ['./onecol.component.scss'],
})
export class OnecolComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
