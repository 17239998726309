import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { EnvConfigService } from './env-config.service';
import { InPersonalInfo, MaintenanceSession } from './profile.interface';
import { StorageService } from './storage.service';

const logger = Logger.getLogger('SessionService');

// codes returned from accounts api calls
export enum SessionCodes {
  NOT_LOGGED_IN = 'PROFINTL100138',
  // TODO: add more codes as required
}

/** This HttpOptions are corresponding to GET/POST options from Angular HttpClient.
 * They are required to constructs a `GET/POST` request that interprets the body as a JSON object.
 */
interface HttpOptions {
  headers:
    | HttpHeaders
    | {
    [header: string]: string | string[];
  };
  observe: 'response';
  params?:
    | HttpParams
    | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public readonly apiHttpOptions: HttpOptions;
  private ocpApimTrace = ''; // Endpoints.ocpApimTrace;
  private ocpApimSubscriptionKey = ''; // Endpoints.ocpApimSubscriptionKey;
  private readonly loginHttpOptions: HttpOptions;
  public readonly apiHttpOptionsWithCredentials: HttpOptions;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private storageService: StorageService,
    @Inject(WINDOW) readonly windowRef: Window,
    private envConfig: EnvConfigService
  ) {
    this.ocpApimSubscriptionKey = this.envConfig.getEnvConfig()?.ocpApimSubscriptionKey;
    this.ocpApimTrace = this.envConfig.getEnvConfig()?.ocpApimTrace;
    this.apiHttpOptions = this.setApiHeaders();
    this.loginHttpOptions = this.setHeadersWithCredentials();
    this.apiHttpOptionsWithCredentials = this.setApiHeadersWithCredentials();
  }

  /**
   * Function to validate Accounts
   */
  public validateSession$(): Observable<MaintenanceSession> {
    const validateTokenUrl = this.appStateService?.getAccountsApiUrl();
    logger.debug('Triggering Validate Maintenance Session API');
    return this.http.get(validateTokenUrl, this.apiHttpOptions).pipe(
      map((sessionData: any) => {
        logger.debug(
          'Session Service - validateSession - Success',
          sessionData
        );
        const result = sessionData?.body as MaintenanceSession;
        logger.debug(result);
        return result;
      })
    );
  }

  /**
   * Validate user data base on cookie data.
   */
  public async validateUser$(): Promise<Observable<InPersonalInfo>> {
    return this.storageService.retrieve('guId').then((guId: string) => {
      const profileUrl =
        this.appStateService.getEnvConfig().ftiApiDomain +
        this.appStateService.getEnvConfig().ftiProfile +
        guId;
      if (guId) {
        return this.storageService
          .retrieveAccessToken()
          .then((accessToken: string) => {
            return this.http
              .get(profileUrl, this.setApiHeaders(accessToken))
              .pipe(
                map((userData: any) => {
                  const personalInfo = userData.body.myProfileObject[0]
                    .personalInfo as InPersonalInfo;
                  logger.debug('userData: ', personalInfo);
                  return personalInfo;
                })
              );
          });
      }
    });
  }

  /**
   * Validating KYCOnBoarding
   */
  public allowOnBoarding$(): Observable<any> {
    const headerParam = this.setApiHeaders();
    const url =
      this.appStateService.getEnvConfig().ftiApiDomain +
      this.appStateService.getEnvConfig().ftiKYCOnboarding;
    return this.http.get(url, headerParam);
  }

  /**
   * Validating KYC
   */
  public validateKYC$(): Observable<any> {
    const headerVal = this.setApiHeaders();
    const url =
      this.appStateService.getEnvConfig().ftiApiDomain +
      '/v1/ide/services/paramCodes?groupId=WebValidKYC';
    return this.http.get(url, headerVal);
  }

  public async getUserType(): Promise<string> {
    return this.storageService.retrieve('userType').then((cookie: string) => {
      return cookie;
    });
  }

  /**
   * calls the authentication API to authenticate user
   * @param payload string: credentials are sent as a single string as payload for the POST API
   */
  public authenticateUserFormEncoded$(payload: any): Observable<any> {
    return this.http.post(this.getLoginUrl(), payload, this.loginHttpOptions);
  }

  public authenticateUser$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() +
      '/validateUserPassword';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public generateOfflineOTP$(data?): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() +
      '/generateOfflineUserOTP';
    return this.http.post(
      url,
      {sourceFlag: data ? data : ''},
      this.loginHttpOptions
    );
  }

  public validatePAN$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() + '/validateUserPAN';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public validateOTP$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() + '/validateUserOTP';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public validateBankAcc$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() +
      '/validateUserBankAccount';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public generatePaperlessOTP$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() +
      '/generatePaperlessOTP';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public validatePaperlessOTP$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() +
      '/validatePaperlessOTP';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public getLoginUrl(): string {
    return this.appStateService.getEnvConfig().ftiAccountsUrl;
  }

  public generateOtp$(data): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() + '/generateOTP';
    return this.http.post(url, data, this.loginHttpOptions);
  }

  public validateFeederOTP$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() + '/validateOTP';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public postDistributeForm$(payload: any): Observable<any> {
    const url =
      this.appStateService.getEnvConfig().ftiApiDomain +
      this.appStateService.getEnvConfig().ftiAskAdvisorApi;
    return this.http.post(url, payload, this.apiHttpOptions);
  }

  public postExistingInvesterDistributeForm$(payload: any): Observable<any> {
    const url =
      this.appStateService.getEnvConfig().ftiApiDomain +
      "/v1/ide/services/callback"
    return this.http.post(url, payload, this.apiHttpOptions);
  }

  public postYourQuery(payload: any): Observable<any> {
    const url =
      this.appStateService.getEnvConfig().ftiApiDomain + "/v1/ide/services/postYourQuery"
    return this.http.post(url, payload, this.apiHttpOptions);
  }

  public logOutApi$(payload: any): Observable<any> {
    const url = this.appStateService.getFtiAccountsValidateUrl() + '/logOut';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public refreshToken$(payload: any): Observable<any> {
    const url =
      this.appStateService.getFtiAccountsValidateUrl() + '/refreshToken';
    return this.http.post(url, payload, this.loginHttpOptions);
  }

  public generateFrkAccessToken$(payload: any): Promise<Observable<any>> {
    return this.storageService
      .retrieveAccessToken()
      .then((accessToken: string) => {
        const url =
          this.appStateService.getFtiAccountsValidateUrl() +
          '/generateFrkAccessToken';
        return this.http.post(url, payload, this.setApiHeaders(accessToken));
      });
  }

  //////////////////
  // Private methods
  //////////////////

  /**
   * Set headers
   * @param accessToken - Access Token string
   */
  private setApiHeaders(accessToken?: string): HttpOptions {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': this.ocpApimSubscriptionKey,
      'Ocp-Apim-Trace': this.ocpApimTrace,
    });
    if (accessToken) {
      headers = headers.append('Authorization', `Bearer ${accessToken}`);
    }
    return {
      headers,
      observe: 'response' as 'response', // Required to pass the location back
    };
  }

  /**
   * Set headers with credentials
   */
  private setHeadersWithCredentials(): HttpOptions {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      observe: 'response' as 'response', // Required to pass the location back
    };
  }

  /**
   *
   * @returns Headers with crossDomain
   */
  private setApiHeadersWithCredentials(): HttpOptions {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': this.ocpApimSubscriptionKey,
      'Ocp-Apim-Trace': this.ocpApimTrace,
    });
    return {
      headers,
      withCredentials: true,
      observe: 'response' as 'response', // Required to pass the location back
    };
  }
}
