<div class="articleDetail">
  <div class="fti-container position-relative">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                    documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                    parameter="document"
                    [relative]="false" root="">
      </ng-container>
      <!-- start section article content -->
      <section class="FT-article-content" *ngIf="articleData?.staticdropdown === 'Article' || 'Blog'">
        <div class="container">
          <div class="article-title">
            <div class="text-meduim mb-md-3 mb-2">{{ articleData?.readingTitle }}</div>
            <h1 class="mb-3">{{ articleData?.title }}</h1>
            <div class="d-flex justify-content-between">
              <div class="text-small">
                <span>{{ articleData?.publishDate | date:'dd MMMM, yyyy' }}</span>
                <span class="tag" *ngFor="let breadcrumb of getRelatedTags(articleData?.pageBreadcrumbs)">
                  {{ breadcrumb }}</span>
              </div>
              <div class="icons d-flex">
                <div class="card-icons">
                  <!-- <span class="img-with-tooltip me-3">
                    <i *ngIf="articleData.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark-fill"
                       (click)="removeToWatchlist(articleData)"></i>
                    <i *ngIf="!articleData.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark"
                       (click)="addToWatshlist(articleData)"></i>
                    <span *ngIf="!articleData.isWatchListed" class="tooltip-block">{{
                            "products.add-cart-save-button" |
                                translate
                      }}</span>
                    <span *ngIf="articleData.isWatchListed" class="tooltip-block">{{
                            "products.article-saved-cource-button" | translate
                      }}</span>
                  </span> -->
                  <span class="dropdown">
                    <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="image" src="assets/images/knowledge-center/share.svg" (mouseenter)="showTooltip = true"
                           (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                           class="d-inline-block mobile-icons light-mode-icon" style="width: 18px;">
                      <img alt="image" src="assets/images/dark/share-dark-icon.png"
                           (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
                           (click)="toggleTooltip()"
                           class="mobile-icons dark-mode-icon"
                           style="width: 21px; top: 4px; position: relative; left: 0;">
                      <span *ngIf="showTooltip" class="tooltip-block">{{
                              "ftiKc.share-label" | translate
                        }}</span>
                    </span>
                    <ul class="dropdown-menu">
                      <li><a style="cursor: pointer;" (click)="toggleShowCopyDialoge(true)" class="dropdown-item"><img
                          alt="Copy/Share"
                          src="assets/images/general/Link.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-copy-btn" | translate
                        }}</span> </a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER')">
                        <img alt="image" class="share-icon" style="width: 20px;"
                             src="assets/images/general/twitter-new-icon.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-twitter-btn" |
                                  translate
                        }}</span> </a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('FACEBOOK')">
                        <img alt="image" src="assets/images/general/FacebookLogo.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-fb-btn" |
                                  translate
                        }}</span> </a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN')">
                        <img alt="image" src="assets/images/general/LinkedinLogo.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-linkedin-btn" |
                                  translate
                        }}</span> </a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP')">
                        <img alt="image" src="assets/images/general/whatsapps.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-whatsapp-btn" |
                                  translate
                        }}</span> </a></li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="article-img-block audio-img-block mt-5"
               *ngIf="getImageURL(articleData?.indwidenImage?.bannerImage?.widenDocument)">
            <img class="w-100 article-img" src="{{getImageURL(articleData?.indwidenImage?.bannerImage?.widenDocument)}}"
                 alt="Image"/>
          </div>
          <div class="content">
            <div class="d-flex align-items-center justify-content-end mb-4 content-btns">
              <div class="flex-wrap">
                <button type="button" class="btn btn-light-primary me-3" (click)="moveToRelatedTag()">
                  <img alt="image" class="d-none d-md-inline-block"
                       src="https://franklintempletonprod.widen.net/content/9zwvfbzzyq/original/thumb2.png">
                  <img alt="image" class="d-none d-md-inline-block"
                       src="https://franklintempletonprod.widen.net/content/vxqbxrhr7c/original/thumb1.png">
                  {{ "ftiKc.related-articles-label" | translate }}
                </button>
              </div>
            </div>
            <ng-container *ngFor="let item of articleData?.contentBlocks">
              <h2 class="mb-md-4 mb-3" *ngIf="item?.title">{{ item?.title }}</h2>
              <div class="mb-md-4 mb-3 custom-article-content" *ngIf="item?.content?.content?.value"
                   [innerHtml]="item?.content?.content?.value | safeHtml"></div>
              <div class="{{ item?.imagePosition }}" *ngIf="item?.widenAssetCompound?.widenDocument">
                <img src="{{
                    getImageURL(item?.widenAssetCompound?.widenDocument)
                  }}" [style.height]="item?.imageHeight" [style.width]="item?.imageWidth" alt="Widen Image"/>
              </div>
            </ng-container>
            <!-- Author Content for Blog goes here -->
            <div *ngIf="author?.contentBlocks && articleData?.staticdropdown === 'Blog'" class="blogmt mb-3">
              <div class="mb-4">
                <h5 class="text-big text-bold">
                  <strong>{{ authorTitle }}</strong>
                </h5>
                <img class="blogmtImage blogmb" alt="Sathish Prabhu" [src]="authorImage"/>
                <span [innerHtml]="authorContent"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ft-related-tags documentType="article" [relatedTags]="getRelatedTags(articleData?.pageBreadcrumbs)"
                       [nextArticle]="nextArticleToCome" [previousArticle]="previousArticleToCome"></ft-related-tags>
      <ft-related-category [page]="page"
                           [relatedTags]="getRelatedTags(articleData?.pageBreadcrumbs)"></ft-related-category>
      <!----modal for test learning-->
      <div class="modal fade" id="testLearning" tabindex="-1" aria-labelledby="testLearningLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="margin-top: 135px;">
          <div class="modal-content" style="min-height: 460px; z-index: 1000000; max-height: fit-content;">
            <div class="d-flex justify-content-between data-header">
              <div class="modalTitle">Test Your Learning.</div>
              <div type="button" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x fs-3"></i></div>
            </div>
            <div class="modal-body">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialoge: copy alert -->
  <div class="save-alert copy-alert" *ngIf="showCopyDialoge">
    <div class="content">
      <span class="close-btn" (click)="toggleShowCopyDialoge(false)">
        <img alt="image" src="assets/images/general/close.webp">
      </span>
      <img alt="Success" src="assets/images/general/check_circle.svg" class="checked-img">
      <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
      <div class="link-box">{{ linkToCopy }}</div>
    </div>
  </div>

  <!-- Dialoge: Max allowed alert -->
  <div class="save-alert" style="z-index:10000" *ngIf="watchListFull">
    <div class="content">
      <ng-container>
        <a class="close-btn" (click)="closeWatchlist()">
          <img alt='image' src="assets/images/general/close.webp">
        </a>
        <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
        <span style="font-size: 1.063rem;">{{ "ftiKc.kc-watchlist-full" | translate }}</span>
      </ng-container>
    </div>
  </div>
</div>
