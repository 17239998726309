<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
</div>

<section class="FT-investor-links" [ngClass]="this.component?.getParameters().param === 'bg-berry'? 'bg-berry' : '' ">
  <div class="container">
    <div class="FT-title-medium mb-3">{{ preTitle }}</div>
    <div class="text-dark-gray mb-4">{{ title }}</div>
    <div class="row" [ngClass]="getCarouselLength === true ? 'mobile' : '' ">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4 col-6 mb-4 slide" *ngFor="let item of linkLists">
            <a class="link-box" [href]="item?.linkCompound?.url">
              <div class="img-box">
                <img src="{{ getImageURL(item?.widenAssetCompound?.widenDocument) }}"
                     alt="{{ content?.linkCompound?.displayText }}"/>
              </div>
              <div class="text-big">{{ item?.linkCompound?.displayText }}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <ngx-slick-carousel *ngIf="getCarouselLength === true" class="carousel desktop" #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem class="slide" *ngFor="let item of linkLists">
        <a class="link-box" [href]="item?.linkCompound?.url">
          <div class="img-box">
            <img src="{{ getImageURL(item?.widenAssetCompound?.widenDocument) }}"
                 alt="{{ content?.linkCompound?.displayText }}"/>
          </div>
          <div class="text-big">{{ item?.linkCompound?.displayText }}</div>
        </a>
      </div>
    </ngx-slick-carousel>
  </div>
</section>
