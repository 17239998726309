import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Location } from "@angular/common";

const logger = Logger.getLogger('AccordionComponent');

@Component({
  selector: 'ft-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() component!: BrComponent;
  @Input() page: Page;
  currentPath = '';
  openFirstAccordion = true;
  hashNavigation: any;
  acordionKey: any;
  faqTitle: any;
  faqDesc: any;
  viewAllText: any;
  viewAllURL: any;
  isDistributor: boolean = false;

  constructor(
    private translateService: TranslateService,
    private location: Location,
  ) {

    if (this.location.path().includes('/distributor')) {
      this.isDistributor = true;
    }
  }

  ngOnInit(): void {
    this.faqTitle = this.data?.title ? this.data?.title : this.translateService.instant(
      'ftiGeneric.home-faq-title'
    );
    this.faqDesc = this.translateService.instant(
      'ftiGeneric.home-faq-description'
    );
    this.viewAllText = this.translateService.instant(
      'ftiGeneric.home-faq-view-all-text'
    );
    this.viewAllURL = this.translateService.instant(
      'ftiGeneric.home-faq-view-all-URL'
    );
  }
  getAccordionTitle(accordionName: any, acordionKey: any) {
    this.acordionKey = acordionKey;
    return accordionName;
  }


  get document() {
    const {document} = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    //logger.debug('accordion data::::', this.document?.getData().contentBlocks);
    return this.document?.getData().contentBlocks;
  }

  get data() {
    //console.log("this.component?.getParameters():::" + this.component?.getParameters())
    return this.component?.getParameters();
  }
}
