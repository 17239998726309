<div class="main-content pb-4">
  <div class="container">
    <div>
      <ng-container brComponent="title-content"></ng-container>
    </div>
    <div class="overview-right-content-main">
      <ng-container brComponent="tab-content"></ng-container>
    </div>
    <div>
      <ng-container brComponent="main-content"></ng-container>
    </div>
    <div class="fd-tab-sections FT-fund-more-details block-border" id="more-details">
      <ng-container brComponent="other-details"></ng-container>
    </div>
    <div>
      <ng-container brComponent="lower-content"></ng-container>
    </div>
  </div>
</div>
