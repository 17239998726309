<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>

  <section *ngIf="document">
    <ng-container *ngFor="let item of content; let i = index">
      <div class="rich-text-content"
           *ngIf="item?.content?.content?.value && item?.content?.content?.value !== ''">
        <div [innerHTML]="item?.content?.content?.value | safeHtml"></div>
      </div>
      <div class="br-html-text-content"
           *ngIf="item?.html?.value && item?.html?.value !== ''"
           [innerHTML]="item?.html?.value | safeHtml"
      ></div>
    </ng-container>
  </section>
</div>
