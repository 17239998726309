<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>

  <section class="FT-csr-info">
    <div class="container">
      <ng-container *ngFor="let item of content; let i=index">
        <!--load first 3 initiatives first -->
        <ng-container *ngIf="i < 3 else loadMoreData" [ngTemplateOutlet]="CSRBox"></ng-container>

        <!--load remaining initiatives once load more clicked -->
        <ng-template #loadMoreData>
          <ng-container *ngIf="showLoadMore" [ngTemplateOutlet]="CSRBox"></ng-container>
        </ng-template>

        <!--common code template-->
        <ng-template #CSRBox>
          <div class="csr-box">
            <div class="row mb-5">
              <div class="col-md-5 col-12">
                <ul class="side-info" [innerHTML]="item.items[1].contentFull.value"></ul>
              </div>
              <div class="col-md-7 col-12">
                <h2 class="mb-3">{{ item.items[0].title }}</h2>
                <span [innerHTML]="item.items[2].contentFull.value"></span>
              </div>
            </div>
            <!--<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
              <ng-container *ngFor="let slide of item.items[0].additionalMobileImages">
                <div ngxSlickItem class="slide">
                  <div class="gallery-img">
                    <img alt="image" src="{{ getImageURL(slide?.mobileImageAsset?.widenDocument) }}">
                  </div>
                </div>
              </ng-container>
            </ngx-slick-carousel>-->
          </div>
        </ng-template>
      </ng-container>

      <!--load more button-->
      <div class="mt-5 text-center" *ngIf="totalCSRLength > 3 && !showLoadMore">
        <button class="btn btn-outline-primary"
                (click)="showAllCSRData()">{{ "ftiGeneric.load-more-label" | translate }}
        </button>
      </div>
    </div>
  </section>
</div>


