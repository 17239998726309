import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('IndNgxSlidesComponent');

@Component({
  selector: 'ft-ind-ngx-slides',
  templateUrl: './ind-ngx-slides.component.html',
  styleUrls: ['./ind-ngx-slides.component.scss'],
})
export class IndNgxSlidesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  categories: any = [];
  contentType = '';
  title = '';

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    this.contentType = this.component?.getParameters().contentType;
    logger.debug('contentType >>>', this.contentType);
  }
}
