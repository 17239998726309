import { Component, Input, OnInit, } from "@angular/core";
import { Component as BrComponent, Document, Page, Reference, } from "@bloomreach/spa-sdk";
import { Logger } from "@utils/logger";
import { getPageUrl } from "@utils/pagemodel-utils";
import { TranslateService } from "@components/shared/translate/translate.service";
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("IndCarouselComponent");

@Component({
  selector: "ft-ind-carousel",
  templateUrl: "./ind-carousel.component.html",
  styleUrls: ["./ind-carousel.component.scss"],
})
export class IndCarouselComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;
  navigationArrow = true;
  navigationIndicators = true;
  getDescriptionValue: any;
  isHomePage: boolean = false;
  ftiOutageMessage: any;

  constructor(
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    let getLayout = this.page?.getComponent().getParameters().layout;
    this.isHomePage = getLayout === "home-page";
    const carouselLength = this.CarouselImages;
    this.navigationArrow = carouselLength?.length > 1;
    this.navigationIndicators = carouselLength?.length > 1;

    const cookkievalues = document.cookie.indexOf('outageBanner=');
    let noticeBannerStatus: boolean;
    this.ftiOutageMessage = this.translateService.instant(
      'ftiGeneric.outage-msg-status'
    );
    noticeBannerStatus = !(cookkievalues <= -1 && this.ftiOutageMessage === 'true');
    const notificationBanner: any = document.getElementById('updates-banners');
    if (noticeBannerStatus) {
      window.innerWidth > 700 ? notificationBanner?.classList.add('margin-top-10')
        : notificationBanner?.classList.add('mobile-margin-top');
    }

  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get CarouselImages() {
    const data = this.content?.carouselCompoundType;
    if (data?.length > 0) {
      this.getDescriptionList(data);
    }
    return data;
  }

  public getPageUrl(ref: Reference | string) {
    return getPageUrl(this.page, ref);
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }

    return "";
  }

  /**
   * Set a background static or dynamic image based on the condition.
   * @param data
   * @returns
   */
  setBackgroundUrl(data): any {
    let imageUrl: string;
    if (data?.description?.value === "" || !this.isHomePage) {
      imageUrl = this.getImageURL(data?.imageForDesktop?.widenDocument);
    } else {
      imageUrl = this.translateService.instant(
        'ftiGeneric.home-page-banner-bg-Image'
      );
    }
    return imageUrl;
  }

  /**
   * Function to get the order list value and set it to the HTML.
   * @param data
   */
  public getDescriptionList(data) {
    data.forEach((dateElement) => {
      if (dateElement?.description?.value) {
        this.getDescriptionValue = dateElement?.description["value"];
        return false;
      }
    });
  }

  /**
   * Function to Get More Details button url
   * @param urls
   * @returns
   */
  getLinkCollectionUrl(urls) {
    return urls?.linkCompound?.linkCollection[0]?.url;
  }

  get isPreview() {
    return this?.page?.isPreview() || {};
  }

  public getImages(imageUrl: any): string {
    let images;
    if (imageUrl?.imagelink) {
      const url = imageUrl?.imagelink?.$ref?.split('/content/')[1] || '';
      const modelKey = 'model';
      const contentKey = 'content';
      images = this.page?.[modelKey][contentKey][url].links.site.href || '';
    } else if (
      imageUrl?.contentType === 'ftcore:ImageCompound' &&
      imageUrl?.widenAsset
    ) {
      images = JSON.parse(imageUrl?.widenAsset).url
        ? JSON.parse(imageUrl?.widenAsset).url
        : '';
    }
    return images;
  }

  /**
   * sets anlytics data on click of banner
   * @param title title of the banner
   */

  setAnalytics(title): void {
    this.analyticsService.trackEvent({
      event: "Event Homepage",
      category: "Homepage",
      action: 'Banner click',
      label: title,
    });
    this.analyticsService.trackEvent({
      event: "Event Homepage",
      ft_event: 'ft_bannerClick',
      category: "Homepage",
      action: 'Banner click',
      label: title,
    });
  }
}
