import { Component, OnInit, Input } from '@angular/core';
import {
  Component as BrComponent,
  Content,
  Page,
  Reference,
} from '@bloomreach/spa-sdk';
import { OriginalBlogs } from '@types';
import { getPagination } from '@utils/pagemodel-utils';
import moment from 'moment';
import { Constant } from 'src/app/ft-constants/constant';

@Component({
  selector: 'ft-display-list',
  templateUrl: './display-list.component.html',
  styleUrls: ['./display-list.component.scss'],
})
export class DisplayListComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  originalBlogs: OriginalBlogs[] = [];
  categories: any = [];
  contentType = '';
  title = '';

  constructor() {
  }

  ngOnInit(): void {
    const component: BrComponent = this.page
      ?.getComponent('main-content')
      ?.getComponent('IND-display-list');
    const componentParams: any = component?.getParameters();
    const componentModels: any = component?.getModels();
    // Content Type
    this.contentType = componentParams?.contentType;
    this.title = componentParams?.title;

    if (
      this.contentType === 'BlogList' ||
      this.contentType === 'reach for better work'
    ) {
      // Blog categories
      const categoryData: Content = this.page?.getContent(
        componentModels?.chooseCategories
      );
      this.categories = categoryData?.getData()?.items;

      // Blogs
      this.originalBlogs = [];
      getPagination(this.page, this.component)?.items.forEach(
        (element: Reference) => {
          const elementData: any = this.page?.getContent(element)?.getData();
          this.originalBlogs.push({
            ref: element,
            data: elementData,
            publishDate: moment
              .unix(elementData.publishDate / 1000)
              .format(Constant.dateFormat),
            imgURL: this.getImageURL(this.getImageDocument(elementData)),
          });
        }
      );

      if (componentParams.sortOrder === 'ASC') {
        this.originalBlogs.sort(
          (a, b) =>
            new Date(a.publishDate).getTime() -
            new Date(b.publishDate).getTime()
        );
      }
      if (componentParams.sortOrder === 'DESC') {
        this.originalBlogs.sort(
          (a, b) =>
            new Date(b.publishDate).getTime() -
            new Date(a.publishDate).getTime()
        );
      }
    }
  }

  /**
   * Returns thumbnail widenAsset JSON or bannerImage if thumbnail not defined
   * @param data - Document data
   */
  private getImageDocument(data: any): string {
    if (data?.widenAssetCompound) {
      return data.widenAssetCompound?.widenDocument;
    }
    return data?.indwidenImage?.bannerImage?.widenDocument;
  }

  private getImageURL(documentData: string): string {
    if (documentData) {
      return JSON.parse(documentData)?.url;
    }
  }
}
