/**
 * WARNING: This service has been deprecated in favour of SiteConfigurationService
 * Service to hold global configuration passed from Bloomreach.
 */
import { Inject, Injectable } from '@angular/core';
import { Content, Page, Reference } from '@bloomreach/spa-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CommonConfig,
  GlobalConfig,
  LanguageData,
  SiteParams,
  SiteSearchConfig,
  SiteSearchParams,
} from '@types';
import get from 'lodash/get';
import { setLocale as setMomentLocale } from '@utils/il8n/moment-il8n';
import { setNumberFormatLocale } from '@utils/il8n/number-il8n';
import { LOCATION } from '@ng-web-apis/common';
import { Meta } from '@angular/platform-browser';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('GlobalConfigService');

// TODO - clarify this and what siteLanguages returns from config
export interface Languages {
  locale: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  config: GlobalConfig = {};
  subject$: BehaviorSubject<GlobalConfig> = new BehaviorSubject<GlobalConfig>(
    this.config
  );

  constructor(
    @Inject(LOCATION) readonly locationRef: Location,
    private meta: Meta
  ) {}

  /**
   * Initialise from first request
   */
  initialiseFromPage(page: Page) {
    if (!this.config.siteConfiguration) {
      // only initialise once
      // const locale = page.getChannelParameters().Language;
      const locale = 'en-gb';
      if (locale) {
        setMomentLocale(locale);
        setNumberFormatLocale(locale);
        // highcharts locale should be configured on lazy loading of high charts.
      }

      const siteConfigurationRef: Reference = page
        .getComponent('site-configuration')
        .getModels()?.siteConfiguration;
      const configContent: Content = page.getContent(siteConfigurationRef);
      if (configContent) {
        const data = configContent.getData().config;
        this.config.siteConfiguration = JSON.parse(data);
        // If maintenance Mode is set in site config redirect to the maintenance page
        if (this.config.siteConfiguration?.site?.maintenanceMode) {
          if (this.config.siteConfiguration?.site?.maintenancePageUrl) {
            this.meta.addTag({
              name: 'prerender-status-code',
              content: '503',
            });
            this.locationRef.href = this.config.siteConfiguration.site.maintenancePageUrl;
          } else {
            logger.error('maintenance page url is not set');
          }
        }
        this.config.siteConfiguration.languages = configContent.getData().multilingual;
        this.subject$.next(this.config);
      }
    }
  }

  getSubject$(): Observable<GlobalConfig> {
    return this.subject$.asObservable();
  }

  /**
   * Returns site languages
   */
  getLanguages(): Array<LanguageData> {
    return get(this.config, 'siteConfiguration.languages', []);
  }

  /**
   * Returns site parameters
   */
  getSiteParams(): SiteParams {
    return get(this.config, 'siteConfiguration.siteParams', {});
  }

  /**
   * Returns general site parameters
   */
  getSiteGeneral(): CommonConfig {
    return get(this.config, 'siteConfiguration.site', {});
  }

  /**
   * Returns search parameters node
   */
  getSearchParameters(): SiteSearchParams {
    return get(this.config, 'siteConfiguration.search', {});
  }

  /**
   * Returns search config node
   */
  getSearchConfig(): SiteSearchConfig {
    return get(this.config, 'siteConfiguration.searchConfig', {});
  }
}
