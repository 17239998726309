import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Component as BrComponent, Document, Page, } from "@bloomreach/spa-sdk";
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Logger } from "@utils/logger";
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger("HomePageBlogsComponent");

@Component({
  selector: 'ft-home-page-blogs',
  templateUrl: './home-page-blogs.component.html',
  styleUrls: ['./home-page-blogs.component.scss']
})
export class HomePageBlogsComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;

  @ViewChild('blogSlickModal')
  blogSlickModal!: SlickCarouselComponent;
  courseSlickModal!: SlickCarouselComponent;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (this.blogSlickModal !== undefined) {
      if (window.innerWidth > 768) {
        if (this.blogSlickModal.initialized) {
          this.blogSlickModal.unslick();
        }
      } else if (!this.blogSlickModal.initialized) {
        this.blogSlickModal.initSlick();
      }
    }
    if (this.courseSlickModal !== undefined) {
      if (window.innerWidth > 768) {
        if (this.courseSlickModal.initialized) {
          this.courseSlickModal.unslick();
        }
      } else if (!this.courseSlickModal.initialized) {
        this.courseSlickModal.initSlick();
      }
    }
  }

  slideConfig: any = {
    dots: false,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    cssEase: 'linear',
    centerMode: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,

        settings: {
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
          centerPadding: '5%',
          slidesToScroll: 1
        }
      }
    ]
  }

  preTitle: any;
  title: any;
  getCarouselBlogsImage: any = [];
  exploreMoreLabel: any;

  constructor(private cd: ChangeDetectorRef, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.exploreMoreLabel = this.translateService.instant(
      'ftiGeneric.quicklink-explore-more'
    );
  }

  ngAfterViewInit() {
    this.getContentTitleData(this.content);
    this.cd.detectChanges();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  getContentTitleData(data) {
    if (data) {
      this.preTitle = data?.titleBlock?.preTitle;
      this.title = data?.titleBlock?.title

      if (data?.linkList.length > 0) {
        this.getCarouselBlogsImage = data?.linkList
      }
    }
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return "";
  }


  changeOnHover(event: any, id: string) {
    let menu: any = document.getElementById(id);
    let menu1: any = document.getElementById(id + '1');
    menu?.classList.add('d-none')
    menu1?.classList.remove('d-none')
  }

  changeOnHover1(event: any, id: string) {
    let menu: any = document.getElementById(id);
    const me = document.getElementById(event.target.id)
    menu?.classList.remove('d-none')
    me?.classList.add('d-none')
  }
}
