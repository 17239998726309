/**
 * helper functions for portfolio components
 */

import { PortfolioAllocationDataPoint } from '@models';
import { parseStdNumber } from '@products/utils/mappers/mapper.utils';
import { PortfolioAllocationDTO } from '@types';

/**
 * This turns a flat array of data points into a nested array
 */
export const nestPortfolioDataPoints = (
  items: PortfolioAllocationDataPoint[],
  label = ''
): PortfolioAllocationDataPoint[] =>
  items
    .filter((item: PortfolioAllocationDataPoint) => item.parent === label)
    .map((item: PortfolioAllocationDataPoint) => {
      const remainingItems: PortfolioAllocationDataPoint[] = items.filter(
        (point: PortfolioAllocationDataPoint) => point.parent !== label
      );
      return {
        ...item,
        children: nestPortfolioDataPoints(remainingItems, item.label),
      };
    });

/**
 * this is used to sort the raw array of data by "rank" proprty
 */
export const sortPortfolioDataPoints = (
  a: PortfolioAllocationDTO,
  b: PortfolioAllocationDTO
): number => parseStdNumber(a.rank) - parseStdNumber(b.rank);
