<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <span [innerHTML]="contentBlock?.html?.value | safeHtml"></span>
</div>
