<div class="d-flex justify-content-between">
  <div>
    <div class="filter-options mt-0 mb-4">
      <div class="form-group ">
        <input type="text" class="form-control search" [placeholder]="searchPlaceholderText" [(ngModel)]="searchedInput"
               (input)="onSearchInput($event)">
        <div class="search-result" *ngIf="searchSuggestions?.length && !clearTextBox">
          <div class="inner">
            <ng-container *ngFor="let suggestion of searchSuggestions">
              <div class="funds-seach-title fontResize" (click)="onSearchSuggestionSelected(suggestion)"
                   style="position: relative; z-index: 999999;">
                {{ suggestion.pageTitle }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="search-result" *ngIf="!searchSuggestions.length && showNoSearchSuggestionMessage && !clearTextBox">
          <div class="inner">
            <div class="funds-seach-title fontResize" style="position: relative; z-index: 999999;">
              {{ "literature.literature-listing-no-search-suggestion" | translate }}
              "{{ searchedInputAgainstNoSearchSuggestionMessage }}"
            </div>
          </div>
        </div>
      </div>
      <div>
        <img alt="image" src="./assets/images/comparefund/closebutton.svg"
             class="make-tag-for-all-page d-md-none share-icon2 clear-icon"
             (click)="onClearSearch()">
        <img alt="image" src="./assets/images/comparefund/closebutton.svg"
             class="make-tag-for-all-page d-none d-md-block share-icon2" (click)="onClearSearch()" style="
                position: relative;
                left: 95%;
                top: -26px;">
      </div>
    </div>
  </div>

</div>
