import isEmpty from 'lodash/isEmpty';
import { IUserProfile } from '@services/profile.interface';
import { EnvConfig } from '@services/env-config.service';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('StringUtils');

export function makeStringNonBreaking(value) {
  return value?.replace(/ /g, '\xa0');
}

export function formatString(val = ''): string {
  if (!val || val === '-') {
    return '—';
  }
  return val;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for user input, makes it safe to inject into code
 *
 * follows w3c recommendations
 * https://www.w3.org/TR/2017/REC-html52-20171214/dom.html#text-content
 */
export function normaliseToHtmlSafeString(input) {
  input = input
    ?.replace(/&/g, '&amp;')
    ?.replace(/\$/g, '&#36;') // $ added for aurelia template injection aka ${somefunc()}
    ?.replace(/>/g, '&gt;')
    ?.replace(/</g, '&lt;')
    ?.replace(/"/g, '&quot;')
    ?.replace(/'/g, '&#39;');
  return input;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for user input, makes it safe to inject into regex string
 *
 * follows someone's recommendations
 * https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
 */
export function normaliseToRegexSafeString(input) {
  input = input
    ?.replace(/[.*+?^${}()|[\]\\:]/g, '\\$&')
    ?.replace(/ /gi, '[ \xa0]', 'ig');
  return input;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for elastic search input that double encodes unicode
 */
export function removeElasticDoubleEncoding(input) {
  if (!input?.includes('\\u')) {
    return input;
  }
  return decodeURIComponent(
    JSON.parse('"' + input?.replace(/"/g, '\\"') + '"')
  );
}

/**
 * Replace tokens like $ENV[] from strings
 * @param input string with tokens
 * @param envConfig Envirionment Config Data
 * @param profile Data
 * @param flatPage Flattened Page Object
 * @returns string without tokens
 */
export function replaceTokens(
  input: string,
  envConfig: EnvConfig,
  profile?: IUserProfile,
  flatPage?: any
): string {
  input = _envTokenReplace(input, envConfig);

  if (flatPage) {
    input = _labelTokenReplace(input, flatPage);
  }

  /**
   * Check if the profile object is empty, as newPage() of
   * page-container.service runs before profile data is loaded first time.
   */
  if (!isEmpty(profile)) {
    input = _profileTokensReplace(input, profile);
  }

  return input;
}

function _envTokenReplace(input: string, envConfig: EnvConfig) {
  const envRegex = /\$(ENV|CONFIG)(\(|\[)(\w+)(\)|\])/g;
  const token: RegExpMatchArray =
    input?.match(envRegex) || input?.match(/\$(ENV|CONFIG)/g);
  input = input?.replace(envRegex, (m, g1, g2, g3) => {
    return envConfig[g3];
  });
  if (input?.match(/\$(ENV|CONFIG)/g) || input?.includes('undefined')) {
    logger.error('Environment config tokens misconfiguration', token);
  }
  return input;
}

function _labelTokenReplace(input: string, flatPage: any) {
  const labelRegex = /\$LABEL(\(|\[)(\w+(?:-\w+)*)(\)|\])/g; // Labels with '-' in their keys
  const token: RegExpMatchArray =
    input?.match(labelRegex) || input?.match(/\$LABEL/g);
  input = input?.replace(labelRegex, (m, g1, g2) => {
    return flatPage[g2];
  });
  if (input?.includes('$LABEL') || input?.includes('undefined')) {
    logger.error('Label tokens misconfiguration', token);
  }
  return input;
}

function _profileTokensReplace(input: string, profile: IUserProfile): string {
  const profileRegex = /\$PROFILE(\(|\[)(\w+)(\)|\])/g;
  const token: RegExpMatchArray =
    input?.match(profileRegex) || input?.match(/\$PROFILE/g);
  input = input?.replace(profileRegex, (m, g1, g2) => {
    return profile?.profileInfo[g2];
  });
  if (input?.includes('$PROFILE') || input?.includes('undefined')) {
    logger.error('Profile tokens misconfiguration', token);
  }
  return input;
}

/**
 * Replace tokens like $ENV() from strings
 * @deprecated
 * @param input string with tokens
 * @param envConfig Envirionment Config Data
 * @param profile Data
 * @param flatPage Flattened Page Object
 * @returns string without tokens
 */
// FIXME: This function is deprecated and it is not in use. Can be removed.
// replaceTokens function covers its functionality.
export function replaceVariables(
  input: string,
  envConfig: EnvConfig,
  profile: IUserProfile,
  flatPage: any
): string {
  const envRegex = /\$ENV\((\w+)\)/g;
  input = input?.replace(envRegex, (m, g1) => envConfig[g1]);

  const configRegex = /\$CONFIG\((\w+)\)/g;
  input = input?.replace(configRegex, (m, g1) => envConfig[g1]);

  const labelRegex = /\$LABEL\((\w+(?:-\w+)*)\)/g; // Labels with '-' in their keys
  input = input?.replace(labelRegex, (m, g1) => flatPage[g1]);

  /**
   * Check if the profile object is empty, as newPage() of
   * page-container.service runs before profile data is loaded first time.
   */
  if (!isEmpty(profile)) {
    const profileRegex = /\$PROFILE\((\w+)\)/g;
    input = input?.replace(profileRegex, (m, g1) => profile?.profileInfo[g1]);
  }

  return input;
}

/**
 * Removes <p></p> tags from HTML string
 * @param string with p tages
 * @returns string without p tags
 */
export function removePTags(content: string): string {
  // Just remove P HTML tag
  return content?.replace(/<\/?p[^>]*>/gi, '');
}

/**
 * capitalizes First Letter in each word
 */
export function titleCase(str: string): string {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
/**
 * converts 999-999-9999 to (999) 999-9999
 */
export function maskUsPhoneNo(input: string): string {
  const usNumberRegex = /(\d{3})-(\d{3})-(\d{4})/;
  if (usNumberRegex.test(input)) {
    const usNumber = input?.match(usNumberRegex);
    return `(${usNumber[1]}) ${usNumber[2]}-${usNumber[3]}`;
  } else {
    return input;
  }
}
