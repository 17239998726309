<div class="feedbackForm">
  <div class="fti-container position-relative">
    <h1 class="nps-title" *ngIf="!isThankYou">
      {{
        "Your feedback is important to us. Kindly enter your details and please give your feedback"
      }}
    </h1>
    <div class="row">
      <div *ngIf="isThankYou" class="col-sm-12 col-xs-12 mt-3">
        <div class="thankYou">
          <h5>
            <!-- <span class="success-icon icon icon-fti_tick"></span> -->
            {{ flashMessage }}
          </h5>
        </div>
      </div>
      <div *ngIf="!isThankYou">
        <form [formGroup]="feedbackForm" (ngSubmit)="formSubmit()">
          <div class="col-sm-12">
            <ng-container *ngIf="!validInput">
              <div class="field-wrapper row">
                <div
                  class="form-group col-md-3"
                  [ngClass]="{
                    focused: this.feedbackForm.value?.accountNo
                  }"
                >
                  <label class="control-label" for="pannumber">
                    {{ labelPlaceHolder }}
                  </label>
                  <input
                    id="pannumber"
                    formControlName="accountNo"
                    maxlength="13"
                    type="text"
                    class="form-field form-control"
                    (keyup)="onKeyUp()"
                  />
                </div>
                <button
                  [disabled]="!validNumber || isFormSubmitted"
                  class="btn col-md-1 btn-default submit-btn"
                  type="submit"
                >
                  {{ "GO" }}
                  <span
                    *ngIf="isFormSubmitted"
                    class="spinner-icon"
                  ></span>
                </button>
              </div>
              <div class="clsRed">
                <span *ngIf="APIerr">
                  {{ inputErr }}
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="validInput">
              <div class="">
                <p class="question">
                  How likely are you to recommend Franklin Templeton to a friend
                  or family?
                </p>
                <ngb-rating
                  [(rate)]="rating"
                  class="rating"
                  [max]="11"
                  (hover)="hovered = $event"
                  (leave)="hovered = ''"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <div class="rating-container">
          
                        <i class="bi bi-circle-fill ms-2" [ngClass]="'rating' + index"  [class.highlight]=" index === rating - 1 || hovered === index + 1 ">
                          <span class="number" [class.twoDigit]="index === 10">
                            {{ index }}
                          </span>
                        </i>
                     
                      <span  *ngIf="index === 0" class="notLikely">
                        Not likely
                      </span>
                      <span *ngIf="index === 9" class="likely">
                        Very likely
                      </span>
                    </div>
                  </ng-template>
                </ngb-rating>
                <div class="field-wrapper feedback-container">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.feedbackForm.value?.remarks
                    }"
                  >
                    <label class="control-label" for="feedbackformlabel">
                      {{ labelFeedback }}
                    </label>
                    <textarea
                      id="feedbackformlabel"
                      formControlName="remarks"
                      maxlength="500"
                      aria-label="feedback"
                      class="form-field form-control remarks-field"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="">
                <button
                  [disabled]="!this.rating || isFormSubmitted"
                  type="button"
                  class="btn btn-default submit-btn"
                  [title]="!this.rating ? 'Please select your rating' : ''"
                  type="submit"
                >
                  {{ labelSubmit }}
                  <span
                    *ngIf="isFormSubmitted"
                    class="spinner-icon"
                  ></span>
                </button>
              </div>
              <div class="clsRed2">
                <span *ngIf="APIerr">
                  {{ inputErr }}
                </span>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
