import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import {
  FtSearchResults,
  FundDataItem,
  FundItem,
} from '@components/shared/search/search.interface';
import { TabName } from '@types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ft-auto-suggestions-list',
  templateUrl: './auto-suggestions-list.component.html',
  styleUrls: ['./auto-suggestions-list.component.scss'],
})
export class AutoSuggestionsListComponent implements OnChanges {
  @Input() searchText: string;
  @Input() triggerChange: boolean;
  fundItems: FundItem[];
  @Output() newSearch: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private ftSearchservice: FtsearchService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private sanitized: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.triggerChange) {
     // console.log("SearchTerm"+this.searchText)
      this.ftSearchservice
        .getAutoCompleteResults(this.searchText, 0, 3)
        .subscribe((data: any) => {
          const responseItems = data?.results.filter((item) => {
            return item.name.toLocaleLowerCase() === 'funds';
          })[0].response?.hits?.hits;
          const fundItems = responseItems.map((item) => {
            const fundDataSource: FundDataItem =
              item.inner_hits.shareClassesMatched.hits.hits[0]._source;
            const fundData = {
              fundId: fundDataSource.fundid,
              fundName: fundDataSource.title,
            };
            return {
              title: this.sanitized.bypassSecurityTrustHtml(// NOSONAR
                this.getFundName(item, fundDataSource)
              ),
              titleLink: this.ftSearchservice.getFundsTabLink(
                fundData,
                TabName.OVERVIEW
              ),
            };
          });
          this.fundItems = fundItems;
          this.cdr.detectChanges();
        });
    }
  }

  getFundName(item, fundDataSource) {
    return item.highlight && item.highlight['title.ngrams']
      ? item.highlight['title.ngrams'][0]
      : fundDataSource.title;
  }

  viewAllFunds() {
    this.addToRecentSerch(this.searchText,'general');
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchText, filter: 'funds' },
    });
  }

  viewAllGeneral() {
    this.addToRecentSerch(this.searchText,'general');
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchText, filter: 'pages' },
    });    
  }

  addToRecentSerch(searchTerm:any, type: string){
    const MAX_RECENT_SEARCHES = 4;
    let recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    recentSearches.unshift({ term: searchTerm, type:type, timestamp: new Date().toISOString() });
    recentSearches = recentSearches.slice(0, MAX_RECENT_SEARCHES);
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    this.newSearch.emit();
  }
  
  
}
