<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document"
                documentTemplateQuery="new-ftindia-document" folderTemplateQuery="new-ftindia-folder"
                parameter="document" [relative]="false" root="">
  </ng-container>
  <!-- start invest btns block -->
  <div *ngIf="isMobileView && siteToggle">
    <div class="tog-site">
      <div class="font-resize-wraper">
        <div class="d-flex newUi align-items-center">
          <span class="tog-new"><span>New</span></span><span class="mr-2" [innerHTML]="siteToggleVal"> </span>
        </div>
      </div>
    </div>
  </div>
  <section class="FT-invest-btns text-center" *ngIf="currentPath !== 'distributor'">
    <div class="container">
      <ng-container *ngIf="isMobileView else desktop">
        <button type="button" data-bs-toggle="offcanvas" (click)="rcGA()" aria-controls="offcanvasBottom"
                class="btn btn-outline-primary"
                data-bs-target="#offcanvascallBackRequest">
          {{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}
        </button>
      </ng-container>
      <ng-template #desktop>
        <button type="button" class="btn btn-outline-primary" (click)="rcGA()" data-bs-toggle="modal"
                data-bs-target="#callBackRequest">
          {{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}
        </button>
      </ng-template>
      <a (click)="investNow()" class="btn btn-primary">{{ "ftiGeneric.lead-form-invest-now" | translate }}</a>
    </div>
  </section>
  <section class="FT-digitalize bg-gradient" *ngIf="currentPath !== 'distributor'; else distlink">
    <div class="container">
      <div class="text-center" *ngIf="preTitle || title">
        <div *ngIf="preTitle" class="FT-title"> {{ preTitle }}</div>
        <div *ngIf="title" class="text-big text-white"> {{ title }}</div>
      </div>
    </div>
    <div class="container-fluid px-0">
      <ngx-slick-carousel class="carousel dw-custom-cl" #slickModal="slick-carousel" [config]="slideConfig"
                          style="padding:0 20px;">
        <div ngxSlickItem class="slide" *ngFor="let content of linkLists">
          <a [href]="checkLink(content?.linkCompound?.url)"
             [target]="content?.linkCompound?.target === '' ? '_self' : content?.linkCompound?.target"
             style="color:inherit;"
             class="analytics-quick-link quicAction-link redirectGuestUrl"
             data-action="Quick Links">
            <div class="creation-box">
              <div class="img-block">
                <img src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}"
                     alt="{{ content?.linkCompound?.displayText }}"/>
              </div>
              <h3 class="digitize-card-heading">{{ content?.linkCompound?.displayText }}</h3>
            </div>
          </a>
        </div>
      </ngx-slick-carousel>
    </div>
  </section>
</div>
<ng-template #distlink>
  <section class="FT-quick-links">
    <div class="container">
      <div class="FT-title text-black">{{ preTitle }}</div>
      <div class="row">
        <div class="col-lg col-md-4 col-6" *ngFor="let item of linkLists">
          <a class="analytics-quick-link quicAction-link redirectGuestUrl quick-links-box"
             [href]="checkLink(item?.linkCompound?.url)"
             [target]="item?.linkCompound?.target === '' ? '_self' : item?.linkCompound?.target"
             data-action="Quick Links">
            <img alt="{{ item?.linkCompound?.displayText }}"
                 src="{{ getImageURL(item?.widenAssetCompound?.widenDocument) }}">
            <div class="text-big text-bold">{{ item?.linkCompound?.displayText }}</div>
          </a>
        </div>
      </div>
    </div>
  </section>
</ng-template>
