<!-- start section calculator -->
<section class="FT-calculator-box mb-6 mt-2">
  <div class="container">
    <h1 class="FT-title-medium text-blue mb-2 mt-2">
      {{ "ftiCalc.calculator-sip-topup-heading" | translate }}
    </h1>
    <div>
      {{ "ftiCalc.calculator-sip-topup-description" | translate }}
    </div>
    <div class="FT-financial">
      <!-- Tab panes -->
      <div class="tab-content calculator-box">
        <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab"
             tabindex="0">
          <div class="tab-content">
            <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
                 tabindex="0">
              <div class="row">
                <div class="col-lg-5 col-12 mb-2">
                  <ft-top-up-calculator-form [(form)]="formValue" [isSIP]="isSIP"
                                             [isTargeted]="isTargeted"
                                             (OnRecalculate)="OnRecalculate($event)">
                  </ft-top-up-calculator-form>
                  <a class="login-dark2" href="" data-bs-toggle="modal" data-bs-target="#exampleModal">{{
                          "ftiCalc.calculator-sip-disclaimer" | translate
                    }}</a>
                </div>
                <div class="col-lg-7 col-12 ps-lg-4">
                  <div class="calculate-statics">
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-4 pb-md-2">
                      <div class="graph-table-btns mb-lg-0 mb-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="btn active" id="Graph-tab" data-bs-toggle="tab"
                                    data-bs-target="#Graph-tab-pane" type="button" role="tab"
                                    aria-controls="Graph-tab-pane" aria-selected="true"
                                    (click)="setIsGraphView(true)">
                              {{ "ftiCalc.calculator-graph" | translate }}
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="btn" id="Table-tab" data-bs-toggle="tab"
                                    data-bs-target="#Table-tab-pane" type="button" role="tab"
                                    aria-controls="Table-tab-pane" aria-selected="false"
                                    (click)="setIsGraphView(false)">
                              {{ "ftiCalc.calculator-table" | translate }}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="text-small text-bold" *ngIf="isGraphView">
                        <div class="d-none d-md-block">
                          <span class="me-3">
                            <span class="circle" style="background: #a669ca"></span>
                            {{ "ftiCalc.calculator-invest-amount" | translate }}
                          </span>
                          <span>
                            <span class="circle" style="background: #00847d"></span>
                            {{ "ftiCalc.calculator-invest-value" | translate }}
                          </span>
                        </div>

                        <div class="d-md-none row text-center">
                          <div class="col-6">
                            <span>
                              <span class="circle" style="background: #a669ca"></span>
                              {{ "ftiCalc.calculator-invest-amount" | translate }}
                            </span>
                          </div>
                          <div class="col-6">
                            <span>
                              <span class="circle" style="background: #00847d"></span>
                              {{ "ftiCalc.calculator-invest-value" | translate }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="Graph-tab-pane" role="tabpanel"
                           aria-labelledby="Graph-tab" tabindex="0">
                        <div class="calculator-graph-box mb-1">
                          <!-- <div class="text-small rotated">
                              {{ "ftiCalc.calculator-invest-value" | translate }}
                          </div> -->
                          <div class="graph-container">
                            <div id="highcharts-container-sip-topup"></div>
                          </div>
                          <!-- <div class="text-small text-center">
                              {{ "ftiCalc.calculator-invest-period" | translate }}
                          </div> -->
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Table-tab-pane" role="tabpanel"
                           aria-labelledby="Table-tab" tabindex="0">
                        <div class="table-responsive text-center mb-1" style="height: 320px">
                          <table class="table table-success table-striped stiky-head" style="
                              border-spacing: 0;
                              border-collapse: separate;
                            ">
                            <thead>
                            <tr>
                              <th style="border-right: 1px solid #cacaca">
                                {{ "ftiCalc.calculator-end-of-year" | translate }}
                              </th>
                              <th colspan="2" style="
                                    border-left: 1px solid #cacaca;
                                    border-right: 1px solid #cacaca;
                                    border-bottom: 1px solid #cacaca;
                                  ">
                                {{
                                      "ftiCalc.calculator-sip-topup-normal-sip" |
                                          translate
                                }}
                              </th>
                              <th colspan="2" style="
                                    border-left: 1px solid #cacaca;
                                    border-bottom: 1px solid #cacaca;
                                  ">
                                {{
                                      "ftiCalc.calculator-sip-topup-text" | translate
                                }}
                              </th>
                            </tr>
                            <tr>
                              <th style="border-right: 1px solid #cacaca"></th>
                              <th style="border-right: 1px solid #cacaca">
                                {{ "ftiCalc.calculator-invest-text3" | translate }}
                              </th>
                              <th style="border-right: 1px solid #cacaca">
                                {{ "ftiCalc.calculator-invest-text5" | translate }}
                              </th>
                              <th style="border-right: 1px solid #cacaca">
                                {{ "ftiCalc.calculator-invest-text3" | translate }}
                              </th>
                              <th>{{ "ftiCalc.calculator-invest-text5" | translate }}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let val of formValue?.eachYearData">
                              <td>{{ val.year }}</td>
                              <td>
                                {{
                                      val.investedAmt
                                          | INRCurrency
                                }}
                              </td>
                              <td>
                                {{
                                      val.expectedAmt
                                          | INRCurrency
                                }}
                              </td>
                              <td>
                                {{
                                      val.topupInvestedAmt
                                          | INRCurrency
                                }}
                              </td>
                              <td>
                                {{
                                      val.topupResultantAmt
                                          | INRCurrency
                                }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="p-3 bg-light-blue mb-2">
                      <div class="text-center mb-3 cal-sub-tital" style="
                          font-size: 1rem;
                          font-weight: 600;
                          line-height: 21px;
                          letter-spacing: -0.03em;
                          text-align: left;
                        ">
                        {{ "ftiCalc.calculator-sip-topup-normal-sip" | translate }}
                      </div>
                      <div class="numbers d-flex justify-content-center text-center mb-4">
                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-invest-text3" | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds for-sip-recommended-fund">
                              {{
                                    formValue?.totalInvestedAmt
                                        | INRCurrency
                              }}
                            </h3>
                          </ng-container>
                        </div>

                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-invest-text5" | translate }}</div>
                          <h3 class="sip-recommended-funds for-sip-recommended-fund">
                            {{
                                  formValue?.totalResultantAmt
                                      | INRCurrency
                            }}
                          </h3>
                        </div>
                      </div>

                    </div>
                    <div class="p-3 bg-light-blue">
                      <div class="text-center mb-3 cal-sub-tital" style="
                          font-size: 1rem;
                          font-weight: 600;
                          line-height: 21px;
                          letter-spacing: -0.03em;
                          text-align: left;
                        ">
                        {{ "ftiCalc.calculator-sip-topup-text" | translate }}
                      </div>
                      <div class="numbers d-flex justify-content-center text-center mb-4">
                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-invest-text3" | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds for-sip-recommended-fund">
                              {{
                                    formValue?.topupTotalInvestedAmt
                                        | INRCurrency
                              }}
                            </h3>
                          </ng-container>
                        </div>

                        <div class="px-3">
                          <div>{{ "ftiCalc.calculator-invest-text5" | translate }}</div>
                          <h3 class="sip-recommended-funds for-sip-recommended-fund">
                            {{
                                  formValue?.topupTotalResultantAmt
                                      | INRCurrency
                            }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-end">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          {{ "ftiCalc.sip-calc-disclaimer-pop-up" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
