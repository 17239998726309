import { DEFAULT_CONFIG, LANG_CONFIG } from './all-langauges.config';

import isNull from 'lodash/isNull';

/**
 * locale code [2 digit ISO-639 language][dash + optional ISO 3166 country code]
 */
let numberLocaleCode = 'en';

/**
 * locale code [2 digit ISO-639 language][dash + optional ISO 3166 country code]
 */
export function setNumberFormatLocale(newLocaleCode: string) {
  numberLocaleCode = newLocaleCode;
}

/**
 * locale code [2 digit ISO-639 language][dash + optional ISO 3166 country code]
 * @param [type='standard'] some languages format different numbers differently (e.g. percentage in en-ch)
 */
export function getNumberFormatLocale(type = 'standard') {
  return LANG_CONFIG[numberLocaleCode]
    ? LANG_CONFIG[numberLocaleCode].numbers[type]
    : DEFAULT_CONFIG;
}

/**
 * WARNING: THIS IS ONLY FOR JAVASCRIPT GENERATED NUMBERS, ALL PDS NUMBER MUST BE DISPLAYED AS GIVEN !
 *
 * WORKAROUND: reason can't use toLocaleString is not implemented on Android, inconsistent results across
 * different Browsers
 *
 * @see inspired by https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 */
export function toLocaleNumber(
  rawNumber,
  type = 'standard',
  noDecimals = false,
  overridePrecision = false
) {
  if (rawNumber === null || rawNumber === undefined) {
    return '—';
  }
  const locale = getNumberFormatLocale(type);
  const fractionPrecision = overridePrecision || locale.fractionPrecision;
  const stringNumber = rawNumber.toFixed(fractionPrecision);
  const integer = stringNumber.slice(0, -(fractionPrecision + 1));
  const delimitedInteger = integer
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, locale.thousandsSep);// NOSONAR
  const fraction = stringNumber.slice(-fractionPrecision);
  if (noDecimals) {
    return delimitedInteger;
  }
  return delimitedInteger + locale.decimalPoint + fraction;
}

const REMOVE_ALL_BUT_LAST_DOT_REGEX = /([.])([0-9]+)(?=[.])/g;
const CLEAN_STRING_REGEX = /[^\d.-]+/g;
/**
 * WARNING: THIS IS ONLY FOR JAVASCRIPT LIGHT CALC, ALL PDS NUMBER MUST BE DISPLAYED AS GIVEN !
 *
 * This function performs light-touch number parsing, this is never for display
 * only ever for light calc (e.g is a number positive or negative)
 *
 * returns null instead of NaN for invalid numbers, due to NaN can't be JSON encoded
 *
 * E.g. parsing german numbers 0,0
 */
export function fromLocaleNumber(val: string | number): number | null {
  if (typeof val === 'number') {
    if (isNaN(val)) {
      return null;
    }
    return val;
  }
  if (isNull(val) || val === undefined) {
    return null;
  }
  let strippedNumber = val.replace(/[,]/g, '.');
  // COREWEB-2116 remove any preceding '.' from commas delimited numbers
  strippedNumber = strippedNumber.replace(REMOVE_ALL_BUT_LAST_DOT_REGEX, '$2');
  strippedNumber = strippedNumber.replace(CLEAN_STRING_REGEX, '');
  const numberVal = parseFloat(strippedNumber);
  if (isNaN(numberVal)) {
    return null;
  }
  return numberVal;
}

/**
 * WARNING: THIS IS ONLY FOR JAVASCRIPT LIGHT CALC, ALL PDS NUMBER MUST BE DISPLAYED AS GIVEN !
 *
 * ... UNLESS YOU HAVE A REALLY REALLY GOOD REASON : aka DYNAMIC CHARTING / JS VALUE ETC
 *
 * IF IN DOUBT ASK ONE OF THE FRONTEND DEV: as of writing : ANTHONY, BARTOSZ, BEN, PIOTR
 *
 * This function performs light-touch number fractional count, for determining format for
 * copying to javascript generated numbers
 *
 * E.g. parsing german numbers 0,0 == 1 au numbers 0.1234 == 4
 */
export function countPrecisionFromLocaleNumber(
  val: string | number
): number | null {
  if (typeof val === 'number') {
    const locale = getNumberFormatLocale();
    return locale.fractionPrecision;
  }
  if (!val) {
    const locale = getNumberFormatLocale();
    return locale.fractionPrecision;
  }
  const fractionalInNumber = val.match(/[,.]([0-9]+)%?$/);
  if (!fractionalInNumber) {
    const locale = getNumberFormatLocale();
    return locale.fractionPrecision;
  }
  return fractionalInNumber[1].length;
}
