import { Inject, Injectable, Optional } from '@angular/core';
import { LOCAL_STORAGE, SESSION_STORAGE } from '@ng-web-apis/common';
import {
  IAnalyticsDataStore,
  InUserType,
  LanguageData,
  SegmentId,
  UserTokenResponse,
  UserValidate,
} from '@types';
import {
  BYPASS,
  PCS_ACCEPT_FLAG,
  SEGMENT_COOKIE,
  USER_FIRM_COOKIE,
} from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { CookieService } from 'ngx-cookie-service';
import { AppStateService } from './app-state.service';
import { EnvConfigService } from '@services/env-config.service';
import { BypassRole, ProfileSummary } from './profile.interface';
import { DOCUMENT } from '@angular/common';

const logger = Logger.getLogger('StorageService');

export const STORE_KEY_SELECTED_TAB = 'selectedTab';
export const STORE_KEY_BREADCRUMBS = 'breadcrumbData';

/**
 * This service is used to store and retrieve data in the browser via local storage and cookies
 * Instead of using cookies or local storage directly, use this service
 * Where cookie and local storage values differ, Local Storage will always take priority
 * Data will be read from LS first, and only check cookie if no value found
 * By default, data will only be written to LS going forward unless specifically set to also back up in cookie
 */

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly channel: string;
  public checkCookie = false;
  public userTokenResponseCookies = [
    'accessToken',
    'guId',
    'userType',
    'isIDEInvestor',
    'userRedirectURI',
    'usersysId',
    'userFeedbackStatus',
    'enableLogging',
  ];
  public serviceBodyCookies = [
    'isRememberMe',
    'isOnlineUser',
    'authType',
    'passwordExpired',
  ];

  constructor(
    @Inject(LOCAL_STORAGE)
    @Optional()
    private readonly localStorageRef: Storage,
    @Inject(SESSION_STORAGE)
    @Optional()
    private readonly sessionStorageRef: Storage,
    @Inject(DOCUMENT)
    @Optional()
    private documentRef: Document,
    private cookieService: CookieService,
    private appState: AppStateService,
    private envConfigService: EnvConfigService
  ) {
    this.channel = this.appState.getChannel();
  }

  /**
   * Sync cookies with localstorage when configuration is loaded.
   * It was moved to method due to issue with loading configuration in service.
   * Used in profile-service.
   */
  public syncCookieOnStartup(envCookieOptions?: string): void {
    // Sync cookies when environment configuration is loaded
    // certain cookies get synced on startup
    // This is required when user logging in on account and has no localstorage cookies
    if (this.channel === 'en-in-new') {
      const cookieOptions = envCookieOptions
        ? this.getCustomCookieOptions(envCookieOptions)
        : undefined;
      if (!this.cookieService.get('accessToken')) {
        this.removeLoggedInCookies();
      } else {
        this.userTokenResponseCookies.forEach((cookie: string) => {
          this.syncCookie(cookie, undefined, cookieOptions);
        });
        this.serviceBodyCookies.forEach((cookie: string) => {
          this.syncCookie(cookie, undefined, cookieOptions);
        });
      }
      if (
        this.cookieService.get('accessToken') &&
        this.cookieService.get('guId')
      ) {
        this.storeIsLoggedInSession(true);
      }
      logger.debug('Cookies in sync');
    }
  }

  private removeDuplicatedCookies() {
    // List of domain prefixes which can have duplicated cookies.
    const domainPrefixes = ['en-in.dev', 'en-in.staging', 'pre', 'www'];
    const domain = this.appState.getCookieDomain();
    domainPrefixes.forEach((pre: string) => {
      this.cookieService.delete(USER_FIRM_COOKIE, '/', pre + domain);
      this.cookieService.delete(SEGMENT_COOKIE, '/', pre + domain);
    });
  }

  /**
   * Get bypass cookie if exist. This is read-only cookie for "Smarsh" tool. Cookie is not set by application.
   */
  public getBypassCookie(): BypassRole {
    const bypassCookie = this.cookieService.get(BYPASS);
    return bypassCookie as BypassRole;
  }

  /**
   * Remove Bypass cookie for sign-out method.
   */
  public removeBypassCookie(): void {
    this.cookieService.delete(BYPASS);
  }

  /**
   * Remove PCS tool cookie on sign-out.
   */
  public removePcsCookie(): void {
    this.cookieService.delete(PCS_ACCEPT_FLAG);
  }

  /**
   * This saves a value to session or local storage
   * If local storage AND cookieKey passed, also backs it up to cookie
   * @param name key to use in storage
   * @param value if not string, value will be stringified
   * @param useSession boolean. use session storage instead of local storage
   * @param cookieKey string. If present, will also write to cookie of this name
   */
  public store<T>(
    name: string,
    value: T,
    useSession = false,
    cookieKey?: string,
    cookieOptions?: any
  ): void {
    if (this.isStorageEnabled(useSession)) {
      const valString: string = JSON.stringify(value);
      if (useSession) {
        // store in session storage
        logger.debug('store in session storage', name, value);
        this.sessionStorageRef.setItem(name, valString);
      } else {
        // store in local storage
        logger.debug('store in local storage', name, value);
        this.localStorageRef.setItem(name, valString);

        this.checkCookie = !!cookieKey;
        if (this.checkCookie && cookieKey) {
          // back up value to cookie
          logger.debug('store in cookie', cookieKey, value);
          // for backwards compatibility, if value is a string then don't convert to json
          const cookieVal: string =
            typeof value === 'string' ? value : valString;
          this.cookieService.set(
            cookieKey, // use cookieKey if set, otherwise same key as for local storage
            cookieVal,
            // @ts-ignore - works correct with object variable as options
            this.getCookieOptions(cookieOptions)
          );
        }
      }
    } else {
      logger.debug('storage not available');
    }
  }

  /**
   * This retrieves a value from session or local storage
   * If local storage AND cookieKey passed, will try to read value from local storage first, then try cookie if nothing found
   * @param name key to use in storage
   * @param useSession boolean. use session storage instead of local storage
   * @param cookieKey string. If present, will also check cookie of this name
   * @returns value (if found) or null (if not found)
   */
  public retrieve<T>(
    name: string,
    useSession = false,
    cookieKey?: string
  ): Promise<T> {
    if (this.isStorageEnabled(useSession)) {
      return new Promise((resolve, reject) => {
        let jsonString: string | T;
        if (useSession) {
          // get value from session storage
          jsonString = this.sessionStorageRef.getItem(name);
          logger.debug('retrieve from session storage', name, jsonString);
        } else {
          // get value from local storage
          jsonString = this.localStorageRef.getItem(name);
          logger.debug('retrieve from local storage', name, jsonString);
          if (this.checkCookie && jsonString === null) {
            // if value not in local storage, try to get from cookie
            jsonString = this.cookieService.get(cookieKey || name);
            // cookieService.get() returns '' if cookie doesn't exist, so change to null
            if (jsonString === '') {
              jsonString = null;
            }
            logger.debug('retrieve from cookie', cookieKey || name, jsonString);
          }
        }
        try {
          resolve(JSON.parse(jsonString as string) as T);
        } catch (e) {
          // sometimes json parsing breaks, so just return value as string
          resolve(jsonString as T);
        }
      });
    } else {
      logger.debug('storage not available');
    }
  }

  public remove(
    name: string,
    useSession = false,
    cookieKey?: string,
    cookieOptions?: any
  ): void {
    if (this.isStorageEnabled(useSession)) {
      if (useSession) {
        // delete session storage
        logger.debug('delete session storage', name);
        this.sessionStorageRef.removeItem(name);
      } else {
        // delete local storage
        logger.debug('delete local storage', name);
        this.localStorageRef.removeItem(name);
        this.checkCookie = !!cookieKey;
        if (this.checkCookie && cookieKey) {
          // delete cookie
          logger.debug('delete cookie', cookieKey);
          // NB: this is a hacky way to delete cookies, but needed for options to match what was set originally
          this.cookieService.set(cookieKey, '', {
            ...this.getCookieOptions(cookieOptions),
            expires: -1,
          });
        }
      }
    } else {
      logger.debug('storage not available');
    }
  }

  public isStorageEnabled(useSession = false): boolean {
    try {
      if (
        typeof Storage !== 'undefined' &&
        (useSession ? this.localStorageRef : this.sessionStorageRef)
      ) {
        return true;
      }
    } catch (e) {}
    return false;
  }

  /**
   * Determines if value exists in local storage (or cookie)
   */
  public isSet(
    name: string,
    useSession = false,
    cookieKey?: string
  ): Promise<boolean> {
    if (this.isStorageEnabled(useSession)) {
      return new Promise((resolve, reject) => {
        let isSet = false;
        if (useSession) {
          // get value from session storage
          if (this.sessionStorageRef.getItem(name) !== null) {
            isSet = true;
          }
          logger.debug('isSet in session storage?', name, isSet);
        } else {
          // get value from local storage
          if (this.localStorageRef.getItem(name) !== null) {
            isSet = true;
          }
          logger.debug('isSet in local storage?', name, isSet);

          if (this.checkCookie && !isSet) {
            // if value not in local storage, try to get from cookie
            if (this.cookieService.get(cookieKey || name) !== '') {
              isSet = true;
            }
            logger.debug('isSet in cookie?', cookieKey || name, isSet);
          }
        }
        resolve(isSet);
      });
    } else {
      logger.debug('storage not available');
    }
  }

  /**
   * called to synchronise values between local storage and cookies
   */
  public syncCookie(
    name: string,
    cookieKey?: string,
    cookieOptions?: any
  ): void {
    if (this.isStorageEnabled()) {
      const localStorageStr: string = this.localStorageRef.getItem(name);
      const cookieStr: string = this.cookieService.get(cookieKey || name);
      if (
        localStorageStr !== null &&
        (cookieStr === '' || cookieStr !== localStorageStr)
      ) {
        // if cookie not set or different from local storage, set cookie to local storage value
        let parsed: unknown;
        try {
          // In some cases local storage cookies can't be parsed as JSON
          parsed = JSON.parse(localStorageStr);
        } catch (e) {
          logger.debug('localStorageStr not parsable: ', e);
          parsed = localStorageStr;
        }
        const cookieVal: string =
          typeof parsed === 'string' ? parsed : localStorageStr;
        this.cookieService.set(
          cookieKey || name,
          cookieVal,
          // @ts-ignore - works correct with object variable as options
          this.getCookieOptions(cookieOptions)
        );
      }
      if (localStorageStr === null && cookieStr !== '') {
        // if local storage not set, copy over cookie value
        this.localStorageRef.setItem(name, cookieStr);
      }
    }
  }

  //////////////////////////
  // shortcut methods below
  //////////////////////////

  // profile methods
  /**
   * Sets local storage cookies for profile
   * @param value - ProfileSummary to set in local storage cookie
   */
  public storeProfileSummary(value: ProfileSummary): void {
    // split out isLoggedIn to store in session, while rest is stored in local storage
    const { isLoggedIn = false, ...rest } = value;
    this.retrieveIsLoggedIn()
      .then((loggedIn: boolean) => {
        if (loggedIn) {
          value.isLoggedIn = loggedIn;
        }
        this.store<ProfileSummary>(this.getProfileSummaryLocalKey(), rest);
      })
      .catch((error) => {
        logger.debug('isLogin cookie does not exist. Setting to false');
        this.storeIsLoggedInSession(isLoggedIn);
        this.store<ProfileSummary>(this.getProfileSummaryLocalKey(), rest);
      });

    this.store<string>(
      USER_FIRM_COOKIE,
      value?.role || '',
      false,
      USER_FIRM_COOKIE
    );
  }

  public removeProfileSummary(): void {
    this.remove(this.getProfileSummaryLocalKey());
  }

  public storeIsLoggedInSession(value: boolean): void {
    this.store<boolean>(this.getIsLoggedInLocalKey(), value, true);
  }

  public async retrieveProfileSummary(): Promise<ProfileSummary> {
    const storedSummary: ProfileSummary = await this.retrieve<ProfileSummary>(
      this.getProfileSummaryLocalKey()
    );
    // return early if no profile data found
    if (!storedSummary) {
      logger.debug('No profile summary found in storage');
      return null;
    }
    const isLoggedIn: boolean = await this.retrieve<string | boolean>(
      this.getIsLoggedInLocalKey(),
      true
    ).then(
      (loggedInAsString) =>
        loggedInAsString === 'true' || loggedInAsString === true
    );
    const summary: ProfileSummary = {
      isLoggedIn: isLoggedIn || false,
      ...storedSummary,
    };
    logger.debug('Profile Summary loaded from storage', summary);
    return summary;
  }

  public isProfileSummarySet(): Promise<boolean> {
    // only checks local storage values. ignores isLoggedIn in session storage
    return this.isSet(this.getProfileSummaryLocalKey());
  }

  // Selected Tab
  public storeSelectedTab(tabName: string) {
    this.store<string>(STORE_KEY_SELECTED_TAB, tabName);
  }

  public retrieveSelectedTab(): Promise<string> {
    return this.retrieve(STORE_KEY_SELECTED_TAB);
  }

  public clearSelectedTab(): void {
    this.remove(STORE_KEY_SELECTED_TAB);
  }

  // Breadcrumbs
  public storeBreadcrumbs(breadcrumbData: any) {
    this.store<string>(STORE_KEY_BREADCRUMBS, breadcrumbData);
  }

  // segment methods
  public storeSegment(
    segmentId: SegmentId,
    multilingual?: LanguageData[]
  ): void {
    this.store<SegmentId>(
      this.getSegmentLocalKey(),
      segmentId,
      false,
      this.getSegmentCookieKey()
    );
    if (multilingual && multilingual.length > 0) {
      multilingual.forEach((languageCode: LanguageData) => {
        this.store<SegmentId>(
          this.getSegmentLanguageKey(languageCode.locale),
          segmentId,
          false,
          this.getSegmentLanguageKey(languageCode.locale)
        );
      });
    }
  }

  public retrieveSegment(): Promise<SegmentId> {
    return this.retrieve<SegmentId>(
      this.getSegmentLocalKey(),
      false,
      this.getSegmentCookieKey()
    );
  }

  public retrieveSegmentStatic(): SegmentId {
    const segmentLocalKey: string = this.getSegmentLocalKey();
    logger.debug('retrieve segment key', segmentLocalKey);
    const segmentId = this.localStorageRef.getItem(segmentLocalKey);
    try {
      // typescript:S4325 - Need to parse segmentId as string then return segmentId
      return JSON.parse(segmentId as string) as SegmentId; // NOSONAR
    } catch (e) {
      return segmentId as SegmentId;
    }
  }

  public removeSegment(multilingual?: LanguageData[]): void {
    this.remove(this.getSegmentLocalKey(), false, this.getSegmentCookieKey());
    if (multilingual && multilingual.length > 0) {
      multilingual.forEach((languageCode: LanguageData) => {
        this.remove(
          this.getSegmentLanguageKey(languageCode.locale),
          false,
          this.getSegmentLanguageKey(languageCode.locale)
        );
      });
    }
  }

  public retrieveFundFavorites(): Promise<string[]> {
    return this.retrieve(this.getFavoriteFundsLocalKey());
  }

  public storeFundFavorites(data: string[]) {
    this.store(this.getFavoriteFundsLocalKey(), data);
  }

  // Analytics Data Sotrage
  public storeAnalyticsData(data: IAnalyticsDataStore): void {
    this.store<IAnalyticsDataStore>(
      this.getAnalyticsPersistantLocalKey(),
      data
    );
  }

  public retrieveAnalyticsData(): Promise<IAnalyticsDataStore> {
    return this.retrieve<IAnalyticsDataStore>(
      this.getAnalyticsPersistantLocalKey()
    );
  }

  public removeAnalyticsData(): void {
    this.remove(this.getAnalyticsPersistantLocalKey());
  }

  public isSegmentSet(): Promise<boolean> {
    return this.isSet(
      this.getSegmentLocalKey(),
      false,
      this.getSegmentCookieKey()
    );
  }

  // terms agreed methods
  public storeTermsAgreed(segmentId: SegmentId, value: boolean): void {
    const key: string = this.getTermsAgreedLocalKey(segmentId);
    this.store<boolean>(key, value, false, key);
  }

  public retrieveTermsAgreed(segmentId: SegmentId): Promise<boolean> {
    return this.retrieve<boolean>(this.getTermsAgreedLocalKey(segmentId));
  }

  public removeTermsAgreed(segmentId: SegmentId): void {
    this.remove(this.getTermsAgreedLocalKey(segmentId));
  }

  public isTermsAgreedSet(segmentId: SegmentId): Promise<boolean> {
    return this.isSet(this.getTermsAgreedLocalKey(segmentId));
  }

  /**
   * Sets cookie with storage service
   * @param bodyVariable - Variable from accounts validation response object
   * @param envConfigBaseCookieVal - additional string set in Env Config (India app requirement)
   * @param cookieName - cookie name string
   */
  public setCookieByName(
    bodyVariable: UserValidate | UserTokenResponse,
    cookieName: string,
    envConfigBaseCookieVal: string
  ): void {
    const cookieOptions = this.getCustomCookieOptions(envConfigBaseCookieVal);
    this.store(
      cookieName,
      this.getConditionalCookieValue(bodyVariable, cookieName),
      false,
      cookieName,
      cookieOptions
    );
  }

  public setCookieByNameVal(
    cookieName: string,
    cookieVal: string,
    cookieOptions: string
  ): void {
    this.store(cookieName, cookieVal, false, cookieName, cookieOptions);
  }

  /**
   * Get Custom cookie options from ; separated string (domain=.franklintempletonindia.com;path=/)
   * @param envConfigBaseCookieVal - ; separated string
   */
  public getCustomCookieOptions(envConfigBaseCookieVal: string): any {
    return Object.fromEntries(
      envConfigBaseCookieVal.split(';').map((cookieOption) => {
        return cookieOption.split('=');
      })
    );
  }

  /**
   * Clear Cookies
   */
  public clearCookies(envCookieOptions?: string): void {
    for (
      let cookies = this.documentRef.cookie.split(';'), cookieCounter = 0;
      cookieCounter < cookies.length;
      cookieCounter++
    ) {
      const cookieIndex = cookies[cookieCounter].indexOf('=');
      let cookieName: string =
        -1 < cookieIndex
          ? cookies[cookieCounter].substr(0, cookieIndex)
          : cookies[cookieCounter];
      cookieName = cookieName.trim();
      // NGC-15627 - exclude cookies from deletion
      const cookiesToExclude = ['_pk_', 'outage_en_IN'];
      let removeCookie = true;
      cookiesToExclude.forEach((cookie: string) => {
        if (cookieName.includes(cookie)) {
          removeCookie = false;
        }
      });
      if (removeCookie) {
        const cookieOptions = envCookieOptions
          ? this.getCustomCookieOptions(envCookieOptions)
          : undefined;
        this.remove(cookieName, false, cookieName, cookieOptions);
      }
    }
  }

  public async retrieveIsLoggedInFromProfile(): Promise<boolean> {
    return this.retrieveProfileSummary().then((profile) => {
      return profile.isLoggedIn;
    });
  }

  public async retrieveIsLoggedIn(): Promise<boolean> {
    return this.retrieve(this.getIsLoggedInLocalKey(), true);
  }

  public async retrieveAccessToken(): Promise<string> {
    const cookieName = 'accessToken';
    if (!this.cookieService.get('accessToken')) {
      this.removeLoggedInCookies();
    }
    return this.retrieve(cookieName, false, cookieName);
  }

  public async retrieveUserType(): Promise<InUserType> {
    const cookieName = 'userType';
    return this.retrieve(cookieName, false, cookieName);
  }

  //////////////////////////
  // private methods below
  //////////////////////////

  private getSegmentLocalKey(): string {
    return `segment_${this.channel}`;
  }

  private getSegmentLanguageKey(languageKey: string): string {
    return `segment_${languageKey}`;
  }

  private getProfileSummaryLocalKey(): string {
    return `profile_${this.channel}`;
  }

  private getIsLoggedInLocalKey(): string {
    return `isLoggedIn_${this.channel}`;
  }

  private getAnalyticsPersistantLocalKey(): string {
    return `analyticsData_${this.channel}`;
  }

  private getFavoriteFundsLocalKey(): string {
    return `watchlist_${this.channel}`;
  }

  /**
   * If site is US, use SEGMENT_COOKIE (user_role) for legacy cookie
   * For intl sites, returns same as LocalStorage key e.g. segment_en-lu
   */
  private getSegmentCookieKey(): string {
    return this.channel === 'en-us'
      ? SEGMENT_COOKIE
      : this.getSegmentLocalKey();
  }

  private getTermsAgreedLocalKey(segmentId: SegmentId): string {
    return `segment_${this.channel}_${segmentId}_termsagreed`;
  }

  private getCookieOptions(options?: any): object {
    if (typeof options === 'string' || options instanceof String) {
      const optionsStr = options as string;
      options = this.getCustomCookieOptions(optionsStr);
    }
    const secure: boolean =
      this.envConfigService.getEnvConfig()?.environment !== 'dev';
    const cookieOptions: any = {
      path: options?.path ? options.path : '/', // Avoid setting separate cookie for each patch
      secure,
      sameSite: 'Lax',
    };
    // If expires option = 0 do net set it
    if (options?.expires !== '0') {
      cookieOptions.expires = options?.expires ? options.expires : 365;
    }
    // Set localhost domain
    if (this.documentRef.domain === 'localhost') {
      cookieOptions.domain = this.documentRef.domain;
    } else {
      cookieOptions.domain = options?.domain
        ? options.domain
        : this.appState.getCookieDomain();
    }
    return cookieOptions;
  }

  private removeLoggedInCookies(): void {
    // Remove Cookie from local storage if cookie does not exist.
    // This is required when cookie will be removed on account
    this.storeIsLoggedInSession(false);
    this.userTokenResponseCookies.forEach((cookie: string) => {
      this.remove(cookie);
    });
  }

  /**
   * Returns cookie value to set.
   * @param bodyVariable - User validation response body - UserValidate | UserTokenResponse
   * @param cookieName - cookie name to set from response body - string
   */
  private getConditionalCookieValue(
    bodyVariable: UserValidate | UserTokenResponse,
    cookieName: string
  ): string | boolean {
    const bodyVarName =
      cookieName === 'userFeedbackStatus' ? 'userFeedback' : cookieName;
    return bodyVariable[bodyVarName];
  }
}
