<section class="FT-latest-updates">
  <div class="container">
    <div class="text-center">
      <div class="FT-title">{{ "literature.latestUpdatesHeading" | translate }}</div>
      <div class="text-big">
        {{ "literature.stay-up-to-date-label" | translate }}
      </div>
    </div>
    <div>
      <div class="row g-md-3 g-0">
        <ng-container *ngIf="updateList">
          <div class="col-md-6 col-12">
            <div class="update-box mb-md-3 mb-2" *ngFor="let update of updateListevenDoc">
              <div>
                <img *ngIf="update.literatureHref" [src]="'/assets/images/' +fileExtension(update) +'.svg'" alt="icon"/>
                {{ update.dctermsTitle }}
              </div>
              <a class="analytics-link" [href]="'download' + update.literatureHref" target="_blank"
                 data-event-name="Event Homepage" data-category="Homepage" data-action="Updates"
                 [attr.data-label]="update.dctermsTitle">
                <i class="bi bi-chevron-right"></i>
                <!--<img alt="image" class="update-download-icon" src="assets/images/general/circledown.svg">-->
              </a>
            </div>
          </div>
          <!-- XXXX-->
          <div class="col-md-6 col-12">
            <div class="update-box mb-md-3 mb-2" *ngFor="let update of updateListoddDoc">
              <div>
                <img *ngIf="update.literatureHref" [src]="'/assets/images/'+fileExtension(update) +'.svg'" alt="icon"/>
                {{ update.dctermsTitle }}
              </div>
              <a class="analytics-link" [href]="'download' + update.literatureHref" target="_blank"
                 data-event-name="Event Homepage" data-category="Homepage" data-action="Updates"
                 [attr.data-label]="update.dctermsTitle">
                <i class="bi bi-chevron-right"></i>
                <!--<img alt="image" class="update-download-icon" src="assets/images/general/circledown.svg">-->
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="text-center mb-5">
      <strong>
        <span *ngIf="!updateList" class="mt-5">
          {{ "literature.literatureStatus" | translate }}</span>
      </strong>
    </div>
    <div class="text-center mt-md-5 mb-5">
      <a href="{{viewallupdatesLink}}" class="more-links analytics-link" target="_self"
        data-event-name="Event Homepage"
        data-category="Homepage"
        data-action="Updates"
        data-label="View All">
        <span> {{"literature.updatesCTAText" | translate}}</span>
        <i class="bi bi-chevron-right ms-3"></i></a>
    </div>
  </div>
</section>
