import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { Location, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

const logger = Logger.getLogger('AccordionTabComponent');

const PATH_NAME = {
  help: 'help',
  faqs: 'faqs',
  openAnAccount: 'open an account',
  securityAndPrivacy: 'security-and-privacy',
  securityPrivacy: 'security & privacy',
  termsAndConditions: 'terms-and-conditions',
};

@Component({
  selector: 'ft-accordion-tab',
  templateUrl: './accordion-tab.component.html',
  styleUrls: ['./accordion-tab.component.scss']
})
export class AccordionTabComponent implements OnInit, AfterViewInit {

  @Input() component!: BrComponent;
  @Input() page: Page;
  currentPath = '';
  isCurrentHeadingPath = '';
  openFirstAccordion = false;
  hashNavigation: any;
  acordionKey: any;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private scroller: ViewportScroller
  ) {
  }

  ngOnInit(): void {
    this.currentPath = this.location.path().split('/')[1];
    this.isCurrentHeadingPath = this.router.url.split('#')[1]
    /** Redirect to accordion tab based on url heading */
    if (this.isCurrentHeadingPath !== undefined) {
      for (let i = 0; i <= this.content.length; i++) {
        if (this.isCurrentHeadingPath === "heading" + i) {
          this.currentPath = this.content[i].title.toLowerCase();
        }
      }
    } else {
      if (this.currentPath === PATH_NAME.help) {
        this.currentPath = PATH_NAME.openAnAccount;
      } else if (this.currentPath === PATH_NAME.securityAndPrivacy) {
        this.currentPath = PATH_NAME.securityPrivacy;
      } else if (this.currentPath === PATH_NAME.termsAndConditions) {
        this.openFirstAccordion = true;
      }
    }
    if (this.currentPath === 'faq') {
      this.currentPath = PATH_NAME.faqs
    }
    this.hashNavigation = this.route.snapshot?.fragment;
  }

  ngAfterViewInit() {
    if (this.hashNavigation !== undefined) {
      if (this.currentPath === 'faqs') {
        this.smoothScroll(this.acordionKey);
      } else {
        this.smoothScroll(this.hashNavigation);
      }
    }
    if (this.currentPath === 'faqs') {
      this.smoothScroll(this.acordionKey);
    }
  }

  /** Functionality to set the custom CSS classes based on the accordion pages */
  getCustomCss(item, index, className) {
    let getClass = className;
    if (this.currentPath === PATH_NAME.termsAndConditions) {
      if (this.currentPath !== item?.title.toLowerCase() && (this.openFirstAccordion && index === 0)) {
        return getClass;
      }
    } else if (this.currentPath === item?.title.toLowerCase() && !(this.openFirstAccordion && index === 0)) {
      return getClass;
    }

  }

  getAccordionTitle(accordionName: any, acordionKey: any) {
    this.acordionKey = acordionKey;
    return accordionName;
  }

  smoothScroll(target) {
    if (this.currentPath !== 'faqs') {
      let stra2;
      if (target) {
        stra2 = document.querySelector(`#${target}`);
        stra2 = stra2 === undefined ? document.querySelector(`#${target}`) : stra2;
      }
      const doScrolling = (elementY, duration) => {
        const startingY = window.pageYOffset;
        const diff = elementY - startingY;
        let start;

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
          if (!start) {
            start = timestamp;
          }
          // Elapsed milliseconds since start of scrolling.
          const time = timestamp - start;
          // Get percent of completion in range [0, 1].
          const percent = Math.min(time / duration, 1);

          window.scrollTo(0, startingY + diff * percent + 30);

          // Proceed with animation as long as we wanted it to.
          if (time < duration) {
            window.requestAnimationFrame(step);
          }
        });
      };

      if (stra2 != null) {
        doScrolling(stra2.getBoundingClientRect().top + window.scrollY - 40, 10);
      }

    } else {
      /**
       * This applies just to the FAQ tab section and does not affect other functionality.
       */
      setTimeout(() => {
        this.scroller.scrollToAnchor('heading6')
      }, 0)
    }

  }

  get document() {
    const {document} = this.component?.getModels() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData().contentBlocks;
  }

}
