import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { getMenus } from '@utils/pagemodel-utils';

const logger = Logger.getLogger('BreadcrumbService');

export interface BreadcrumbItem {
  name?: string;
  parent?: string;
  link?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbByNameMap: Map<string, BreadcrumbItem>;
  breadcrumbByUrlMap: Map<string, BreadcrumbItem>;

  constructor(private router: Router) {
  }

  getBreadcrumbs(page: Page, leaf?: string): BreadcrumbItem[] {
    if (!this.breadcrumbByNameMap) {
      this.createBreadcrumbMapFromMenus(page);
      logger.debug(this.breadcrumbByNameMap);
    }

    return this.getBreadcrumbsByUrl(this.router.url, leaf);
  }

  private createBreadcrumbMapFromMenus(page: Page) {
    this.breadcrumbByNameMap = new Map();
    this.breadcrumbByUrlMap = new Map();

    const footerMenus = getMenus(
      page,
      page.getComponent('footer-content', 'footer')
    );

    footerMenus?.siteMenuItems?.forEach((menu) => {
      menu.childMenuItems.forEach((childItem) => {
        this.loopThroughSiteMenuItems(null, childItem);
      });
    });
    // do the header menus second so they overwrite dodgy footer menu items
    // removed as it picks ViewALL link from header links
    /*const headerMenus = getMenus(
      page,
      page.getComponent('header-content', 'header')
    );

    headerMenus?.siteMenuItems?.forEach((menu) => {
      menu.childMenuItems.forEach((childItem) => {
        this.loopThroughSiteMenuItems(null, childItem);
      });
    });*/
  }

  private loopThroughSiteMenuItems(parent: BreadcrumbItem, menuItem: any) {
    const link = menuItem.links?.site?.href;
    const breadcrumb: BreadcrumbItem = {
      parent: parent?.name,
      name: menuItem.name,
      link,
    };
    this.breadcrumbByNameMap.set(menuItem.name, breadcrumb);
    if (link) {
      this.breadcrumbByUrlMap.set(link, breadcrumb);
    }
    menuItem.childMenuItems.forEach((childItem) => {
      this.loopThroughSiteMenuItems(breadcrumb, childItem);
    });
  }

  private getBreadcrumbsByUrl(url: string, leaf?: string): BreadcrumbItem[] {
    if (url?.includes('?')) {
      url = url?.split('?')[0];
    }
    const ret: BreadcrumbItem[] = [];
    let item: BreadcrumbItem;
    // try special cases first
    if (url?.startsWith('/fund-details') || url?.startsWith('/funds-and-solutions/funds-explorer')) {
      // fund detail page
      item = {
        parent: 'All Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.startsWith('/about-us/our-team/')) {
      item = {
        parent: 'Our People',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.startsWith('/about-us/')) {
      item = {
        parent: 'About Us',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/investor/') && url?.includes('-calculator')) {
      item = {
        parent: 'All Calculators',
        name: this.getLeaf(url, leaf),
      };
    }  else if (url?.startsWith('/knowledge-centre/quick-learn/related-articles')) {
      item = {
        parent: 'Quick Learn',
        name: this.getLeaf(url, leaf),
      };
    }else if (
      url?.includes('/knowledge-centre/quick-learn/new-to-mutual-funds/')
    ) {
      item = {
        parent: 'New to Mutual Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/more-about-mutual-funds/')
    ) {
      item = {
        parent: 'More About Mutual Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/women-and-investing/')
    ) {
      item = {
        parent: 'Women and Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/planning-for-retirement/')
    ) {
      item = {
        parent: 'Planning for Retirement',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/your-childs-future/')
    ) {
      item = {
        parent: "Your Child's Future",
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/smart-tax-planning/')
    ) {
      item = {
        parent: 'Guide to Income Tax Planning',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/managing-volatility-and-pitfalls-of-investing/')
    ) {
      item = {
        parent: 'Managing Volatility and Pitfalls of Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/webinars/')
    ) {
      item = {
        parent: 'Webinars',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/knowledge-centre/quick-learn/new-to-investing/')
    ) {
      item = {
        parent: 'New to Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/knowledge-centre/quick-learn/latest-commentaries/')) {
      item = {
        parent: 'Latest Commentaries',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/knowledge-centre/quick-learn/blogs/')) {
      item = {
        parent: 'Blog',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/knowledge-centre/quick-learn/frankly-speaking/')) {
      item = {
        parent: 'Frankly Speaking',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/investor-services/')) {
      item = {
        parent: 'Investor Services',
        name: this.getLeaf(url, leaf),
      };
    }
    if (!item) {
      item = this.breadcrumbByUrlMap.get(url);
    }

    // build up breadcrumbs by adding ancestors
    if (item) {
      ret.unshift(item);
      while (item.parent) {
        item = this.breadcrumbByNameMap.get(item.parent);
        ret.unshift(item);
      }
    } else {
      if (url?.includes('?')) {
        url = url?.split('?')[0];
      }
      ret.unshift({
        parent: null,
        name: this.getLeaf(url, leaf),
      });
    }
    
    if (url?.includes('/nps')) {
      ret[0].name = 'Feedback';
    }
    return ret;
  }

  private getLeaf(url: string, leaf?: string): string {
    return leaf ? leaf : this.extractNameFromUrl(url);
  }

  private extractNameFromUrl(url): string {
    const lastPathFragment = url?.split('/').reverse()[0];
    let flag = true;
    let name = '';
    const arrName = lastPathFragment?.split('-');
    arrName?.forEach((value) => {
      if (flag) {
        name = value.charAt(0).toUpperCase() + value.substring(1);
        flag = false;
      } else {
        name = name + ' ' + value.charAt(0).toUpperCase() + value.substring(1);
      }
    });
    return name;
  }
}
