import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'spacing' })
export class SpacingPipe implements PipeTransform {
  constructor() {}
  transform(value: string) {
    switch (value) {
      case 's':
        return 5;
      case 'm':
        return 10;
      case 'l':
        return 15;
      case 'xl':
        return 20;
      case 'xxl':
        return 25;
      case 'xxxl':
        return 30;
      case 'xxxxl':
        return 35;
      case 'xxxxxl':
        return 40;
      case 'xxxxxxl':
        return 45;
      case 'moveUp':
      case 'moveDown':
        return -30;
      default:
        return 0;
    }
  }
}
