<ng-container *ngIf="results?.length !== 0; else elseBlock">
  <ng-container *ngFor="let result of results; let i = index">
    <ng-container *ngIf="i < valueToShow">
      <div class=" text-start fw-bold mb-3">
        <a
            class="article"
            [href]="result?.litLink"
            [innerHTML]="result?.litName"
        >
        </a><br>
        <span><small [innerHTML]="result?.litDetail"></small></span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #elseBlock>
  <p class="search-item">
    {{ "ftiGeneric.search-no-results-desc" | translate }}
    "{{ this.queryParams.query }}"
  </p>
</ng-template>
<div class="col-6 text-right mt-4" *ngIf="!isViewAll && totalCount > 0">
  <a class="view-all" style="cursor: pointer;" (click)="clickEvent($event)">View All {{ totalCount }} results</a>
</div>
<div class="text-center mt-4" *ngIf="isViewAll && totalCount > valueToShow">
  <button class="btn btn-white" (click)="loadMore($event)">{{
          "products.fund-listing-load-more-button" | translate
    }}
  </button>
</div>
