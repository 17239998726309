import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interface of a Debug Service that will get injected into various components.
 * The components can then be put into debug mode at runtime.
 *
 * Defining this as an interface allows the logic of the debug service to be defined outside of the EDS project
 *
 * **TODO**: add more methods as requirements defined
 */
export interface DebugService {
  /**
   * Determines if footnote debug is visible
   */
  getFootnoteDebug$: () => Observable<boolean>;

  /**
   * Determines if proximal debug is visible
   */
  getProximalDebug$: () => Observable<boolean>;

  /**
   * Determines if all components need to be expanded
   * for Smarsh web crawler
   */
  getExpandAllComponents$: () => Observable<boolean>;
}

/**
 * Used when configuring a {@link DebugService} inside a module
 *
 * We need to create an injection token, because we can't inject an interface directly. (Typescript removes them when compiling)
 *
 * see: {@link https://angular.io/guide/dependency-injection-providers#non-class-dependencies}
 *
 * example: `providers: [{provide: DebugServiceToken, useClass: MyDebugService}]`
 */
export const DebugServiceToken = new InjectionToken<DebugService>(
  'debug.service'
);
