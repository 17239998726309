export const environment = {
  config: 'config.json',
  production: true,
  appInsights: {
    instrumentationKey: '',
  },
  recaptcha: {
    siteKey: '6LdC9CcTAAAAAI6Rd79zDCIftoG8bXjeJdpv0zNr',
  },
};
