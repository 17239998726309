<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>

  <div class="FT-title-small">{{ 'products.address-heading' | translate }}</div>
  <div class="category-btns my-4" id="btn-dark">
    <ul class="location-branch-btn">
      <li [class.active]="selectedCategory === 'All'" (click)="selectCategory('All')"
          class="">{{ 'products.ppss-filter-type-all' | translate }}
      </li>
      <li [class.active]="selectedCategory === 'FT Branches'" (click)="selectCategory('FT Branches')"
          class="">{{ 'products.address-ft-branches' | translate }}
      </li>
      <li [class.active]="selectedCategory === 'CAMS'" (click)="selectCategory('CAMS')"
          class="">{{ 'products.address-cams' | translate }}
      </li>
    </ul>
  </div>
  <div class="mb-4">
    <select class="form-select" aria-label="State Selection" (change)="stateChange($event)">
      <option [selected]="selectedState === 'All'" value="All">{{ 'products.address-all-states' | translate }}
      </option>
      <option *ngFor="let item of allStates" [value]="item.slug"
              [selected]="selectedState === item.slug">{{ item.state }}
      </option>
    </select>
    <select class="form-select" aria-label="City Selection" (change)="cityChange($event)">
      <option [selected]="selectedCity === 'All'" value="All">{{ 'products.address-all-cities' | translate }}
      </option>
      <option *ngFor="let item of allCities" [value]="item.cityslug"
              [selected]="selectedState === item.cityslug">{{ item.cityname }}
      </option>
    </select>
    <button type="button" class="btn btn-primary mx-3 loca-serch-btn"
            (click)="onSearchInit()">{{ 'products.add-fund-search-input-placeholder' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary"
            (click)="reset()">{{ 'products.add-fund-reset-label' | translate }}
    </button>
  </div>
  <div class="row g-4">
    <div class="col-lg-6 col-md-6 col-12"
         *ngFor="let item of filteredBranches.slice(0, itemsToShow);let i = index">
      <div class="bg-white box-shadow" id="location-dark-color">
        <h3 id="location-tital">{{ item?.cityname }}</h3>
        <div class="text-big mb-3">{{ item?.branchname }}</div>
        <div class="d-flex mb-3" *ngIf="item?.telephoneNumber">
          <img alt="image" src="assets/images/footer-pages/whatsapp-sm.svg">
          <div>
            {{ item?.telephoneNumber }}
          </div>
        </div>
        <div class="d-flex mb-3" *ngIf="item?.sales && item?.services else eitherBlock">
          <span class="text-big" *ngIf="item?.sales">Sales:&nbsp;</span> {{ item?.sales }}&nbsp;/&nbsp;
          <span class="text-big" *ngIf="item?.services">Services:&nbsp; </span>{{ item?.services }}
        </div>
        <ng-template #eitherBlock>
          <div class="d-flex mb-3" *ngIf="item?.sales || item?.services">
            <span class="text-big" *ngIf="item?.sales">Sales:&nbsp; </span>{{ item?.sales }}
            <span class="text-big" *ngIf="item?.services">Services:&nbsp; </span>{{ item?.services }}
          </div>
        </ng-template>
        <div class="d-flex">
          <img alt="image" src="assets/images/footer-pages/MapPin.svg">
          <div>
            {{ item?.address }}
          </div>
        </div>
        <div class="mt-4 text-start fw-bold">
          <!--TODO: Need further implementations-->
          <!--<a href="javascript:void(0)" (click)="showModal(mapcontent, item.latitude, item.longitude)"
             data-target="#locateAddressMapModal"
             data-toggle="modal" id="Agartala">
            {{ 'products.addreess-open-map' | translate }}
            <i class="bi bi-chevron-right"></i>
          </a>-->
        </div>
      </div>
    </div>
    <div class="text-center pt-5">
      <button class="btn btn-outline-primary" (click)="loadMore()" *ngIf="canLoadMore">
        {{ 'products.fund-listing-load-more-button' | translate }}
      </button>
    </div>
  </div>
</div>
<!-----i'm interested-->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
     style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <ft-imintrestedform></ft-imintrestedform>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-bottom sort-bottom" tabindex="-1" id="offcanvasBottomLocate"
     aria-labelledby="offcanvasBottomLabel"
     style="height: 55%; border-top-left-radius: 39px; border-top-right-radius: 39px;">
  <div class="offcanvas-header">
    <div class="line"></div>
    <div class="d-flex justify-content-between mt-4 w-100">
      <div>
        <div class="modal-title custom-modal-title" id="callBackLabel">I’m Interested</div>
        <div class="mt-1 modal-subtitile">Please enter your details
        </div>
      </div>
      <div class="text-end">
        <div type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><img
            src="assets/images/general/Frame1000009207.svg"></div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body small">
    <ft-imintrestedform [isSmall]="true"></ft-imintrestedform>
  </div>
</div>
<ng-template #mapcontent let-modal>
  <div class="modal-content map-modal"><!--
      <div class="modal-header">
        <div class="text-center w-100">
          <h5 class="modal-title" id="exampleModalLabel">Email Us</h5>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>-->
    <!--<div class="modal-body">
      <google-map width="800px" height="500px" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
                  (mapMousemove)="move($event)">
        <map-marker *ngFor="let markerPosition of markerPositions"
                    [position]="markerPosition"
                    [options]="markerOptions"></map-marker>
      </google-map>
      <div>Latitude: {{ display?.lat }}</div>
      <div>Longitude: {{ display?.lng }}</div>
    </div>-->
  </div>
</ng-template>
