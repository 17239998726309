import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Inject,
  OnDestroy
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import * as Highcharts from 'highcharts';
import { TranslateService } from "@shared/translate/translate.service";
import { ChartService } from '../ind-calculators.service';
import { Subscription } from 'rxjs';
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("SipCalculatorComponent");

@Component({
  selector: "ft-sip-calculator",
  templateUrl: "./sip-calculator.component.html",
  styleUrls: ["./sip-calculator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SipCalculatorComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  formValue: any;

  isGraphView: boolean = true;
  readonly sipType: any = {
    normal: "normal",
    amount: "amount",
    year: "year",
  };

  selectedSipType: any = this.sipType.normal;

  label1: string;
  label2: string;
  private chartOptionsSubscription: Subscription;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private translateService: TranslateService,
    private chartService: ChartService,
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    // Initialize your form and selectedSipType if needed
    this.label1 = this.translateService.instant('ftiCalc.calculator-invest-amount');
    this.label2 = this.translateService.instant('ftiCalc.calculator-invest-value');

    this.chartOptionsSubscription = this.chartService.getChartOptionsObservable().subscribe((chartOptions) => {
      this.renderChart(chartOptions);
    });
    this.chartService.initializeChartOptions({series: []});
  }

  // Set isGraphView
  setIsGraphView(v: any) {
    this.isGraphView = v;
  }

  toggleIsSIP = (type: any) => {
    this.selectedSipType = type;
    this.setAnalytics();
  };

  showRiskOptions = false;
  showRecomendedFunds = false;

  /**
   * Sets Analytics for SIP calculator page
   * @param radioBtnSelected radio button selected
   */
  setAnalytics(isSas?: boolean) {
    const invest = 'normal';
    const wantToInvest = 'I Want to Invest';
    let knowMyGoal = this.selectedSipType === 'year' ? 'I want to know my tenure' : 'I Know MY Goal'
    
    let event =
      this.selectedSipType === invest ? 'Event wenttoinvest' : 'Event knowmygoal';
      if (isSas) {
        event =
          this.selectedSipType === invest
            ? 'Event  saswanttoinvest'
            : 'Event sasknowmygoal';
      }
    const label = this.selectedSipType === invest ? wantToInvest : knowMyGoal;
    this.analyticsService.trackEvent({
      event,
      category: 'SIP-Calculator',
      action: isSas ? 'Start A SIP' : 'Select Option',
      label,
    });
  }

  OnRecalculate(data: any) {
    const {labels, totalValueData, investedData} = this.chartService.processData(data);

    this.chartService.updateChart({
      xAxis: {categories: labels,
        title: {
          text: 'Investment Period',
          style: {
            color: '#3C425D',
            fontWeight: 'bold',
            fontSize : '14px',
            fontFamily : 'TTCommonsProRegular, sans-serif'
        }
        },
      },
      series: [
        {
          name: this.label1,
          data: investedData,
          type: 'line',
        },
        {
          name: this.label2,
          data: totalValueData,
          type: 'line',
        },
      ],
    });
  }

  private renderChart(chartOptions: Highcharts.Options): void {
    Highcharts.chart('highcharts-container-sip', chartOptions);
  }

  investNow(fund) {
    this.setAnalytics(true);
    this.analyticsService.trackEvent({
      event: 'calculator Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow Sticky',
    });
    this.investNowService.investNow('');
  }

  ngOnDestroy(): void {
    if (this.chartOptionsSubscription) {
      this.chartOptionsSubscription.unsubscribe();
    }
  }
}
