import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interface of a Footnote Service that will get injected into the {@link FootnoteComponent}.
 *
 * Defining this as an interface allows the logic of the footnote service to be defined outside of the EDS project
 */
export interface FootnoteService {
  /**
   * This both registers the footnote and gets an Observable of matching refs
   */
  getRefs$: (placement: string) => Observable<string[]>;
}

/**
 * Used when configuring a {@link FootnoteService} inside a module
 *
 * We need to create an injection token, because we can't inject an interface directly. (Typescript removes them when compiling)
 *
 * see: {@link https://angular.io/guide/dependency-injection-providers#non-class-dependencies}
 *
 * example: `providers: [{provide: FootnoteServiceToken, useClass: MyFootnoteService}]`
 */
export const FootnoteServiceToken = new InjectionToken<FootnoteService>(
  'footnote.service'
);
