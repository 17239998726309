import { Inject, Injectable } from '@angular/core';
import { FundDetailService } from '@components/products/services/fund-detail.service';
import {
  DropdownItem,
  FundHeaderState,
  ProductDetailConfiguration,
} from '@types';
import FundHeaderQuery from '@graphql/overview/fund-header.graphql';
import { Logger } from '@utils/logger';
import { Product } from '@components/products/models';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@components/shared/translate/translate.service';
import moment from 'moment';

const logger = Logger.getLogger('FundHeaderService');

@Injectable({
  providedIn: 'root',
})
export class FundHeaderService {
  state: FundHeaderState;
  state$: Subject<FundHeaderState>;

  constructor(
    private fundDetailService: FundDetailService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.state = {
      fundName: null,
      fundInceptionDate: null,
      assetClass: null,
      investmentFocus: null,
      perfDropdownItems: null,
      minimumInvestment: null,
      additionalInvestment: null,
      alternativeFor: null,
    };
    this.state$ = new Subject();
  }

  getFundHeaderState$(): Observable<FundHeaderState> {
    return this.state$.asObservable();
  }

  /**
   * Fetch the fund information from PDS and initialize state.
   * @param productDetailConfiguration product detail configuration from bloomreach
   */
  populate(productDetailConfiguration: ProductDetailConfiguration): void {
    this.fundDetailService
      .register(FundHeaderQuery, {
        fundId: productDetailConfiguration.fundId,
      })
      .subscribe(
        (fundDetail: Product) => {
          logger.debug(`Mapped fund detail response: ${fundDetail}`);
          this.state.fundName = fundDetail.fundName;
          this.state.fundInceptionDate = fundDetail.fundInceptionDate;
          this.state.assetClass = fundDetail.assetClass;
          this.state.investmentFocus = fundDetail.investmentFocus;
          const firstShareClass = fundDetail.shareClasses[0];
          this.state.minimumInvestment = firstShareClass?.minimumInvestment;
          this.state.additionalInvestment =
            firstShareClass?.additionalInvestment;
          this.state.alternativeFor = fundDetail.alternativeFor;
          this.state$.next(this.state);
        },
        (error) => {
          logger.error(`Error in invoking fund detail service: ${error}`);

          this.state$.error(error);
        }
      );
    if (!this.state.perfDropdownItems) {
      this.state.perfDropdownItems = this.createPeriodDropdownItems();
    }
  }

  /**
   * Create dropdown item list as per performance periods.
   * Intentionally kept 'value' matched to "prop value" in 'FundPerformanceAnnualizedDetail'.
   */
  private createPeriodDropdownItems(): DropdownItem[] {
    return [
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-1-year'
        ),
        value: 'year1Return',
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-3-year'
        ),
        value: 'year3Return',
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-5-year'
        ),
        value: 'year5Return',
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-10-year'
        ),
        value: 'year10Return',
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-since-inception'
        ),
        value: 'sinceInceptionReturn',
        selected: true,
      },
    ];
  }

  public getEndOfLastQuarter(): string {
    const quarterAdjustment = (moment().month() % 3) + 1;
    const lastQuarterEndDate = moment()
      .subtract({months: quarterAdjustment})
      .endOf('month');

    return lastQuarterEndDate.format('L');
  }
}
