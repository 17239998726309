import { Directive, ElementRef } from '@angular/core';

@Directive({selector: 'img'})
export class LazyImgDirective {
  constructor({nativeElement}: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    let className = nativeElement.getAttribute('class');
   // console.log("lazy className", className)
    if (supports && className !== 'noLazy') {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}
