<!-- start section financial plan -->
<section class="FT-financial">
  <div class="container">
    <div class="text-center mb-sm-5 mb-4">
      <div class="FT-title d-none d-md-block">{{ "ftiCalc.calculator-title" | translate }}</div>
      <div class="FT-title d-md-none px-1"> {{ "ftiCalc.calculator-title" | translate }}</div>
      <div class="text-big px-3 goal-description">
        {{ "ftiCalc.calculator-description" | translate }}
      </div>
    </div>
    <!-- Nav tabs -->
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button (click)="toggleIsSIP(true)" class="nav-link" [ngClass]="{'active': isSIP}" id="Systematic-tab"
                type="button" role="tab" aria-controls="Systematic"
                aria-selected="true">{{ "ftiCalc.calculator-systematic" | translate }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button (click)="toggleIsSIP(false)" class="nav-link" [ngClass]="{'active': !isSIP}" id="Lumpsum-tab"
                type="button" role="tab" aria-controls="Lumpsum"
                aria-selected="false">{{ "ftiCalc.calculator-lumpsum" | translate }}
        </button>
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab" tabindex="0">
        <h3 class="mb-md-4 ">{{ isSIP ? ('ftiCalc.calculator-sip' | translate) : ("ftiCalc.calculator-lumpsum-heading" | translate) }}</h3>
        <!-- <div
            class="text-big">{{isSIP ? ("ftiCalc.calculator-text1" | translate) : ("ftiCalc.calculator-text2" | translate)}}</div> -->
        <div>
          <div class="graph-table-btns">
            <ul class="nav nav-tabs justify-content-center" id="myTab2" role="tablist">
              <li class="nav-item" role="presentation">
                <button (click)="toggleIsisTargeted(false,'Investment amount')" class="btn"
                        [ngClass]="{'active': !isTargeted}"
                        id="Investment-tab" type="button" role="tab" aria-controls="Investment"
                        aria-selected="true">{{ "ftiCalc.calculator-invest-text1" | translate }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button (click)="toggleIsisTargeted(true,'Target amount')" class="btn"
                        [ngClass]="{'active': isTargeted}"
                        id="Target-tab"
                        type="button" role="tab" aria-controls="Target"
                        aria-selected="false">{{ "ftiCalc.calculator-invest-text2" | translate }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
               tabindex="0">
            <div class="row">
              <div class="col">
                <!-- Calc from -->
                <ft-calculator-form [(form)]="formValue" [isSIP]="isSIP"
                                    [isTargeted]="isTargeted"></ft-calculator-form>
              </div>
              <div class="col-auto" [ngClass]="isTargeted?'m-auto':''" style="display: flex; align-items: center;">
                <div class="fund-info d-flex flex-column">
                  <div class="numbers d-flex flex-column">
                    <div class="" *ngIf="!(!isSIP && !isTargeted)">
                      <ng-container *ngIf="!(isSIP && isTargeted); else haveToInvestRef">
                        <div *ngIf="isSIP && !isTargeted">{{ "ftiCalc.calculator-invest-text3" | translate }}</div>
                        <div *ngIf="!isSIP && isTargeted">{{ "ftiCalc.calculator-invest-text4" | translate }}</div>
                        <h3 class="sip-recommended-funds">{{ formValue?.totalInvestAmt  | currency : "INR": 'symbol':'1.0-0' }}</h3>
                      </ng-container>
                      <ng-template #haveToInvestRef>
                        <!-- <h3 class="sip-recommended-funds">{{getTotalInvestment(getHaveToInvestAmount()) | currency: "INR"}}</h3> -->
                      </ng-template>
                    </div>
                    <div class="" *ngIf="!isTargeted">
                      <div>{{ "ftiCalc.calculator-invest-text5" | translate }}</div>
                      <h3 class="sip-recommended-funds">{{ formValue?.totalValues | currency: "INR" : 'symbol':'1.0-0' }}</h3>
                    </div>
                    <div class="" *ngIf="isSIP && isTargeted">
                      <div>{{ "ftiCalc.calculator-invest-text6" | translate }} <span
                          class="text-lowercase">{{ formValue?.time }}</span></div>
                      <h3 class="sip-recommended-funds">{{ formValue?.installmentAmount | currency: "INR" : 'symbol':'1.0-0' }}</h3>
                    </div>
                  </div>
                  <button (click)="setAnalytics(true)"
                     class="btn btn-primary">{{ isSIP ? ("ftiCalc.calculator-sip-text" | translate) : ("ftiCalc.calculator-invest-now" | translate) }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center calculators-CTA">
      <a href="{{'ftiCalc.calculator-explore-all-link' | translate }}"
         class="more-links" target="_self"><span>{{ "ftiCalc.calculator-explore-all" | translate }}</span><i
          class="bi bi-chevron-right ms-3"></i></a>
    </div>
  </div>
</section>
