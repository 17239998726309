import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'IsDataTarget'})
export class IsDataTargetPipe implements PipeTransform {
  transform(value: string) {
    return value.includes('dataTarget');
  }
}

@Pipe({name: 'DataTargetValue'})
export class DataTargetValuePipe implements PipeTransform {
  transform(value: string) {
    if (value.includes('dataTarget')) {
      return '#' + value.split('=')[1];
    }
  }
}

@Pipe({name: 'DataTargetVal'})
export class DataTargetValPipe implements PipeTransform {
  transform(value: string) {
    if (value.includes('dataTarget')) {
      return '#offcanvas' + value.split('=')[1];
    }
  }
}
