import { Component, Input, OnInit, ViewEncapsulation, } from '@angular/core';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import { NgbModal, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { CommonService } from '@services/common.service';
import { Logger } from '@utils/logger';
import { Location } from '@angular/common';
import { InvestNowService } from '@services/invest-now.service';
import { EnvConfigService } from '@services/env-config.service';
import { getPageContent } from '@utils/pagemodel-utils';
import { KnowledgeCenterService } from '@services/knowledge-center.service';

const logger = Logger.getLogger('VideoArticlesComponent');

@Component({
  selector: 'ft-video-articles',
  templateUrl: './video-articles.component.html',
  styleUrls: ['./video-articles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoArticlesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  urlSafe: SafeResourceUrl;
  mailBackForm: FormGroup;
  layout: string;
  largeTabsLayout: string = Constant.isLargeTabsLayout;
  quizDocument: any = {};
  quizAnswer: any;
  quizForm: FormGroup;
  investNow: any;
  investNowLink$: Observable<string>;
  url = '';
  isScrom = false;
  public subscription: Subscription;
  ftIndiaBrandName: string = "- Franklin Templeton India®";
  currentPageUrl: any;
  textTitle: any;
  scromVid: any;
  showTranscript: boolean = true;
  showIconForWishlist: boolean = true;
  showTooltip: boolean = false;
  modifiedContent: any = [];
  nextArticleToCome: any;
  previousArticleToCome: any;
  watchListFull: boolean = false;

  language = [
    {id: 'check1', label: 'English', isChecked: true},
    {id: 'check2', label: 'Hindi', isChecked: false},
    {id: 'check3', label: 'Gujrati', isChecked: false},
    {id: 'check4', label: 'Bengali', isChecked: false},
    {id: 'check5', label: 'Tamil', isChecked: false},
  ];
  linkToCopy: string;
  showCopyDialoge: boolean = false;
  currentVideo: any;
  isMobileView: boolean = false;
  isReload: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    config: NgbNavConfig,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private investNowService: InvestNowService,
    private location: Location,
    private envConfig: EnvConfigService,
    private metaService: Meta,
    public pagetitle: Title,
    private knowledgeCenter: KnowledgeCenterService
  ) {
    config.destroyOnHide = false;
    this.quizForm = this.formBuilder.group({
      quizAnswer: ['', [Validators.required]],
    });
    this.quizAnswer = new Array<Comment>();
  }

  ngOnInit(): void {
    this.isMobileView = window.innerWidth < 700;
    this.layout = this.page?.getComponent().getParameters().layout;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(// NOSONAR
      this.content?.iframeURL
    );
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(// NOSONAR
      this.content?.scromUrl
    );

    if (this.content?.testYourLearningDocument !== null) {
      this.quizDocument = this.getQuizDocument(
        this.content?.testYourLearningDocument
      );
    }
    this.setMetaData();
    this.investNowLink$ = this.investNowService.getInvestNowLink$();
    const videos = this.videoInfoSize;
    let vidId = videos ? videos[0]?.videoid : '';
    if (videos?.length <= 1 && vidId.indexOf('/static') !== -1) {
      this.isScrom = true;
      const baseUrl = this.envConfig.getEnvConfig()?.baseUrl;
      vidId = vidId.replace("https://www.franklintempletonindia.com/", baseUrl);
    }
    this.scromVid =
      '<iframe class="scrom-iframe" id="vid_frame1" src="' +
      vidId +
      '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    this.modifiedContent = this.content?.videoArticleInfo.map((video, index) => ({...video, ...this.language[index]}));

    this.currentVideo = this.content?.videoArticleInfo[0];
    this.initializeData();
    this.iframeUrl(this.currentVideo?.videoid);
  }

  get videoInfoSize() {
    return this.content?.videoArticleInfo;
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    const data = this.document?.getData();
    this.textTitle = data?.title;
    return data;
  }

  initializeData() {
    this.modifiedContent.isWatchListed = this.checkWatchList(this.content);
    this.nextPost();
    this.previousPost();
  }

  nextPost() {
    let data = this.document?.getData();
    if (data?.relatedVideo2) {
      this.nextArticleToCome = getPageContent(this.page, data?.relatedVideo2);
    }
  }

  previousPost() {
    let data = this.document?.getData();
    if (data?.relatedVideo1) {
      this.previousArticleToCome = getPageContent(this.page, data?.relatedVideo1);
    }
  }

  setMetaData() {
    const pageData = this.content?.ftindiaPageMetaData;
    this.metaService.updateTag(
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      "property='twitter:card'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='twitter:title'"
    );
    this.metaService.updateTag(
      {
        property: 'og:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='og:title'"
    );
    const PageTitle = pageData?.metaTitle
      ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
      : '';
    this.pagetitle.setTitle(PageTitle);
    this.metaService.updateTag(
      {
        property: 'twitter:image',
        content: '',
      },
      "property='twitter:image'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:description',
        content: pageData?.metaDescription ? pageData?.metaDescription : '',
      },
      "property='twitter:description'"
    );
  }

  getThumbnailImage(ref: any) {
    return JSON.parse(ref)?.url;
  }

  getQuizDocument(ref: any) {
    const pageUrl = this.page?.getContent(ref)?.getData();
    pageUrl?.quizQuestions?.forEach((element) => {
      element.options = [
        element.option1,
        element.option2,
        element.option3,
        element.option4,
      ];
    });
    logger.debug('QuizDocuments');
    logger.debug(pageUrl);
    return pageUrl;
  }

  scromOpenModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      modalDialogClass: 'scrom-xxl',
    });
  }

  get f() {
    return this.mailBackForm.controls;
  }


  iframeUrl(videoid) {
    const videoUrl =
      'https://www.youtube.com/embed/' + videoid + '?rel=0&enablejsapi=1';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);// NOSONAR
    return this.urlSafe;
  }

  moveToRelatedTag() {
    const elementToScroll = document.getElementById('related_tag');
    if (elementToScroll) {
      // Scroll to the element with a delay of 10 milliseconds (or any desired delay)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  toggleTranscript() {
    this.showTranscript = !this.showTranscript;
  }

  toggleCheckbox(clickedCheckbox): void {
    this.modifiedContent.forEach(checkbox => checkbox.isChecked = false);
    // Toggle the clicked checkbox
    clickedCheckbox.isChecked = true;
    this.currentVideo = clickedCheckbox;
    this.iframeUrl(this.currentVideo.videoid);
  }

  // New Flow//
  toggleIconForWishlist() {
    this.showIconForWishlist = !this.showIconForWishlist;
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

  addToWatchlist(video: any) {
    this.watchListFull = false;
    const watchListedIds = this.knowledgeCenter.getWatchList();
    if (watchListedIds.length <= 7) {
      this.knowledgeCenter.addToWatchList(video?.title);
      this.initializeData();
    } else {
      this.watchListFull = true;
    }
  }

  closeWatchlist() {
    this.watchListFull = false;
  }

  removeToWatchlist(video: any) {
    this.knowledgeCenter.removeFromWatchList(video?.title);
    this.initializeData();
  }

  async toggleShowCopyDialoge(check: boolean): Promise<void> {
    if (check) {
      this.linkToCopy = this.currentPageUrl ? this.currentPageUrl : `${window.location.origin}${this.location.path()}`;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  checkWatchList(video: any): boolean {
    const watchListedIds = this.knowledgeCenter.getWatchList();
    return watchListedIds.includes(video?.title)
  }

  shareOnApp(appName): void {
    const url = this.currentPageUrl ? this.currentPageUrl : `${window.location.origin}${this.location.path()}`;
    this.commonService.shareOn(appName, url);
  }

  subscribe() {
    window.open('https://www.youtube.com/watch?v=' + this.currentVideo.videoid + '&sub_confirmation=1');
  }

  investNowLink() {
    this.investNowService.investNow('');
  }

  reloadQuiz(event) {
    this.isReload = event;
  }

  getRelatedTags(str?: string) {
    if (str?.trim() !== '') {
      return str?.split(',');
    }
    return '';
  }
}
