import {
  Component,
  ChangeDetectorRef,
  HostListener,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Location } from '@angular/common';
import { Configuration, Page } from '@bloomreach/spa-sdk';
import {
  PageConfig,
  PageContainerService,
} from '../services/page-container.service';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AnalyticsService } from '@services/analytics.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';

const FT_CLICK_LABELS = {
  FTAcademyLinkClick: 'FT Academy link click',
  FTAcademyAndroidLinkClick: 'FT Academy android link click',
  FTAcademyIosLinkClick: 'FT Academy ios link click',
  FTAcademyVideoLinkClick: 'FT Academy video link click',
};

@Component({
  selector: 'ft-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnDestroy {
  configuration: Configuration;
  page: Page;
  mapping: object;
  layout;
  tabCount = 0;
  reachForBetterInLifePage: boolean;
  atft: boolean;
  life: boolean;
  volatility: boolean;
  emi: boolean;
  current: boolean;
  campaigns: boolean;
  isToolsPage: boolean;
  isBetterLifeVisible: boolean;
  isMarathonPage: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isFundPages = false;
  flashPage: string;
  isFlashMessageExist = false;
  goodemi: boolean;
  arrLayout: any = [];
  isLayoutVisible = true;
  // Resource Bundle
  outflowService: any;
  outflowCarousalHeader: any;

  // Clear localStorage if browser refreshed
  isMobileView: boolean;
  calculatorPage: boolean;
  bgImage: boolean;
  isAboutPage: boolean;
  isLocateus: boolean = false;


  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    localStorage.removeItem('selectedTab');
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    localStorage.removeItem('selectedTab');
  }

  constructor(
    private location: Location,
    private pageContainerService: PageContainerService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private title: Title,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) private documentRef: Document,
  ) {
    // this.pageContainerService.newPage(this.location.path());
    this.pageContainerService.page$.subscribe((pageConfig: PageConfig) => {
      // Get values from resource bundle
      this.outflowService = this.translateService.instant(
        'ftiLayouts.outflow-service'
      );
      this.outflowCarousalHeader = this.translateService.instant(
        'ftiLayouts.outflow-carousal-header'
      );
      this.arrLayout.push(this.outflowService, this.outflowCarousalHeader);

      this.page = pageConfig.page;
      this.mapping = pageConfig.mapping;
      this.configuration = pageConfig.configuration;
      this.layout = this.page.getComponent().getParameters().layout;
      if (this.arrLayout.indexOf(this.layout) !== -1) {
        this.isLayoutVisible = false;
      }
      if (this.location.path().indexOf('funds-and-solutions/tools') > 0) {
        this.isToolsPage = true;
      }
      if (this.location.path().indexOf('-calculator') > 0) {
        this.calculatorPage = true;
      }
      if (this.layout == 'full-width' || this.layout == 'pagenotfound' || this.location.path().indexOf('/meri-pyaari-saheliyan') > 0 || this.location.path().indexOf('/feedback') > 0 || this.location.path().indexOf('/investment-glossary') > 0) {
        this.bgImage = true;
      }
      if (this.location.path().includes('/about-us/')) {
        this.isAboutPage = true;
      }
      if (this.location.path().includes('/locate-us')) {
        this.isLocateus = true;
      }
      this.isFundPages =
        this.layout === 'onecol' ||
        this.layout === 'twocol-leftwide';
      this.tabCount = this.page.getComponent().getParameters().tabCount;
      this.setMetaData(this.page);
    });
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Flash Messages
        this.isFlashMessageExist = false;
        this.current = location.path().indexOf('current') > 0;
        //Router Call
        this.reachForBetterInLifePage =
          location.path().indexOf('reachforbetter') > 0;
        this.atft = location.path().indexOf('atft') > 0;
        this.life =
          location.path().indexOf('reachforbetter/life') > 0;
        this.volatility =
          location.path().indexOf('sipislikeagoodemi/market-volatility') > 0;
        this.emi = this.volatility;
        this.current = location.path().indexOf('current') > 0;
        this.campaigns =
          location.path().indexOf('campaigns') > 0;
        this.isBetterLifeVisible =
          this.reachForBetterInLifePage ||
          this.atft ||
          this.life ||
          this.current ||
          this.campaigns;
        this.isMarathonPage = this.location.path().includes('marathon');
        this.pageContainerService.newPage(location.path());
        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
      });
    // To change the document referrer first time when you land and navigate inside the site
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe((event) => {
        this.analyticsService.trackEvent({
          event: 'spa_pageview',
          pagePath: window.location.origin + this.router.url,
        });
        if (!document.referrer.includes(window.location.origin)) {
          Object.defineProperty(document, 'referrer', {
            get() {
              return window.location.origin;
            },
          });
        }
      });
    this.isMobileView = window.innerWidth < 768;
    // Show toast message for comparison when an update is received.
    // This message will be either for the limit reached or already added fund for comparison.
    // Also, it will only work when compare button is clicked in fund header section i.e., fund detail page load.

  }

  /**
   * Returns clicked Anchor element
   * @param event - click event
   */
  private checkLink(event: MouseEvent): EventTarget {
    if (
      event.target instanceof HTMLAnchorElement ||
      event.target instanceof HTMLButtonElement ||
      event.target instanceof HTMLLIElement
    ) {
      return event.target;
    }
    // Try to find anchor element in event patch
    const anchorEl = event
      .composedPath()
      .find((el) => el instanceof HTMLAnchorElement);
    if (anchorEl) {
      return anchorEl;
    }
    // Try to find button element in event patch
    const buttonEl = event
      .composedPath()
      .find((el) => el instanceof HTMLButtonElement);
    if (buttonEl) {
      return buttonEl;
    }
    const listEl = event
      .composedPath()
      .find(
        (el) =>
          el instanceof HTMLLIElement || el instanceof HTMLParagraphElement
      );
    if (listEl) {
      return listEl;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const trg: any = event.target;
    if (
      trg?.classList.contains('copy-clipboard') ||
      trg?.classList.contains('fti-date-picker') ||
      trg?.classList.contains('date-field') // TODO: Make independent directive
    ) {
      this.ref.detach();
      const parent = trg?.parentElement;
      if (parent !== undefined) {
        const child = parent.querySelector('.copy-to-clipboard');
        if (child?.classList.contains('show')) {
          child?.classList.remove('show');
        } else {
          const elems = document.querySelectorAll('.copy-to-clipboard');
          [].forEach.call(elems, (el) => {
            el.classList.remove('show');
          });
          child?.classList.add('show');
        }
      }
      setTimeout(() => {
        this.ref.reattach();
      }, 100);
      return;
    } else {
      this.ref.detach();
      const elems = document.querySelectorAll('.copy-to-clipboard');
      [].forEach.call(elems, (el) => {
        el.classList.remove('show');
      });
      setTimeout(() => {
        this.ref.reattach();
      }, 100);
    }
    if (!trg.closest('.siteSearch-form')) {
      this.ref.detach();
      const elems = document.querySelectorAll('.search-auto-suggestion-list');
      [].forEach.call(elems, (el) => {
        el.classList.add('d-none');
      });
      setTimeout(() => {
        this.ref.reattach();
      }, 100);
    } else {
      this.ref.detach();
      const elems = document.querySelectorAll('.search-auto-suggestion-list');
      [].forEach.call(elems, (el) => {
        el.classList.remove('d-none');
      });
      setTimeout(() => {
        this.ref.reattach();
      }, 100);
    }
    const target: EventTarget = this.checkLink(event);
    if (target instanceof HTMLAnchorElement) {
      const classList: DOMTokenList = target.classList;
      const linkText: string = target.textContent;
      const routerUrl = this.router.url;
      if (classList.contains('top-navigation')) {
        this.analyticsService.trackEvent({
          event: 'Event All pages',
          category: 'Top Navigation',
          action: linkText,
          label: 'click',
        });
      } else if (classList.contains('logo-click')) {
        this.analyticsService.trackEvent({
          event: 'Event All pages',
          category: 'Top Navigation',
          action: 'Logo Click',
          label: window.location.origin,
        });
      } else if (
        classList.contains('top-sub-navigation') ||
        classList.contains('main-sub-navigation')
      ) {
        const mainCategory = target
          .closest('.dropdown')
          .querySelector('.top-navigation').textContent;
        this.analyticsService.trackEvent({
          event: 'Event All pages',
          category: 'Top Navigation',
          action: mainCategory,
          label: linkText || 'click',
        });
      } else if (
        classList.contains('nav-link') &&
        routerUrl.includes('why-franklin-templeton')
      ) {
        this.analyticsService.trackEvent({
          event: 'Event Distributor Zone',
          category: 'Distributor Zone',
          action: linkText,
          label: 'Tabclick',
        });
      } else if (
        classList.contains('distributor-ftacademy-link') ||
        classList.contains('distributor-android-link') ||
        classList.contains('distributor-ios-link') ||
        classList.contains('distributor-video-link')
      ) {
        let label = '';
        if (classList.contains('distributor-ftacademy-link')) {
          label = FT_CLICK_LABELS.FTAcademyLinkClick;
        } else if (classList.contains('distributor-android-link')) {
          label = FT_CLICK_LABELS.FTAcademyAndroidLinkClick;
        } else if (classList.contains('distributor-ios-link')) {
          label = FT_CLICK_LABELS.FTAcademyIosLinkClick;
        } else if (classList.contains('distributor-video-link')) {
          label = FT_CLICK_LABELS.FTAcademyVideoLinkClick;
        }
        this.analyticsService.trackEvent({
          event: 'Event Distributor Zone',
          category: 'Distributor Zone',
          action: 'FT Academy',
          label,
        });
      } else if (classList.contains('analytics-link')) {
        this.processAnalyticsLink(target);
      } else if (
        (classList.contains('analytics-quick-link') ||
          classList.contains('analytics-carousel-link') ||
          classList.contains('analytics-tab-link')) &&
        routerUrl === '/'
      ) {
        const dataset = target.dataset;
        const child: any = target.children[0];
        this.analyticsService.trackEvent({
          event: 'Event Homepage',
          category: 'Homepage',
          action: dataset.action,
          label: linkText || (child?.tagName === 'IMG' ? child?.alt : ''),
        });
      }
    } else if (
      target instanceof HTMLLIElement ||
      target instanceof HTMLParagraphElement ||
      target instanceof HTMLButtonElement
    ) {
      const classList: DOMTokenList = target.classList;
      if (classList.contains('analytics-link')) {
        this.processAnalyticsLink(target);
      }
    }
  }

  private setMetaData(page: Page): void {
    const metadata = page.getComponent().getModels().pageData;
    if (metadata) {
      if (metadata.title) {
        this.title.setTitle(
          metadata.title +
          (metadata.appendedTitleText
            ? ' | ' + metadata.appendedTitleText
            : '')
        );
      }

      this.setCanonical(metadata.canonicalUrl);
    }
  }

  private setCanonical(url: string): void {
    const value = url ? url : this.windowRef.location.href;
    let link: HTMLLinkElement =
      this.documentRef.querySelector(`link[rel='canonical']`) || null;
    if (!link) {
      link = this.documentRef.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.documentRef.head.appendChild(link);
    }
    link.setAttribute('href', value);
  }

  private processAnalyticsLink(target: HTMLOrSVGElement) {
    const dataset = target.dataset;
    this.analyticsService.trackEvent({
      event: dataset.eventName,
      category: dataset.category,
      action: dataset.action,
      label: dataset.label,
    });
    if (dataset.url) {
      this.router.navigateByUrl(dataset.url);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
