import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { SlickCarouselComponent } from "ngx-slick-carousel";

const logger = Logger.getLogger('WhoWeAreSlickSlideComponent');

@Component({
  selector: 'ft-who-we-are-slick-slide',
  templateUrl: './who-we-are-slick-slide.component.html',
  styleUrls: ['./who-we-are-slick-slide.component.scss'],
})
export class WhoWeAreSlickSlideComponent {
  @Input() component!: BrComponent;
  @Input() page: Page;

  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 3.8,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 3.2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,

        settings: {
          arrows: false,
          slidesToShow: 2.2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      }
    ]
  };

  @ViewChild('slickModal')
  slickModal!: SlickCarouselComponent;

  @HostListener('window:resize', ['$event'])
  onWindowResize($event: any) {
    if (this.slickModal !== undefined) {
      if (window.innerWidth < 768) {
        if (this.slickModal.initialized) {
          this.slickModal.unslick();
        }
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    }
  }

  constructor() {
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    logger.debug("who we are slide:", this.document?.getData());
    return this.document?.getData();
  }

  get linkLists() {
    //console.log('linkLists:=====', this.content?.linkList)
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }
}
