import moment from 'moment';

/**
 * utils for creating new moment locales
 * @see https://momentjs.com/docs/#/i18n/
 */

/**
 * clone a moment locale
 * @param parentLocale (note parent is needed to be imported in)
 */
export function cloneMomentLocale(
  childLocale,
  parentLocale,
  overrideConfig?
): moment.Locale {
  const cloneConfig = {
    parentLocale,
  };
  if (overrideConfig) {
    Object.assign(cloneConfig, overrideConfig);
  }
  const clonedMomentLocale = moment.updateLocale(childLocale, cloneConfig);
  return clonedMomentLocale;
}

/**
 * update a existing moment locale
 */
export function updateMomentLocale(
  localeToUpdate: string,
  changeConfig
): moment.Locale {
  const updatedMomentLocale = moment.updateLocale(localeToUpdate, changeConfig);
  return updatedMomentLocale;
}
