import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ft-two-col-left-wide',
  templateUrl: './two-col-left-wide.component.html',
  styleUrls: ['./two-col-left-wide.component.scss'],
})
export class TwoColLeftWideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
