import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@services/common.service';
import { TranslateService } from '../translate/translate.service';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { FormService } from "@services/form.service";
import { Logger } from "@utils/logger";
import { NgForm, NgModel } from '@angular/forms';
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("EmailUsFormComponent");

@Component({
  selector: 'ft-email-us-form',
  templateUrl: './email-us-form.component.html',
  styleUrls: ['./email-us-form.component.scss']
})
export class EmailUsFormComponent implements OnInit {

  @Input() isSmall = false

  intrestedForm: FormGroup = new FormGroup({});
  name = '';
  email = '';
  pan = '';
  query = '';
  namePlaceholder: any;
  panPlaceholder: any;
  emailPlaceholder: any;
  queryPlaceholder: any;
  subscription: Subscription;
  distributerFormData = {
    refNo: '',
    mobileNo: '',
    emailId: '',
    guId: '',
  };
  filedDefaullt: boolean = false;
  queryList: any = [];
  successMsgInvest: string = '';
  submitSuccess: boolean = false;
  loading: boolean = false;
  panFormatError: boolean = false;

  //Recaptcha
  captchaResolved: boolean = false;
  captchaResponse: string;

  constructor(
    private sessionService: SessionService,
    private translateService: TranslateService,
    private formService: FormService,
    private commonService: CommonService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscription = this.commonService?.cityData?.subscribe(
      (citiesValues) => (this.queryList = citiesValues)
    );
    this.namePlaceholder = this.translateService.instant(
      'ftiGeneric.namePlaceholder'
    );
    this.panPlaceholder = this.translateService.instant(
      'ftiGeneric.panPlaceholder'
    );
    this.emailPlaceholder = this.translateService.instant(
      'ftiGeneric.emailPlaceholder'
    );
    this.queryPlaceholder = this.translateService.instant(
      'ftiGeneric.queryPlaceholder'
    );
  }

  initForm = () => {
    this.intrestedForm = new FormGroup({//NOSONAR
      name: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
      email: new FormControl("", [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),//NOSONAR
      pan: new FormControl(),
      //new FormControl("", [Validators.required, Validators.pattern(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)]),
      query: new FormControl("", Validators.required)
    });
  }

  control1Change() {
    const control1 = <FormControl>this.intrestedForm.get('pan');
    control1.setValidators(Validators.pattern(/^([A-Z a-z]){5}([0-9]){4}([A-Z a-z]){1}?$/));
    if (control1.value?.length === 1) {
      this.panFormatError = true;
    } else {
      this.panFormatError = false;
    }
    if (!control1.value) {
      control1.setValidators(null);
    }
  }

  checkboxCondition(event: any) {
    if (event.target.checked) {
      this.filedDefaullt = true;
    } else {
      this.filedDefaullt = false;
    }
  }

  submitInvestForm() {
    const communicationName = this.name = this.intrestedForm.value.name
    const communicationPanNumber = this.pan = this.intrestedForm.value.pan
    const communicationEmail = this.email = this.intrestedForm.value.email
    const query = this.query = this.intrestedForm.value.query
    const isValidEmail = this.commonService.isValidEmail(communicationEmail);
    const phoneErr = document.querySelector<HTMLElement>(
      '.communicationPhoneNumber-error'
    );
    const emailErr = document.querySelector<HTMLElement>(
      '.communicationEmail-error'
    );
    if (!isValidEmail) {
      emailErr.style.display = 'block';
    }
    if (isValidEmail) {
      this.submit();
    }
    grecaptcha.reset();
    this.captchaResolved = false;
  }

  submit() {
    this.loading = true;
    const payload = {
      name: this.name,
      panNumber: this.pan ? this.pan.toUpperCase() : "",
      emailId: this.email,
      query: this.query
    };
    this.sessionService.postYourQuery(payload).subscribe(
      (response) => {
        if (response) {
          this.loading = false;
          const successMsg = this.translateService.instant(
            'ftiGeneric.customLinkText'
          );
          this.analyticsService.trackEvent({
            event: 'All Pages',
            category: 'Customer Service',
            action: 'Email Us',
            label: 'Query Submit',
          });
          this.submitSuccess = true;
          this.successMsgInvest = successMsg
          this.intrestedForm.reset();
        }
      },
      (error) => {
        this.loading = false;
        if (error && error.error[0]) {
          const otpErr = document.querySelector<HTMLElement>(
            '.email_OTP-error'
          );
          otpErr.style.display = 'block';
          document.getElementsByClassName(
            'email_OTP-error'
          )[0].innerHTML = error.error[0].errorDescription;
          logger.error("Email Us form submission failure" + error.error[0]);
        }
      }
    );
  }

  public onPaste(event): void {
    this.formService.onPasteForms(event);
  }

  public onKeyUp($event: any, fieldName: string): void {
    let enteredCharacter = $event.target.value;
    enteredCharacter = enteredCharacter.replace(/[\/\\$'"<>]/g, '');
    this.intrestedForm.controls[fieldName].setValue(enteredCharacter);
  }

  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
    this.captchaResolved = !this.captchaResolved; // Enable the submit button when reCAPTCHA is resolved
  }

}
