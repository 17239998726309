import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('MediaService');

@Injectable({
  providedIn: 'root'
})
export class MediaService {


  constructor() { }

  addToWatchList(documentId: string): void {
    const watchList = this.getWatchList();
    
    // Add the documentId to the watch list if it's not already present
    if (!watchList.includes(documentId)) {
      watchList.push(documentId);
      // Update the local storage with the modified watch list
      localStorage.setItem('media-watch-list', JSON.stringify(watchList));
    }
  }
  
  removeFromWatchList(documentId: string): void {
    const watchList = this.getWatchList();

    // Remove the documentId from the watch list if it's present
    const index = watchList.indexOf(documentId);
    if (index !== -1) {
      watchList.splice(index, 1);
      // Update the local storage with the modified watch list
      localStorage.setItem('media-watch-list', JSON.stringify(watchList));
    }
  }

  // Other methods

  getWatchList(): string[] {
    const watchListString = localStorage.getItem('media-watch-list');
    return watchListString ? JSON.parse(watchListString) : [];
  }

}
