import { Component, Input, OnInit, ViewEncapsulation, } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-fund-caveat',
  templateUrl: './fund-caveat.component.html',
  styleUrls: ['./fund-caveat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FundCaveatComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor() {
  }

  ngOnInit(): void {
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    return this.content?.contentBlock || [];
  }

  get isPreview() {
    return this.page?.isPreview();
  }
}
