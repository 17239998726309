import { Component } from '@angular/core';

@Component({
  selector: 'ft-speak-to-us-data',
  templateUrl: './speak-to-us-data.component.html',
  styleUrls: ['./speak-to-us-data.component.scss']
})
export class SpeakToUsDataComponent {

}
