<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
    *ngIf="page?.isPreview()"
    [brManageContentButton]="document"
    documentTemplateQuery="new-ftindia-document"
    folderTemplateQuery="new-ftindia-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <section class="FT-distributor-testimonials">
    <div class="container">
      <div class="FT-title text-black mb-5">{{title}}</div>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem class="slide" *ngFor="let list of linkLists">
          <div class="testimonial-box">
            <div class="row">
              <div class="col-md-auto col-12">
                <img
                  src="{{ getImageURL(list?.widenAssetCompound?.widenDocument) }}"
                  class="box-img"
                />
              </div>
              <div class="col" [innerHTML]="list?.content?.content?.value">
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </section>
</div>
