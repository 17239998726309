<div class="dropdown-menu show">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active fontResize" id="home-tab" data-bs-toggle="tab"
                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                aria-selected="true">{{ "ftiKc.open-lear-tab-text1" | translate }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link fontResize" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{{ "ftiKc.open-lear-tab-text2" | translate }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link fontResize" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">{{ "ftiKc.open-lear-tab-text3" | translate }}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <!---for open learniing-->
        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
            tabindex="0">
            <div class="mb-4">
                <h3 class="fontResize">{{ "ftiKc.categories-text" | translate }}</h3>

                <!-- frankly.component.html -->
                <div>
                    <div class="form-check custom-check" *ngFor="let checkbox of checkboxes">
                        <input class="form-check-input" type="checkbox" [routerLink]="checkbox.link" [id]="checkbox.id"
                            (click)="toggleCheckbox(checkbox)" [checked]="checkbox.isChecked">
                        <label class="form-check-label fontResize" [for]="checkbox.id">{{ checkbox.label }}</label>
                    </div>
                </div>
            </div>
        </div>

        <!----for franklin templton academy-->
        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="mb-4">
                <h3 class="fontResize">{{ "ftiKc.categories-text" | translate }}</h3>

                <div>
                    <div class="form-check custom-check" *ngFor="let checkbox of checkboxeFranklin">
                        <input class="form-check-input" type="checkbox" [id]="checkbox.id"
                            (click)="toggleFranklinCheckbox(checkbox)" [checked]="checkbox.isChecked">
                        <label class="form-check-label fontResize" [for]="checkbox.id">{{ checkbox.label }}</label>
                    </div>
                </div>

            </div>
        </div>

        <!---for market insight-->
        <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <div class="mb-4">
                <h3 class="fontResize">{{ "ftiKc.categories-text" | translate }}</h3>

                <div>
                    <div class="form-check custom-check" *ngFor="let checkbox of checkboxeMarket">
                        <input class="form-check-input" type="checkbox" [routerLink]="checkbox.link" [id]="checkbox.id"
                            (click)="toggleMarketCheckbox(checkbox)" [checked]="checkbox.isChecked">
                        <label class="form-check-label fontResize" [for]="checkbox.id">{{ checkbox.label }}</label>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>