import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostListener, Inject, AfterViewInit, ChangeDetectorRef,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { AnalyticsService } from '@services/analytics.service';
import { AppStateService } from '@services/app-state.service';
import { TranslateService } from '../translate/translate.service';
import { last } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getModelFromRef, getMenus } from '@utils/pagemodel-utils';
import { loadScript } from "@utils/load-script";
import { DOCUMENT, Location } from "@angular/common";
import { CommonService } from "@services/common.service";

export interface DataWindow extends Window {
  dataLayer: any[];
}

@Component({
  selector: 'ft-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  private unsubscribe$: Subject<void> = new Subject<void>();
  menu: any;
  fullYear = new Date().getFullYear();
  showContactBox = false;
  showContactBoxInner = true;
  isMobileView: boolean = true;
  carrersMenuGrouped = [];
  sideWidgetPHNumber2: string = '';
  segmentSelected: string;
  whatsAppNum: string = '';

  constructor(
    private analyticsService: AnalyticsService,
    public appStateService: AppStateService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.appStateService = appStateService;
  }

  ngOnInit(): void {
    this.initMenu();
    this.getCarrersMenu();
    this.isMobileView = window.innerWidth < 700;
    this.sideWidgetPHNumber2 = this.translateService.instant('ftiGeneric.sidewidget-call-us-ph-number2');
    this.whatsAppNum = this.translateService.instant('ftiGeneric.investors-WhatsApp-number');


    /**
     * To Render Segment specific menus.
     */
    const isDistributor = this.location.path().includes('/distributor');
    const segmentSelected = localStorage.getItem("current-segment");
    let isInvHome = this.commonService.isHome(this.page?.getUrl());
    if (!isInvHome && (isDistributor || segmentSelected)) {
      this.segmentSelected = 'Distributor';
      this.whatsAppNum = this.translateService.instant('ftiGeneric.distributors-WhatsApp-number');
    } else {
      this.segmentSelected = '';
    }
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    const current = new Date();
    const timestamp = current.getTime();
    loadScript(this.document.body, {
      //src: `/assets/js/communicationInit.js` + '?' + timestamp,
      async: '',
      src: `/assets/js/communicationInit.js` + '?07022024',
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    this.isMobileView = window.innerWidth < 700;
  }

  toggleShowContactBox = () => {
    this.showContactBox = !this.showContactBox;
  };
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  get menuModel() {
    return getModelFromRef(this.page, this.component, 'menu');
  }

  private initMenu() {
    this.menu = getMenus(this.page, this.component);
  }

  chunkArray(myArray, chunk_size) {
    var results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  getCarrersMenu() {
    const menuval = getMenus(this.page, this.component)?.siteMenuItems[1] // this.component.getModels<MenuModels>()?.menu
      .childMenuItems;
    //console.log(menuval.length);
    if (menuval && menuval.length < 14) {
      this.carrersMenuGrouped = this.chunkArray(menuval, 3);
    } else if (menuval && menuval.length > 13) {
      this.carrersMenuGrouped = this.chunkArray(menuval, 4);
    }
  }

  /**
   * Sets Analytics data on click of footer events
   */
  setFooterAnalytics(
    noSecondLevel?: boolean,
    topCategory?: any,
    category?: any,
    subCategory?: any
  ): boolean {
    const analyticsObj = {
      event: 'Event All pages',
      category: 'Footer',
      action: noSecondLevel
        ? topCategory?.name
        : category
          ? category?.name
          : 'click',
      label: subCategory?.name,
    };
    this.analyticsService.trackEvent(analyticsObj);
    return true;
  }

  setCareersAnalytics(category, subCategory) {
    const analyticsObj = {
      event: 'Event All pages',
      category: 'Footer',
      action: category?.name,
      label: subCategory?.name,
    };
    this.analyticsService.trackEvent(analyticsObj);
  }

  setSocialMediaAnalytics(label) {
    this.analyticsService.trackEvent({
      event: 'Event All pages',
      category: 'Footer',
      action: 'Social Link clicks',
      label,
    });
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected readonly last = last;

  chatBotGA() {
    this.analyticsService.trackEvent({
      event: 'event chatbot',
      ft_event: "ft_chatbot_clicked",
      category: 'Chatbot',
      action: 'Open',
      label: 'Click',
    });
    this.sideWidgetGA('Chat with us');
  }

  sideWidgetGA(action: string) {
    this.analyticsService.trackEvent({
      event: 'Event All pages',
      category: 'Side Widget',
      action: action,
      label: 'click',
    });
  }

  whatsAppUs() {
    this.sideWidgetGA('WhatsApp us');
    let whatsAppLink= 'https://api.whatsapp.com/send/?phone=91'+this.whatsAppNum+'&text=Hi&type=phone_number&app_absent=0';
    window.open(whatsAppLink, '_blank');
  }
}
