import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import * as Highcharts from "highcharts";
import { TranslateService } from "@shared/translate/translate.service";
import { ChartService } from "../ind-calculators.service";
import { Subscription } from "rxjs";
import { Logger } from "@utils/logger";
import { WINDOW } from "@ng-web-apis/common";

const logger = Logger.getLogger("SipCalculatorComponent");
@Component({
  selector: "ft-sip-top-up-calculator",
  templateUrl: "./sip-top-up-calculator.component.html",
  styleUrls: ["./sip-top-up-calculator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SipTopUpCalculatorComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  formValue: any;
  isSIP = true;
  isTargeted = false;
  retrievedValue: any;
  isGraphView: boolean = true;
  label1: string;
  label2: string;
  private chartOptionsSubscription: Subscription;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {}

  ngOnInit(): void {
    // Initialize your form and selectedSipType if needed
    this.label1 = this.translateService.instant(
      "ftiCalc.calculator-invest-amount"
    );
    this.label2 = this.translateService.instant(
      "ftiCalc.calculator-invest-value"
    );

    this.chartOptionsSubscription = this.chartService
      .getChartOptionsObservable()
      .subscribe((chartOptions) => {
        this.renderChart(chartOptions);
      });
    this.chartService.initializeChartOptions({ series: [] });
  }

  // Set isGraphView
  setIsGraphView(v: any) {
    this.isGraphView = v;
  }

  showRiskOptions = false;
  showRecomendedFunds = false;
  toggleShowRiskOptions() {
    this.showRiskOptions = !this.showRiskOptions;
  }
  toggleShowRecomendedFunds() {
    this.showRecomendedFunds = !this.showRecomendedFunds;
  }

  // On Calculator params change
  OnRecalculate(data: any) {
   // console.log("data", data);
    const { labels, totalValueData, investedData } =
      this.chartService.processData(data);
    this.chartService.updateChart({
      xAxis: { categories: labels,
        title: {
        text: 'Investment Period',
        style: {
          color: '#3C425D',
          fontWeight: 'bold',
          fontSize : '14px',
          fontFamily : 'TTCommonsProRegular, sans-serif'
      }
      },
     },
      series: [
        {
          name: this.label1,
          data: investedData,
          type: "line",
        },
        {
          name: this.label2,
          data: totalValueData,
          type: "line",
        },
      ],
    });
  }

  private renderChart(chartOptions: Highcharts.Options): void {
    Highcharts.chart("highcharts-container-sip-topup", chartOptions);
  }

  ngOnDestroy(): void {
    if (this.chartOptionsSubscription) {
      this.chartOptionsSubscription.unsubscribe();
    }
  }
}
