<!-- start categories box -->
<div class="fd-tab-sections insights" [ngClass]="contentType === 'Homepage View' ? 'knowledge-box' : 'categories-box' "
     id="insights">
  <!-- start ad box -->
  <div class="FT-ad-box" *ngIf="contentType === 'Homepage View'">
    <div class="row align-items-center justify-content-between">
      <div class="col-md col-12">
        <div class="FT-title-medium mb-2">
          {{ "ftiKc.market-insights-banner-title" | translate }}
        </div>
        <div class="text-big mb-lg-4 mb-3">
          {{ "ftiKc.market-insights-banner-desc" | translate }}
        </div>
        <ul>
          <li>
            <strong>{{
                    "ftiKc.market-insights-banner-value1" | translate
              }}</strong
            >{{ "ftiKc.market-insights-banner-text1" | translate }}
          </li>
          <li>
            <strong>{{
                    "ftiKc.market-insights-banner-value2" | translate
              }}</strong
            >{{ "ftiKc.market-insights-banner-text2" | translate }}
          </li>
          <li>
            <strong>{{
                    "ftiKc.market-insights-banner-value3" | translate
              }}</strong
            >{{ "ftiKc.market-insights-banner-text3" | translate }}
          </li>
        </ul>
      </div>
      <div class="col-md-auto col-12 mt-4 mt-md-0">
        <a [routerLink]="startLearningURL" class="btn btn-primary">{{
                "ftiKc.start-learning" | translate
          }}</a>
        <!-- "['/knowledge-center/market-insights']" -->
      </div>
    </div>
  </div>

  <div class="row" *ngIf="contentType === 'Homepage View'; else market_Insights_Blogs">
    <div class="col-md-6 col-12 mb-4 mb-md-0">
      <h3 class="fontResize">
        Blogs
      </h3>

      <div *ngFor="let blog of relateddata1; let i = index"
           class="category-card big-card mb-3 analytics-link"
           (click)="setAnalytics(blog?.title, blog?.data?._source?.documentType)">
        <div class="card-img" (click)="blogLinks(blog.target)" style="cursor: pointer">
          <img [src]="blog.image" alt="{{blog.title}}"/>
          <span class="time-tag fontResize">{{ blog.time }}</span>
        </div>
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <div class="blog-date fontResize">{{ blog.date }}</div>
          </div>
          <div class="text-bold card-title mt-4 fontResize">
            <a
                (click)="blogLinks(blog.target)" style="cursor: pointer"
            >{{ blog?.title }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Commentaries blog-->
    <div class="col-md-6 col-12">
      <h3 class="fontResize">
        Commentaries
        <a
            class="knowledge-center-CTA fontResize"
            [routerLink]="['/knowledge-centre/quick-learn/latest-commentaries']"
        >
          <span class="login-dark2">{{ "products.focus-card-view-all"| translate }}</span
          ><i class="bi bi-chevron-right"></i>
        </a>
      </h3>

      <div
          *ngFor="let commentary of getData; let i = index"
          class="category-card big-card mb-3 analytics-link"
          (click)="setAnalytics(commentary?.pageTitle, commentary?.data?._source?.documentType)"
      >
        <div class="card-img" (click)="categoryOnClick(commentary)" style="cursor: pointer">
          <img alt="Commentary" [src]="commentary.image"/>
        </div>
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <div class="blog-date fontResize">
              {{ getFormatedDate(commentary?.data?._source?.referenceDate) }}
            </div>
            <div class="card-icons" style="text-align: center">
              <span
                  class="img-with-tooltip me-3"
                  (click)="toggleIconForWatchlist(commentary)"
                  type="button"
              >
                <i [ngStyle]="{'display': !commentary?.isWatchListed ? 'inline' : 'none'}" class="bi bi-bookmark"></i>
                <i *ngIf="commentary?.isWatchListed" class="bi bi-bookmark-fill"></i>
                <span [ngStyle]="{'display': !commentary?.isWatchListed ? 'inline' : 'none'}"
                      class="tooltip-block">Save</span>
                <span *ngIf="commentary?.isWatchListed" class="tooltip-block">Saved Course</span>
              </span>

              <span class="dropdown">
                <span class="img-with-tooltip" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="assets/images/knowledge-center/share.svg"
                       (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
                       (click)="toggleTooltip()" class="d-inline-block mobile-icons light-mode-icon"
                       style="width: 18px" alt="Commentary"/>
                  <img src="assets/images/dark/share-dark-icon.png"
                       (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
                       (click)="toggleTooltip()" class="mobile-icons dark-mode-icon"
                       style="width: 21px; top: 5px; position: relative; left: 0" alt="Commentary"/>
                  <span *ngIf="showTooltip" class="tooltip-block fontResize">
                    share</span>
                </span>
                <ul class="dropdown-menu">
                  <li (click)="toggleShowCopyDialoge(true,commentary)">
                    <span class="dropdown-item fontResize" >
                      <img alt="Link" class="share-icon" src="assets/images/general/Link.webp"
                      /><span class="action-icon">{{
                            "products.home-fund-card-share-copy-btn" | translate
                      }}</span></span>
                  </li>
                  <li (click)="shareOnApp('TWITTER',commentary)">
                    <a class="dropdown-item fontResize">
                      <img alt="twitter" class="share-icon3"
                           src="assets/images/general/twitter-new-icon.webp"
                           style="width: 20px"/>
                      <span class="action-icon">{{
                              "products.home-fund-card-share-twitter-btn" | translate
                        }}</span></a>
                  </li>
                  <li (click)="shareOnApp('FACEBOOK',commentary)">
                    <a class="dropdown-item fontResize">
                      <img alt="facebook"
                           class="share-icon"
                           src="assets/images/general/FacebookLogo.webp"
                      /><span class="action-icon">{{
                            "products.home-fund-card-share-fb-btn" | translate
                      }}</span></a>
                  </li>
                  <li (click)="shareOnApp('LINKEDIN',commentary)">
                    <a class="dropdown-item fontResize">
                      <img alt="linkdin" class="share-icon"
                           src="assets/images/general/LinkedinLogo.webp"
                      /><span class="action-icon">{{
                            "products.home-fund-card-share-linkedin-btn" | translate
                      }}</span></a
                    >
                  </li>
                  <li (click)="shareOnApp('WHATAPP',commentary)">
                    <a class="dropdown-item fontResize">
                      <img alt="whatsapp" class="share-icon"
                           src="assets/images/general/whatsapps.webp"
                      /><span class="action-icon">{{
                            "products.home-fund-card-share-whatsapp-btn" | translate
                      }}</span></a
                    >
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div class="text-bold card-title mt-4 fontResize">
            {{ commentary?.data?._source?.title }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #market_Insights_Blogs>
    <ft-marketing-category></ft-marketing-category>
  </ng-template>
</div>

<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false,'')">
      <img alt="close" src="assets/images/general/close.webp">
    </span>
    <img alt="Tick" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>