import { Component, Input } from '@angular/core';
import { Logger } from '@utils/logger';
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";

const logger = Logger.getLogger("RelatedTagsComponent");

@Component({
  selector: 'ft-related-article',
  templateUrl: './related-article.component.html',
  styleUrls: ['./related-article.component.scss']
})
export class RelatedArticleComponent {
  @Input() page: Page;
  @Input() component!: BrComponent;

  constructor() {
  }

  get data() {
    return this.component?.getParameters();
  }

  get params() {
    return this.data;
  }

}
