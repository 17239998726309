<form [formGroup]="intrestedForm" (ngSubmit)="interestedSubmit()" *ngIf="!isThankYou">
  <div class="modal-header" *ngIf="!isSmall">
    <div class="text-center w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{ "ftiFeedback.interested-form-title" | translate }}</h5>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="row g-3 mt-3" [ngClass]="{'mt-3':!isSmall}">
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="text" class="form-control" formControlName="name" placeholder="{{namePlaceholder}} *">
      <div
          *ngIf="intrestedForm?.get('name')?.hasError('required') && !intrestedForm?.get('name')?.value && intrestedForm?.get('name')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.ValidateMsgName" | translate }}</span>
      </div>
      <div
          *ngIf="intrestedForm?.get('name')?.hasError('pattern') && !intrestedForm?.get('name')?.hasError('required') && intrestedForm?.get('name')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.form-name-validator" | translate }}</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="text" class="form-control" formControlName="email" placeholder="{{emailPlaceholder}} *">
      <div
          *ngIf="intrestedForm?.get('email')?.hasError('required') && !intrestedForm?.get('email')?.value && intrestedForm?.get('email')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.validateMsgEmail" | translate }}</span>
      </div>
      <div
          *ngIf="intrestedForm?.get('email')?.hasError('pattern') && !intrestedForm?.get('email')?.hasError('required') && intrestedForm?.get('email')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.labelEmailFormatErrMsg" | translate }}</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="text" (keydown)="blockAlphabetsAndSpecialChars($event)" class="form-control" formControlName="phone"
             placeholder="{{phonePlaceholder}}" maxlength="10">
      <div
          *ngIf="intrestedForm?.get('phone')?.hasError('pattern') && !intrestedForm?.get('phone')?.hasError('required') && intrestedForm?.get('phone')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.mobileValidationMsg" | translate }}</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <select class="form-select" aria-label="Query list" formControlName="query">
        <option value="" selected disabled>{{ "ftiGeneric.query-label" | translate }}</option>
        <option *ngFor="let city of citiesValues | slice: 1;" value="{{ city?.name}}">{{ city?.name }}</option>
      </select>
    </div>
    <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
      <input type="text" class="form-control" formControlName="riaCode" placeholder="ARN/RIA Code">
      <div
          *ngIf="intrestedForm?.get('riaCode')?.hasError('required') && !intrestedForm?.get('riaCode')?.value && intrestedForm?.get('riaCode')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.distIdRequiredErrorMsg" | translate }} </span>
      </div>
      <div
          *ngIf="intrestedForm?.get('riaCode')?.hasError('pattern') && !intrestedForm?.get('riaCode')?.hasError('required') && intrestedForm?.get('riaCode')?.touched">
        <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.distIdValidationMsg" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="form-check my-3">
    <input class="form-check-input" type="checkbox" value="" [checked]="accepted" (click)="arnNotRequired($event)"
           id="flexCheckDefault">
    {{ "ftiFeedback.labelARNCheckbox" | translate }}
  </div>
  <div class="form-group col-12 mb-3">
    <div *ngIf="intrestedForm?.get('comments')?.hasError('required') && intrestedForm?.get('comments')?.touched">
      <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-remarks" | translate }}</span>
    </div>
    <textarea class="form-control" id="interestedarea" formControlName="comments"  (input)="onInput($event, 'comments')"
              placeholder="{{queryPlaceholder}} *"></textarea>
    <div>
      <span style="color: #F04438;"
            class="error-msg">{{ "ftiFeedback.specialCharactersNotAllowed" | translate }}</span>
    </div>
  </div>
  <div class="row gx-3 callBack-footer">
    <div class="col-12 d-flex gap-3">
      <button type="button" class="btn btn-outline-primary" style="padding: 10px 36px 10px 36px"
              data-bs-dismiss="modal" (click)="close()">
              {{ "ftiFeedback.labelCancel" | translate }}
      </button>
      <button class="btn btn-primary" style="padding: 10px 36px 10px 36px"
              [disabled]="!intrestedForm.valid">
        <span *ngIf="isFormSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ "ftiFeedback.labelSubmit" | translate }}
      </button>
    </div>
  </div>
</form>

<div *ngIf="errorMessage">
  <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.errorHandlingResponse" | translate }}</span>
</div>
<div class="container row gx-3 text-center form-success-msg" *ngIf="isThankYou">
  <div class="row justify-content-end m-0" style="cursor:pointer;" (click)="clear()"><img
    src="assets/images/home/close-form.svg" style="height:3rem;width:3rem;"></div>
  <img alt="image" src="assets/images/general/check_circle.svg" style="height:3rem;" class="checked-img mt-4 mb-2">
  <span style="font-size:18px;"><strong>{{ "ftiFeedback.successMessage" | translate }}</strong></span>
</div>
