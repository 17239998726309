import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { initialize, Page } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { GlobalConfigService } from '@services/global-config-service';
import { SiteConfigService } from '@services/site-config.service';
import { CaveatCollection, FundId, FundShareClassId } from '@types';
import { Logger } from '@utils/logger';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const logger = Logger.getLogger('CaveatDataService');

@Injectable({
  providedIn: 'root',
})
export class CaveatDataService {
  constructor(
    private appState: AppStateService,
    private globalConfig: GlobalConfigService,
    private siteConfig: SiteConfigService
  ) {}

  private static mapAlert(
    alertMap: object = {},
    fund: FundId | FundShareClassId,
    alert,
    ppssMap: {}
  ) {
    if (alertMap[fund] === undefined) {
      alertMap[fund] = [alert];
    } else {
      alertMap[fund].push(alert);
    }
    if (alert.ppssMessage && !ppssMap[fund]) {
      ppssMap[fund] = alert;
    }
  }

  getData$(): Observable<CaveatCollection> {
    return this.globalConfig.getSubject$().pipe(
      switchMap(() => {
        return from(
          initialize({
            ...this.appState.getBrConfiguration(),
            path: '/caveats',
          }).then((page: Page) => {
            return page.getComponent().getModels().caveats as CaveatCollection;
          })
        );
      })
    );
  }
}
