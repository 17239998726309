import { Mapper, MapperParams } from './type.mapper';
import {
  ShareClassCode,
  FundShareClassId,
  CurrencyCode,
  ProductType,
  FundId,
  FundIdentifier,
  ShareClassDTO,
  WebProductTaxonomy,
} from '@types';
import { IdentifierKey, Identifiers, ShareClass } from '@models';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { IdentifiersMapper } from './identifiers.type.mapper';
import { ChargesMapper } from './charges.type.mapper';
import { PerformanceMapper } from './performance.type.mapper';
import { NavMapper } from './nav.type.mapper';
import { YieldsMapper } from './yields.type.mapper';
import { RatingsMapper } from './ratings.type.mapper';
import { BMPerformanceMapper } from './benchmark-performance.type.mapper';
import { DistributionsMapper } from './distribution.type.mapper';
import { RiskStatsSummariesMapper } from './risk-stats-summaries.type.mapper';
import { ExchangesMapper } from './exchanges.type.mapper';
import { NA, N_A, SINGLECLASS } from '../constants/product.constants';
import { SalesChargeBreakPointsMapper } from './sales-charge-break-points.type.mapper';
import { BMAssocListMapper } from './bmassoc.type.mapper';
import { RiskStatisticsMapper } from './risk-statistics.mapper';

export class ShareClassMapper extends Mapper<ShareClass> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    shareClassDto: ShareClassDTO,
    productType: ProductType,
    productTaxonomy: WebProductTaxonomy,
    fundCurrencyCode?: string,
    fundName?: string
  ): ShareClass {
    let shareClass: ShareClass;
    const fundId = shareClassDto.identifiers?.fundid as FundId;
    const shareClassCode = shareClassDto.identifiers
      ?.shclcode as ShareClassCode;
    const shareClassKey = (shareClassCode
      ? `${fundId}-${shareClassCode}`
      : fundId) as FundShareClassId;
    const currencyCode = this.mapperParams?.useFundCurrencyCode
      ? fundCurrencyCode
      : shareClassDto?.shclcurr;
    const defaultIdentifierKey: IdentifierKey = this.mapperParams.config.getDefaultIdentifierKey(
      productTaxonomy
    );
    const identifiers: Identifiers = shareClassDto.identifiers
      ? this.createMapper(IdentifiersMapper).toDomain(shareClassDto.identifiers)
      : null;
    const defaultIdentifier: FundIdentifier = defaultIdentifierKey
      ? (identifiers[defaultIdentifierKey] as FundIdentifier)
      : null;

    shareClass = {
      identifiers,
      shareClassName: shareClassDto.shclname,
      icradataservice: shareClassDto.icradataservice,
      primaryShareClass: shareClassDto.prmryshclind === 'Yes',
      inceptionDate: shareClassDto.incepdt,
      inceptionDateStd: shareClassDto.incepdtstd,
      performanceInceptionDate: shareClassDto.perfincdt,
      performanceInceptionDateStd: shareClassDto.perfincdtstd,
      minimumInvestment: shareClassDto.investmentminimum,
      additionalInvestment: shareClassDto.subseqinvestmentmin,
      charges: shareClassDto.charges
        ? this.createMapper(ChargesMapper).toDomain(shareClassDto.charges)
        : null,
      nav: shareClassDto.nav
        ? this.createMapper(NavMapper).toDomain(shareClassDto.nav)
        : undefined, // Intentionally made it 'undefined' for lodash merge to work effectively.
      performance: shareClassDto.performance
        ? this.createMapper(PerformanceMapper).toDomain(
            shareClassDto.performance
          )
        : null,
      benchmarkPerformance: shareClassDto.bmperformance
        ? this.createMapper(BMPerformanceMapper).toDomain(
            shareClassDto.bmperformance
          )
        : null,
      distribution: shareClassDto.distribution
        ? this.createMapper(DistributionsMapper).toDomain(
            shareClassDto.distribution
          )
        : undefined, // Intentionally made it 'undefined' for lodash merge to work effectively.
      shareClassCurrency: currencyCode as CurrencyCode,
      shareClassFeeStructure: shareClassDto.shclfeestructr as ShareClassCode,
      shareClassCode,
      yields: shareClassDto.yields
        ? this.createMapper(YieldsMapper).toDomain(shareClassDto.yields)
        : null,
      ratings: shareClassDto.ratings
        ? this.createMapper(RatingsMapper).toDomain(shareClassDto.ratings)
        : null,
      distributionIndicator: shareClassDto.distributionindicator
        ? shareClassDto.distributionindicator
        : null,
      dividendFrequency: shareClassDto.dividendfreq
        ? shareClassDto.dividendfreq
        : null,
      capgainFrequency: shareClassDto.capgainfreq
        ? shareClassDto.capgainfreq
        : null,
      riskFactor: shareClassDto.srrivalue ? shareClassDto.srrivalue : null,
      riskStatsSummary: shareClassDto.riskstatssummary
        ? this.createMapper(RiskStatsSummariesMapper).toDomain(
            shareClassDto.riskstatssummary
          )
        : null,
      riskStatistics: shareClassDto.riskstatistics
        ? this.createMapper(RiskStatisticsMapper).toDomain(
            shareClassDto.riskstatistics
          )
        : null,
      exchanges: shareClassDto.exchanges
        ? this.createMapper(ExchangesMapper).toDomain(shareClassDto.exchanges)
        : null,
      salesChargeBreakPoints: shareClassDto.saleschargebreakpoints
        ? this.createMapper(SalesChargeBreakPointsMapper).toDomain(
            shareClassDto.saleschargebreakpoints
          )
        : null,
      isSingleShareClass:
        shareClassDto.identifiers?.shclcode === SINGLECLASS ||
        shareClassDto.identifiers?.shclcode === NA ||
        // FIX - NGC-5781:
        // Added below shareClassName condition as well.
        // Since, code and name can be different in case, singlclass.
        shareClassDto.shclname?.toLocaleLowerCase() ===
          SINGLECLASS.toLocaleLowerCase() ||
        shareClassDto.shclname?.toLocaleLowerCase() === N_A.toLocaleLowerCase(),
      bmassoc: shareClassDto.bmassoc
        ? this.createMapper(BMAssocListMapper).toDomain(shareClassDto.bmassoc)
        : null,
    };

    return shareClass;
  }
}
