<!-- start section calculator -->
<section class="FT-calculator-box mb-6 mt-2">
  <div class="container">
    <h1 class="FT-title-medium text-blue mb-2 mt-2">{{ 'ftiCalc.calculator-stp-heading' | translate }}</h1>
    <div>
      {{ 'ftiCalc.calculator-stp-description' | translate }}
    </div>
    <div class="FT-financial">
      <!-- Tab panes -->
      <div class="tab-content calculator-box">
        <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab"
             tabindex="0">
          <div class="tab-content">
            <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
                 tabindex="0">
              <div class="row align-items-center">
                <!-- Section: Form -->
                <div class="col-lg-6 col-12">
                  <form [formGroup]="calcForm" *ngIf="calcForm">
                    <!-- Amount Source Fund -->
                    <div class="form-group">
                      <label id="iWantToInvestInSourceFund" class="form-label">
                        <span class="label-text">{{ "ftiCalc.calculator-stp-investment" | translate }}</span>
                        <div class="selected-range">
                          <span class="calc-input-prefix">₹</span>
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.sourceFund, false)"
                              [attr.max]="getInputLimit(sliderKeyName.sourceFund, true)"
                              [(ngModel)]="calcForm.value.sourceFund"
                              formControlName="sourceFund"
                              (change)="onCalculatorChange($event, 'sourceFund')"
                              class="calc-input amount text-start"
                              aria-labelledby="iWantToInvestInSourceFund">
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            [attr.min]="getInputLimit(sliderKeyName.sourceFund, false)"
                            [attr.max]="getInputLimit(sliderKeyName.sourceFund, true)"
                            [value]="calcForm.value.sourceFund || getInputLimit(sliderKeyName.sourceFund)"
                            formControlName="sourceFund"
                            (change)="onCalculatorChange($event, 'sourceFund')"
                            class="custom-range"
                            [style.background]="sourceFundSliderStyle"
                            aria-labelledby="iWantToInvestInSourceFund">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>₹{{ "ftiCalc.calculator-10k" | translate }}</span>
                        <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
                      </div>
                    </div>
                    <!-- Amount Target Fund -->
                    <div class="form-group">
                      <label id="iWantToTransferToDestinationFund" class="form-label">
                        <span class="label-text">
                          {{ "ftiCalc.calculator-transfer-amount" | translate }}
                        </span>
                        <div class="selected-range add-space-transfer">
                          <span class="calc-input-prefix">₹</span>
                          <input type="number" style="width: 100%;"
                                 [attr.min]="getInputLimit(sliderKeyName.targetFund, false)"
                                 [attr.max]="getInputLimit(sliderKeyName.targetFund, true)"
                                 [(ngModel)]="calcForm.value.targetFund"
                                 formControlName="targetFund"
                                 (change)="onCalculatorChange($event, 'targetFund')"
                                 class="calc-input targetFund text-start"
                                 aria-labelledby="iWantToTransferToDestinationFund">
                        </div>
                      </label>
                      <div class="invest-options">
                        <div class="form-check">
                          <input aria-labelledby="iWantToTransferMonthly" formControlName="time" value="MONTHLY" class="form-check-input" type="radio"
                                 name="time"
                                 id="Monthly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label id="iWantToTransferMonthly" class="form-check-label" for="Monthly">
                            {{ "ftiCalc.calculator-lumpsum-text4" | translate }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input aria-labelledby="iWantToTransferQuarterly" formControlName="time" value="QUARTERLY" class="form-check-input" type="radio"
                                 name="time"
                                 id="Quarterly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label id="iWantToTransferQuarterly" class="form-check-label" for="Quarterly">
                            {{ "ftiCalc.calculator-quarterly-text1" | translate }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input aria-labelledby="iWantToTransferWeekly" formControlName="time" value="WEEKLY" class="form-check-input" type="radio" name="time"
                                 id="Weekly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label id="iWantToTransferWeekly" class="form-check-label" for="Weekly">
                            {{ "ftiCalc.calculator-lumpsum-text3" | translate }}
                          </label>
                        </div>
                      </div>
                      <div class="range-slider">
                        <input
                            type="range"
                            [attr.min]="getInputLimit(sliderKeyName.targetFund, false)"
                            [attr.max]="getInputLimit(sliderKeyName.targetFund, true)"
                            [value]="calcForm.value.targetFund || getInputLimit(sliderKeyName.targetFund)"
                            formControlName="targetFund"
                            (change)="onCalculatorChange($event, 'targetFund')"
                            class="custom-range"
                            [style.background]="targetFundSliderStyle"
                            aria-labelledby="iWantToTransferToDestinationFund">

                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>₹{{ "ftiCalc.calculator-500" | translate }}</span>
                        <span>₹{{ "ftiCalc.calculator-10L" | translate }}</span>
                      </div>
                    </div>
                    <!-- Period -->
                    <div class="form-group ">
                      <label id="forAPeriodOf" class="form-label">
                        <span class="label-text">{{ 'ftiCalc.calculator-foryear' | translate }}</span>
                        <div class="selected-range" (click)="autoFocus($event, 'period')">
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.period, false)"
                              [attr.max]="getInputLimit(sliderKeyName.period, true)"
                              formControlName="period"
                              [(ngModel)]="calcForm.value.period"
                              (change)="onCalculatorChange($event, 'period')"
                              class="calc-input text-end forchangecustomcalculator" id="period"
                              aria-labelledby="forAPeriodOf">
                          <span class="calc-input-prefix"> {{ "ftiCalc.calculator-years" | translate }}</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            formControlName="period"
                            [value]="calcForm.value.period || getInputLimit(sliderKeyName.period)"
                            (change)="onCalculatorChange($event, 'period')"
                            class="custom-range"
                            [attr.min]="getInputLimit(sliderKeyName.period, false)"
                            [attr.max]="getInputLimit(sliderKeyName.period, true)"
                            [style.background]="periodSliderStyle"
                            aria-labelledby="forAPeriodOf">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.period, false) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                        <span>{{ getInputLimit(sliderKeyName.period, true) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                      </div>
                    </div>
                    <!-- Source ROR -->
                    <div class="form-group ">
                      <label id="RORFromSource" class="form-label">
                        <span class="label-text">{{ "ftiCalc.calculator-rate-of-return-source" | translate }}</span>
                        <div class="selected-range add-space-source" (click)="autoFocus($event, 'sourceROR')">
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.sourceROR, false)"
                              [attr.max]="getInputLimit(sliderKeyName.sourceROR, true)"
                              formControlName="sourceROR"
                              [(ngModel)]="calcForm.value.sourceROR"
                              (change)="onCalculatorChange($event, 'sourceROR')"
                              class="calc-input text-end forchangecustomcalculator" id="sourceROR"
                              aria-labelledby="RORFromSource">
                          <span class="calc-input-prefix"> %</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            formControlName="sourceROR"
                            [value]="calcForm.value.sourceROR || getInputLimit(sliderKeyName.sourceROR)"
                            (change)="onCalculatorChange($event, 'sourceROR')"
                            class="custom-range"
                            [attr.min]="getInputLimit(sliderKeyName.sourceROR, false)"
                            [attr.max]="getInputLimit(sliderKeyName.sourceROR, true)"
                            [style.background]="sourceRORSliderStyle"
                            aria-labelledby="RORFromSource"
                        >
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.sourceROR, false) }}%</span>
                        <span>{{ getInputLimit(sliderKeyName.sourceROR, true) }}%</span>
                      </div>
                    </div>
                    <!-- Target ROR -->
                    <div class="form-group ">
                      <label id="RORFromTarget" class="form-label">
                        <span
                            class="label-text">{{ "ftiCalc.calculator-rate-of-return-destination" | translate }}</span>
                        <!-- <div>
                            <span class="selected-range">{{sipCalcForm.value?.expectedReturn}}%</span>
                        </div> -->
                        <div class="selected-range add-space-destination" (click)="autoFocus($event, 'targetROR')">
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.targetROR, false)"
                              [attr.max]="getInputLimit(sliderKeyName.targetROR, true)"
                              formControlName="targetROR"
                              [(ngModel)]="calcForm.value.targetROR"
                              (change)="onCalculatorChange($event, 'targetROR')"
                              class="calc-input text-end forchangecustomcalculator" id="targetROR"
                              aria-labelledby="RORFromTarget">
                          <span class="calc-input-prefix"> %</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            formControlName="targetROR"
                            [value]="calcForm.value.targetROR || getInputLimit(sliderKeyName.targetROR)"
                            (change)="onCalculatorChange($event, 'targetROR')"
                            class="custom-range"
                            [attr.min]="getInputLimit(sliderKeyName.targetROR, false)"
                            [attr.max]="getInputLimit(sliderKeyName.targetROR, true)"
                            [style.background]="targetRORSliderStyle"
                            aria-labelledby="RORFromTarget"
                        >
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.targetROR, false) }}%</span>
                        <span>{{ getInputLimit(sliderKeyName.targetROR, true) }}%</span>
                      </div>
                    </div>
                  </form>
                  <div class="mb-2">
                    <a class="login-dark2" href="" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {{ 'ftiCalc.calculator-sip-disclaimer' |   translate }}</a>
                  </div>
                </div>
                <!-- Section: graph & table -->
                <div class="col-lg-6 col-12 ps-lg-4">
                  <div class="calculate-statics">
                    <div class="graph-table-btns">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="btn active" id="Graph-tab" data-bs-toggle="tab"
                                  data-bs-target="#Graph-tab-pane" type="button" role="tab"
                                  aria-controls="Graph-tab-pane"
                                  aria-selected="true"
                                  (click)="setIsGraphView(true)">
                            {{ 'ftiCalc.calculator-graph' | translate }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="btn" id="Table-tab" data-bs-toggle="tab"
                                  data-bs-target="#Table-tab-pane" type="button" role="tab"
                                  aria-controls="Table-tab-pane"
                                  aria-selected="false"
                                  (click)="setIsGraphView(false)">
                            {{ 'ftiCalc.calculator-table' | translate }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-content calculator-graph-box1" id="myTabContent2">
                      <div class="tab-pane fade show active" id="Graph-tab-pane" role="tabpanel"
                           aria-labelledby="Graph-tab" tabindex="0">
                        <div class="calculator-graph-box bar-graph">
                          <div class="">
                            <div class="graph-container">
                              <div id="highcharts-container-stp"></div>
                            </div>
                            <!-- <canvas baseChart class="chart" [data]="barChartData"
                                 [options]="barChartOptions" [type]="barChartType"
                                 (chartHover)="chartHovered($event)"
                                 (chartClick)="chartClicked($event)">
                             </canvas>-->
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Table-tab-pane" role="tabpanel"
                           aria-labelledby="Table-tab" tabindex="0">
                        <div class="table-responsive text-center">
                          <table class="table table-success table-striped stiky-head">
                            <thead>
                            <tr>
                              <th>{{ 'ftiCalc.calculator-end-of-year' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-balance-source-fund' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-amount-destination-fund' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-invest-value' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let val of calculatedData.eachYearData">
                              <td>{{ val.year }}</td>
                              <td>{{ val.sourceFund | INRCurrency }}</td>
                              <td>{{ val.destinationFund | INRCurrency }}</td>
                              <td>{{ (val.destinationFund + val.sourceFund) | INRCurrency }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="p-3 bg-light-blue">
                      <div class="numbers d-flex-column justify-content-center text-center mb-4">
                        <div class="px-3 column-number">
                          <div>{{ 'ftiCalc.calculator-invest-text3' | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds">
                              {{ calculatedData?.investedAmt | INRCurrency }}
                            </h3>
                          </ng-container>
                        </div>
                        <div class="px-3 column-number">
                          <div> {{ 'ftiCalc.calculator-balance-source-fund' | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds">
                              {{ calculatedData?.sourceFund | INRCurrency }}
                            </h3>
                          </ng-container>
                        </div>
                        <div class="px-3 column-number">
                          <div>{{ 'ftiCalc.calculator-amount-destination-fund' | translate }}</div>
                          <h3 class="sip-recommended-funds">
                            {{ calculatedData?.destinationFund | INRCurrency }}
                          </h3>
                        </div>
                        <!-- <div class="px-3 column-number">
                            <div class="text-bold mb-3">Potential returns from</div>
                            <div class="d-flex justify-content-center">
                                <div class="px-4">
                                    <div>Liquid Fund</div>
                                    <h3 class="sip-recommended-funds">
                                        {{calculatedData?.sourceFund | currency : "INR": 'symbol':'1.0-0'}}
                                    </h3>
                                </div>
                                <div class="px-4">
                                    <div>Equity Fund</div>
                                    <h3 class="sip-recommended-funds">
                                        {{calculatedData?.destinationFund | currency : "INR": 'symbol':'1.0-0'}}
                                    </h3>
                                </div>
                            </div>
                        </div> -->
                        <div class="px-3 column-number">
                          <div> {{ 'ftiCalc.calculator-invest-value' | translate }}</div>
                          <h3 class="sip-recommended-funds">
                            {{ calculatedData?.totalInvestmentValue | INRCurrency }}
                          </h3>
                        </div>
                      </div>
                      <button (click)="investNow('')" class="btn btn-primary recommende-btn">
                        {{ 'ftiCalc.calculator-invest-now' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-end">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          {{ 'ftiCalc.sip-calc-disclaimer-pop-up' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
