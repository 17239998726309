import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

const elementType = {
  html: 'html',
  style: 'style',
  script: 'script',
  url: 'url',
  resourceUrl: 'resourceUrl',
};

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}
  transform(
    value: string,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {// NOSONAR
      case elementType.html:
        return this._sanitizer?.bypassSecurityTrustHtml(value);// NOSONAR
      case elementType.style:
        return this._sanitizer?.bypassSecurityTrustStyle(value);// NOSONAR
      case elementType.script:
        return this._sanitizer?.bypassSecurityTrustScript(value);// NOSONAR
      case elementType.url:
        return this._sanitizer?.bypassSecurityTrustUrl(value);// NOSONAR
      case elementType.resourceUrl:
        return this._sanitizer?.bypassSecurityTrustResourceUrl(value);// NOSONAR
      default:
        return this._sanitizer?.bypassSecurityTrustHtml(value);// NOSONAR
    }
  }
}
