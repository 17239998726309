import { Nominal } from 'simplytyped';
import { FundId } from './fund-identifier.type';
import { ShareClassCode } from './share-class.type';

export enum ConfigurationId {
  GW = 'GW',
  ETF = 'ETFs',
  CEF = 'Closed-End Funds',
  MF = 'Mutual Funds',
  MMF = 'Money Market Funds',
  TIF = 'TIF',
  VIP = 'Variable Insurance',
  _529 = '529 Portfolios',
  SMA = 'Managed Accounts',
  US = 'US',
}

export interface ProductDetailConfiguration {
  componentType?: string; // TODO: define ProductComponentId type
  fundId: FundId;
  shareClassCode: ShareClassCode;
  location?: string;
  configurationName?: ConfigurationId;
  empId?: string;
}

// TODO: could be enums?
export type ConfigurationCountry = Nominal<string, 'ConfigurationCountry'>; // 2 digit country code e.g. 'LU'
export type ConfigurationLocale = Nominal<string, 'ConfigurationLocale'>; // locale code e.g. 'en_GB'
