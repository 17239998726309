<div *ngIf="document" [ngClass]="{ 'has-edit-button': page.isPreview() }">
  <div>
    <ng-container
      [brManageContentButton]="document"
      class="has-edit-button float-right"
      documentTemplateQuery="new-ContentBlocks-document"
      folderTemplateQuery="new-ContentBlocks-folder"
      parameter="document"
      [relative]="false"
    ></ng-container>
    <div class="html-page-container">
      <div class="reachForBetter articlePageContentHeader">
        <div class="iw_component" id="c1536236281325">
          <header class="header">
            <div class="header-top-bar u-bg-primary">
              <div class="container">
                <div class="row">
                  <div class="col-xs-12">
                    <img
                      alt="Franklin Templeton"
                      src="https://franklintempletonprod.widen.net/content/r407gnxxye/original/FTI_LArt_Alt_neg_0114.png"
                      style="padding-left: 25px"
                    />
                    <div class="header-lang-change">
                      <a
                        class="analytics-link btn btn-small"
                        data-action="Main Site Redirect"
                        data-category="Reach For              Better India"
                        data-event-name="RFBI"
                        data-label="Visit our Homepage"
                        href="/"
                        >Visit our homepage</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container d-none d-lg-block">
              <div class="row">
                <div class="col-xs-12">
                  <div class="header-logo">
                    <a
                      class="analytics-link"
                      data-action="Main Site Redirect"
                      data-category="Reach For Better  India"
                      data-event-name="RFBI"
                      data-label="Franklin Templeton"
                      onclick="location.href='/';"
                      ><img
                        alt="Franklin Templeton"
                        src="https://franklintempletonprod.widen.net/content/8nxtzkjgrf/jpeg/ft-logo-pos-0119.jpeg"
                        style="padding-top: 0px; margin-left: -30px"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="u-vertical-center timeline fix-nav" id="left-nav">
              <div class="wrapper">
                <ul class="container main-nav" id="mainNav">
                  <li
                    class="analytics-link nav-hero"
                    data-action="Life|Top Nav"
                    data-category="Reach  For Better India"
                    data-event-name="RFBI"
                    data-label="Home"
                    data-section=""
                    data-url="/reachforbetter"
                    name="nav-hero"
                  >
                    <span
                      ><a href="/reachforbetter/work" class="topMenu"
                        >Home</a
                      ></span
                    >
                  </li>
                  <li
                    class="analytics-link nav-haveagoal"
                    data-action="Life|Top Nav"
                    data-category="Reach  For Better India"
                    data-event-name="RFBI"
                    data-label="Have a Goal"
                    data-smooth-scroll=""
                    name="nav-haveagoal"
                  >
                    <span
                      ><a
                        href="/reachforbetter/work/#nav-luminary"
                        class="topMenu"
                        >Inspiration from Icons</a
                      ></span
                    >
                  </li>
                  <li
                    class="analytics-link nav-guidance"
                    data-action="Life|Top Nav"
                    data-category="Reach  For Better India"
                    data-event-name="RFBI"
                    data-label="Seek Guidance"
                    data-smooth-scroll=""
                    name="nav-guidance"
                  >
                    <span
                      ><a
                        href="/reachforbetter/work/#nav-videos"
                        class="topMenu"
                        >Career Boosters</a
                      ></span
                    >
                  </li>
                  <li
                    class="analytics-link nav-neverlate"
                    data-action="Life|Top Nav"
                    data-category="Reach  For Better India"
                    data-event-name="RFBI"
                    data-label="It's Never Too Late To Begin"
                    data-smooth-scroll=""
                    name="nav-neverlate"
                  >
                    <span
                      ><a
                        href="/reachforbetter/work/#nav-articles"
                        class="topMenu"
                        >Knowledge Hub</a
                      ></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <a
            class="back-to-top u-caps u-primary analytics-link fix-btt"
            data-action="All Pages"
            data-category="Reach For Better India"
            data-event-name="RFBI"
            data-label="Go To Top"
            data-scroll-to-top=""
            href="#"
            id="back-to-top"
            onclick="return false"
            ><i class="arrow-up">&nbsp;</i>TOP
          </a>

          <section
            class="us hero topBanner"
            id="hero"
            [style.backgroundImage]="'url(' + careerInnerArticleTopBanner + ')'"
          >
            <div>
              <video
                autoplay="autoplay"
                class="bg-video d-md-none d-lg-none d-none d-sm-none"
                height="150"
                loop="loop"
                muted="true"
                src="https://player.vimeo.com/external/269158208.hd.mp4?s=4dd83795847ac7d257df5c05d2c787cb412ee241&amp;profile_id=175"
                width="300"
              >
                &nbsp;
              </video>

              <div class="background-tint">&nbsp;</div>
            </div>

            <div class="container">
              <div class="row">
                <div
                  class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-8 col-lg-12 col-md-offset-2"
                >
                  <div class="hero-content">
                    <h1 class="h1 u-white u-caps">
                      {{ careerInnerArticleTopbannerTitle
                      }}<span class="trademark">TM</span>
                    </h1>

                    <p class="u-white topBannerContent">
                      {{ CareerIneerArticleTopBannerText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="content-section content-section-no-flex content-section-perspectives articleSectionContent"
            style="background-color: gray"
          >
            <div class="container">
              <div class="row">
                <div class="content-section-content">
                  <div class="col-md-12">
                    <div class="row commentary-india">
                      <div class="col-md-12">
                        <div id="load-article-content">
                          <div
                            class="panel panel-default"
                            id="c1561493256412-11"
                          >
                            <div class="panel-title">
                              <h1 class="header-commentary">
                                {{ content?.title }}
                              </h1>
                            </div>

                            <div class="panel-body commentary-profile">
                              <img
                                alt="content?.title"
                                class="insights-card__image"
                                [src]="
                                  getImageURL(
                                    content?.indwidenImage?.bannerImage
                                      ?.widenDocument
                                  )
                                "
                              />
                              <h2>{{ content?.title }}</h2>

                              <span
                                [innerHtml]="
                                  content?.contentBlocks[0]?.content?.content
                                    ?.value
                                "
                              >
                              </span>
                            </div>
                          </div>
                        </div>

                        <p class="articleDisclaimer">
                          Disclaimer: {{ careerInnerarticleDisclaimer }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <!-- <div
                      class="addthis_inline_share_toolbox"
                      style="margin-top: 10px; margin-left: -2px"
                    ></div> -->
                    <div class="social-share mt-3">
                      <!--<ft-social-media-icons></ft-social-media-icons>-->
                    </div>
                    <!--insert widget-->
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="modal" data-js-modal="">
            <!-- Modal content -->
            <div class="my-modal-content">
              <a class="fill-circle-close close" type="button"
                ><span aria-hidden="true" class="close" id="closeBtn"
                  >x</span
                ></a
              >

              <div
                class="modal-inner"
                style="
                  position: relative;
                  display: block;
                  max-width: 100%;
                  width: 6000px;
                "
              >
                <div class="modal-inner-2">
                  <div style="padding-top: 56.25%">
                    <iframe
                      allow="encrypted-media"
                      allowfullscreen="allowfullscreen"
                      class="pd-ifvid"
                      frameborder="0"
                      gesture="media"
                      height="150"
                      id="widget2"
                      src="https://www.youtube.com/embed/XKsP_xHzBuQ?rel=0&amp;enablejsapi=1"
                      style="
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                      "
                      width="300"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="iw_component" id="c1536236281326">
          <footer
            class="u-bg-primary clearfix"
            style="position: relative; z-index: 998"
          >
            <div class="container">
              <div class="row">
                <div class="footer-container">
                  <div class="u-white footer-copyright">
                    <p class="section">
                      {{ careerInnerArticleFooterTop }}
                    </p>

                    <p class="section">
                      <a href="/terms-and-conditions">TERMS &amp; CONDITIONS</a
                      >, <a href="/disclaimer">DISCLAIMER</a>,
                      <a href="/security-and-privacy">SECURITY &amp; PRIVACY</a
                      >,
                      <a href="/anti-corruption-policy"
                        >ANTI-CORRUPTION POLICY</a
                      >
                    </p>

                    <p class="section">
                      <br />
                      {{ careerInnerArticleFooterbottom }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</div>
