import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { WINDOW } from '@ng-web-apis/common';
import { AnalyticsService } from '@services/analytics.service';
import { WindowService } from '@services/window.service';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'ft-ind-glosarry',
  templateUrl: './ind-glosarry.component.html',
  styleUrls: ['./ind-glosarry.component.scss']
})
export class IndGlosarryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @ViewChild('searchBarInput') searchBarInput: ElementRef;
  @ViewChild('stickyHeader') stickyHeader: ElementRef;
  clickedIndex: any;
  glosarryData: any;
  alphabeticalData: any;
  term: string;
  isSearch = false;
  finalResults: any = [];
  noResultsFound = false;
  isAlphabetClick = false;
  isSearchItemClicked = false;
  filteredSearchResults: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  searchTerm: string = '';
  private analyticsTerms = new Subject<string>();
  private analyticsSubscription: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
    this.clickedIndex = 0;
    this.getData();
    this.analyticsSubscription = this.analyticsTerms.pipe(
      debounceTime(300), // wait 300ms after the last event before emitting last event
      distinctUntilChanged() // only emit if value is different from the previous value
    ).subscribe(term => {
      if (term.length >= 3) {
        this.setAnalytics(term);
      }
    });

  }

  ngAfterViewInit(): void {
    fromEvent<string>(this.searchBarInput?.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event;
        })
      )
      .subscribe((event) => {
        this.term = event.target.value;
        if (event.keyCode === 13) {
          this.isSearch = true;
          this.setAnalytics(this.term);
        } else {
          this.isSearch = false;
        }
        if (String(this.term).length >= 3 || this.term === '') {
          this.isSearchItemClicked = false;
          this.noResultsFound = false;
          this.finalResults = [];
          if (this.term) {
            this.updateData(this.term);
          } else {
            this.getData();
            this.cdRef.detectChanges();
          }
        }
      });
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  getData() {
    const documentData: any = this.document;
    this.glosarryData = documentData?.getData()?.glossaryCompound;
    this.alphabeticalData = this.glosarryData;
    return this.glosarryData;
  }

  setAnalytics(title) {
    this.analyticsService.trackEvent({
      event: 'Event InvestorEducation',
      category: 'InvestorEducation',
      action: 'Search',
      label: title,
    });
  }

  onSearchClick(event) {
    let searchTerm = event.target.value;
    this.trackSearchAnalytics(searchTerm);
    this.isSearch = true;
    this.isSearchItemClicked = false;
  }

  trackSearchAnalytics(searchTerm: string): void {
    this.analyticsTerms.next(searchTerm); // Emit the search term for analytics tracking
  }

  onSearchItemClick(result) {
    this.searchTerm = result?.title;
    this.isSearchItemClicked = true;
    this.filteredSearchResults = [];
    this.filteredSearchResults = [result];
    this.setAnalytics(result?.title);
    this.cdRef.detectChanges();
  }

  noItemSearch(term) {
    this.searchTerm = term;
    this.isSearchItemClicked = true;
    this.filteredSearchResults = [];
  }

  onAlphabetClick(e) {
    this.isAlphabetClick = true;
    this.isSearch = false;
    this.isSearchItemClicked = false;
    this.searchTerm = '';
    setTimeout(() => {
      if (e.target.textContent !== '' || e.target.textContent! == undefined) {
        if (e.target.textContent === " All ") {
          this.alphabeticalData = this.glosarryData;
        } else {
          const selectedDiv = this.glosarryData.find((element) => {
            return element?.alphabets === e.target.textContent;
          });
          this.alphabeticalData = [selectedDiv];
        }
        this.isAlphabetClick = false;
      }
    });
  }

  updateData(searchTerm) {
    this.finalResults = [];
    const data = JSON.parse(JSON.stringify(this.getData()));
    data.forEach((element) => {
      const filteredResults = element.glossaryContent.filter((innerEle) => {
        return innerEle?.title
          ?.toLowerCase()
          .includes(searchTerm.toString().toLowerCase());
      });
      if (filteredResults && filteredResults.length > 0) {
        filteredResults.forEach((ele) => {
          this.finalResults.push(ele);
        });
      }
    });
    if (!this.finalResults.length) {
      this.noResultsFound = true;
    }
    this.cdRef.detectChanges();
  }

  resetFundExplore() {
    this.isSearchItemClicked = false
    this.isSearch = false
    this.searchTerm = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.analyticsSubscription) {
      this.analyticsSubscription.unsubscribe();
    }
  }
}
