import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { LiteratureService } from '@services/literature.service';
import { Subscription, timer } from 'rxjs';
import { Logger } from '@utils/logger';
import { MediaArticle, MediaSortingOptions } from 'src/app/types/media-articles';
import { Constant } from 'src/app/ft-constants/constant';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Content } from '@bloomreach/spa-sdk';

const logger = Logger.getLogger('MediaReleasesComponent');

@Component({
  selector: 'ft-media-releases',
  templateUrl: './media-releases.component.html',
  styleUrls: ['./media-releases.component.scss']
})
export class MediaReleasesComponent implements OnInit, OnDestroy {
  @Input() config: Content;
  subscription: Subscription;
  isLoaded = false;
  isMobileView = false;
  processedData: MediaArticle[];
  releasesData: MediaArticle[];
  releasesDataSearch: MediaArticle[];
  yearsFilterDropdown: Array<any> = [];
  selectedYear: any;
  sortingOptions: MediaSortingOptions[] = [];
  selectedSortItem: MediaSortingOptions;
  tabTitle: string;
  tabDescription: string;
  tabType: string;

  searchTerm: string = '';
  showFundSearchDropdown: boolean = true;

  @HostListener('click', ['$event'])
  getTableDataPage(event: Event) {
    const clickedElement = event.target as Element;
    if (clickedElement.classList.contains('funds-seach-title')) {
      this.showFundSearchDropdown = false;
    } else if (clickedElement.classList.contains('form-control')) {
      this.showFundSearchDropdown = true;
    }
  }

  constructor(
    private litService: LiteratureService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
  ) {
  }


  ngOnInit(): void {
    this.tabTitle = this.config?.getData()?.mediaArticleTabs?.[1]?.tabTitle;
    this.tabType = this.config?.getData()?.mediaArticleTabs?.[1]?.articlesDataParam;
    this.tabDescription = this.config?.getData()?.mediaArticleTabs?.[1]?.descriptionPath;
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobileView = result.matches;
    });

    try {
      let sortingOptions = this.translateService.instant("products.media-sort-options");
      timer(0).subscribe(() => {
        this.getMediaSortOptions(sortingOptions)
      });
    } catch (error) {
      console.log("Error in parsing Json", error);
    }


    // Call service for article data
    let query = `?channel=en-in&type=${this.tabType}&cache=true`
    this.subscription = this.litService
      .getData$(query)
      ?.subscribe(
        (response) => {
          const rawData = response.document;
          // Set records in descending order
          this.processedData = orderBy(
            this.mapMediaData(rawData),
            ['date'],
            ['desc']
          );
          this.releasesData = this.processedData.filter(item => item.mediaType === this.tabType);
          this.yearsFilterDropdown = this.getYearsDropdown();
          this.selectedYear = this.yearsFilterDropdown[0];
          this.isLoaded = true;
          this.cdr.detectChanges();
        },
        (error) => {
          logger.debug(error);
          this.isLoaded = false;
        }
      );
  }

  getMediaSortOptions(categoryList) {

    this.sortingOptions = JSON.parse(categoryList);
    if (this.sortingOptions) {
      this.selectedSortItem = this.sortingOptions?.find(item => item.default) || this.sortingOptions[0];
    }
  }

  getYearsDropdown(): any[] {
    const years = uniqBy(this.releasesData, 'year').map(data => data.year);
    years.unshift('All');
    return years;
  }

  mapMediaData = (input): MediaArticle[] => {
    return input.map(
      (doc): MediaArticle => {
        // Extract file extension from literatureHref or default to 'NA'
        const fileType = doc.fileExtension || this.getFileExtension(doc.literatureHref) || 'NA';
        return {
          title: doc.dctermsTitle,
          documentId: doc.documentId,
          year: moment(doc.frkReferenceDate).year(),
          date: doc.frkReferenceDate,
          longDate: moment(doc.frkReferenceDate).format(Constant.dateFormat),
          shortDate: moment(doc.frkReferenceDate).format(Constant.latestDateFormat),
          link: doc.literatureHref,
          fileType: fileType,
          mediaType: doc.dctermsType
        };
      }
    );
  };

  getFileExtension = (url: string): string | undefined => {
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    }
    return undefined;
  };

  selectYear(year) {
    this.selectedYear = year;
    this.clearSearch();
    if (year !== 'All') {
      this.releasesData = this.processedData.filter(item => item.year === year && item.mediaType === this.tabType);
      this.sortMedia(this.selectedSortItem);
    } else {
      this.releasesData = this.processedData.filter(item => item.mediaType === this.tabType);
      this.sortMedia(this.selectedSortItem);
    }
  }

  openLink(article: MediaArticle) {
    const url = `download${article.link}`
    window.open(url, '_blank')
  }

  truncateText(input) {
    if (input.length > 50) {
      return input.substring(0, 50) + '...';
    }
    return input;
  }

  showSelectedArticle(article: MediaArticle) {
    this.searchTerm = article?.title;
    this.releasesData = [article];
    this.selectedYear = this.yearsFilterDropdown[0];
    this.showFundSearchDropdown = false;
  }

  searchArticles(searchTerm: string) {
    if (searchTerm && searchTerm.length >= 3) {
      this.releasesDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
      this.releasesDataSearch = this.releasesDataSearch.filter((article: any) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.releasesDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
    }
  }

  clearSearch() {
    this.searchTerm = '';
    this.releasesData = this.processedData.filter(item => item.mediaType === this.tabType);
    this.releasesDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
  }

  sortMedia(item: MediaSortingOptions) {
    this.selectedSortItem = item;
    this.sortByDate(item);
  }

  sortByDate(item: MediaSortingOptions): void {
    this.releasesData.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (item.key === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

