import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EnvConfigService } from '@services/env-config.service';
import { AppStateService } from '@services/app-state.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF } from '@angular/common';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { PageContainerComponent } from '@pages/page-container/page-container.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { SharedModule } from '@components/shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResourceApiHeadersInterceptor } from '@components/shared/utils/resource-api-headers-interceptor';
import { CommonComponent } from '@marketing/common/common.component';
import { TextContentComponent } from '@marketing/text-content/text-content.component';
import { IndCarouselComponent } from '@marketing/ind-carousel/ind-carousel.component';
import { CommonService } from '@services/common.service';
import { DisplayContentComponent } from '@components/marketing/display-content/display-content.component';
import { OnecolComponent } from '@pages/onecol/onecol.component';
import { TwoColLeftWideComponent } from '@pages/two-col-left-wide/two-col-left-wide.component';
import { BreadcrumbComponent } from '@shared/breadcrumb/breadcrumb.component';
import { FundCaveatComponent } from '@marketing/fund-caveat/fund-caveat.component';
import { FootnoteServiceToken } from './types/footnote-service.interface';
import { IndHighchartsThemeService } from '@components/shared/services/highchart/ind.highcharts-theme.service';
import {
  HighchartsThemeService
} from '@components/shared/services/highchart/highcharts-theme/highcharts-theme.service';
import { CaveatService } from '@components/products/caveats/services/caveat.service';
import { ProximalServiceToken } from './types/proximal-service.interface';
import { FundReportsComponent } from '@components/literature/fund-reports/fund-reports.component';
import { SafePipe } from '@shared/pipes/safe-content.pipe';
import { LocationsComponent } from '@marketing/locations/locations.component';
import { DigitizeWealthComponent } from '@components/marketing/digitize-wealth/digitize-wealth.component';
import { GoalsCalculatorComponent } from '@marketing/goals-calculator/goals-calculator.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RegulatoryUpdatesComponent } from '@marketing/regulatory-updates/regulatory-updates.component'
import { InvestNowFormComponent } from '@marketing/invest-now-form/invest-now-form.component';
import { DownloadAppComponent } from '@marketing/download-app/download-app.component';
import { AccordionComponent } from '@marketing/accordion/accordion.component';
import { LatestUpdatesComponent } from '@marketing/latest-updates/latest-updates.component';
import { InvestmentServicesComponent } from '@marketing/investment-services/investment-services.component';
import { HomePageBlogsComponent } from '@marketing/home-page-blogs/home-page-blogs.component';
import { CheckProgressComponent } from '@marketing/check-progress/check-progress.component';
import { RichTextComponent } from '@marketing/rich-text/rich-text.component';
import { SectionTitleComponent } from '@marketing/section-title/section-title.component';
import { AccordionTabComponent } from '@marketing/accordion-tab/accordion-tab.component';
import { IndCalculatorsComponent } from '@marketing/ind-calculators/ind-calculators.component';
import { SipCalculatorComponent } from '@marketing/ind-calculators/sip-calculator/sip-calculator.component';
import { LumpsumCalculatorComponent } from '@marketing/ind-calculators/lumpsum-calculator/lumpsum-calculator.component';
import { SipCalculatorFormComponent } from '@marketing/ind-calculators/sip-calculator/sip-calculator-form/sip-calculator-form.component';
import { LumpsumCalculatorFormComponent } from '@marketing/ind-calculators/lumpsum-calculator/lumpsum-calculator-form/lumpsum-calculator-form.component';
import { SolutionsCarouselComponent } from "@marketing/solutions-carousel/solutions-carousel.component";
import { MoreCalculatorsComponent } from "@marketing/more-calculators/more-calculators.component";
import { SipTopUpCalculatorComponent } from '@marketing/ind-calculators/sip-top-up-calculator/sip-top-up-calculator.component';
import { TopUpCalculatorFormComponent } from '@marketing/ind-calculators/sip-top-up-calculator/top-up-calculator-form/top-up-calculator-form.component';
import { StpCalculatorComponent } from '@marketing/ind-calculators/stp-calculator/stp-calculator.component';
import { ArticleDetailComponent } from '@marketing/article-detail/article-detail.component';
import { VideoArticlesComponent } from '@components/marketing/video-articles/video-articles.component';
import { ArticleListComponent } from '@marketing/article-list/article-list.component';
import { CagrCalculatorComponent } from '@marketing/ind-calculators/cagr-calculator/cagr-calculator.component';
import { AddressBookComponent } from '@marketing/address-book/address-book.component';
import { StickyTabsComponent } from "@marketing/sticky-tabs/sticky-tabs.component";

//search related
import { SwpCalculatorComponent } from '@marketing/ind-calculators/swp-calculator/swp-calculator.component';
import { RelatedTagsComponent } from '@marketing/article-detail/related-tags/related-tags.component';
import { LinkListComponent } from '@marketing/link-list/link-list.component';
import { MarketInsightsComponent } from '@marketing/market-insights/market-insights.component';
import { IndNgxSlidesComponent } from "@marketing/ind-ngx-slides/ind-ngx-slides.component";
import {
  WhoWeAreSlickSlideComponent
} from "@marketing/ind-ngx-slides/who-we-are-slick-slide/who-we-are-slick-slide.component";
import { CsrInitiativesComponent } from "@marketing/ind-ngx-slides/csr-initiatives/csr-initiatives.component";
import { IndGlosarryComponent } from '@marketing/ind-glosarry/ind-glosarry.component';
import { ReachforbetterWorkComponent } from "@marketing/reachforbetter-work/reachforbetter-work.component";
import {
  ReachforbetterarticlePageComponent
} from "@marketing/reachforbetterarticle-page/reachforbetterarticle-page.component";
import { DisplayListComponent } from "@marketing/display-list/display-list.component";
import { FeedbackComponent } from '@marketing/feedback/feedback.component';
import { DistributorOfferingsComponent } from '@marketing/distributor-offerings/distributor-offerings.component';
import { TestimonialsComponent } from '@marketing/ind-ngx-slides/testimonials/testimonials.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterServiceModule } from '@utils/toaster/toaster-service.module';
import { TestYourLearningComponent } from '@marketing/test-your-learning/test-your-learning.component';
import { CategoryComponent } from '@marketing/category/category.component';
import { KcLandingComponent } from '@marketing/kc-landing/kc-landing.component';
import { KnowledgeCenterModalComponent } from '@marketing/article-list/knowledge-center-modal/knowledge-center-modal.component';
import { MarketingCategoryComponent } from '@marketing/market-insights/marketing-category/marketing-category.component';
import { RelatedCategoryComponent } from '@marketing/article-detail/related-category/related-category.component';
import { RelatedArticleComponent } from "@marketing/ind-calculators/related-articles/related-article.component";
import { NpsComponent } from './ft-components/marketing/nps/nps.component';



const initConfig = (
  envConfig: EnvConfigService,
  appStateService: AppStateService
) => {
  return async () => {
    await envConfig.loadEnvConfig();
    appStateService.initializeLogging();
  };
};

// creates a brand specific version of highcharts service, suitable for the current channel
// Will need to update this function if EDS creates more brands in future
const highchartsThemeFactory = (): HighchartsThemeService => {
  return new IndHighchartsThemeService();
};

@NgModule({
  declarations: [
    AppComponent,
    PageContainerComponent,
    CommonComponent,
    DisplayContentComponent,
    TextContentComponent,
    IndCarouselComponent,
    OnecolComponent,
    TwoColLeftWideComponent,
    BreadcrumbComponent,
    SafePipe,
    DigitizeWealthComponent,
    GoalsCalculatorComponent,
    InvestmentServicesComponent,
    LocationsComponent,
    RegulatoryUpdatesComponent,
    LocationsComponent,
    DigitizeWealthComponent,
    InvestNowFormComponent,
    DownloadAppComponent,
    AccordionComponent,
    LatestUpdatesComponent,
    FundCaveatComponent,
    FundReportsComponent,
    HomePageBlogsComponent,
    CheckProgressComponent,
    RichTextComponent,
    SectionTitleComponent,
    AccordionTabComponent,
    IndCalculatorsComponent,
    SipCalculatorComponent,
    LumpsumCalculatorComponent,
    SipCalculatorFormComponent,
    LumpsumCalculatorFormComponent,
    SolutionsCarouselComponent,
    MoreCalculatorsComponent,
    SipTopUpCalculatorComponent,
    TopUpCalculatorFormComponent,
    StpCalculatorComponent,
    ArticleDetailComponent,
    VideoArticlesComponent,
    CategoryComponent,
    ArticleListComponent,
    KcLandingComponent,
    CagrCalculatorComponent,
    SwpCalculatorComponent,
    RelatedTagsComponent,
    AddressBookComponent,
    LinkListComponent,
    MarketInsightsComponent,
    StickyTabsComponent,
    IndNgxSlidesComponent,
    WhoWeAreSlickSlideComponent,
    CsrInitiativesComponent,
    IndGlosarryComponent,
    ReachforbetterWorkComponent,
    ReachforbetterarticlePageComponent,
    DisplayListComponent,
    FeedbackComponent,
    DistributorOfferingsComponent,
    TestimonialsComponent,
    TestYourLearningComponent,
    KnowledgeCenterModalComponent,
    MarketingCategoryComponent,
    RelatedCategoryComponent,
    RelatedArticleComponent,
    NpsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrSdkModule,
    PipesModule,
    NgbModule,
    SharedModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    ToasterServiceModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResourceApiHeadersInterceptor,
      multi: true,
    },
    EnvConfigService,
    AppStateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [EnvConfigService, AppStateService],
    },
    {
      provide: HighchartsThemeService,
      useFactory: highchartsThemeFactory,
      deps: [],
    },
    {provide: FootnoteServiceToken, useExisting: CaveatService},
    {provide: ProximalServiceToken, useExisting: CaveatService},
    CommonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
