<div class="d-flex filter-box align-items-center">
  <div class="text-big text-bold me-lg-5 me-3 fs-5 bold-tex"
       [id]="selectedCategoryData.isContent ? 'menu-name' : 'default-menu-name'"
       *ngIf="pageCategory!=='Reports'">{{ selectedFilter }}
  </div>
  <div class="text-big text-bold me-lg-5 me-3 fs-5 bold-tex" *ngIf="pageCategory==='Reports'">
    {{ selectedTab.label ?? ("literature." + selectedTab.id | translate) }}
  </div>
  <div class="filter-date" *ngIf="pageCategory !== 'Reports' && !(selectedCategoryData?.isContent)">
    <span class="text-small text-medium d-none d-md-inline-block">Filter by Date </span>
    <form class="d-flex report-date-fiilter">
      <div class="date-group bg-white report-date-group">
        <input aria-label="selectdate" class="form-control date-field fillter-date" type="text" placeholder="From"
               [(ngModel)]="fromDate" [ngModelOptions]="{standalone:true}"/>
        <ft-date-picker
            [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd" [convertToFormat]="dateFormatForComponent"
            (finalDate)="onFinalDate($event, 'fromDate')"
            [customLeftPosition]="'calc(0% - 80px)'"></ft-date-picker>
      </div>
      <div class="date-group mx-2 bg-white report-date-group">
        <input aria-label="selectdate" type="text" style="background: #fff;"
               class="form-control date-field fillter-date" placeholder="To"
               [(ngModel)]="toDate" [ngModelOptions]="{standalone: true}">
        <ft-date-picker
            [sentDateFormat]="'YYYY-MM-DD'" [displayDate]="currentDateStd" [convertToFormat]="dateFormatForComponent"
            (finalDate)="onFinalDate($event, 'toDate')"
            [customLeftPosition]="'calc(0% - 180px)'"></ft-date-picker>
      </div>
      <button class="btn btn-primary" (click)="applyFilter()">Apply</button>
    </form>
  </div>
</div>

<div class="category-btns" *ngIf="subCategoryList.length">
  <div class="scrollable-tabs" id="scroll" style="overflow-x: auto; white-space: nowrap;">
    <ul class="forms-dowld-scroll">
      <li *ngFor="let category of subCategoryList;" [class.active]="selectedCategory === category"
          (click)="selectSubCategory(category)">{{ category }}
      </li>
    </ul>
  </div>
</div>
<div class="accordion inner-accordion" id="accordionExample" *ngIf="
  (selectedFilter !== 'All' && pageCategory!=='Reports') || (pageCategory ==='Reports')"
     [style.padding-top.px]="subCategoryList.length ? 0 : 32">
  <div class="accordion-item">
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
         data-bs-parent="#accordionExample">
      <div id="scroll-bar" class="accordion-body main-content-acc-body">
        <!-- start categories block -->
        <div class="category-block">
          <div class="table-responsive reports-box">
            <table class="table table-success table-striped">
              <tbody>
              <ng-container *ngIf="!selectedCategoryData.isContent">
                <ng-container *ngIf="!!selectedCategoryData.length else noDocumentsMessage">
                  <tr *ngFor="let categoryData of selectedCategoryData;">
                    <td *ngIf="hasCustomLinkInput &&
                                      categoryData.id === 'Half-Yearly-Portfolio-Statement'
                                    "><a [href]="'literature.customLinkURL' | translate" target="_blank"
                                         rel="noopener" style="text-decoration: underline">
                      {{ "literature.customLinkText" | translate }}
                    </a></td>
                    <td *ngIf="hasCustomLinkInput"><a rel="noopener" [href]="'literature.customLinkURL' | translate"
                                                      target="_blank"
                                                      style="text-decoration: underline">
                      {{ "literature.customLinkText" | translate }}
                    </a></td>
                    <td><a [href]="getDocumentURL(categoryData)" [target]="isInternalLink ? '_self':'_blank'"
                           [attr.rel]="isInternalLink? null:'noopener'">{{ categoryData.dctermsTitle }}</a>
                    </td>
                    <td class="text-end">
                      <a [href]="getDocumentURL(categoryData)" [target]="isInternalLink ? '_self':'_blank'"
                         [attr.rel]="isInternalLink? null:'noopener'">
                        <ng-container *ngIf="fileExtension(categoryData) !== undefined">
                          <img *ngIf="categoryData.literatureHref" [src]="
                                                    '/assets/images/' +
                                                    fileExtension(categoryData) +
                                                    '.png'
                                                  " alt="icon"/>
                        </ng-container>
                        <span *ngIf="!categoryData.literatureHref">N/A</span>
                      </a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="selectedCategoryData.isContent">
                <div class="docContentContainer" [innerHTML]="selectedCategoryData.markup"></div>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="scroll-bar" [style.padding-top.px]="subCategoryList.length ? 0 : 32"
     *ngIf="pageCategory!=='Reports' && selectedFilter === 'All' && selectedCategoryData" class="main-content-acc-body">
  <ng-container *ngIf="selectedCategoryData.length > 0; else noDocumentsMessage">
    <ng-container *ngFor="let categoryData  of selectedCategoryData ; let i = index">
      <ng-container *ngIf="categoryData?.dataRecords?.linkdata?.length > 0 || categoryData.isContent">
        <div class="accordion inner-accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" [attr.id]="'literatureheading' + i">
              <button class="accordion-button tab-high" type="button"
                      [ngClass]="{collapsed:i  !== 0}" [attr.aria-expanded]="i === 0?'true':'false'"
                      data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#literature' + i" aria-expanded="false"
                      [attr.aria-controls]="'collapse' + i">
                {{ ("literature." + categoryData.id | translate) }}
              </button>
            </h2>
            <div [attr.id]="'literature' + i" class="accordion-collapse collapse"
                 [attr.aria-labelledby]="'literatureheading' + i"
                 data-bs-parent="#accordionExample" [ngClass]="{show: i === 0}">
              <div class="accordion-body">
                <!-- start categories block -->
                <div class="category-block">
                  <div class="table-responsive reports-box">
                    <table class="table table-success table-striped">
                      <tbody>
                      <ng-container *ngIf="!categoryData.isContent">
                        <ng-container *ngIf="categoryData?.dataRecords?.linkdata?.length > 0">
                          <tr *ngFor="let document of categoryData?.dataRecords?.linkdata">
                            <td><a [href]="getDocumentURL(document)" [target]="isInternalLink ? '_self':'_blank'"
                                   [attr.rel]="isInternalLink? null:'noopener'">{{ document.dctermsTitle }}</a>
                            </td>
                            <td class="text-end">
                              <a [href]="getDocumentURL(document)" [target]="isInternalLink ? '_self':'_blank'"
                                 [attr.rel]="isInternalLink? null:'noopener'">
                                <ng-container *ngIf="fileExtension(document) !== undefined">
                                  <img *ngIf="document.literatureHref" [src]="
                                                                      '/assets/images/' +
                                                                      fileExtension(document) +
                                                                      '.png'
                                                                    " alt="icon"/>
                                </ng-container>
                                <span *ngIf="!document.literatureHref">N/A</span>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="categoryData.isContent">
                        <div class="docContentContainer" [innerHTML]="categoryData.markup"></div>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!(categoryData?.dataRecords?.linkdata?.length > 0)" [ngTemplateOutlet]="secondLevelPanel"
                    [ngTemplateOutletContext]="{ secondLevelPanels: categoryData }"></ng-container>

    </ng-container>
  </ng-container>
</div>

<div class="button-container d-flex justify-content-center">
  <div [ngClass]="pageCategory==='Reports'?'report-detail':'arrowleft-img'" (click)="scrollToAccodianBottom()">
    <img alt="scrollToBottom" src="assets/images/fund/iconamoon_arrow-down-2.svg">
  </div>
</div>
<ng-template #secondLevelPanel let-secondLevelPanels="secondLevelPanels">
  <ng-container *ngFor="let fundDocument of secondLevelPanels.secondDropDown; let y = index">
    <div class="accordion inner-accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" [attr.id]="'heading' + y">
          <!--TODO: Had to hardcode to keep 1st accordion open for now, can be handled later-->
          <button class="accordion-button tab-high" type="button"
                  [ngClass]="{collapsed: !('literature.' + fundDocument.id | translate).includes('Fund Factsheets - 2024')}"
                  [attr.aria-expanded]="!('literature.' + fundDocument.id | translate).includes('Fund Factsheets - 2024')?'true':'false'"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#secondAccordion' + y" aria-expanded="false"
                  [attr.aria-controls]="'#secondAccordion' + y">
            {{ ("literature." + fundDocument.id | translate) }}
          </button>
        </h2>
        <div [attr.id]="'secondAccordion' + y" class="accordion-collapse collapse"
             [ngClass]="{show: ('literature.' + fundDocument.id | translate).includes('Fund Factsheets - 2024')}"
             [attr.aria-labelledby]="'heading' + y" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <!-- start categories block -->
            <div class="category-block">
              <div class="table-responsive reports-box">
                <table class="table table-success table-striped">
                  <tbody>
                  <ng-container *ngIf="fundDocument?.dataRecords?.linkdata?.length > 0">
                    <tr *ngFor="let document of fundDocument?.dataRecords?.linkdata">
                      <td><a [href]="getDocumentURL(document)" [target]="isInternalLink ? '_self':'_blank'"
                             [attr.rel]="isInternalLink? null:'noopener'">{{ document.dctermsTitle }}</a>
                      </td>
                      <td class="text-end">
                        <a [href]="getDocumentURL(document)" [target]="isInternalLink ? '_self':'_blank'"
                           [attr.rel]="isInternalLink? null:'noopener'">
                          <ng-container *ngIf="fileExtension(document) !== undefined">
                            <img *ngIf="document.literatureHref" [src]="
                                                                  '/assets/images/' +
                                                                  fileExtension(document) +
                                                                  '.png'
                                                                " alt="icon"/>
                          </ng-container>
                          <span *ngIf="!document.literatureHref">N/A</span>
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="
                    hasCustomLinkInput &&
                    fundDocument.id === 'Half-Yearly-Portfolio-Statement'">
                      <td><a [href]="'literature.customLinkURL' | translate" target="_blank"
                             rel="noopener"
                             style="text-decoration: underline">{{ "literature.customLinkText" | translate }}</a>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noDocumentsMessage>
  <div class="text-center pt-3">
    <p>{{ "products.no-data-distribution-historical-filter" | translate }}</p>
  </div>
</ng-template>
