import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger('DownloadAppComponent');

@Component({
  selector: 'ft-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  downloadAppImageDesktop: any;
  downloadAppImageMobile: any;
  appPlayStoreImage: any;
  appStoreImage: any;
  appStoreURL: any;
  playStoreURL: any;
  downloadText1: any;
  isMobileView: boolean = false;

  constructor(
    private translateService: TranslateService
  ) {
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize($event: any = {}) {
    this.isMobileView = window.innerWidth < 790;
  }

  ngOnInit(): void {
    this.isMobileView = window.innerWidth < 790;

    this.downloadAppImageDesktop = this.translateService.instant(
      'ftiGeneric.mobile-app-download-image'
    );
    this.downloadAppImageMobile = this.translateService.instant(
      'ftiGeneric.mobile-app-download-small-image'
    );
    this.downloadText1 = this.translateService.instant(
      'ftiGeneric.mobile-app-download-text'
    );
    this.appPlayStoreImage = this.translateService.instant(
      'ftiGeneric.ft-app-google-play-image'
    );
    this.appStoreImage = this.translateService.instant(
      'ftiGeneric.ft-app-playstore-image'
    );
    this.appStoreURL = this.translateService.instant(
      'ftiGeneric.ft-app-google-play-link'
    );
    this.playStoreURL = this.translateService.instant(
      'ftiGeneric.ft-app-playstore-url'
    );

  }

  get isPreview() {
    return this.page.isPreview();
  }

}
