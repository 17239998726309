<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>

  <div class="fd-tab-sections info-box" id="History">
    <h3 class="text-center mb-3" *ngIf="preTitle">{{ preTitle }}</h3>
    <div class="mb-5" *ngIf="title" [innerHTML]="title"></div>
    <ngx-slick-carousel class="carousel box-bg" #slickModal="slick-carousel" [config]="slideConfig">
      <ng-container *ngFor="let item of linkLists">
        <div ngxSlickItem class="slide">
          <div class="history-box">
            <!--<img alt="history slide images" src="{{ getImageURL(item?.widenAssetCompound?.widenDocument) }}"
                 class="box-img"/>-->
            <span *ngIf="item.content?.content?.value !==''" [innerHTML]="item.content?.content?.value"></span>
          </div>
          <!--<a [href]="content?.linkCompound?.url"
             [target]="content?.linkCompound?.target == '' ? '_self' : content?.linkCompound?.target"
             style="color:inherit;">
            <div class="creation-box">
              <div class="img-block">
                <img alt="image" src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}"
                     alt="{{ content?.linkCompound?.displayText }}"/>
              </div>
              <h3 class="digitize-card-heading">{{ content?.linkCompound?.displayText }}</h3>
            </div>
          </a>-->
        </div>
      </ng-container>
    </ngx-slick-carousel>
  </div>
</div>

