<div class="loactions-cust" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <!-- start section locations -->
  <section class="FT-locations">
    <div class="container">
      <div class="text-center">
        <div class="FT-title"> {{ preTitle }}</div>
        <div class="text-big locations-description">
          {{ title }}
        </div>
      </div>
      <div class="d-relative">
        <div *ngIf="linkLists" id="map-statistics-block" [class.d-none]="isVideoPlaying" class="statistics-block d-none"
             [innerHTML]="linkLists[0]?.content?.content?.value | safeHtml">
        </div>
        <div class="location-img-block text-center">
          <ng-container *ngIf="isMediaTypeImage;else imageSection">
            <video *ngIf="isMobileView else desktop" [src]="mediaSrc" playsinline type="video/mp4" loading="lazy"
                   class="w-100 d-lg-none" id="map-video-mobile"
                   autoplay muted>
            </video>
            <ng-template #desktop>
              <video playsinline type="video/mp4" [src]="mediaSrc" loading="lazy" class="w-100 d-none d-lg-block"
                     id="map-video"
                     muted></video>
            </ng-template>
          </ng-container>
          <!-- will be based on size of image -->
          <ng-template #imageSection>
            <img alt="locations" [src]="mediaSrc" class="img-fluid">
          </ng-template>
        </div>
      </div>
      <div class="text-center loc-more">
        <a [href]="learnMoreURL" class="more-links">
          <span>{{ learnMore }}</span>
          <i class="bi bi-chevron-right ms-3"></i>
        </a>
      </div>
    </div>
  </section>
</div>
