import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@shared/translate/translate.service";
import moment from "moment";
import { EnvConfigService } from "@services/env-config.service";

@Component({
  selector: "ft-document-panel",
  templateUrl: "./document-panel.component.html",
  styleUrls: ["./document-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentPanelComponent implements OnInit, OnChanges {
  @Input() fundDocuments: any;
  @Input() errorInService: boolean;
  @Input() hasFilters = false;
  @Input() selectedFilter: string;
  @Input() allDocs: any;
  @Input() notFundsDocumentsData = false;
  @Input() pageCategory: string;
  @Input() selectedTab: any;
  @Input() selectedCategoryData: any;
  @Output() categoryChange: EventEmitter<any> = new EventEmitter<any>();

  public hasCustomLinkInput: boolean;
  public isLinksGenerated = false;
  selectedCategory: string;
  subCategoryList: any = [];
  selectedFilterData: any;
  fromDate: string;
  toDate: string;
  dateFormatForComponent = "DD/MM/YYYY";
  currentDateStd: string = moment(new Date()).format("YYYY-MM-DD");
  initialSelectedCategoryData: any;
  selectedSubCategoryData: any;
  isInternalLink: boolean = false;

  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private envConfig: EnvConfigService,
  ) {
  }

  ngOnInit(): void {
    this.initialSelectedCategoryData = this.selectedCategoryData;
    this.subCategoryList = [];
    this.loadCustomLinks();
    this.composeCustomDocumentLinks();

    if (
      this.selectedFilter &&
      this.selectedFilter != "All" &&
      !this.selectedCategoryData.isContent
    ) {
      this.getSubCategories();
    } else {
      if (this.selectedFilter == "All" && this.pageCategory != "Reports") {
        this.selectedCategoryData = this.selectedTab.FirstDropDown.filter(
          (item) => item.label != "All"
        );
      }
      if (this.pageCategory == "Reports") {
        this.getSubCategories();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fromDate = "";
    this.toDate = "";
    this.initialSelectedCategoryData = this.selectedCategoryData;
    if (changes.selectedFilter) {
      this.selectedFilter = changes.selectedFilter?.currentValue;
    }
    if (
      this.selectedCategoryData &&
      changes.selectedCategoryData?.currentValue?.length
    ) {
      this.subCategoryList = [];
      this.selectedSubCategoryData = [];
      this.loadCustomLinks();
      this.composeCustomDocumentLinks();
      if (
        this.selectedFilter &&
        this.selectedFilter != "All" &&
        !this.selectedCategoryData.isContent
      ) {
        this.getSubCategories();
      } else {
        if (this.selectedFilter == "All" && this.pageCategory != "Reports") {
          this.selectedCategoryData = this.selectedTab.FirstDropDown.filter(
            (item) => item.label != "All"
          );
        }
        if (this.pageCategory == "Reports") {
          this.getSubCategories();
          this.selectedCategoryData =
            changes.selectedCategoryData?.currentValue ??
            this.selectedCategoryData;
        }
      }
    }
  }

  private composeCustomDocumentLinks() {
    // Get custom document links from BR labels

    const customDocumentLinksGroup = JSON.parse(
      this.translateService.instant("literature.customDocumentLinksGroup")
    );

    if (customDocumentLinksGroup && !this.isLinksGenerated) {
      this.fundDocuments?.forEach((doc) => {
        customDocumentLinksGroup?.find((category) => {
          if (category?.id === doc.id) {
            if (category.hasOwnProperty("secondDropDown")) {
              category.secondDropDown.forEach((secondaryCategory) => {
                doc.secondDropDown.find((secondaryDocs) => {
                  if (secondaryDocs.id === secondaryCategory.id) {
                    secondaryCategory.dataRecords.linkdata.forEach((input) => {
                      if (input.position === "top") {
                        secondaryDocs.dataRecords.linkdata.unshift(input);
                      } else {
                        secondaryDocs.dataRecords.linkdata.push(input);
                      }
                    });
                  }
                });
              });
              this.isLinksGenerated = true;
            } else {
              category.dataRecords.linkdata.forEach((group) => {
                if (group.position === "top") {
                  doc.dataRecords.linkdata.unshift(group);
                } else {
                  doc.dataRecords.linkdata.push(group);
                }
              });
              this.isLinksGenerated = true;
            }
          }
        });
      });
    }
  }

  private loadCustomLinks() {
    if (
      this.selectedFilter === "Mutual Fund Reports" ||
      this.selectedFilter === "FORTNIGHT-PORTFOLIO-DEBT-SCHEMES" ||
      this.selectedFilter === "MONTHLY-PORTFOLIO-DSCLR"
    ) {
      this.hasCustomLinkInput = true;
    } else {
      this.hasCustomLinkInput = false;
    }
  }

  public getDocumentURL(input) {
    const baseUrl = this.envConfig.getEnvConfig()?.baseUrl;
    this.isInternalLink = false;
    if (input?.literatureHref?.indexOf("BASE_URL/") > -1) {
      this.isInternalLink = true
      return input?.literatureHref.replace("BASE_URL/", baseUrl);
    }

    return input?.literatureHref?.indexOf("http") > -1
      ? input.literatureHref
      : "download" + input?.literatureHref;
  }

  // Capture file extension from document url (if filetype is not present)
  public fileExtension(input) {
    const extensionIndex = input?.fileExtension
      ? input?.fileExtension
      : input?.literatureHref.lastIndexOf(".");
    if (input?.fileExtension !== "NA") {
      if (input?.fileExtension && input?.fileExtension !== "") {
        return extensionIndex?.toLowerCase();
      } else {
        return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
      }
    }
  }

  getSubCategories(): void {
    if (this.selectedFilter) {
      if (this.selectedTab?.FirstDropDown) {
        this.selectedFilterData = this.selectedTab.FirstDropDown?.find(
          (item) => {
            return (
              this.translateService.instant("literature." + item.id) ==
              this.selectedFilter
            );
          }
        );
      }
      if (this.selectedTab?.secondDropDown?.length) {
        this.selectedFilterData = this.selectedTab;
      }

      if (this.selectedFilterData?.secondDropDown?.length) {
        this.selectedFilterData.secondDropDown.map((subCategory) =>
          this.subCategoryList.push(
            this.translateService.instant("literature." + subCategory.id)
          )
        );
        if (this.pageCategory == "Reports") {
          this.selectSubCategory(this.selectedFilter);
        }
        if (this.subCategoryList && this.pageCategory != "Reports") {
          this.selectSubCategory(this.subCategoryList[0]);
        }
      }
    }
  }

  selectSubCategory(category: string) {
    this.selectedCategory = category;
    if (this.pageCategory == "Reports") {
      this.categoryChange.emit(this.selectedCategory);
    }
    if (this.selectedFilterData?.secondDropDown?.length) {
      let selectedCategoryItem = this.selectedFilterData.secondDropDown.find(
        (subCategory) =>
          this.translateService.instant("literature." + subCategory.id) ===
          this.selectedCategory
      );
      this.selectedSubCategoryData = selectedCategoryItem.dataRecords.linkdata;
      this.selectedCategoryData = selectedCategoryItem.dataRecords.linkdata;
    }
  }

  applyFilter() {
    if (!this.fromDate || !this.toDate) {
      return;
    }
    if (!this.initialSelectedCategoryData.length) {
      return;
    }

    const fromdate = moment(this.fromDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const todate = moment(this.toDate, "DD-MM-YYYY").format("YYYY-MM-DD");

    const deepCopyArray = JSON.parse(
      JSON.stringify(this.initialSelectedCategoryData)
    );

    let filteredArray = [];

    if (this.selectedFilter !== "All") {
      const selectedData = this.selectedSubCategoryData.length
        ? this.selectedSubCategoryData
        : this.initialSelectedCategoryData;

      this.selectedCategoryData = selectedData.filter(
        (m) => m.frkReferenceDate >= fromdate && m.frkReferenceDate <= todate
      );
    } else {
      filteredArray = deepCopyArray
        .filter((item) => {
          let dataToCheck = [];

          if (item.dataRecords?.linkdata?.length > 0) {
            dataToCheck = item.dataRecords.linkdata.filter(
              (linkItem) =>
                linkItem.frkReferenceDate >= fromdate &&
                linkItem.frkReferenceDate <= todate
            );
          } else if (item.secondDropDown?.length > 0) {
            dataToCheck = item.secondDropDown.flatMap((subItem) =>
              subItem.dataRecords.linkdata.filter(
                (linkItem) =>
                  linkItem.frkReferenceDate >= fromdate &&
                  linkItem.frkReferenceDate <= todate
              )
            );
          }
          // TODO: UN COMMENT BELOW CODE IF NEEDED,
          //       TO DISPLAY CATEGORIES WITH ONLY CONTENT UNDER ALL CATEGORY LIST
          //       IRRESPECTIVE TO APPLIED FILTER CONDITION
          // else if(item.isContent){
          //   return item
          // }

          return dataToCheck.length > 0;
        })
        .map((item) => {
          if (item.secondDropDown && item.secondDropDown.length > 0) {
            item.secondDropDown.forEach((subItem) => {
              subItem.dataRecords.linkdata =
                subItem.dataRecords.linkdata.filter(
                  (linkItem) =>
                    linkItem.frkReferenceDate >= fromdate &&
                    linkItem.frkReferenceDate <= todate
                );
            });

            item.secondDropDown = item.secondDropDown.filter(
              (subItem) => subItem.dataRecords.linkdata.length > 0
            );
          }
          return item;
        })
        .filter((item) =>
          item.secondaryDropDown
            ? item.secondDropDown.filter(
              (subItem) => subItem.dataRecords.linkdata.length > 0
            )
            : item
        );

      this.selectedCategoryData = filteredArray;
    }
  }

  onFinalDate(selectedDate: string, dateField: string): void {
    if (dateField == "fromDate") {
      this.fromDate = selectedDate;
    } else {
      this.toDate = selectedDate;
    }
    this.cdr.detectChanges();
  }

  scrollToAccodianBottom() {
    const scrollBar = document.querySelector('.main-content-acc-body');
    if (scrollBar) {
      if (scrollBar.scrollTop + scrollBar.clientHeight !== scrollBar.scrollHeight) {
        scrollBar.scrollTop += 200; // Scrolls down by 200px
      } else {
        scrollBar.scrollTop = scrollBar.scrollHeight; // Scrolls to the bottom
      }
    }
  }
}
