import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { Location } from '@angular/common';
import { CommonService } from "@services/common.service";
import { AnalyticsService } from "@services/analytics.service";
import { InvestNowService } from "@services/invest-now.service";

const logger = Logger.getLogger('DigitizeWealthComponent');

@Component({
  selector: 'ft-digitize-wealth',
  templateUrl: './digitize-wealth.component.html',
  styleUrls: ['./digitize-wealth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DigitizeWealthComponent implements OnInit {
  quickLinksexploreLabel: any;
  quickLinksexploreURL: any;
  currentPath = '';

  @Input() component!: BrComponent;
  @Input() page: Page;
  isMobileView: boolean = false;
  siteToggle: boolean = false;
  siteToggleVal: string;
  siteToggleStatus: string;

  constructor(
    private translateService: TranslateService,
    private location: Location,
    private commonService: CommonService,
    private analyticsService: AnalyticsService,
    private investNowService: InvestNowService,
  ) {
  }

  ngOnInit(): void {
    this.isMobileView = window.innerWidth < 900;
    this.siteToggleStatus = this.translateService.instant(
      'ftiGeneric.site-toggle'
    );
    if (this.siteToggleStatus === 'true') {
      this.siteToggle = true;
    }
    this.siteToggleVal = this.translateService.instant(
      'ftiGeneric.site-toggle-value'
    );
    this.quickLinksexploreLabel = this.translateService.instant(
      'ftiGeneric.quicklink-explore-more'
    );
    this.quickLinksexploreURL = this.translateService.instant(
      'ftiGeneric.quicklink-explore-more-url'
    );
    this.currentPath = this.location.path().split('/')[1];
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  get preTitle() {
    return this.content?.titleBlock?.preTitle;
  }


  @ViewChild('slickModal')
  slickModal!: SlickCarouselComponent;

  @HostListener('window:resize', ['$event'])
  onWindowResize($event: any) {
    if (this.slickModal !== undefined) {
      if (window.innerWidth < 768) {
        if (this.slickModal.initialized) {
          this.slickModal.unslick();
        }
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    }
    this.isMobileView = window.innerWidth < 900;
  }

  slideConfig: any = {
    dots: false,
    infinite: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    centerMode: false,
    centerPadding: '75px',
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '10%',
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerPadding: '5%',
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,
        settings: "unslick",
      }
    ]
  };

  get contentBlocks() {
    return this.content?.contentBlocks;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }

  checkLink(url: any) {
    return this.commonService.checkForAccountsLink(url);
  }

  investNow() {
    this.analyticsService.trackEvent({
      event: 'Home Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow',
    });
    this.analyticsService.trackEvent({
      event: "Home Page",
      ft_event: 'ft_InvestNow_Clicked',
      category: "Homepage",
      action: 'BelowBanner',
      label: 'InvestNow',
    });

    this.investNowService.investNow('');
  }

  rcGA() {
    this.analyticsService.trackEvent({
      event: "Home Page",
      ft_event: 'ft_RequestCallback',
      category: "Homepage",
      action: 'BelowBanner',
      label: 'RequestCallBack',
    });
  }
}
