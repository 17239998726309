<section>
  <div class="container">
    <div class="container-wraper feedback-wrapper">
      <div class="page-wrap feedback-container">
        <div class="title">{{ "ftiFeedback.feedback-title" | translate }}</div>
        <h3 class="title-2">{{ "ftiFeedback.feedback-text-2" | translate }} <span>*</span></h3>
        <!-- Choose feedback -->
        <div class="choose-feedback-wrap">
          <div class="fb-card-wrap" [ngClass]="{'active': currentRatting === 3}">
            <button class="fb-card-img" (click)="setRatting(3)">
              <img src="{{ 'ftiFeedback.feedback-rating-1' | translate }}" style="width: 75%"
                   alt='{{ "ftiFeedback.feedback-label-1" | translate }}'>
            </button>
            <p>{{ "ftiFeedback.feedback-label-1" | translate }}</p>
          </div>
          <div class="fb-card-wrap" [ngClass]="{'active': currentRatting === 2}">
            <button class="fb-card-img" (click)="setRatting(2)">
              <img src="{{ 'ftiFeedback.feedback-rating-2' | translate }}"
                   alt='{{ "ftiFeedback.feedback-label-3" | translate }}'>
            </button>
            <p>{{ "ftiFeedback.feedback-label-3" | translate }}</p>
          </div>
          <div class="fb-card-wrap" [ngClass]="{'active': currentRatting === 1}">
            <button class="fb-card-img" (click)="setRatting(1)">
              <img src="{{ 'ftiFeedback.feedback-rating-3' | translate }}"
                   alt='{{ "ftiFeedback.feedback-label-2" | translate }}'>
            </button>
            <p>{{ "ftiFeedback.feedback-label-2" | translate }}</p>
          </div>
        </div>

        <!-- Rating - 1 -->
        <div class="rating-1-wraper" *ngIf="currentRatting === 1">
          <p>{{ "ftiFeedback.feedback-text-4" | translate }}</p>
          <button type="button" class="btn btn-primary btn-ext"
                  (click)="submitForm()">{{ "ftiFeedback.labelSubmit" | translate }}
          </button>
        </div>
        <button id="openThankYouModal" [hidden]="true" data-bs-toggle="modal" data-bs-target="#submitFeedback">Open
          Modal
        </button>
        <div class="rating-reest-wraper" *ngIf="currentRatting > 1">
          <p class="title" [ngStyle]="{'color':currentRatting === 2? 'green':'red'}">
            {{ "ftiFeedback.feedback-text-3" | translate }}</p>
          <form [formGroup]="feedbackForm"
                class="feedback-form forms-data">
            <div class="row">
              <div class="col-sm-6">
                <label class="form-label txt-ar-mgn">{{ "ftiFeedback.feedback-text-5" | translate }}
                </label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-5">
                        <label class="form-label">{{ "ftiFeedback.labelName" | translate }} <span
                            class="required">*</span></label>
                        <input type="text" id="communication_Name" maxlength="80" formControlName="name"
                               class="form-control" placeholder="Enter name">
                        <div
                            *ngIf="feedbackForm?.get('name')?.hasError('required') && !feedbackForm?.get('name')?.value && feedbackForm?.get('name')?.touched">
                          <span style="color: #F04438;"
                                class="error-msg">{{ "ftiFeedback.ValidateMsgName" | translate }}</span>
                        </div>
                        <div
                            *ngIf="feedbackForm?.get('name')?.hasError('pattern') && !feedbackForm?.get('name')?.hasError('required') && feedbackForm?.get('name')?.touched">
                          <span style="color: #F04438;"
                                class="error-msg">{{ "ftiFeedback.labelNameFormatErrMsg" | translate }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-5">
                        <label class="form-label">{{ "ftiFeedback.labelEmail" | translate }} <span
                            class="required">*</span></label>
                        <input type="email" id="communication_Email" formControlName="emailId" class="form-control"
                               placeholder="Enter email address">
                        <div
                            *ngIf="feedbackForm?.get('emailId')?.hasError('required') && !feedbackForm?.get('emailId')?.value && feedbackForm?.get('emailId')?.touched">
                          <span style="color: #F04438;"
                                class="error-msg">{{ "ftiFeedback.validateMsgEmail" | translate }}</span>
                        </div>
                        <div class="communicationEmail-error"
                             *ngIf="feedbackForm?.get('emailId')?.hasError('pattern') && !feedbackForm?.get('emailId')?.hasError('required') && feedbackForm?.get('emailId')?.touched">
                          <span style="color: #F04438;"
                                class="error-msg">{{ "ftiFeedback.labelEmailFormatErrMsg" | translate }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-5">
                        <label class="form-label">{{ "ftiFeedback.labelPan" | translate }}</label>
                        <input type="text" id="communication_PAN" maxlength="13" formControlName="accountNo"
                               class="form-control" placeholder="Enter PAN / Folio number">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" style="margin-bottom: 20px;">
                  <label class="form-label"
                         style="margin-bottom: 20px;">{{ "ftiFeedback.feedback-button-form-label-1" | translate }}
                  </label>
                  <div class="category-btns">
                    <ul>
                      <li *ngFor="let category of categoryList;" style="border-color: rgb(199, 197, 197);"
                          [class.active]="selectedCategory.includes(category)" (click)="selectCategory(category)">
                        {{ category }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="form-group txt-ar-mgn">
                  <label class="form-label">{{ "ftiFeedback.feedback-button-form-label-2" | translate }}
                    <span class="required">*</span>
                  </label>
                  <textarea class="form-control txt-ar" type="text" id="communication_Feedback" maxlength="500"
                            formControlName="feedback" (keyup)="onKeyUp($event, 'feedback')"
                            (paste)="onPaste($event)"></textarea>
                  <div
                      *ngIf="feedbackForm?.get('feedback')?.hasError('required') && !feedbackForm?.get('feedback')?.value && feedbackForm?.get('feedback')?.touched">
                    <span style="color: #F04438;"
                          class="error-msg">{{ "ftiFeedback.ValidateMsgFeedback" | translate }}</span>
                  </div>
                  <div class="mt-2">
                    <span style="color: #F04438;"
                          class="error-msg">{{ "ftiFeedback.specialCharactersNotAllowed" | translate }}</span>
                  </div>
                </div>
                <div class="text-end">
                  <button type="button" class="btn btn-primary btn-ext" [disabled]="!feedbackForm.valid"
                          (click)="feedbackSubmit()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                    {{ "ftiFeedback.labelSubmit" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Feedback Modal -->
<div class="modal fade" id="submitFeedback" tabindex="-1" aria-labelledby="submitFeedbackLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="">
      <div class="modal-body">
        <div class="save-alert feedback-save-alt">
          <div class="content">
            <span class="close-btn" data-bs-dismiss="modal" aria-label="Close"
                  (click)="closeSuccessPopup('submitFeedback')">
              <img alt="image" src="assets/images/general/close.webp">
            </span>
            <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
            <span>{{ "ftiFeedback.flashMessage" | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
