import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from "@utils/logger";

const logger = Logger.getLogger('StickyTabsComponent');

@Component({
  selector: 'ft-sticky-tabs',
  templateUrl: './sticky-tabs.component.html',
  styleUrls: ['./sticky-tabs.component.scss']
})
export class StickyTabsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor(
    private elRef: ElementRef
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {

    const sections = document.querySelectorAll<any>('.fd-tab-sections');
    const navLi = document.querySelectorAll('.FT-blocks-nav ul li');
    let current = '';
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (pageYOffset >= (sectionTop - 85)) {
        current = section.getAttribute('id');
      }
    });
    navLi.forEach((li) => {
      current ? li.children[0].classList.remove('active-item') : '';
      if (li.children[0].classList.contains(current)) {
        li.children[0].classList.add('active-item');
      }
    });
  }


  ngOnInit() {
    this.elRef.nativeElement.closest('.br-container-box')?.classList.add('cont-first-main');
    this.elRef.nativeElement.closest('.br-container-box')?.children[0]?.classList.add('cont-first-main');
    this.elRef.nativeElement.closest('.br-container-box')?.children[1]?.classList.add('cont-first-main');
    this.elRef.nativeElement.closest('.br-container-box')?.children[2]?.classList.add('cont-first-main');
  }

  get data() {
    const test = this.component?.getParameters()
    return this.component?.getParameters();
  }

  get params() {
    return this.data;
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get accordion() {
    return this.content?.acccordionCompoundType;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  scrollTo(param: any) {
    const elementToScroll = document.getElementById(param);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
}
