import isNull from 'lodash/isNull';
import { fromLocaleNumber } from './number-il8n';

/**
 * World Currency ISO 4217 symbols map
 *
 * for list and details
 *
 * @see http://www.xe.com/symbols.php
 * @see https://www.iso.org/iso-4217-currency-codes.html
 * @see https://en.wikipedia.org/wiki/ISO_4217
 */
export const symbols = new Map([
  ['', ''], // No currency
  ['CNH', 'CNH'], // Chinese yuan (offshore) [CNH is non-ISO code], Written normally as ¥ not CNH
  ['CNY', '¥'], // Chinese yuan (onshore)
  ['CHF', 'CHF'], // Swiss Franc, Written normally as Sfr. or fr. not CHF
  ['EUR', '€'], // Euro
  ['GBP', '£'], // British Pound
  ['JPY', '¥'], // Japanese Yen
  ['NOK', 'Kr'], // Norwegian Krone
  ['USD', '$'], // United States Dollar
  ['CAD', '$'], // Canada Dollar
  // untested
  ['AED', 'د.إ'], // United Arab Emirates dirham
  ['ARS', '$'], // Argentine Peso
  ['AUD', '$'], // Australia Dollar
  ['BGN', 'лв'], // Bulgaria Lev
  ['BRL', 'R$'], // Brazil Real
  ['BSD', '$'], // Bahamas Dollar
  ['CZK', 'Kč'], // Czech Republic Koruna
  ['DKK', 'kr'], // Denmark Krone
  ['EGP', '£'], // Egypt Pound
  ['HKD', '$'], // Hong Kong Dollar
  ['HRK', 'kn'], // Croatia Kuna
  ['HUF', 'Ft'], // Hungary Forint
  ['ILS', '₪'], // Israel Shekel
  ['INR', '₹'], // India Rupee
  ['ISK', 'kr'], // Iceland Krona
  ['KRW', '₩'], // South Korean Won
  ['MXN', '$'], // Mexican Peso
  ['MYR', 'RM'], // Malaysia Ringgit
  ['NZD', '$'], // New Zealand Dollar
  ['PLN', 'zł'], // Poland Zloty
  ['RON', 'lei'], // Romania Leu
  ['RSD', 'Дин.'], // Serbia Dinar
  ['RUB', '₽'], // Russia Ruble
  ['RMB', '￥'], // Chinese renminbi (Chinese yuan has two forms CNH / CNY)
  ['SEK', 'Kr'], // Sweden Krona
  ['SGD', '$'], // Singapore Dollar
  ['TRY', '₺'], // Turkey Lira
  ['UAH', '₴'], // Ukraine Hryvnia
  ['ZAR', 'R'], // South Africa Rand
]);

/**
 * This function performs light-touch currency formatting, so as not to lose any locale specific formatting
 * All it does is prepend (if necessary) a minus symbol and the currency symbol
 * Commas and other numeric separators should be left intact
 */
export function formatCurrency(val: any = '', currency = ''): string {
  let currencySymbol = symbols.get(currency);
  if (!currencySymbol) {
    currencySymbol = '';
  }
  if (!val && val !== 0) {
    return '—';
  }
  const stripped = val.replace(currencySymbol, '');
  const parsed = fromLocaleNumber(stripped);
  if (isNull(parsed)) {
    return '—';
  }
  if (parsed === 0) {
    // remove minus symbol if zero
    return currencySymbol + val.replace('-', '').replace(currencySymbol, '');
  }
  if (parsed < 0) {
    // make sure minus symbol is before the currency symbol
    return (
      '-' + currencySymbol + val.replace('-', '').replace(currencySymbol, '')
    );
  }
  return currencySymbol + val.replace(currencySymbol, '');
}
