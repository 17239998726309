import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from '@shared/date-picker/date-picker.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@shared/translate/translate.module';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [CommonModule, DpDatePickerModule, FormsModule, TranslateModule],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
