import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import { EnvConfigService } from '@services/env-config.service';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { StorageService } from '@services/storage.service';
import { FormService } from '@services/form.service';

const logger = Logger.getLogger('FeedbackComponent');

@Component({
  selector: 'ft-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  selectedCategory: any = ['Checking Fund details'];
  feedbackForm: FormGroup = new FormGroup({});
  isFormSubmitted = false;
  subscriptions: Subscription[] = [];
  categoryList: any = [];
  url = '';
  urlPrefix = '';
  hasFeedback = true;
  rating: any;
  errMsg: any = [];
  currentRatting: any;
  categoryValue: any;
  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private envConfig: EnvConfigService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private storageService: StorageService,
    private formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.urlPrefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    this.url = this.urlPrefix + this.translateService.instant('ftiFeedback.formURL');
    this.categoryList = [
      this.translateService.instant('ftiFeedback.feedback-category-label-1'),
      this.translateService.instant('ftiFeedback.feedback-category-label-2'),
      this.translateService.instant('ftiFeedback.feedback-category-label-3'),
      this.translateService.instant('ftiFeedback.feedback-category-label-4'),
      this.translateService.instant('ftiFeedback.feedback-category-label-5')
    ];
  }

  initForm = () => {
    this.feedbackForm = new FormGroup({// NOSONAR
      name: new FormControl("", [Validators.required, Validators.maxLength(80), Validators.pattern('^[a-zA-Z ]+$')]),
      emailId: new FormControl("", [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),// NOSONAR
      accountNo: new FormControl("", [Validators.maxLength(13)]),
      feedback: new FormControl("", [Validators.required, Validators.maxLength(500)])
    });
  }


  selectCategory(category: string): void {
    if (this.selectedCategory.includes(category)) {
      this.selectedCategory = this.selectedCategory.filter((d: any) => d != category);
    } else {
      this.selectedCategory.push(category);
    }
  }

  public onKeyUp($event: any, fieldName: string): void {
    let enteredCharacter = $event.target.value;
    enteredCharacter = enteredCharacter.replace(/[\/\\$'"<>]/g, '');
    this.feedbackForm.controls[fieldName].setValue(enteredCharacter);
  }

  public onPaste(event): void {
    this.formService.onPasteForms(event);
  }

  /**
   * Form submit
   */
  feedbackSubmit() {
    this.errMsg = [];
    this.isFormSubmitted = true;
    this.loading = true;
    const name = this.feedbackForm.value.name;
    const emailId = this.feedbackForm.value.emailId;
    const accountNo = this.feedbackForm.value.accountNo;
    const category = this.selectedCategory;
    for (let i = 0; i < category.length; i++) {
      if (i == 0) {
        this.categoryValue = category[i]
      } else {
        this.categoryValue += '|' + category[i]
      }
    }
    const feedback = this.rating + '|' + this.categoryValue + '|' + this.feedbackForm.value.feedback;
    const data = {
      name,
      emailId,
      accountNo,
      feedback,
    };

    const options =
      this.envConfig.getEnvConfig().environment === 'prod'
        ? this.sessionService.apiHttpOptionsWithCredentials
        : this.sessionService.apiHttpOptions;

    const formSubmit: Subscription = this.http
      .post(this.url, data, options)
      .subscribe(
        () => {
          this.loading = false;
          document.getElementById("openThankYouModal").click();
          this.isFormSubmitted = false;
          this.closeFeedbackForm();
          this.changeDetector.markForCheck();
        },
        (error) => {
          this.loading = false;
          logger.error('error >>>> ', error);
        }
      );
    this.subscriptions.push(formSubmit);
    if (!this.hasFeedback) {
      this.storageService.store('feedbackSubmitted', true, true);
    }
  }

  closeFeedbackForm() {
    this.feedbackForm.reset();
    this.currentRatting = 0;
    this.selectedCategory = ['Checking Fund details'];
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  closeSuccessPopup(id: any) {
    this.currentRatting = 0;
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  setRatting(r: any) {
    this.currentRatting = r;
    if (r === 3) {
      this.rating = this.translateService.instant('ftiFeedback.feedback-label-1');
    } else if (r === 2) {
      this.rating = this.translateService.instant('ftiFeedback.feedback-label-3');
    } else {
      this.rating = this.translateService.instant('ftiFeedback.feedback-label-2');
    }
  }

  submitForm() {
    this.currentRatting = 0;
    document.getElementById("openThankYouModal").click();
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
