<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <!-- dekstop view -->
  <section class="FT-calculators-slider mb-6 d-none d-md-block">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-sm-4 mb-3">
        <div class="FT-title-small">{{ "ftiGeneric.calculator-solutions-label" | translate }}</div>
        <a class="line login-dark2" href="{{exploreallsolutionsURL}}">{{ "products.focus-card-view-all" | translate }}<i
            class="bi bi-chevron-right"></i></a>
      </div>
      <ngx-slick-carousel class="carousel desktop-services-wrapper" #slickModal="slick-carousel"
                          [config]="slideConfig">
        <ng-container *ngFor="let content of linkLists">
          <div ngxSlickItem class="slide">
            <a class="solution-box" href="{{content?.linkCompound?.url}}"
               [target]="content?.linkCompound?.target ==='' ? '_self' : content?.linkCompound?.target">
              <h3 class="colorTitle">{{ content?.linkCompound?.displayText }}</h3>
              <img alt="{{content?.linkCompound?.title}}"
                   src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}"/>
              <div class="btn btn-primary goal-btn-zoom-fixes">
                {{ content?.linkCompound?.title }}
              </div>
            </a>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>
  </section>

  <!-- mobile view  -->
  <section class="FT-solution-planning d-block d-md-none">
    <div class="container-row goal-cards">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between mb-sm-4 mb-3">
          <div class="FT-title-small">{{ "ftiGeneric.calculator-solutions-label" | translate }}</div>
          <a href="{{exploreallsolutionsURL}}" class="line login-dark2">{{ "products.focus-card-view-all" | translate }} <i class="bi bi-chevron-right"></i></a>
        </div>
        <div class="d-md-none">
          <div class="cp-wraper">
            <ng-container *ngFor="let content of linkLists">
              <div class="choose-plan-card">
                <div class="img-wrap">
                  <img alt="{{content?.linkCompound?.title}}"
                       src="{{ getImageURL(content?.widenAssetCompound?.widenDocument) }}">
                </div>
                <div class="choose-plan-title fontResize">{{ content?.linkCompound?.title }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
