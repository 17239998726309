<div
    [style.marginTop.px]="params?.marginTop | spacing"
    [style.marginBottom.px]="params?.marginBottom | spacing"
    [style.paddingLeft.px]="params?.paddingLeft | spacing"
    [style.paddingRight.px]="params?.paddingRight | spacing">

  <div [ngClass]="{'has-edit-button': page?.isPreview(),'container': params?.container }">
    <ng-container [ngSwitch]="data?.background">
      <ng-container *ngSwitchDefault>
        <div *ngIf="data?.title" class="FT-title-medium text-bold text-blue mb-3">{{ data!.title }}</div>
      </ng-container>
    </ng-container>
  </div>
</div>
 
