import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";
import { Component as BrComponent, Document, Page } from "@bloomreach/spa-sdk";
import { Location, LocationStrategy, PathLocationStrategy, } from "@angular/common";
import { Logger } from "@utils/logger";
import { Meta, Title } from "@angular/platform-browser";
import { CommonService } from "@services/common.service";
import { EnvConfigService } from "@services/env-config.service";
import { KnowledgeCenterService } from "@services/knowledge-center.service";
import { getPageContent } from '@utils/pagemodel-utils';

const logger = Logger.getLogger("ArticleDetailComponent");

@Component({
  selector: "ft-article-detail",
  templateUrl: "./article-detail.component.html",
  styleUrls: ["./article-detail.component.scss"],
  providers: [
    Location,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleDetailComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;

  currentPageUrl: any;
  showTooltip: boolean = false;
  linkToCopy: string;
  showCopyDialoge: boolean = false;
  articleData: any;
  nextArticleToCome: any;
  previousArticleToCome: any;
  watchListFull: boolean = false;

  author: any;
  authorContent: any;
  authorImage: any;
  authorTitle: any;
  ftIndiaBrandName: string = "- Franklin Templeton India®";

  constructor(
    private location: Location,
    private metaService: Meta,
    private commonService: CommonService,
    public pagetitle: Title,
    private envConfig: EnvConfigService,
    private knowledgeCenter: KnowledgeCenterService,
    private elRef: ElementRef
  ) {
  }

  get content() {
    return this.document?.getData();
  }

  ngOnInit(): void {

    this.elRef.nativeElement.closest('.FT-footer-pages')?.classList.remove('pt-5');
    this.elRef.nativeElement.closest('.FT-footer-pages')?.classList.remove('pt-3');
    this.elRef.nativeElement.closest('.FT-footer-pages')?.classList.add('no-padding');
    this.initializeData();
    this.author = getPageContent(this.page, this.content?.author);
    if (this.author?.contentBlocks) {
      this.authorContent = this.author?.contentBlocks[0]?.content?.content?.value;
      this.authorImage = this.getImageURL(
        this.author?.contentBlocks[0]?.widenAssetCompound?.widenDocument
      );
      this.authorTitle = this.author?.contentBlocks[0]?.title;
    }
    this.setMetaData();
  }

  setMetaData() {
    const pageData = this.content?.ftindiaPageMetaData;
    this.metaService.updateTag(
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      "property='twitter:card'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='twitter:title'"
    );
    this.metaService.updateTag(
      {
        property: 'og:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='og:title'"
    );
    const PageTitle = pageData?.metaTitle
      ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
      : '';
    this.pagetitle.setTitle(PageTitle);
    this.metaService.updateTag(
      {
        property: 'twitter:image',
        content: '',
      },
      "property='twitter:image'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:description',
        content: pageData?.metaDescription ? pageData?.metaDescription : '',
      },
      "property='twitter:description'"
    );
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  getImageURL(data: string | undefined): string | null {
    try {
      const parsedData = JSON.parse(data || '');
      return parsedData?.url || null;
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      return null;
    }
  }


  ngAfterViewInit() {
  }

  ngOnChanges() {
    this.initializeData();
  }

  toggleTooltip() {
    this.showTooltip = false;
  }

  moveToRelatedTag() {
    const elementToScroll = document.getElementById("related_tag");
    if (elementToScroll) {
      // Scroll to the element with a delay of 10 milliseconds (or any desired delay)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  initializeData() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    this.articleData = content?.getData() || {};
    this.articleData.isWatchListed = this.checkWatchList(this.articleData);
    this.nextPost();
    this.previousPost();
  }

  nextPost() {
    let data = this.document?.getData();
    if (data?.upcomingArticle) {
      this.nextArticleToCome = getPageContent(this.page, data?.upcomingArticle);
    }
  }

  previousPost() {
    let data = this.document?.getData();
    if (data?.upcomingArticle) {
      this.previousArticleToCome = getPageContent(this.page, data?.previousArticle);
    }
  }

  closeWatchlist() {
    this.watchListFull = false;
  }

  checkWatchList(article: any): boolean {
    const watchListedIds = this.knowledgeCenter.getWatchList();
    let isWatchListed = false;
    watchListedIds.forEach(id => {
      if (article?.title === id.pageTitle) {
        isWatchListed = true;
      }
    })
    return isWatchListed;
  }

  shareOnApp(appName): void {
    const currentURL = this.location.path();
    this.currentPageUrl =
      this.envConfig.getEnvConfig()?.baseUrl + currentURL.substring(1);
    const url = this.currentPageUrl
      ? this.currentPageUrl
      : `${window.location.origin}${this.location.path()}`;
    this.commonService.shareOn(appName, url);
  }

  async toggleShowCopyDialoge(check: boolean): Promise<void> {
    if (check) {
      this.linkToCopy = this.currentPageUrl
        ? this.currentPageUrl
        : `${window.location.origin}${this.location.path()}`;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  getRelatedTags(str?: string) {
    if (str?.trim() !== '') {
      return str?.split(',');
    }
    return '';
  }
}
