<div class="one-col-title-content">
  <ng-container brComponent="title-content"></ng-container>
</div>
<div class="FT-knowledge">
  <div class="container">
    <div>
      <ng-container brComponent="main-content"></ng-container>
    </div>
  </div>
</div>
<div class="one-col-lower-content">
  <ng-container brComponent="lower-content"></ng-container>
</div>
