import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-display-content',
  templateUrl: './display-content.component.html',
  styleUrls: ['./display-content.component.scss'],
})
export class DisplayContentComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  contentType = '';

  constructor() {
  }

  ngOnInit(): void {
    this.contentType = this.component?.getParameters().contentType;
  }
}
