import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SessionService } from '@services/session.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@services/common.service';
import { TranslateService } from '../translate/translate.service';
import Citylist from '../../../../assets/data/citylist.json';
import { Subscription } from 'rxjs';
import { Logger } from "@utils/logger";
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("CallBackFormComponent");

@Component({
  selector: 'ft-call-back-form',
  templateUrl: './call-back-form.component.html',
  styleUrls: ['./call-back-form.component.scss']
})
export class CallBackFormComponent implements OnInit, AfterViewInit {
  @Input() isSmall = false;
  @Input() isCallback = false;
  @Input() classVal: string;
  @Output() backToInvest: EventEmitter<any> = new EventEmitter();
  otp: string;
  name = '';
  email = '';
  phone = '';
  city = '';
  utmParams = [];
  successMsg = '';
  otpRequired: boolean = false;
  otpSubmitted: boolean = false;
  formRequired: boolean = false;
  formSubmitted: boolean = false;
  countdownTimeout: any
  remainingTime: number = 0;
  filedDefaullt: boolean = false
  otpNumber: Array<number> = [];
  distributerFormData = {
    refNo: '',
    mobileNo: '',
    emailId: '',
    guId: '',
  };
  queryList: any = [];
  callbackfinancialForm: FormGroup = new FormGroup({});
  otpsubmitForm: FormGroup = new FormGroup({});
  otpMessage: any = '';
  successMsgInvest: string = '';
  timerOTP: any = 30;
  errormsg: any;
  submitnotdisabled: boolean = true;
  namePlaceholder: any;
  phonePlaceholder: any;
  emailPlaceholder: any;
  queryPlaceholder: any;
  subscription: Subscription;
  loading: boolean = false;
  OTPresent: boolean = false;

  //new design
  newInvestor: boolean = true;
  existingInvestor: boolean = false;
  emails: string = '';
  mobile: string = '';
  isChecked: boolean = false;
  otpChecked: any = 0;
  investNowForm1: FormGroup = new FormGroup({});
  isExistingInvestor: boolean = false;
  existingName: any;
  existingPhone: any;
  showHeading: boolean = true;
  loadDD: boolean = false;

  //Recaptcha
  captchaResolved: boolean = false;
  captchaResponse: string;

  constructor(
    private sessionService: SessionService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.queryList = Citylist;
    /*this.subscription = this.commonService?.cityData?.subscribe(
      (citiesValues) => (this.queryList = citiesValues)
    );*/
    this.namePlaceholder = this.translateService.instant(
      'ftiGeneric.namePlaceholder'
    );
    this.phonePlaceholder = this.translateService.instant(
      'ftiGeneric.phonePlaceholder'
    );
    this.emailPlaceholder = this.translateService.instant(
      'ftiGeneric.emailPlaceholder'
    );
    this.queryPlaceholder = this.translateService.instant(
      'ftiGeneric.queryPlaceholder'
    );
  }

  ngAfterViewInit() {
    this.getUrlParams();
  }

  getUrlParams() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        for (let [key, value] of Object.entries(params)) {
          if (key && key.includes('amp;')) {
            const newKey = key.split('amp;');
            key = newKey ? newKey[1] : newKey[0];
            this.utmParams[key] = value;
          } else {
            this.utmParams[key] = value;
          }
        }
      }
    });
  }

  submitInvestForm() {
    this.isExistingInvestor = false;
    this.submitnotdisabled = true;
    const communicationName = this.name = this.callbackfinancialForm.value.name
    const communicationPhoneNumber = this.phone = this.callbackfinancialForm.value.phone
    const communicationEmail = this.email = this.callbackfinancialForm.value.email
    const city = this.city = this.callbackfinancialForm.value.query
    const isValidMobile = this.commonService.isValidMobile(
      communicationPhoneNumber
    );
    const isValidEmail = this.commonService.isValidEmail(communicationEmail);
    const phoneErr = document.querySelector<HTMLElement>(
      '.communicationPhoneNumber-error'
    );
    const emailErr = document.querySelector<HTMLElement>(
      '.communicationEmail-error'
    );
    if (!isValidMobile) {
      phoneErr.style.display = 'block';
    }

    if (!isValidEmail) {
      emailErr.style.display = 'block';
    }
    if (isValidMobile && isValidEmail) {
      this.distributerFormData = {
        refNo: '',
        mobileNo: communicationPhoneNumber,
        emailId: communicationEmail,
        guId: '',
      };
      this.moveTo()
    }
  }

  sendOtp() {
    this.otpNumber = [];
    this.successMsg = this.translateService.instant(
      'ftiGeneric.fundCampaignSuccessMsg'
    );
    this.loading = true;
    if (this.distributerFormData.mobileNo) {
      this.sessionService.generateOtp$(this.distributerFormData).subscribe(
        (response) => {
          this.loading = false;
          const successOtp = 'SUCCESSOTP';
          if (response && response.body.data.status === successOtp) {
            this.otpMessage = response.body.data.otpMessage;
            this.handleOtpTimer();
          }
          this.analyticsService.trackEvent({
            event: 'All Pages',
            ft_event: 'ft_form_clicked',
            category: 'Ask For Distributor',
            action: 'Lead',
            label: 'GenerateOTP - Success',
          });

        },
        (error) => {
          this.loading = false;
          const errorResponse = error?.error[0] || error;
          if (errorResponse) {
            let errorMessage = '';
            if (error.status === 500) {
              errorMessage = error.error[0].errorDescription;
            } else {
              errorMessage = this.translateService.instant(
                'ftiGeneric.customErrText'
              );
            }
            document.getElementsByClassName('fleadgen-otp-message')[0].innerHTML = errorMessage;
            const advisorOtpMessageElement = Array.from(document.getElementsByClassName('fleadgen-otp-message'));
            advisorOtpMessageElement.forEach((element: any) => {
              element.style.color = 'red';
              element.style.textAlign = 'center';
            });
          }
          logger.error("Lead form Send OTP submission failure" + errorResponse);
        }
      );
    }
  }

  moveTo() {
    clearInterval(this.countdownTimeout); // Clear the previous interval
    this.remainingTime = 30; // Reset the countdown time
    this.otpRequired = !this.otpRequired;
    this.callbackfinancialForm.reset();
    this.startCountdown();
    this.sendOtp();
  }

  startCountdown() {
    clearTimeout(this.countdownTimeout); // Clear any existing timeout
    if (this.remainingTime > 0) {
      this.countdownTimeout = setTimeout(() => {
        this.remainingTime--;
        this.startCountdown(); // Recursively call startCountdown
      }, 1000); // Update every 1 second (1000 milliseconds)
    }
    this.cdr.detectChanges();
  }

  resetCountdown() {
    clearTimeout(this.countdownTimeout); // Clear the countdown timeout
    this.remainingTime = 30; // Reset the remaining time
    this.startCountdown();
    // this.handleOtpTimer()
    this.sendOtp();
    this.OTPresent = true;
  }

  handleOtpTimer() {
    let timeleft = 1;
    const self = this;
    const otpTimer = setInterval(() => {
      if (timeleft === 30) {
        clearInterval(otpTimer);
      } else {
        this.timerOTP = `(Active in 00:${30 - timeleft})`;
      }
      timeleft += 1;
    }, 1000);
  }

  submitButton() {
    if (this.otp) {
      const data = {
        OTP: this.otp,
        guId: '',
      };
      this.loading = true;
      this.sessionService.validateFeederOTP$(data).subscribe(
        (response) => {
          this.loading = false;
          if (response) {
            this.postDistributerFormData();
          }
        },
        (error) => {
          this.loading = false;
          const otpErr = document.querySelector<HTMLElement>('.fleadgen_OTP-error');
          if (error?.error[0] || error) {
            otpErr.style.display = 'block';
            this.errormsg = error.error[0].errorDescription;
            otpErr.innerHTML = this.errormsg;
            const advisorOtpMessageElement = Array.from(
              document.getElementsByClassName('fleadgen-otp-message')
            );
            advisorOtpMessageElement.forEach((element: any) => {
              element.style.color = 'red';
              element.style.textAlign = 'center';
            });
            logger.error("Lead form validateFeederOTP submission failure" + error?.error[0] || error);
          }
        }
      );
    } else {
      const otpErr = document.querySelector<HTMLElement>('.fleadgen_OTP-error');
      otpErr.style.display = 'block';
      document.getElementsByClassName(
        'fleadgen_OTP-error'
      )[0].innerHTML = this.translateService.instant(
        'ftiCommon.otpRequiredMsg'
      );
    }
  }

  initForm = () => {
    this.callbackfinancialForm = new FormGroup({//NOSONAR
      name: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
      email: new FormControl("", [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),//NOSONAR
      phone: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
      query: new FormControl("", Validators.required),
    });
    this.otpsubmitForm = new FormGroup({
      otpNumber0: new FormControl(),
      otpNumber1: new FormControl(),
      otpNumber2: new FormControl(),
      otpNumber3: new FormControl(),
      otpNumber4: new FormControl(),
      otpNumber5: new FormControl()
    });
    this.investNowForm1 = new FormGroup({
      existingInvestorname: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
      existingInvestorphone: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
    });
  }

  clear() {
    this.otpRequired = false;
    this.otpSubmitted = false;
    this.backToInvest.emit(true);
    this.callbackfinancialForm.reset();
    this.otpsubmitForm.reset();
    this.otpNumber = [];
    this.showHeading = true;
    this.filedDefaullt = false;
    this.newInvestor = true;
    this.existingInvestor = false;
    this.otpChecked= 0;
    grecaptcha.reset();
    this.investNowForm1.reset();
    this.captchaResolved = false;
    this.formSubmitted = false;
  }

  checkboxCondition(event: any) {
    if (event.target.checked) {
      this.filedDefaullt = true;
    } else {
      this.filedDefaullt = false;
    }
  }

  otpValidation(event: any, nextElement: any, id: any ,previousElement: any) {
    if (event.code === 'Backspace') { 
      if (previousElement) {
        previousElement.focus();
        this.submitnotdisabled = true;
      }
     }
    if (this.otpNumber[id - 1]) {
      if (this.otpNumber.length == 6) {
        this.otp = this.otpNumber.join('');
      }
      if (this.otp && this.otp.length == 6) {
        this.submitnotdisabled = false;
      }
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  getTxnSource() {
    const location = window.location.href;
    let txnSource;
    if (location.includes('sip-calculator')) {
      txnSource = 'PM_SIPCalc';
    } else if (location.includes('lumpsum-calculator')) {
      txnSource = 'PM_LSCalc';
    } else if (location.includes('mutual-fund-calculators')) {
      txnSource = 'PM_MFCalc';
    } else if (location.includes('fund-overview')) {
      txnSource = 'PM_FundPage';
    } else {
      txnSource = 'PM_HomePage';
    }
    return txnSource;
  }

  postDistributerFormData() {
    const utmCampaign = 'utm_campaign';
    const utmSource = 'utm_source';
    const utmMmedium = 'utm_medium';
    const payload = {
      name: this.name,
      mobile: this.phone,
      emailId: this.email,
      city: this.city,
      refNo: '',
      guid: '',
      txnSource: this.getTxnSource(),
      campaignType: this.utmParams[utmCampaign] || '',
      source: this.utmParams[utmSource] || 'marketing-leads-form',
      medium: this.utmParams[utmMmedium] || '',
    };
    this.loading = true;
    this.sessionService.postDistributeForm$(payload).subscribe(
      (response) => {
        this.loading = false;
        if (response) {
          const successMsg = this.translateService.instant('ftiGeneric.customMsg');
          this.otpSubmitted = true;
          this.successMsgInvest = successMsg;
          this.showHeading = false;
          this.filedDefaullt = false;
        }
      },
      (error) => {
        this.loading = false;
        if (error && error.error[0]) {
          const otpErr = document.querySelector<HTMLElement>('.fleadgen_OTP-error');
          otpErr.style.display = 'block';
          document.getElementsByClassName(
            'fleadgen_OTP-error')[0].innerHTML = error.error[0].errorDescription;
        }
        logger.error("Lead form submission failure" + error?.error[0] || error);
      }
    );
  }

  newInvestorInput() {
    this.filedDefaullt = false;
    this.otpSubmitted = false;
    this.formSubmitted = false;
    this.submitnotdisabled = true;
    this.newInvestor = true;
    this.existingInvestor = false;
    this.emails = ''; // Clear mobile input if any
  }

  existingInvestorInput() {
    this.filedDefaullt = false;
    this.formRequired = false;
    this.submitnotdisabled = true;
    this.existingInvestor = true;
    this.newInvestor = false;
    this.mobile = ''; // Clear email input if any
  }

  moveTo1() {
    const communicationName = this.existingName = this.investNowForm1.value.existingInvestorname;
    const communicationPhoneNumber = this.existingPhone = this.investNowForm1.value.existingInvestorphone;
    this.submitExistingInvestorForm();
    this.formRequired = !this.formRequired;
    this.investNowForm1.reset();
    this.submitnotdisabled = true;
  }

  blockAlphabetsAndSpecialChars(event: any) {
    // console.log(event.target.value)
    const char = event.key;
    // Check if the character is a numeric digit (0-9) or Backspace
    if (!/^[0-9]$/.test(char) && char !== 'Backspace') {
      event.preventDefault(); // Prevent the input of non-numeric characters
    }
  }


  submitExistingInvestorForm() {
    const payload = {
      name: this.existingName,
      mobile: this.existingPhone
    };
    this.loading = true;
    this.sessionService.postExistingInvesterDistributeForm$(payload).subscribe(
      (response) => {
        this.loading = false;
        if (response) {
          const successMsg = this.translateService.instant('ftiGeneric.customMsg');
          this.formSubmitted = true;
          this.showHeading = false;
          this.successMsgInvest = successMsg;
          this.filedDefaullt = false;
          this.cdr.detectChanges();
          this.otpChecked = 0;
          grecaptcha.reset();
        }
      },
      (error) => {
        this.loading = false;
        if (error && error.error[0]) {
          const otpErr = document.querySelector<HTMLElement>('.fleadgen_OTP-error');
          otpErr.style.display = 'block';
          document.getElementsByClassName(
            'fleadgen_OTP-error')[0].innerHTML = error.error[0].errorDescription;
        }
        logger.error("Lead form submission failure" + error?.error[0] || error);
      }
    );
  }

  close() {
    this.formRequired = false;
    this.formSubmitted = false;
    this.backToInvest.emit(true);
    this.investNowForm1.reset();
    this.showHeading = true;
    this.newInvestor = true;
    this.existingInvestor = false;
    this.filedDefaullt = false;
    this.captchaResolved = false;
  }

  loadOptions() {
    if (!this.loadDD) {
      this.loadDD = true;
    }
  }

  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
    this.captchaResolved = !this.captchaResolved; // Enable the submit button when reCAPTCHA is resolved
  }

}
