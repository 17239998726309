import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LiteratureService } from '@services/literature.service';
import { Subscription } from 'rxjs';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { Constant } from 'src/app/ft-constants/constant';
import { Logger } from '@utils/logger';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MediaArticle, MediaSortingOptions } from 'src/app/types/media-articles';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Content } from '@bloomreach/spa-sdk';
import { CommonService } from '@services/common.service';
import { MediaService } from '@services/media.service';


const logger = Logger.getLogger('ArticlesComponent');


@Component({
  selector: 'ft-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {
  @Input() config: Content;
  subscription: Subscription;
  processedData: MediaArticle[];
  articleData: MediaArticle[];
  articleDataSearch: MediaArticle[];
  yearsFilterDropdown: Array<any> = [];
  selectedYear: any;
  isLoaded = false;
  isMobileView = false;
  itemsToShow = 8; // Initial number of items to show
  itemsPerLoad = 2; // Number of items to load on each "Load more" click
  sortingOptions: MediaSortingOptions[] = [];
  selectedSortItem: MediaSortingOptions;
  tabTitle: string;
  tabDescription: string;
  searchTerm: string = '';
  showFundSearchDropdown: boolean = true;
  showCopyDialoge: boolean = false;
  showIconForWatchlist: boolean[] = [];
  linkToCopy: string;
  tabType: string;

  @HostListener('click', ['$event'])
  getTableDataPage(event: Event) {
    const clickedElement = event.target as Element;
    if (clickedElement.classList.contains('funds-seach-title')) {
      this.showFundSearchDropdown = false;
    } else if (clickedElement.classList.contains('form-control')) {
      this.showFundSearchDropdown = true;
    }
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const sections = document.querySelectorAll<any>('.fd-tab-sections');
    const navLi = document.querySelectorAll('.FT-blocks-nav ul li');
    let current = '';
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (pageYOffset >= (sectionTop - 85)) {
        current = section.getAttribute('id');
      }
    });
    navLi.forEach((li) => {
      current ? li.children[0].classList.remove('active-item') : '';
      if (li.children[0].classList.contains(current)) {
        li.children[0].classList.add('active-item');
      }
    });

  }

  constructor(
    private litService: LiteratureService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private commonService: CommonService,
    private mediaService: MediaService
  ) {
  }


  ngOnInit(): void {
    this.tabTitle = this.config?.getData()?.mediaArticleTabs?.[0]?.tabTitle;
    this.tabType = this.config?.getData()?.mediaArticleTabs?.[0]?.articlesDataParam;
    this.tabDescription = this.config?.getData()?.mediaArticleTabs?.[0]?.descriptionPath;
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobileView = result.matches;
    });

    try {
      let sortOptions = this.translateService.instant("products.media-sort-options");
      this.sortingOptions = JSON.parse(sortOptions);
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      return null;
    }

    if (this.sortingOptions) {
      this.selectedSortItem = this.sortingOptions?.find(item => item.default) || this.sortingOptions[0];
    }

    let query = `?channel=en-in&type=${this.tabType}&cache=true`
    // Call service for article data
    this.subscription = this.litService
      .getData$(query)
      ?.subscribe(
        (response) => {
          const rawData = response.document;

          // Set records in descending order
          this.processedData = orderBy(
            this.mapMediaData(rawData),
            ['date'],
            ['desc']
          );
          // Filtering the Article Data
          this.articleData = this.processedData.filter(item => item.mediaType === this.tabType);

          // Get Years from Data
          this.yearsFilterDropdown = this.getYearsDropdown();

          // Set default year in dropdown
          this.selectedYear = this.yearsFilterDropdown[0];
          // END
          this.isLoaded = true;

          this.cdr.detectChanges();
        },
        (error) => {
          logger.debug(error);
        }
      );
  }

  mapMediaData = (input): MediaArticle[] => {
    const watchListedIds = this.getWatchListedIds(); // Get watch-listed documentIds from local storage

    return input.map(
      (doc): MediaArticle => {
        // Extract file extension from literatureHref or default to 'NA'
        const fileType = doc.fileExtension || this.getFileExtension(doc.literatureHref) || 'NA';
        // Check if the documentId is in the watch list and set isWatchListed accordingly
        const isWatchListed = watchListedIds.includes(doc.documentId);

        return {
          title: doc.dctermsTitle,
          documentId: doc.documentId,
          year: moment(doc.frkReferenceDate).year(),
          date: doc.frkReferenceDate,
          longDate: moment(doc.frkReferenceDate).format(Constant.dateFormat),
          shortDate: moment(doc.frkReferenceDate).format(Constant.latestDateFormat),
          link: doc.literatureHref,
          fileType: fileType,
          mediaType: doc.dctermsType,
          isWatchListed: isWatchListed
        };
      }
    );
  };

  getFileExtension = (url: string): string | undefined => {
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    }
    return undefined;
  };

  getWatchListedIds(): string[] {
    // Retrieve watch-listed documentIds from local storage
    const watchListString = localStorage.getItem('media-watch-list');
    return watchListString ? JSON.parse(watchListString) : [];
  }

  getYearsDropdown(): any[] {
    const firstTab: string = this.translateService.instant("products.media-page-all-years-tab");
    const lastTab: string = this.translateService.instant("products.media-page-watchlist-tab");
    const years = uniqBy(this.articleData, 'year').map(data => data.year);
    years.unshift(firstTab);
    years.push(lastTab)
    return years;
  }

  selectYear(year) {
    this.itemsToShow = 8;
    this.selectedYear = year;
    this.clearSearch();
    const lastTab: string = this.translateService.instant("products.media-page-watchlist-tab");
    if (year === lastTab) {
      const watchList = this.mediaService.getWatchList();
      this.articleData = this.processedData.filter(item => watchList.includes(item.documentId));
      this.sortMedia(this.selectedSortItem);
    } else if (year !== 'All') {
      this.articleData = this.processedData.filter(item => item.year === year && item.mediaType === this.tabType);
      this.sortMedia(this.selectedSortItem);
    } else {
      this.articleData = this.processedData.filter(item => item.mediaType === this.tabType);
      this.sortMedia(this.selectedSortItem);
    }
  }

  openLink(article: MediaArticle) {
    const url = `download${article.link}`
    window.open(url, '_blank')
  }

  loadMore(): void {
    this.itemsToShow += this.itemsPerLoad * 4;
  }

  truncateText(input) {
    if (input.length > 50) {
      return input.substring(0, 50) + '...';
    }
    return input;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  showSelectedArticle(article: MediaArticle) {
    this.searchTerm = article?.title;
    this.articleData = [article];
    this.selectedYear = this.yearsFilterDropdown[0];
    this.showFundSearchDropdown = false;
  }

  searchArticles(searchTerm: string) {
    if (searchTerm && searchTerm.length >= 3) {
      this.articleDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
      this.articleDataSearch = this.articleDataSearch.filter((article: any) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.articleDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
    }
  }

  clearSearch() {
    this.searchTerm = '';
    this.articleData = this.processedData.filter(item => item.mediaType === this.tabType);
    this.articleDataSearch = this.processedData.filter(item => item.mediaType === this.tabType);
  }

  sortMedia(item: MediaSortingOptions) {
    this.selectedSortItem = item;
    this.sortByDate(item);
  }

  sortByDate(item: MediaSortingOptions): void {
    this.articleData.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (item.key === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
  }


  async toggleShowCopyDialoge(check: boolean, article?: MediaArticle): Promise<void> {
    if (check) {
      const baseUrl = this.commonService.getBaseUrl();
      const url = `/download${article.link}`
      this.linkToCopy = `${baseUrl}${url}`;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  shareOnApp(appName, article: MediaArticle): void {
    const url = `/download${article.link}`
    this.commonService.shareOn(appName, url);
  }

  toggleIconForWatchlist(index: number) {
    this.showIconForWatchlist[index] = !this.showIconForWatchlist[index];
  }

  addToWatshlist(article: MediaArticle) {
    this.mediaService.addToWatchList(article.documentId);
    // Find the article to update in articleData
    const articleToUpdate = this.articleData.find(
      (articleInArray) => articleInArray.documentId === article.documentId
    );
    // Update isWatchListed if the article is found
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = true;
    }
  }

  removeToWatchlist(article: MediaArticle) {
    this.mediaService.removeFromWatchList(article.documentId);
    // Find the article to update in articleData
    const articleToUpdate = this.articleData.find(
      (articleInArray) => articleInArray.documentId === article.documentId
    );
    // Update isWatchListed if the article is found
    if (articleToUpdate) {
      articleToUpdate.isWatchListed = false;
    }
    const lastTab: string = this.translateService.instant("products.media-page-watchlist-tab");
    if (this.selectedYear === lastTab) {
      const watchList = this.mediaService.getWatchList();
      this.articleData = this.processedData.filter(item => watchList.includes(item.documentId));
      this.sortMedia(this.selectedSortItem);
    }
  }
}
