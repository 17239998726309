<section class="FT-knowledge rel-cat-custom">
  <div class="container">
    <div class="category-block">
      <div class="d-flex align-items-center justify-content-between">
        <ng-container *ngIf="!calcTags else elseBlock">
          <h3>{{ dropdownTitle | titlecase }} </h3>
          <a class="knowledge-center-CTA" href="{{articleCategoryLink}}">
            <span>View all</span><i class="bi bi-chevron-right"></i></a>
        </ng-container>
        <ng-template #elseBlock>
          <h3>{{ "ftiKc.related-resources" | translate }}</h3>
          <a class="knowledge-center-CTA" href="/knowledge-centre/quick-learn/related-articles?rc={{calcTags.trim()}}">
            <span>View all</span><i class="bi bi-chevron-right"></i></a>
        </ng-template>

      </div>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let slide of articleArray; let i = index">
          <div class="category-card">
            <ng-container>
              <div class="card-img" type="button" (click)="categoryOnClick(slide)">
                <ng-container *ngIf="slide?.image">
                  <img src="{{ slide?.image }}" alt="{{ slide?.pageTitle }}"
                       title="{{ slide?.pageTitle }}">
                </ng-container>
                <ng-container *ngIf="!slide?.image">
                  <img src="{{ defaultArticleBannerImage }}" alt="Image"/>
                </ng-container>
                <span class="ebook-icon" *ngIf="slide?.pdfUrl">
                  <img alt="Open PDF" src="assets/images/knowledge-center/ebook-icon.svg">
                </span>
              </div>
            </ng-container>
            <div class="card-body">
              <div class="d-flex"
                   [ngClass]="slide?.data?._source?.videoTimeStamp?'justify-content-between':'justify-content-end'">
                <span class="time-tag fontResize" *ngIf="slide?.data?._source?.videoTimeStamp">{{
                        slide?.data?._source?.videoTimeStamp
                  }}</span>
                <div class="card-icons d-flex">
                  <span class="img-with-tooltip me-3" (click)="toggleIconForWatchlist(i)">
                    <!-- Use ngIf to conditionally display the appropriate bookmark icon -->
                    <i *ngIf="slide?.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark-fill"
                       (click)="removeToWatchlist(slide)"></i>
                    <i *ngIf="!slide?.isWatchListed" style="cursor: pointer;" class="bi bi-bookmark"
                       (click)="addToWatshlist(slide)"></i>
                    <!-- Use ngIf to conditionally display the tooltip based on isWatchListed -->
                    <span *ngIf="!slide.isWatchListed" class="tooltip-block">Save</span>
                    <span *ngIf="slide.isWatchListed" class="tooltip-block">Saved Course</span>
                  </span>
                  <span class="dropup">
                    <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="image" src="assets/images/knowledge-center/share.svg" (mouseenter)="showTooltip = true"
                           (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                           class="d-inline-block mobile-icons light-mode-icon" style="width: 18px;">
                      <img alt="image" src="assets/images/dark/share-dark-icon.png"
                           (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false"
                           (click)="toggleTooltip()"
                           class="mobile-icons dark-mode-icon"
                           style="width: 21px; top: -1px; position: relative; left: 0;">
                      <span *ngIf="showTooltip" class="tooltip-block fontResize">share</span>
                    </span>
                    <ul class="dropdown-menu relet-resour related-pos">
                      <li><a style="cursor: pointer;" (click)="toggleShowCopyDialoge(true,slide)"
                             class="dropdown-item"><img alt="image" src="assets/images/general/Link.webp">{{
                              "products.home-fund-card-share-copy-btn" | translate
                        }}</a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER',slide)">
                        <img alt="image" src="assets/images/general/twitter-new-icon.webp"
                             style="width: 20px;">{{
                              "products.home-fund-card-share-twitter-btn" |
                                  translate
                        }}</a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('FACEBOOK',slide)">
                        <img alt="image" src="assets/images/general/FacebookLogo.webp">{{
                              "products.home-fund-card-share-fb-btn" |
                                  translate
                        }}</a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN',slide)">
                        <img alt="image" src="assets/images/general/LinkedinLogo.webp">{{
                              "products.home-fund-card-share-linkedin-btn" |
                                  translate
                        }}</a></li>
                      <li><a style="cursor: pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP',slide)">
                        <img alt="image" src="assets/images/general/whatsapps.webp">{{
                              "products.home-fund-card-share-whatsapp-btn" |
                                  translate
                        }}</a></li>
                    </ul>
                  </span>
                </div>
              </div>
              <ng-container (click)="categoryOnClick(slide)">
                <div type="button" *ngIf="!(slide?.pageTitle?.length > 35);else elseBlock"
                     class="text-bold card-title forcategortcardtitle fontResize">
                  {{ slide?.pageTitle }}
                </div>
                <ng-template #elseBlock>
                  <div type="button" *ngIf="slide?.pageTitle?.length > 35"
                       class="text-bold card-title forcategortcardtitle fontResize">
                    {{ slide?.pageTitle.toString()?.substring(0, 35) }}...
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">

  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false,'none')">
      <img alt="image" src="assets/images/general/close.webp">
    </span>
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>

</div>

<!-- Dialoge: Max allowed alert -->
<div class="save-alert" style="z-index:10000" *ngIf="watchListFull">
  <div class="content">
    <ng-container>
      <a class="close-btn" (click)="closeWatchlist()">
        <img alt='image' src="assets/images/general/close.webp">
      </a>
      <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
      <span style="font-size: 1.063rem;">{{ "ftiKc.kc-watchlist-full" | translate }}</span>
    </ng-container>
  </div>
</div>
