import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { InUserType } from '../types/segment.type';
import { EnvConfigService } from './env-config.service';
import { LoginService } from './login.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@components/shared/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class InvestNowService {
  constructor(
    private loginService: LoginService,
    private envconfig: EnvConfigService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(WINDOW) private _window: Window
  ) {
  }

  investNow(fundId: string, fname?: string) {
    this.getInvestNowLink$(fundId, fname)
      .pipe(take(1))
      .subscribe((link) => {
        this._window.location.href = link;
      });
  }

  getInvestNowLink$(fundId?: string, fundName?: string): Observable<string> {
    return forkJoin([
      this.loginService.isLoggedIn$(),
      this.loginService.loggedInUserType$(),
    ]).pipe(
      map((userData) => {
        const [isloggedIn, userType] = userData;
        const advisorDetails = this.route.snapshot.queryParams?.arncode;
        const euinDetails = this.route.snapshot.queryParams?.euin;
        const accountsUrl = this.envconfig.getEnvConfig().ftiAccountsUrl;
        let nfoUrl = this.translateService.instant('products.nfo-investnow');
        nfoUrl = this.replaceNfoFundId(nfoUrl, fundId);
        const nfoFundList = this.translateService.instant(
          'products.nfo-fund-code-list'
        );
        const investNowToLogin = this.translateService.instant(
          'products.investNowToLogin'
        );
        const paramFundID =
          fundId && fundName
            ? `?fundid=${fundId}&fundname=${fundName}`
            : fundId
              ? `?fundid=${fundId}`
              : '';

        const custInvestLink = 'products.' + fundId + '-investnow-cust-link';
        const custInvestNowLink = this.translateService.instant(custInvestLink);
        let paramArnCode: string;
        if (nfoFundList.indexOf(fundId) !== -1) {
          const queryParams = this.route.snapshot.queryParams || {};

          if (queryParams.utm_source && queryParams.utm_campaign) {
            const UTMParamsStr = location.href.split('?')[1];
            nfoUrl = nfoUrl + '&' + UTMParamsStr;
            return `${accountsUrl}${nfoUrl}`;
          }
          return `${accountsUrl}${nfoUrl}`;
        } else {
          let arnEuinQueryParams = '';
          if(advisorDetails){
            arnEuinQueryParams =  `arncode=${advisorDetails}`;
            if(euinDetails){
              arnEuinQueryParams += `&euin=${euinDetails}`;
            }
          }
          if (paramFundID) {
            paramArnCode = advisorDetails ? `&${arnEuinQueryParams}` : '';
          } else {
            paramArnCode = advisorDetails ? `?${arnEuinQueryParams}` : '';
          }
          const param = paramFundID + paramArnCode;
          if (custInvestNowLink !== '[' + custInvestLink + ']') {
            return `${custInvestNowLink}${param}`;
          }
          let preloginparam = param
            ? param + `&target=campaign&proActive=false`
            : '?target=campaign&proActive=false';
          if (!isloggedIn) {
            const queryParams = this.route.snapshot.queryParams || {};
            if (queryParams.utm_source && queryParams.utm_campaign) {
              const UTMParamsStr = location.href.split('?')[1];
              preloginparam = preloginparam + '&' + UTMParamsStr;
            }
            if (investNowToLogin === 'false') {
              return `${accountsUrl}/ngGuest/#/enterPAN${preloginparam}`;
            } else {
              return `${accountsUrl}/ngGuest/#/transactnowlogin${param}`;
            }
          } else {
            if (userType === InUserType.INVESTOR) {
              return `${accountsUrl}/ngInvestor/#/transact/buy/exisitngfolio/foliodetails${param}`;
            } else {
              return `${accountsUrl}/advisor/#/transact/base/buy${param}`;
            }
          }
        }
      })
    );
  }

  private replaceNfoFundId(nfoInvestUrl: string, fundId: string): string {
    // replaces in order to frame URL with oneTis
    return nfoInvestUrl.replace(/OneTis_ID/g, fundId);
  }
}
