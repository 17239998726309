import { Component, Input, OnInit } from "@angular/core";
import { Component as BrComponent, Document, Page } from "@bloomreach/spa-sdk";
import { Logger } from "@utils/logger";

const logger = Logger.getLogger("DistributorOfferingsComponent");

@Component({
  selector: "ft-distributor-offerings",
  templateUrl: "./distributor-offerings.component.html",
  styleUrls: ["./distributor-offerings.component.scss"],
})
export class DistributorOfferingsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  distributorHeading: any;
  getReadMoreContent: any;

  constructor() {
  }

  ngOnInit(): void {
    this.distributorHeading = this.component?.getParameters().param;
  }

  get document() {
    const {document} = this.component?.getModels() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData();
  }

  get title() {
    return this.content?.dataInterval;
  }

  get preTitle() {
    return this.content?.displayName;
  }

  get linkLists() {
    return this.content?.carouselCompoundType;
  }

  setReadMorePopupContent(popupContent) {
    this.getReadMoreContent = popupContent;
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return "";
  }

  get isPreview() {
    return this.page.isPreview();
  }
}
