import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss'],
})
export class CommonComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  tabCount = 0;
  contentType = '';

  constructor() {}

  ngOnInit(): void {
    this.tabCount = this.page?.getComponent().getParameters().tabCount;
    this.contentType = this.component?.getParameters().contentType;
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }
}
