import { AfterViewInit, Component, Input, } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { CommonService } from "@services/common.service";

const logger = Logger.getLogger('RichTextComponent');

@Component({
  selector: 'ft-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent implements AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  showCopyDialoge = false;
  linkToCopy: string;

  constructor(
    private commonService: CommonService
  ) {
  }

  ngAfterViewInit() {
    //Podcast
    const copyD = document.querySelector('.copy-class1');
    const copyM = document.querySelector('.copy-class2');
    const closeBtn = document.querySelector('#copy-box-main .close-btn');
    copyD?.addEventListener('click', () => {
      this.writeURLToClip();
    });
    copyM?.addEventListener('click', () => {
      this.writeURLToClip();
    });
    closeBtn?.addEventListener('click', () => {
      (document.getElementById('copy-box-main') as HTMLInputElement).style.display = 'none';
    });

    //Digital Touch-points
    const kmBtn = document.querySelector('.touch-points-knowmore');
    const vLBtn = document.querySelector('.touch-points-viewless');
    const vmSection = document.querySelector('.touch-points-viewmore');

    kmBtn?.addEventListener('click', () => {
      vmSection.removeAttribute("style");
    });
    vLBtn?.addEventListener('click', () => {
      vmSection.setAttribute("style", "display:none");
    });
  }

  async writeURLToClip(): Promise<void> {
    const copyLink = document.querySelector('.copy-text-goes-here');
    (document.getElementById('copy-box-main') as HTMLInputElement).style.display = 'block';
    copyLink.innerHTML = location.href;
    this.linkToCopy = location.href;
    try {
      await navigator.clipboard.writeText(this.linkToCopy);
      logger.info("Link copied to clipboard:", this.linkToCopy);
    } catch (err) {
      logger.error("Unable to copy to clipboard", err);
    }
  }

  get document() {
    const {document} = this.component?.getModels() || {};
    return document && this.page?.getContent<Document>(document);
  }

  get content() {
    return this.document?.getData().contentBlocks;
  }

}
