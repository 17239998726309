<section class="FT-footer-pages FT-knowledge">
  <div class="container">
    <div class="media-contact">
      <div class="row">
        <div class="col-lg-6">
          <div class="contact-box">
            <h3>{{ "products.fundCompareInvestors" | translate }}</h3>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.phone-logo' | translate }}"/>
              <div>
                {{ "ftiGeneric.investors-contact" | translate }}
              </div>
            </div>
            <div class="d-flex mb-0">
              <p class="text-small">
                {{ "ftiGeneric.investors-time" | translate }}
              </p>
            </div>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.whatsapp-logo' | translate }}"/>
              <div>
                <div>
                  <a href="https://api.whatsapp.com/send/?phone=91{{ 'ftiGeneric.investors-WhatsApp-number' | translate }}&text=Hi&type=phone_number&app_absent=0">{{ "ftiGeneric.investors-WhatsApp-number" | translate }}</a>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.envelope-logo' | translate }}"/>
              <div>
                <a href="mailto:{{ 'ftiGeneric.investors-email' | translate }}"> {{ "ftiGeneric.investors-email" | translate }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="contact-box">
            <h3>{{ "literature.DISTRIBUTORS" | translate }}</h3>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.phone-logo' | translate }}"/>
              <div>
                {{ "ftiGeneric.distributors-contact" | translate }}
              </div>
            </div>
            <div class="d-flex mb-0">
              <p class="text-small">
                {{ "ftiGeneric.distributors-time" | translate }}
              </p>
            </div>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.whatsapp-logo' | translate }}"/>
              <div>
                <a href="https://api.whatsapp.com/send/?phone=91{{ 'ftiGeneric.distributors-WhatsApp-number' | translate }}&text=Hi&type=phone_number&app_absent=0">{{ "ftiGeneric.distributors-WhatsApp-number" | translate }}</a>
              </div>
            </div>
            <div class="d-flex">
              <img alt="image" src="{{ 'ftiGeneric.envelope-logo' | translate }}"/>
              <div>
                <a href="mailto:{{ 'ftiGeneric.distributors-email' | translate }}"> {{ "ftiGeneric.distributors-email" | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
