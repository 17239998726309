import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Component as BrComponent, Page, Document } from "@bloomreach/spa-sdk";
import { getPagination } from '@utils/pagemodel-utils';
import { Logger } from "@utils/logger";
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

const logger = Logger.getLogger("MarketingCategoryComponent");

@Component({
  selector: 'ft-marketing-category',
  templateUrl: './marketing-category.component.html',
  styleUrls: ['./marketing-category.component.scss']
})
export class MarketingCategoryComponent implements OnInit {

  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;
  pagination: any;
  getData: any = [];
  allPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  start = 0;
  end = 8;
  componentDestroyed$: Subject<boolean> = new Subject();

  showIconForWatchlist1: boolean[] = [];
  showIconForWatchlist2: boolean[] = [];
  showTooltip: boolean = false;
  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary left-arrow'><i class='bi bi-arrow-left-short'></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  };
  relatedData: any = [
    {
      "id": 1,
      "imgSrc": "https://franklintempletonprod.widen.net/content/dzqnspzeqz/jpeg/fti_blog_thumb_01.jpeg",
      "timeTag": "",
      "views": "",
      "wishlistCount": "",
      "title": "Emerging Markets",
      "target": "https://emergingmarkets.blog.franklintempleton.com/"
    },
    {
      "id": 2,
      "imgSrc": "https://franklintempletonprod.widen.net/content/bodveddyle/jpeg/fti_blog_thumb_03.jpeg",
      "timeTag": "",
      "views": "",
      "wishlistCount": "",
      "title": "Beyond Bulls & Bears",
      "target": "https://global.beyondbullsandbears.com/"

    },
    {
      "id": 3,
      "imgSrc": "https://franklintempletonprod.widen.net/content/t5e0bm59zq/jpeg/ind-blog-icon-63-by-63.jpeg",
      "timeTag": "",
      "views": "",
      "wishlistCount": "",
      "title": "It’s All in the Mind !",
      "target": "KC"
    },
  ]
  showIconForWishlist: boolean[] = [];
  showIconForWatchlist: boolean[] = [];


  constructor(
    private searchService: FtsearchService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.relatedData.forEach(() => {
      this.showIconForWishlist.push(true);
      this.showIconForWatchlist.push(true);
    });

    this.contentType = this.component?.getParameters().contentType;
    this.getFTIALLData("latest-commentaries", 0, 6);
    this.content();
  }

  get params() {
    return this.component?.getParameters();
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  content() {
    if (!this.pagination) {
      this.pagination = getPagination(this.page, this.component);
    }
    return this.pagination;
  }

  getFormatedDate(date) {
    let timeDate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-US', {month: "long", day: "2-digit", year: "numeric"});
    const formattedDate = formatter.format(timeDate);
    return formattedDate
  }

  blogLinks(card: any) {
    if (card !== 'KC') {
      window.open(card, "_blank");
    } else {
      this.router.navigate(["/knowledge-centre/quick-learn/blog"]);
    }
  }

  categoryOnClick(card: any) {
    if (!card?.pdfUrl) {
      this.router.navigate([this.page.getUrl(card?.url)]);
    }
    if (card?.pdfUrl) {
      window.open(card?.data?._source?.pdfURL, "_blank");
    }
  }

  private getFTIALLData(pageType, start, end) {
    if (start === 0) {
      this.allPages.results = [];
      this.getData = [];
    }
    this.end = end;
    const filterData = [
      {fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles', 'INDArticleDetails']},
      {fieldName: 'pageType', fieldValue: [pageType]},
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.allPages.results = data['results']?.response?.hits['hits'] || [];
        this.allPages.count = this.allPages.results.length;
        this.allPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          if (article._source.articleType === "Article") {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pdfUrl: article?._source?.pdfURL,
              pageTitle: `${pageTitle}`,
            });
          } else {
            this.getData.push({
              data: article,
              url: article._source.navigationUrl,
              image: article._source.image,
              pageTitle: `${pageTitle}`,
            });
          }
        });
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }


  toggleIconForWishlist(index: number) {
    this.showIconForWishlist[index] = !this.showIconForWishlist[index];
  }

  toggleIconForWatchlist(index: number) {
    this.showIconForWatchlist[index] = !this.showIconForWatchlist[index];
  }

  toggleTooltip() {
    this.showTooltip = false;
  }
}
