import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { Logger } from '../logger';

const logger = Logger.getLogger('moment-il8n');

/**
 * Locale Month Year format for moment
 * @param locale = null
 */
export function momentMonthYearFormat(locale: string = null): string {
  let longDateFormat;
  try {
    if (!locale) {
      locale = moment.locale();
    }
    longDateFormat = moment.localeData(locale).longDateFormat('L');
  } catch (e) {
    logger.debug('Locale not configured. Uses default [en-us]');
    longDateFormat = moment.localeData('en').longDateFormat('L');
  }
  return longDateFormat.replace(/((DD|D|dd|d)[-./])|([-./](DD|D|dd|d)$)/, '');
}

/**
 * Give Locale Time, e.g. when given 6 + yy(years) = 6 years(en-gb) or 6 Jahren(de-de)
 * @param locale e.g. de-de
 * @param amountOfUnit e.g. 6
 * @param units e.g. yy (years) in momentjs https://momentjs.com/docs/#/i18n/locale-data/
 * @returns output e.g. 6 Jahren
 */
export function momentTimePeriodFormat(
  locale: string,
  amountOfUnit: number,
  units: moment.RelativeTimeKey,
  isFuture: boolean
): string {
  let timePeriodFormat;
  try {
    if (!locale) {
      locale = moment.locale();
    }
    timePeriodFormat = moment
      .localeData(locale)
      .relativeTime(amountOfUnit, true, units, isFuture);
  } catch (e) {
    logger.debug('Locale not configured. Uses default [en-us]');
    timePeriodFormat = moment
      .localeData('en')
      .relativeTime(amountOfUnit, true, units, isFuture);
  }
  return timePeriodFormat;
}

/**
 * locale code [2 digit ISO-639 language][dash + optional ISO 3166 country code]
 */
export function setLocale(newLocaleCode: string) {
  const returnLocaleCode = moment.locale(newLocaleCode);
  if (newLocaleCode.indexOf(returnLocaleCode) === -1) {
    // WORKAROUND: this code runs before Aurelia logging is setup hence requires a actual console.error
    // rather than a logger.error
    // eslint-disable-next-line no-console
    console.error(`unknown locale '${newLocaleCode}' defaulting to english`);
    moment.locale('en');
  }
}

let CACHED_DATES = {};

/**
 * cached 6 months ago
 * cached because moment is very, very slow (few ms per eval)
 * @returns 6 months ago in std format
 */
export function getCached6MonthsAgoStd(): string {
  if (!CACHED_DATES['6_MONTHS']) {
    CACHED_DATES['6_MONTHS'] = moment()
      .subtract(6, 'months')
      .format('YYYY-MM-DD');
  }
  return CACHED_DATES['6_MONTHS'];
}

/**
 * cached 1 year ago
 * cached because moment is very, very slow (few ms per eval)
 * @returns 1 year ago in std format
 */
export function getCached1YearAgoStd(): string {
  if (!CACHED_DATES['1_YEAR']) {
    CACHED_DATES['1_YEAR'] = moment().subtract(1, 'years').format('YYYY-MM-DD');
  }
  return CACHED_DATES['1_YEAR'];
}

