<!-- scroll top button-->
<div id="scrollTopBtn" class="scroll-top d-none">
  <a (click)="scrollToTop()" class="btn btn-primary btn-sm"><i
      class="bi bi-arrow-up"></i>{{ isMobileView ? '' : 'Top' }}
  </a>
</div>
<!-- contact us block -->
<div id="contact-block">
  <div class="contact-box" *ngIf="showContactBox">
    <div class="d-block" *ngIf="showContactBoxInner">
      <div class="d-flex">
        <div class="d-none d-md-block" data-bs-toggle="modal" (click)="sideWidgetGA('Call us')"
             data-bs-target="#speakModal">
          <div class="text-blue">{{ 'ftiGeneric.sidewidget-call-us-label' | translate }}</div>
        </div>
        <div class="d-md-none" data-bs-toggle="offcanvas" (click)="sideWidgetGA('Call us')"
             aria-controls="offcanvasBottom"
             data-bs-target="#offcanvasspeakModal">
          <div class="text-blue">{{ 'ftiGeneric.sidewidget-call-us-label' | translate }}</div>
        </div>
        <span class="box-icon">
          <img alt="call us" src='{{ "ftiGeneric.sidewidget-call-us-icon" | translate }}'>
        </span>
      </div>
      <div class="d-flex">
        <div class="d-none d-md-block" (click)="sideWidgetGA('Write to us')" data-bs-toggle="modal"
             data-bs-target="#emailModal">
          <div class="text-blue">{{ "ftiGeneric.sidewidget-write-to-us-label" | translate }}</div>
        </div>
        <div class="d-md-none" data-bs-toggle="offcanvas" (click)="sideWidgetGA('Write to us')"
             aria-controls="offcanvasBottom"
             data-bs-target="#offcanvasemailModal">
          <div class="text-blue">{{ "ftiGeneric.sidewidget-write-to-us-label" | translate }}</div>
        </div>
        <span class="box-icon">
          <img alt="write to us" src='{{ "ftiGeneric.sidewidget-write-to-us-icon" | translate }}'>
        </span>
      </div>
      <div class="d-flex">
        <div (click)="whatsAppUs()">
          <div class="text-blue">{{ "ftiGeneric.sidewidget-whatsapp-us-label" | translate }}</div>
        </div>
        <span class="box-icon">
          <img alt="whatsapp us"
               src="https://franklintempletonprod.widen.net/content/71hohfsyhg/original/en-in-new-whatsapp-sm.svg">
        </span>
      </div>
      <div class="d-flex chatbot-main">
        <div class="fti-globalChat" (click)="chatBotGA()">
          <div class="text-blue toggle-chat-icon"
               id="toggleChat" data-target="#ftiChat_bot">{{ "ftiGeneric.sidewidget-chat-with-us-label" | translate }}
          </div>
        </div>
        <span class="box-icon">
          <img alt="chat with us" src='{{ "ftiGeneric.sidewidget-chat-with-us-icon" | translate }}'>
        </span>
      </div>
      <div class="d-flex">
        <div class="d-none d-md-block" data-bs-toggle="modal" (click)="sideWidgetGA('Request a Callback')"
             data-bs-target="#callBackRequest">
          <small></small>
          <div class="text-blue">{{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}</div>
        </div>
        <div class="d-md-none" data-bs-toggle="offcanvas" (click)="sideWidgetGA('Request a Callback')"
             aria-controls="offcanvasBottom"
             data-bs-target="#offcanvascallBackRequest">
          <small></small>
          <div class="text-blue">{{ "ftiGeneric.sidewidget-req-call-back-label" | translate }}</div>
        </div>
        <span class="box-icon">
          <img alt="request a call" src='{{ "ftiGeneric.sidewidget-req-call-back-icon" | translate }}'>
        </span>
      </div>
    </div>
  </div>
  <button class="btn btn-primary headphone-close" aria-label="headphone Button" id="toggleBox" (click)="toggleShowContactBox()"
          [ngClass]="showContactBox ? 'opened' : ''"></button>

</div>

<!-- footer block -->
<footer class="position-relative">
  <ng-container [brManageMenuButton]="menuModel"></ng-container>
  <div class="container" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
    <div class="d-flex align-items-center justify-content-between" id="footer-head">
      <div>
        <span class="logo-img">
          <img src='{{ "ftiGeneric.footer-logo" | translate }}' alt="Franklin Templeton Investments"
               title="Franklin Templeton Investments">
        </span>
      </div>
      <div class="social-links" style="display: flex; gap: 10px;">
        <ng-container *ngFor="let socialmenu of menu.siteMenuItems[2].childMenuItems"
                      [ngTemplateOutlet]="socialMenu"
                      [ngTemplateOutletContext]="{socialmenu: socialmenu}">
        </ng-container>
      </div>
      <ng-template #socialMenu let-socialmenu="socialmenu">
        <a *ngIf="
                    socialmenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('externalUrl')"
           [attr.href]="socialmenu?.links?.site?.href"
           [attr.target]="socialmenu | isNewPage"
           (click)="setSocialMediaAnalytics(socialmenu?.name)">
          <img src="{{socialmenu.parameters['Icon']}}" alt="Franklin Templeton on {{ socialmenu?.name }}">
        </a>
      </ng-template>
    </div>
    <div class="row gx-xl-5">
      <ng-container
          *ngFor="let footerTopMenu of menu.siteMenuItems[0].childMenuItems; let i = index; ">
        <div class="col-md-4 col-12"
             *ngIf="!(footerTopMenu.parameters['Request parameter (key=value)'].includes( 'appInvestor') || footerTopMenu.parameters['Request parameter (key=value)'].includes( 'appAdvisor'))">
          <div class="light-box">
            <div class="d-none d-md-flex footer-title">{{ footerTopMenu.name }}</div>
            <div class="accordion row g-0 d-md-none" [id]="'more-links-btns'+i">
              <div class="accordion-item" style="border:0;">
                <h2 class="accordion-header" [id]="'heading'+i">
                  <button class="accordion-button collapsed accordion-button-style" type="button"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i"
                          aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                    {{ footerTopMenu.name }}
                  </button>
                </h2>
              </div>
            </div>
            <div class="row d-none d-md-flex">
              <div class="col-6" *ngFor="let footerSubMenu of footerTopMenu.childMenuItems;let j = index;">
                <div class="small-title"> {{ footerSubMenu?.name }}</div>
                <ng-container
                    [ngTemplateOutlet]="footerMenuItems"
                    [ngTemplateOutletContext]="{
                              footerTopMenu: footerTopMenu,
                              footerSubMenu: footerSubMenu,
                              class: 'analytics-link',
                              uclass:''}">
                </ng-container>
              </div>
            </div>
            <div [id]="'collapse'+i" class="accordion-collapse collapse"
                 [attr.aria-labelledby]="'heading'+i" data-bs-parent="#filterAccordion">
              <div class="accordion-body mt-1">
                <div class="row" style="gap:18px;">
                  <div class="col-12" *ngFor="let footerSubMenu of footerTopMenu.childMenuItems;let j = index;">
                    <div class="accordion row g-0 d-md-none" [id]="'more-links-btns'+i">
                      <div class="accordion-item" style="border:0;">
                        <h2 class="accordion-header"
                            id="{{'subheading'+i+j}}">
                          <button class="accordion-button collapsed accordion-button-style"
                                  type="button" style="padding: 0 20px;font-size:0.938rem;"
                                  data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#collapse'+i+j"
                                  aria-expanded="false"
                                  [attr.aria-controls]="'collapse'+i+j">
                            {{ footerSubMenu?.name }}
                          </button>
                        </h2>
                      </div>
                    </div>
                    <div [id]="'collapse'+i+j"
                         class="accordion-collapse collapse"
                         [attr.aria-labelledby]="'subheading'+i+j">
                      <div class="container accordion-body mt-3">
                        <ng-container
                            [ngTemplateOutlet]="footerMenuItems"
                            [ngTemplateOutletContext]="{
                              footerTopMenu: footerTopMenu,
                              footerSubMenu: footerSubMenu,
                              class: 'analytics-link',
                              uclass:'sub-ul' }">
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <ng-container *ngFor="
                let footerLastTopMenu of menu.siteMenuItems[0].childMenuItems;let i = index; ">
        <ng-container
            *ngIf="footerLastTopMenu.parameters['Request parameter (key=value)'].includes( 'appInvestor') || footerLastTopMenu.parameters['Request parameter (key=value)'].includes( 'appAdvisor')">
          <ng-container
              *ngIf="segmentSelected ==='Distributor' && !footerLastTopMenu.parameters['Request parameter (key=value)'].includes( 'appInvestor')"
              [ngTemplateOutlet]="footerLastMenu">
          </ng-container>
          <ng-container
              *ngIf="segmentSelected !=='Distributor' && !footerLastTopMenu.parameters['Request parameter (key=value)'].includes( 'appAdvisor')"
              [ngTemplateOutlet]="footerLastMenu">
          </ng-container>
          <ng-template #footerLastMenu>
            <div class="col-12">
              <div class="light-box">
                <div class="footer-title d-none d-md-flex">{{ footerLastTopMenu.name }}</div>
                <div class="accordion row g-0 d-md-none" [id]="'more-links-btns'+i">
                  <div class="accordion-item" style="border:0;">
                    <h2 class="accordion-header" [id]="'heading'+i">
                      <button class="accordion-button collapsed accordion-button-style" type="button"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i"
                              aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                        {{ footerLastTopMenu.name }}
                      </button>
                    </h2>
                  </div>
                </div>
                <div [id]="'collapse'+i" class="accordion-collapse collapse"
                     [attr.aria-labelledby]="'heading'+i" data-bs-parent="#filterAccordion">
                  <div class="accordion-body">
                    <div class="row" style="gap:18px;">
                      <div class="col-12"
                           *ngFor="let footerSubMenu of footerLastTopMenu.childMenuItems;let j = index;">
                        <div class="accordion row g-0 d-md-none" [id]="'more-links-btns'+i">
                          <div class="accordion-item" style="border:0;">
                            <h2 class="accordion-header"
                                id="{{'subheading'+i+j}}">
                              <button class="accordion-button collpased accordion-button-style collapsed"
                                      type="button" style="padding: 0 20px;font-size:0.938rem;"
                                      data-bs-toggle="collapse"
                                      [attr.data-bs-target]="'#collapse'+i+j"
                                      aria-expanded="false"
                                      [attr.aria-controls]="'collapse'+i+j">
                                {{ footerSubMenu?.name }}
                              </button>
                            </h2>
                          </div>
                        </div>
                        <div [id]="'collapse'+i+j"
                             class="accordion-collapse collapse"
                             [attr.aria-labelledby]="'subheading'+i+j">
                          <div class="container accordion-body mt-3">
                            <ng-container
                                [ngTemplateOutlet]="footerMenuItems"
                                [ngTemplateOutletContext]="{
                              footerTopMenu: footerLastTopMenu,
                              footerSubMenu: footerSubMenu,
                              class: 'analytics-link',
                              uclass:'sub-ul'}">
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-none d-md-flex justify-content-around">
                  <div class="col-lg-2 col-md-4 col-6"
                       *ngFor="let footerSubMenu of footerLastTopMenu.childMenuItems;">
                    <div class="small-title"> {{ footerSubMenu?.name }}</div>
                    <ng-container
                        [ngTemplateOutlet]="footerMenuItems"
                        [ngTemplateOutletContext]="{
                              footerTopMenu: footerLastTopMenu,
                              footerSubMenu: footerSubMenu,
                              class: 'analytics-link',
                              uclass:''}">
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
    <ng-template
        #footerMenuItems
        let-class="class"
        let-uclass='uclass'
        let-footerTopMenu="footerTopMenu"
        let-footerSubMenu="footerSubMenu"
        let-noSecondLevel="noSecondLevel">
      <ul class="{{ uclass }}">
        <li *ngFor="let submenu of footerSubMenu?.childMenuItems">
          <a *ngIf="submenu.parameters['Request parameter (key=value)'].includes('externalUrl')"
             [attr.href]="submenu?.links?.site?.href"
             [attr.target]="submenu | isNewPage"
             class="{{ class }}"
             (click)="$event.stopPropagation(); setFooterAnalytics(noSecondLevel,footerTopMenu,footerSubMenu,submenu)">
            {{ submenu?.name }}
          </a>
          <a *ngIf=" submenu.parameters['Request parameter (key=value)'].includes('redirectGuestUrl')"
             [attr.href]=" appStateService.getftiAccountsUrl()+submenu?.links?.site?.href"
             [attr.target]="submenu | isNewPage" class="{{ class }}"
             (click)="$event.stopPropagation(); setFooterAnalytics(noSecondLevel,footerTopMenu,footerSubMenu,submenu)">
            {{ submenu?.name }}
          </a>
          <a *ngIf="!submenu.parameters['Request parameter (key=value)'].includes('externalUrl') &&
                  !submenu.parameters['Request parameter (key=value)'].includes('redirectGuestUrl') &&
                  !(submenu.parameters['Request parameter (key=value)'] | IsDataTarget)"
             [routerLink]="submenu?.links?.site?.href"
             [attr.target]="submenu | isNewPage" class="{{ class }}"
             (click)="$event.stopPropagation(); setFooterAnalytics(noSecondLevel,footerTopMenu,footerSubMenu,submenu)">
            {{ submenu?.name }}
          </a>
          <ng-container *ngIf="submenu.parameters['Request parameter (key=value)'] | IsDataTarget">
            <a *ngIf="!isMobileView else mobileView" href="#" data-bs-toggle="modal" class="{{ class }}"
               [attr.data-bs-target]="submenu.parameters['Request parameter (key=value)']| DataTargetValue"
               (click)="$event.stopPropagation(); setFooterAnalytics(noSecondLevel,footerTopMenu,footerSubMenu,submenu)">
              {{ submenu?.name }}
            </a>
            <ng-template #mobileView>
              <a href="#" data-bs-toggle="offcanvas" aria-controls="offcanvasBottom" class="{{ class }}"
                 [attr.data-bs-target]="submenu.parameters['Request parameter (key=value)']| DataTargetVal"
                 (click)="$event.stopPropagation(); setFooterAnalytics(noSecondLevel,footerTopMenu,footerSubMenu,submenu)">
                {{ submenu?.name }}
              </a>
            </ng-template>
          </ng-container>
        </li>
      </ul>
    </ng-template>

    <div class="row py-32">
      <div class="col-lg-3 col-6" *ngFor="let carrerMenu of carrersMenuGrouped">
        <ul>
          <li *ngFor="let carrMenuItem of carrerMenu">
            <ng-container
                [ngTemplateOutlet]="careerMenu"
                [ngTemplateOutletContext]="{ careermenu: carrMenuItem }">
            </ng-container>
          </li>
        </ul>
      </div>
      <ng-template #careerMenu let-careermenu="careermenu">
        <a
            *ngIf="
                    careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('externalUrl')
                  "
            [attr.href]="careermenu?.links?.site?.href"
            [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
            (click)="setCareersAnalytics(undefined, careermenu)"
        >{{ careermenu?.name }}</a>
        <a
            *ngIf="
                    careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('redirectGuestUrl')
                  "
            [attr.href]="
                    appStateService.getftiAccountsUrl() +
                    '/' +
                    careermenu?.links?.site?.href
                  "
            [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
            [routerLink]="page.getUrl(careermenu?.links?.site?.href)"
            (click)="setCareersAnalytics(undefined, careermenu)"
        >{{ careermenu?.name }}</a>
        <a
            *ngIf="
                    !careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('externalUrl') &&
                    !careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('redirectGuestUrl')
                  "
            [routerLink]="careermenu?.links?.site?.href"
            [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
            (click)="setCareersAnalytics(undefined, careermenu)"
        >{{ careermenu?.name }}</a>
      </ng-template>
    </div>
    <!-- Content visible on mobile only -->
    <div class="row py-32 info justify-content-between d-md-none">
      <div class="col-lg-auto col-md-6 d-md-block mb-lg-0 mb-sm-3 Registered-office-address-box">
        <div class="text-big fontResize text-big-imp">{{ "ftiGeneric.footer-reg-office-label" | translate }}</div>
        <div class="text-bold fontResize" id="Registered-office-address">
          <!--{{ "ftiGeneric.footer-trademark-label" | translate }} <br/>-->{{ "ftiGeneric.fotter-ftt-address" | translate }}
        </div>
      </div>
      <div class="col-lg-auto col-md-6 col-12 mb-lg-0 mb-sm-3">
        <div class="text-big fontResize text-big-imp">{{ "ftiGeneric.footer-for-queries-label1" | translate }} <span
            class="d-md-inline-block d-none fontResize">{{ "ftiGeneric.footer-for-queries-label2" | translate }}</span>
          <span class="d-md-none fontResize">{{ "ftiGeneric.footer-for-queries-label2" | translate }}</span></div>
        <div class="text-bold fontResize">{{ "ftiGeneric.footer-grievance-email" | translate }}</div>
      </div>
      <div class="col-lg-auto col-sm-4 col-12 mb-lg-0 mb-sm-3">
        <div class="text-big fontResize text-big-imp">{{ "ftiGeneric.footer-tel-no-label" | translate }}</div>
        <div class="text-bold fontResize">{{ "ftiGeneric.footer-tel-no" | translate }}</div>
      </div>
      <div class="col-lg-auto col-sm-4 col-12 mb-lg-0 mb-sm-3">
        <div class="text-big fontResize text-big-imp">{{ "ftiGeneric.footer-fax-no-label1" | translate }} <span
            class="d-md-inline-block d-none fontResize">{{ "ftiGeneric.footer-fax-no-label2" | translate }}</span></div>
        <div class="text-bold fontResize">{{ "ftiGeneric.footer-fax-no" | translate }}</div>
      </div>
      <div class="col-lg-auto col-sm-4 col-12 mb-lg-0 mb-sm-3">
        <div class="text-big fontResize text-big-imp">{{ "ftiGeneric.footer-cin-label" | translate }}</div>
        <div class="text-bold fontResize">{{ "ftiGeneric.footer-cin-no" | translate }}</div>
      </div>
    </div>
    <!-- Content visible on desktop -->
    <div class="row desktop-view text-center" style="padding:8px;">
      <div class="desktop-view pt-4">
        <span
            class="fontResize Copyright">{{ "ftiGeneric.footer-trademark-label" | translate }}</span>
      </div>
      <div class="desktop-view">
        <span
            class="fontResize Copyright ">{{ "ftiGeneric.footer-reg-office-label" | translate }}
          &nbsp;: {{ "ftiGeneric.fotter-ftt-address" | translate }}</span>
      </div>
      <div class="desktop-view">
        <span
            class="fontResize Copyright ">{{ "ftiGeneric.footer-cin-label" | translate }}
          &nbsp;: {{ "ftiGeneric.footer-cin-no" | translate }}</span>
      </div>
      <div class="desktop-view">
        <span
            class="fontResize Copyright ">
          {{ "ftiGeneric.footer-tel-no-label" | translate }} : {{ "ftiGeneric.footer-tel-no" | translate }}
          ; {{ "ftiGeneric.footer-fax-no-label1" | translate }} {{ "ftiGeneric.footer-fax-no-label2" | translate }}
          &nbsp;: {{ "ftiGeneric.footer-fax-no" | translate }}</span>
      </div>
      <div class="desktop-view">
        <span
            class="fontResize Copyright ">{{ "ftiGeneric.footer-for-queries-label1" | translate }} {{ "ftiGeneric.footer-for-queries-label2" | translate }}
          &nbsp;: {{ "ftiGeneric.footer-grievance-email" | translate }}</span>
      </div>
    </div>


    <div class="row text-center" style="padding:8px;">
      <div class="desktop-view">
        <!-- Content visible on desktop -->
        <span style="font-size:1.375rem;">{{ "ftiGeneric.footer-main-disclaimer" | translate }}</span>
      </div>
      <div class="mobile-view">
        <!-- Content visible on mobile only -->
        <span style="font-size:0.938rem">{{ "ftiGeneric.footer-main-disclaimer" | translate }}</span>
      </div>
    </div>
    <div class="copyrights footer-copyright">
      <div class="container">
        <!-- Content visible on desktop -->
        <div class="row desktop-view algin-items-center justify-content-between">
          <div class="col-auto">{{ "ftiGeneric.footer-trademark-label" | translate }}</div>
          <div
              class="col-auto">{{ "ftiGeneric.footer-copyright-label" | translate }} {{ fullYear }} {{ "ftiGeneric.footer-copyright-text" | translate }}
          </div>
        </div>
        <!-- Content visible on mobile only -->
        <div class="row mobile-view algin-items-center justify-content-between">
          <div
              class="col-auto">{{ "ftiGeneric.footer-copyright-label" | translate }} {{ fullYear }} {{ "ftiGeneric.footer-copyright-text" | translate }}
          </div>
          <div class="col-auto">{{ "ftiGeneric.footer-trademark-label" | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</footer>

