import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DashIfEmptyPipe } from './dash-if-empty.pipe';
import { IsExternalLinkPipe } from './is-external-link.pipe';
import { IsInternalLinkPipe } from './is-internal-link.pipe';
import { IsDataTargetPipe, DataTargetValuePipe, DataTargetValPipe } from './data-target.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { SpacingPipe } from './spacing.pipe';
import { InrSymbolPipe } from './inr-symbol.pipe';
import { IsNewPagePipe } from './is-new-page.pipe';
import { CurrencyFormatPipe, CurrencyNumberFormatIntPipe, CurrencyNumberFormatPipe } from './currency-format.pipe';

// Components
@NgModule({
  declarations: [
    DashIfEmptyPipe,
    SafeHtmlPipe,
    ToNumberPipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    SpacingPipe,
    InrSymbolPipe,
    IsDataTargetPipe,
    DataTargetValuePipe,
    DataTargetValPipe,
    IsNewPagePipe,
    CurrencyFormatPipe,
    CurrencyNumberFormatPipe,
    CurrencyNumberFormatIntPipe,
  ],
  imports: [],
  exports: [
    DashIfEmptyPipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    SafeHtmlPipe,
    ToNumberPipe,
    SpacingPipe,
    InrSymbolPipe,
    IsDataTargetPipe,
    DataTargetValuePipe,
    DataTargetValPipe,
    IsNewPagePipe,
    CurrencyFormatPipe,
    CurrencyNumberFormatPipe,
    CurrencyNumberFormatIntPipe,
  ],
  providers: [DashIfEmptyPipe, InrSymbolPipe],
})
export class PipesModule {
}
